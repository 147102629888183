import React from 'react';
import { Nav } from 'rsuite';

const NavRsuite = ({ list=[], active, onSelect, ...props }) => {
  return (
    <Nav {...props} activeKey={active} onSelect={onSelect}>
      {
        list.length > 0 ? list.map((item, i) => {
          return (
            <Nav.Item eventKey={item.key} key={`nav-items-${props.appearance}-${i}`} {...item}>
              {item.name}
            </Nav.Item>
          );
        }) : null
      }
    </Nav>
  );
};
export default NavRsuite;