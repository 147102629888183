import React, { Component } from 'react';
import Camera, { FACING_MODES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import '../../../styles/component.admin.getphoto.scss';

class GetPhoto extends Component {

    handlePhoto = (data) => {
        const { onTakePhoto } = this.props
        onTakePhoto(data)
    }
    render() {
        return (
            <div className="container-getphoto">
                <Camera onTakePhoto={this.handlePhoto} idealFacingMode = {FACING_MODES.ENVIRONMENT}/>
            </div>
        )
    }
}

export default GetPhoto