import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect } from 'react-router-dom';

import LayoutTwo from '../components/Layouts/Layout_Two';

import NotFound from '../components/NotFound'
import Historial from '../pages/Profile/Historial';
import DetailProfile from '../pages/Profile/Detail_Profile';
import DetailAddresses from '../pages/Profile/Detail_Addresses';
import history from '../services/history';
import { getBannerList } from '../redux/actions/banner';

class ProfileRouter extends Component {
	async componentDidMount() {
		const { getBanners } = this.props
		await getBanners(history.location.search)
	}
	render() {
		const { auth } = this.props;
		return (
			<React.Fragment>
				{
					auth.session ?
						<LayoutTwo>
							<Switch>
								<Route exact path="/perfil" component={DetailProfile} />
								<Route exact path="/perfil/datos" component={DetailProfile} />
								<Route exact path="/perfil/direcciones" component={DetailAddresses} />
								<Route exact path={['/perfil', '/perfil/pedidos']} component={Historial} />
								<Route component={NotFound} />
							</Switch>
						</LayoutTwo>
						: <Redirect to='/' />
				}
			</React.Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
	getBanners: (data) => dispatch(getBannerList(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileRouter);