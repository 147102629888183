import { Component } from "react";
import { connect } from "react-redux";
import { categorySchema } from '../../../utils/validations';
import Input from "../Input";
import Switch from "../Switch";
import Title from "../Title";
import validate from '../../../utils/validate';
import '../../../styles/component.admin.roles.scss';
import { doCreateRole } from "../../../redux/actions/role";
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { Modules } from "../../../services/api/endpoints";
import { v4 } from "uuid";

class EditRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputs: { nombre: '' },
            data: [],
            headers: [],
            errors: {}
        }
    }

    async componentDidMount() {
        const { auth } = this.props
        const headers = [
            {
                name: "Nombre",
                target: "name"
            },
            {
                name: "Ver",
                target: "view"
            },
            {
                name: "Editar",
                target: "edit"
            }
        ]
        const data = await Modules(auth.session.token);
        this.setState({
            headers,
            data: data.data
        })
    }

    handleToggleSwitch = (module, prop, e) => {
        const { data } = this.state
        const item = data.findIndex((m) => m.nombre === module);
        data[item][prop] = e.target.checked
        this.setState({ data })
    }

    handleChange = ({ target }) => {
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [target.name]: target.value } }, () => {
            const { errors } = this.state
            Object.keys(errors).forEach((key) => {
                if (key === target.name) {
                    delete errors[key]
                }
            })
            this.setState({ errors })
        })
    }

    handleSubmit = async () => {
        const { inputs } = this.state
        const validations = await validate(categorySchema, inputs, () => this._createRole())
        if (validations) {
            this.setState({ errors: validations })
        }
    }

    async _createRole() {
        const { inputs, data } = this.state
        const { createRole, auth, drawer, CLOSE_DRAWER } = this.props
        const permissions = {}
        data.forEach((m) => {
            permissions[m.nombre.toLowerCase()] = { view: m.view === undefined ? false : m.view, edit: m.edit === undefined ? false : m.edit }
        })
        await createRole({ nombre: inputs.nombre, data: permissions }, auth.session.token)
        CLOSE_DRAWER(drawer.RENDER);
    }

    render() {
        const { inputs, errors, headers, data } = this.state
        const { nombre } = inputs
        return (
            <div className="container-assign-user size mt-5">
                <Title text="Agregar Rol" />
                <div className="row justify-content-center px-md-2 px-1 mb-2">
                    <div className="col-12 mb-2">
                        <Input
                            label="Nombre"
                            placeholder="Nombre Rol"
                            name="nombre"
                            errors={errors}
                            value={nombre}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <Title text="Modulos" />

                <div className="row justify-content-center px-md-2 px-1 mb-2">
                    <div className="container-table">
                        <table className="table table-striped table-hover table-bordered" style={{ overflow: 'auto' }}>
                            <thead>
                                <tr>
                                    {headers && headers.map((header) => (
                                        <th className="text-center" scope="col" key={v4()}>
                                            {header.name}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.length > 0
                                    ? data.map((item, index) => (
                                        <tr key={v4()}>
                                            <td className="text-center" key={v4()}>
                                                {item.nombre}
                                            </td>
                                            <td className="text-center" key={v4()}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}><Switch checked={item.view} onChange={(event) => this.handleToggleSwitch(item.nombre, 'view', event)} /></div>
                                            </td>
                                            <td className="text-center" key={v4()}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}><Switch checked={item.edit} onChange={(event) => this.handleToggleSwitch(item.nombre, 'edit', event)} /></div>
                                            </td>
                                        </tr>
                                    ))
                                    : (<tr><td className="text-center">'Sin Data'</td></tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>



                <div className="row justify-content-center px-md-2 px-1 mb-2">
                    <div className="col-12 mb-2 d-flex justify-content-end">
                        <button className="btn btn-rioclaro" onClick={this.handleSubmit}>Guardar</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    drawer: state.drawer,
})
const mapDispatchToProps = (dispatch) => ({
    createRole: (data, token) => dispatch(doCreateRole(data, token)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component))
})
export default connect(mapStateToProps, mapDispatchToProps)(EditRole)