import { Component } from "react";
import { connect } from "react-redux";
import { CLEAR_RENDER, OPEN_DRAWER, SET_RENDER } from "../../../redux/actions/drawer";
import { SET_BANNER } from "../../../redux/actions/banner";
import EditBanner from "../Banners/EditBanner";

import '../../../styles/component.admin.actions.scss'
import BannerImage from "../Banners/BannerImage";
import { Notification } from "rsuite";

class BannersActions extends Component {

    hasPermission = () => {
        return JSON.parse(sessionStorage.getItem('modules'))['banners'].edit;
    }

    handleRender = (component) => {
        if (!this.hasPermission()) {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
            return;
        }
        const { OPEN_DRAWER, SET_RENDER, CLEAR_RENDER, SET_BANNER, item } = this.props
        SET_BANNER(item)
        CLEAR_RENDER()
        SET_RENDER(component === 'EDIT_BANNER' ? EditBanner : BannerImage)
        OPEN_DRAWER()
    }

    render() {
        return (
            <div className="container-product-actions">
                <i dataToggle="tooltip" title="Editar" className="fa fa-pencil" onClick={() => this.handleRender('EDIT_BANNER')}></i>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    CLEAR_RENDER: () => dispatch(CLEAR_RENDER()),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
    SET_BANNER: (payload) => dispatch(SET_BANNER(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(BannersActions)