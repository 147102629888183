import { handleActions } from 'redux-actions'
import { SET_DISCOUNT } from '../actions/discount'
import moment from 'moment'
export const INITIAL_STATE = {
    cantidad: 0,
    codigo: "",
    fecha_creacion: moment().format('YYYY-MM-DD'),
    fecha_expiracion: moment().format('YYYY-MM-DD'),
    valor: 0,
    state: false
}

export default handleActions({
    [SET_DISCOUNT]: (state, action) => {
        return { ...state, ...action.payload }
    }
}, INITIAL_STATE)