import { Component } from 'react'
import Table from '../../../components/Admin/Table'
import Title from '../../../components/Admin/Title'
import { CLOSE_DRAWER, OPEN_DRAWER, SET_RENDER } from '../../../redux/actions/drawer'
import { connect } from 'react-redux'
import Contract from '../../../components/Admin/Actions/Contracts'
import { END_LOADING, START_LOADING } from '../../../redux/actions/loader'
import '../../../styles/page.admin.contracts.scss'
import { getTextList } from '../../../redux/actions/text'
import history from '../../../services/history'
import { textheaders } from '../../../utils/constants'

class Contracts extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }

    async init() {
        const { START_LOADING, END_LOADING } = this.props
        START_LOADING();
        const responseApi = this.props.text?.data
        responseApi?.map((item) => {
            item.actions = <Contract id={item.id} item={{ nombre: item.nombre, texto: item.texto, id: item.id }} />
            delete item.contract
            return item
        })
        this.setState({ data: { headers: textheaders, items: responseApi } })
        END_LOADING();
    }

    async componentDidMount() {
        await this._showTextsList();
        this.init();
    }

    componentDidUpdate(prevProp) {
        if (prevProp.text?.data !== this.props.text?.data) this.init();
    }

    _showTextsList = async () => {
        const { getTexts, START_LOADING, END_LOADING } = this.props
        START_LOADING();
        await getTexts(history.location.search)
        END_LOADING();
    }

    render() {
        const { data } = this.state
        return (
            <div className="container-categories">
                <Title text="Textos Legales" />
                {data && data.items && data.items.length && (
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-2">
                        <Table data={data} />
                    </div>
                )}
                {(data !== {} && data.items && data.items.length === 0) && (
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-2 text-center">
                        No hay textos legales registrados...
                    </div>
                )}
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    drawer: state.drawer,
    text: state.text
})
const mapDispatchToProps = (dispatch) => ({
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
    getTexts: (data) => dispatch(getTextList(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Contracts)