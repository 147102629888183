import { Component } from "react";
import Image from "../../../components/Admin/Image";
import { CLEAR_RENDER, CLOSE_DRAWER, OPEN_DRAWER, SET_RENDER } from "../../../redux/actions/drawer";
import Images from '../../../assets/image';
import '../../../styles/DashboardStyles/Header.scss';
import { connect } from "react-redux";
import MenuAccount from '../MenuAccount';
import MenuMobile from '../MenuMobile';

class Header extends Component {

    openMenu = (menu) => {
        const { OPEN_DRAWER, SET_RENDER } = this.props
        switch (menu) {
            case 'account':
                SET_RENDER(MenuAccount)
                OPEN_DRAWER();
                break;
            case 'mobile':
                SET_RENDER(MenuMobile)
                OPEN_DRAWER();
                break;
            default:
                break;
        }
    }

    render() {
        const { Logo, Menu } = Images
        return (
            <header className="container-header">
                {/* Desktop */}
                <Image className="d-none d-md-block" src={Logo} alt="logo" style={{ width: '200px' }} />
                <div className="d-none d-md-block">
                    <button onClick={() => this.openMenu('account')} className="btn btn-drop">{this.props.auth?.session?.usuario?.nombre_usuario}</button>
                </div>

                {/* Mobile */}
                <Image className="d-block d-md-none" src={Logo} alt="logo" style={{ width: '130px' }} />
                <div className="d-block d-md-none">
                    <Image src={Menu} alt="menu" style={{ width: '27px' }} onClick={() => this.openMenu('mobile')} />
                </div>
            </header>
        )
    }
}

const mapStateToProps = (state) => ({
    drawer: state.drawer,
    auth: state.auth
})
const mapDispatchToProps = (dispatch) => ({
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    CLOSE_DRAWER: () => dispatch(CLOSE_DRAWER()),
    CLEAR_RENDER: () => dispatch(CLEAR_RENDER()),
    SET_RENDER: (payload) => dispatch(SET_RENDER(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)