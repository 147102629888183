import { createAction } from 'redux-actions'
import { Categories, CreateCategory, EditCategory, RemoveCategory } from '../../services/api/endpoints';

export const fetch_start_categories = createAction('fetch_start_categories');
export const fetch_success_categories = createAction('fetch_success_categories');
export const fetch_error_categories = createAction('fetch_error_categories');

export const getCategoriesList = (query = {}) => async (dispatch) => {
    dispatch(fetch_start_categories());
    try {
        const response = await Categories(query);
        console.log(response, 'RESPUESTA DE LISTADO DE CATEGORIAS');
        const { data } = response;
        dispatch(fetch_success_categories({ data }))
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_categories());
    }
};

export const doRemoveCategory = (params, id, token) => async (dispatch) => {
    dispatch(fetch_start_categories());
    try {
        await RemoveCategory(params, id, token);
        dispatch(getCategoriesList({}));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_categories());
        return false;
    }
}

export const doCreateCategory = (params, id, token) => async (dispatch) => {
    dispatch(fetch_start_categories());
    try {
        await CreateCategory(params, id, token);
        dispatch(getCategoriesList({}));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_categories());
        return false;
    }
}

export const doEditCategory = (params, id, token) => async (dispatch) => {
    dispatch(fetch_start_categories());
    try {
        await EditCategory(params, id, token);
        dispatch(getCategoriesList({}));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_categories());
        return false;
    }
}


export const SET_CATEGORY = createAction('SET_CATEGORY');