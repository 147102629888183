import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import ProfileRouter from './Profile_Router';
import { Contact, Changes, Sends, Terms, Us } from '../pages/Web';
import LayoutOne from '../components/Layouts/Layout_One';
import Scroll from '../components/Scroll';
import history from '../services/history';
import Checkout from '../pages/Checkout';
import Products from '../pages/Products';
import Detail from '../pages/Detail';
import Cart from '../pages/Cart';
import { Empty } from '../pages/Cart/Empty';
import PurchaseDetails from '../pages/Purchase_Details';
import Privacy from '../pages/Web/Privacy';
import { Login, Register, ResetPass, RecoveryPass } from '../pages/Auth/ManageAccess';
import Confirm from '../pages/Confirm';
import Dashboard_Router from './Dashboard_Router';
import SigIn from '../pages/Admin/SigIn';
import Loader from '../components/Admin/Loader'
import { END_LOADING } from '../redux/actions/loader';
import { getBannerList } from '../redux/actions/banner';

class AppRouter extends Component {

    async componentDidMount() {
        const { END_LOADING, getBanners } = this.props
        await getBanners(history.location.search)
        END_LOADING();
    }

    render() {
        history.listen(location => {
            ReactGA.set({ page: location.pathname }); // Update the user's current page
            ReactGA.pageview(location.pathname); // Record a pageview for the given page
        });

        const { auth } = this.props;
        const { location } = history
        const { pathname } = location
        const admin = pathname.indexOf('admin');
        if (admin === -1) {
            return (
                <Router history={history}>
                    <Scroll />
                    <LayoutOne>
                        <Switch>
                            <Route exact path="/" component={Products} />
                            <Route exact path="/compra" component={PurchaseDetails} />
                            <Route exact path="/carrito-con-productos" component={Cart} />
                            <Route exact path="/carrito" component={Empty} />
                            <Route exact path="/productos/id_producto/:slug_name" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega/:tipo_entrega" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega/:tipo_entrega/region" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega/:tipo_entrega/region/:region" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega/:tipo_entrega/region/:region/sucursal" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega/:tipo_entrega/region/:region/sucursal/:comuna" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega/:tipo_entrega/region/:region/sucursal/:comuna/formato" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega/:tipo_entrega/region/:region/sucursal/:comuna/formato/:formato" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega/:tipo_entrega/region/:region/comuna" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega/:tipo_entrega/region/:region/comuna/:comuna" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega/:tipo_entrega/region/:region/comuna/:comuna/formato" component={Detail} />
                            <Route exact path="/productos/id_producto/:slug_name/metodo_entrega/:tipo_entrega/region/:region/comuna/:comuna/formato/:formato" component={Detail} />
                            <Route exact path={["/perfil/*", "/perfil"]} component={ProfileRouter} />
                            <Route exact path="/finalizar-compra" component={Checkout} />
                            <Route exact path="/politicas-de-cambios-y-devoluciones" component={Changes} />
                            <Route exact path="/politicas-de-privacidad" component={Privacy} />
                            <Route exact path="/terminos-y-condiciones" component={Terms} />
                            <Route exact path="/puntos-de-venta" component={Contact} />
                            <Route exact path="/despachos" component={Sends} />
                            <Route exact path="/actualizar-contraseña/:token" component={RecoveryPass} />
                            <Route exact path="/confirmar_cuenta/:token" component={Confirm} />
                            <Route exact path="/nosotros" component={Us} />
                            {
                                !auth.session ?
                                    <>
                                        <Route exact path="/ingresar" component={Login} />
                                        <Route exact path="/registro" component={Register} />
                                        <Route exact path="/recuperar-contraseña" component={ResetPass} />
                                    </>
                                    :
                                    <Redirect to='/' />
                            }
                        </Switch>
                    </LayoutOne>
                </Router>
            )
        } else {
            return (
                <Router history={history}>
                    <Scroll />
                    <Loader />
                    <Switch>
                        <Route exact path="/admin" component={SigIn} />
                        <Route exact path={["/admin/dashboard", "/admin/dashboard/*"]} component={Dashboard_Router} />
                    </Switch>
                </Router>
            )
        }

    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    loader: state.loader
});

const mapDispatchToProps = (dispatch) => ({
    END_LOADING: () => dispatch(END_LOADING()),
    getBanners: (data) => dispatch(getBannerList(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);