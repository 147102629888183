/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from "react-redux"

import '../../../styles/component.admin.loader.scss'

const Loader = () => {
    const { loader } = useSelector((state) => state)
    const { loading } = loader

    return (
        <div className={`container-loader ${loading ? 'd-flex' : 'd-none'}`}>
            <div className="spinner-border text-light" style={{ width: '80px', height: '80px' }} role="status" />
        </div>
    )
}

export default Loader