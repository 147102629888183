import React from 'react';
import { Link } from 'react-router-dom';

const FooterTerms = () => (
    <div className="my-4">
        <div className="d-block d-sm-none text-center">
            <Link to="/terminos-y-condiciones" style={{fontSize: '.8em', color: 'black'}}>
                Ver términos y Condiciones de Uso.
            </Link>
        </div>
        <div className="d-none d-sm-flex justify-content-end w-100">
            <Link to="/terminos-y-condiciones" style={{fontSize: '.8em', color: '#f04e28'}}>
                Ver términos y Condiciones de Uso.
            </Link>
        </div>
    </div>
);

export default FooterTerms;