import { Component } from "react";
import history from '../../../services/history'

class Exit extends Component {

    componentDidMount() {
        history.push('/admin')
    }
    render() {
        return <></>
    }
}

export default Exit