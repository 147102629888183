import { Component } from "react";
import { v4 } from 'uuid'
import '../../../styles/component.admin.uploader.scss'
import { Radio, RadioGroup } from "rsuite";
const linkApi = process.env.REACT_APP_ENV_API_URL

class Uploader extends Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    render() {
        const { data, onChange, handleRemove, label, noRemove, noMultiple, errors, name, selectFirst, onFirstChange, isBanner } = this.props
        return (
            <>
                <div className={errors && errors[name] && errors[name].length > 0 ? 'container-uploader border border-danger d-flex p-3' : 'container-uploader border d-flex p-3'}>
                    <RadioGroup defaultValue={0} onChange={onFirstChange} style={{ display: "flex" }}>
                        {data && data.map((image, index) => (
                            <>
                                {selectFirst && <Radio value={index}>Principal</Radio>}
                                <div className="container-uploader__image" key={`uploader-${index}`}>
                                    <img src={image.new ? image.src : `${linkApi}/imagenes/${isBanner ? 'banners': 'productos'}/${image.src}`} alt="uploader" />
                                    {!noRemove && <i className="fa fa-times-circle" onClick={() => handleRemove(image.id)}></i>}
                                    <input type="file" onChange={(e) => { onChange(e); e.target.value = null }} data-reference={image.id} name={name} accept=".png,.jpg,.jpeg,.gif" />
                                </div>
                            </>
                        ))}
                    </RadioGroup>
                    <div className="container-uploader__empty">
                        {label || 'Elija una Imagen'}
                        <input type="file" onChange={(e) => { onChange(e); e.target.value = null }} multiple={noMultiple ? false : true} name={name} accept=".png,.jpg,.jpeg,.gif" />
                    </div>
                </div>
                {errors && errors[name] && errors[name].length > 0 && (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {errors[name].map((error) => (
                            <div className="invalid" key={v4()}>
                                {error}
                            </div>
                        ))}
                    </div>
                )}
            </>
        )
    }
}

export default Uploader