import React, { Component } from 'react';
import AssetsImg from '../../assets/image';
import { Link } from 'react-router-dom';
import * as Api from '../../services/api/endpoints';
import { Loader } from 'rsuite';

class Reset extends Component{
    state = {
        inputs: {
            correo: '',
        },
        btnDisabled: false,
        loading: false,
        success: false,
        error: false
    }

    _handleInput = (e) => {
        let { inputs } = this.state;
        this.setState({ inputs: {...inputs, [e.target.name] : e.target.value} })
    }

    _handleSubmit = async () => {
        const { inputs } = this.state;
        this.setState({ btnDisabled: true, loading: true });
        inputs.correo = inputs.correo.toLowerCase();
        await Api.ResetPassword(inputs)
        .then(() => {
            this.setState({ btnDisabled: false, success: true, loading: false });
        })
        .catch((e) => {
            this.setState({ btnDisabled: false, success: false, loading: false, error: e });
        })
    }

    render(){
        const { collapse, mobile } = this.props;
        const { inputs, btnDisabled, error, success, loading } = this.state;
        if (success) {
            return(
                <div className="row justify-content-lg-center">
                    <div className="col-12 text-center mb-0">
                        <img src={AssetsImg.MailReset} alt="..." className="img-fluid icon-msg"/>
                        <h5 className="mb-2">
                            <strong>Mensaje enviado</strong>
                            <br/>
                            <small>Revisa tu correo y restablece tu contraseña</small>
                        </h5>
                        {
                            mobile && <Link to="/ingresar" className="btn bg-orange text-white font-weight-bold">Ingresa a tu cuenta</Link>
                        }
                    </div>
                </div>
            )
        }

        return(
            <div className="row justify-content-lg-center">
                <div className="col-12 text-center mb-4">
                    <img src={AssetsImg.Logo} alt="..." className="w-75"/>
                </div>
                <h4 className="text-center my-4 font-weight-bold w-100">Recuperar Contraseña</h4>
                <form className="col-lg-8 mt-4" onSubmit={e => e.preventDefault()}>
                    <div className="mb-3">
                        <input
                            name="correo"
                            type="email"
                            className="form-control"
                            placeholder="Correo electrónico"
                            onChange={this._handleInput}
                            value={inputs.correo}
                        />
                    </div>
                    <button disabled={btnDisabled} type="submit" className="btn btn-success w-100 font-weight-bold" onClick={this._handleSubmit}>ENVIAR</button>
                    {error && <p className="text-center mt-1 mb-0">{error}</p>}
                    {!collapse && (
                        <Link to="/ingresar" className="text-center w-100">
                            <p className="mt-4" style={{ fontSize: '.9em' , color: 'black'}}>Volver</p>
                        </Link>
                    )}
                    {loading && <Loader backdrop content="Cargando..." vertical style={{zIndex:4}}/>}
                </form>
            </div>
        )
    }
}

export default Reset;