import React from 'react';
import { Radio } from 'rsuite';
import AssetsImg from '../../../assets/image';

const StepPayment = () => {
	return(
		<div className="py-3">
			<div className="form-chekout row">
		  		<div className="form-group mb-2 col-12">
					<div className="row mx-0 mb-3 justify-content-between cart-checkbox w-100">
						<Radio className="col-8 col-lg-auto mx-0 px-0" checked>
							Tarjeta de débito / crédito
							<img src={AssetsImg.Webpay}  alt="..." style={{ width: '50px' }}/>
						</Radio>
					</div>
	   			</div>
	  		</div>
	  	</div>
	)
}
export default StepPayment;