import React from 'react';
import { Loader } from 'rsuite';

const LoaderRsuite = (props) => {
	const { loading, fail, children } = props
	if (loading.run) {
		return(
			<div className="container-lg py-5 bg-white">
	   		<div className="row py-5">
		   		<div className="col-12">
		   			<Loader
		   				size="lg"
		   				backdrop
		   				content={loading.title}
		   				vertical
		   				className="position-relative w-100"
		   			/>
		   		</div>
	   		</div>
			</div>
		);
	} else if (fail.run) {
		return(
			<div className="container-lg py-5">
				<h2 className="text-center pt-5 mt-5">
					{fail.title}
				</h2>
				<p className="text-center pb-5 mb-5">
					{props.fail.description}
				</p>
			</div>
		);
	}else{
		return(
			<>
				{children}
			</>
		);
	}
}
export default LoaderRsuite;