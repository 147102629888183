import React, { Component } from 'react'
import { connect } from "react-redux";
import { v4 } from 'uuid'
import { END_LOADING, START_LOADING } from "../../redux/actions/loader";
import '../../styles/component.followup.scss'
class FollowUp extends Component {


    state = {
        code: ''
    }

    _onSearch = (e) => {
        const { START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const { handleSearch } = this.props;
        const { code } = this.state
        handleSearch(e, code)
        this.setState({ code: '' })
        END_LOADING()
    }
    render() {
        const { show, handleClose, data, search } = this.props
        const { code } = this.state
        return (
            <div className={`${show ? 'container-followup' : 'd-none'}`}>
                <div className="container-followup__content">
                    <header className="mb-3">
                        <span>{search ? 'Seguimiento' : `Pedido #${data.id}`}</span>
                        <span onClick={handleClose}>x</span>
                    </header>
                    {search ? (
                        <div className="container-followup__content__section">
                            <div className="input-group mb-3">
                                <input type="text" className="form-control" placeholder="Código de seguimiento" value={code} onChange={(e) => this.setState({ code: e.target.value })} />
                                <div className="input-group-append">
                                    <span className="input-group-text" id="basic-addon2">
                                        <span onClick={this._onSearch}><i className="fa fa-search"></i></span>
                                    </span>
                                </div>
                            </div>

                        </div>
                    ) : (
                        <div className="container-followup__content__section">
                            <h4 className="mb-5">Seguimiento</h4>

                            {data && data.status && data.status.map((item) => (
                                <div className="container-followup__content__section__step" key={v4()}>
                                    <div className="container-followup__content__section__step__items">
                                        <div className="container-followup__content__section__step__items__item">{item.created_at}</div>
                                        <div className="container-followup__content__section__step__items__item">{item.status}</div>
                                    </div>

                                </div>
                            ))}
                        </div>
                    )}

                </div>
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING())
})
export default connect(null, mapDispatchToProps)(FollowUp)