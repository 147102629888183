import * as Yup from 'yup';
import { helperValidate, staticRules } from './helpers';
import moment from 'moment';

Yup.addMethod(Yup.string, 'integer', function () {
	return this.matches(/^\d+$/, 'El teléfono no es válido')
});

export const CheckEmail = (item) => {
	/* eslint-disable */
	let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
	/* eslint-enable */
	if (reg.test(item) === false) {
		return false;
	}
	else {
		return true;
	}
};

export const StepInfo = async (item) => {
	let rules = {
		email: staticRules.email,
		name: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El nombre es requerido'),
		last_name: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El apellido es requerido'),
		rut: Yup.string().max(200, 'Solo hasta 200 caracteres!').test('valid-rut', 'El rut no es válido', value => rutEsValido(value)).required('El rut es requerido'),
		phone: Yup.string().required('El teléfono es requerido').integer().min(9, 'El teléfono es inválido!').max(9, 'El teléfono es inválido!')
	}

	if (item.bill === true) {
		rules = {
			...rules,
			bill_business_name: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La razón social o nombre es requerida'),
			bill_turn: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El giro es requerido'),
			bill_rut: Yup.string().max(200, 'Solo hasta 200 caracteres!').test('valid-rut', 'El rut no es válido', value => rutEsValido(value)).required('El rut es requerido'),
			bill_phone: Yup.string().integer().max(200, 'Solo hasta 200 caracteres!').required('La teléfono es requerido'),
			bill_region_id: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La región es requerida'),
			bill_commune_id: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La comuna es requerida'),
			bill_address_description: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La dirección legal es requerida'),
		}
	}

	return helperValidate(rules, item);
}

export const StepDesktopDelivery = async (item, shipping, buy_without_account) => {
	let rules = {}
	if (shipping) {
		if (item.buy_without_account === true) {
			rules = {
				...rules,
				description: Yup.string().max(400, 'Solo hasta 400 caracteres!').nullable().required('La dirección es requerida'),
				department_number: Yup.string().max(5, 'El numero del Depto/Oficina solo hasta 5 caracteres!'),
				note: Yup.string().max(200, 'La Información adicional solo permite 200 caracteres'),
			}
		} else {
			rules = {
				...rules,
				address_id: Yup.string().max(200, 'Solo hasta 200 caracteres!').nullable().required('La dirección es requerida'),
			}
		}

		if (item.received_by_a_third_party === true) {
			rules = {
				...rules,
				third_name: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El nombre es requerido'),
				third_phone: Yup.string().integer().max(200, 'Solo hasta 200 caracteres!').required('El nombre es requerido'),
				third_rut: Yup.string().max(200, 'Solo hasta 200 caracteres!').test('valid-rut', 'El rut no es válido', value => rutEsValido(value)).required('El rut es requerido'),
			}
		}
	}

	return helperValidate(rules, item);
};

export const StepDelivery = async (item, shipping, buy_without_account) => {
	let rules = {}
	if (shipping) {
		rules = {
			...rules,
			department_number: Yup.string().max(5, 'El numero del Depto/Oficina solo hasta 5 caracteres!'),
			note: Yup.string().max(200, 'La Información adicional solo permite 200 caracteres'),
			received_by_a_third_party: Yup.boolean().required('Define si lo recibe un tercero o tu'),
		}

		if (buy_without_account === true) {
			rules = {
				...rules,
				description: Yup.string().max(400, 'Solo hasta 400 caracteres!').required('La dirección es requerida'),
			}
		}

		if (item.received_by_a_third_party === true) {
			rules = {
				...rules,
				third_name: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El nombre es requerido'),
				third_phone: Yup.string().integer().max(200, 'Solo hasta 200 caracteres!').required('El nombre es requerido'),
				third_rut: Yup.string().max(200, 'Solo hasta 200 caracteres!').test('valid-rut', 'El rut no es válido', value => rutEsValido(value)).required('El rut es requerido'),
			}
		}
	}

	return helperValidate(rules, item);
};

export const StepClient = async (item) => {
	let rules = {
		bill: Yup.boolean().required('Define si es boleta o factura'),
		email: staticRules.email,
		name: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El nombre es requerido'),
		last_name: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El apellido es requerido'),
		rut: Yup.string().max(200, 'Solo hasta 200 caracteres!').test('valid-rut', 'El rut no es válido', value => rutEsValido(value)).required('El rut es requerido'),
		phone: Yup.string().integer().min(9, 'El teléfono es inválido!').max(9, 'El teléfono es inválido!').required('El telefono es requerido')
	}

	if (item.bill === true) {
		rules = {
			...rules,
			bill_business_name: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La razón social o nombre es requerida'),
			bill_turn: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El giro es requerido'),
			bill_rut: Yup.string().max(200, 'Solo hasta 200 caracteres!').test('valid-rut', 'El rut no es válido', value => rutEsValido(value)).required('El rut es requerido'),
			bill_phone: Yup.string().integer().max(200, 'Solo hasta 200 caracteres!').required('La teléfono es requerido'),
			bill_region_id: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La región es requerida'),
			bill_commune_id: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La comuna es requerida'),
			bill_address_description: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La dirección legal es requerida'),
		}
	}

	return helperValidate(rules, item);
};


export const StepPayment = async (item) => {
	return helperValidate({}, item);
};

export const UpdateProfile = async (item) => {
	let rules = {
		correo: staticRules.email,
	}

	rules = {
		...rules,
		nombres: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El nombre es requerido'),
		apellidos: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El apellido es requerido'),
		telefono: Yup.string().integer().max(200, 'Solo hasta 200 caracteres!').required('El telefono es requerido'),
	}

	return helperValidate(rules, item);
}

export const RecoveryPass = async (item) => {
	let rules = {
		nueva_contrasena: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La contraseña nueva es requerida'),
	}

	return helperValidate(rules, item);
}

export const UpdateEnterprise = async (item) => {
	let rules = {
		nombre_empresa: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La razón social es requerida'),
		rut_empresa: Yup.string().max(200, 'Solo hasta 200 caracteres!').test('valid-rut', 'El rut no es válido', value => rutEsValido(value)).required('El rut de empresa es requerido'),
		giro: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El giro es requerido'),
		telefono_empresa: Yup.string().integer().max(200, 'Solo hasta 200 caracteres!').required('El telefono de empresa es requerido'),
		id_region_empresa: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La región es requerida'),
		id_comuna_empresa: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La comuna es requerida'),
		direccion_empresa: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La dirección legal es requerida'),
	}

	return helperValidate(rules, item);
}

export const ChangePassword = async (item) => {
	let rules = {
		contrasena: Yup.string().required('Ingresa tu contraseña actual.'),
		nueva_contrasena: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La contraseña nueva es requerida'),
	}

	return helperValidate(rules, item);
}

export const Register = async (item) => {
	let rules = {
		correo: staticRules.email,
	}

	rules = {
		...rules,
		nombres: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El nombre es requerido'),
		apellidos: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('El apellido es requerido'),
		rut: Yup.string().max(200, 'Solo hasta 200 caracteres!').test('valid-rut', 'El rut no es válido', value => rutEsValido(value)).required('El rut es requerido'),
		contrasena: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('La contraseña es requerida'),
		telefono: Yup.string().integer().max(200, 'Solo hasta 200 caracteres!').required('El telefono es requerido'),
	}

	return helperValidate(rules, item);
}

function revisarDigito(dvr) {
	let dv = dvr + "";
	if (
		dv !== '0' &&
		dv !== '1' &&
		dv !== '2' &&
		dv !== '3' &&
		dv !== '4' &&
		dv !== '5' &&
		dv !== '6' &&
		dv !== '7' &&
		dv !== '8' &&
		dv !== '9' &&
		dv !== 'k' &&
		dv !== 'K') {
		return false;
	}
	return true;
}

function revisarDigito2(crut) {
	let rut = null;
	let largo = crut.length;
	if (largo < 2) {
		return false;
	}
	if (largo > 2) {
		rut = crut.substring(0, largo - 1);
	} else {
		rut = crut.charAt(0);
	}
	let dv = crut.charAt(largo - 1);
	revisarDigito(dv);

	if (rut == null || dv == null) {
		return false;
	}

	var dvr = '0';
	let suma = 0;
	let mul = 2;

	for (let i = rut.length - 1; i >= 0; i--) {
		suma = suma + rut.charAt(i) * mul
		if (mul === 7) {
			mul = 2
		} else {
			mul++
		}
	}

	let res = suma % 11;
	if (res === 1) {
		dvr = 'k'
	} else if (res === 0) {
		dvr = '0'
	} else {
		let dvi = 11 - res
		dvr = dvi + ""
	}

	if (dvr !== dv.toLowerCase()) {
		return false
	}

	return true
}

export function rutEsValido(texto) {
	var tmpstr = "";
	for (let i = 0; i < texto.length; i++)
		if (texto.charAt(i) !== ' ' && texto.charAt(i) !== '.' && texto.charAt(i) !== '-') {
			tmpstr = tmpstr + texto.charAt(i);
		}

	texto = tmpstr;
	let largo = texto.length;

	if (largo < 2) {
		return false;
	}

	for (let i = 0; i < largo; i++) {
		if (
			texto.charAt(i) !== "0" &&
			texto.charAt(i) !== "1" &&
			texto.charAt(i) !== "2" &&
			texto.charAt(i) !== "3" &&
			texto.charAt(i) !== "4" &&
			texto.charAt(i) !== "5" &&
			texto.charAt(i) !== "6" &&
			texto.charAt(i) !== "7" &&
			texto.charAt(i) !== "8" &&
			texto.charAt(i) !== "9" &&
			texto.charAt(i) !== "k" &&
			texto.charAt(i) !== "K") {
			return false;
		}
	}

	var invertido = "";
	for (let i = (largo - 1), j = 0; i >= 0; i--, j++) {
		invertido = invertido + texto.charAt(i);
	}

	var dtexto = "";
	dtexto = dtexto + invertido.charAt(0);
	dtexto = dtexto + '-';
	let cnt = 0;

	for (let i = 1, j = 2; i < largo; i++, j++) {
		if (cnt === 3) {
			dtexto = dtexto + '.';
			j++;
			dtexto = dtexto + invertido.charAt(i);
			cnt = 1;
		} else {
			dtexto = dtexto + invertido.charAt(i);
			cnt++;
		}
	}

	invertido = "";
	for (let i = (dtexto.length - 1), j = 0; i >= 0; i--, j++) {
		invertido = invertido + dtexto.charAt(i);
	}

	if (revisarDigito2(texto)) {
		return true;
	}

	return false;
}

export const updateAddresses = async (item) => {

	let rules = {
		direccion: Yup.string().max(300, 'Solo hasta 300 caracteres!').required('La direccion es inválida'),
		region_id: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('Debe selecionar una region'),
		id_comuna: Yup.string().max(200, 'Solo hasta 200 caracteres!').required('Debe selecionar una comuna'),
	}

	return helperValidate(rules, item);
}

export const bannerSchema = Yup.object().shape({
	name: Yup.string().required('Este campo es requerido.'),
	text: Yup.string().required('Este campo es requerido.'),
})

export const categorySchema = Yup.object().shape({
	nombre: Yup.string().required('Este campo es requerido.'),
})

export const discountSchema = Yup.object().shape({
	cantidad: Yup.number().moreThan(0, 'El valor debe ser mayor a cero').required('Este campo es requerido').typeError('El valor debe ser numérico').integer('El valor debe ser entero'),
	codigo: Yup.string().required('Este campo es requerido'),
	fecha_creacion: Yup.date().required('Este campo es requerido'),
	fecha_expiracion: Yup.date().required('Este campo es requerido').when('fecha_creacion', (fecha_creacion) => {
		if (fecha_creacion) {
			return Yup.date()
				.min(moment(fecha_creacion).add(1, 'days'), 'Fecha de expiracion debe ser despues de la fecha de inicio')
				.typeError('Este campo es requerido')
		}
	}),
	valor: Yup.number().required('Este campo es requerido').typeError('El valor debe ser numérico').min(1, 'El valor minimo debe ser 1').max(100, 'El valor no puede ser mayor a 100').integer('El valor debe ser entero'),
	state: Yup.boolean().required('Este campo es requerido')
})

export const productSchema = Yup.object().shape({
	nombre: Yup.string().required('Este campo es requerido.'),
	descripcion_corta: Yup.string().required('Este campo es requerido.'),
	descripcion_larga: Yup.string().required('Este campo es requerido.'),
	sku: Yup.string().required('Este campo es requerido.'),
	state: Yup.boolean().required('Este campo es requerido.'),
	id_categoria: Yup.number().positive('Elija una categoria'),
	images: Yup.array().min(1, 'Este campo es requerido'),
})

export const priceSchemaDelivery = Yup.object().shape({
	defontana_code: Yup.string().required('Este campo es requerido.'),
	region_id_delivery: Yup.number().positive('Elija una región'),
	commune_id_delivery: Yup.number().positive('Elija una comuna'),
	amount_delivery: Yup.string().required('Este campo es requerido.'),
	format_id: Yup.number().positive('Elija un formato'),
})

export const priceSchemaWithdraw = Yup.object().shape({
	defontana_code: Yup.string().required('Este campo es requerido.'),
	format_id: Yup.number().positive('Elija un formato'),
	sucursal_id: Yup.number().positive('Elija una sucursal'),
	amount_withdraw: Yup.string().required('Este campo es requerido.'),
})
