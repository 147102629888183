import { Component } from "react";
import { connect } from "react-redux";
import { CLEAR_RENDER, OPEN_DRAWER, SET_RENDER } from "../../../redux/actions/drawer";
import EditProduct from '../Products/EditProduct'
import AdvancedProduct from '../Products/AdvancedProduct'
import '../../../styles/component.admin.actions.scss'
import { SET_PRODUCT } from "../../../redux/actions/product_admin";
import ProductFormats from "../Products/ProductFromats";
import { Notification } from "rsuite";
import { getProductsExcel, updateProductsExcel} from "../../../services/api/endpoints";
import { toBase64 } from "../../../utils/helpers";

class ProductActions extends Component {

    hasPermission = () => {
        return JSON.parse(sessionStorage.getItem('modules'))['productos'].edit;
    }

    async handleRender (component) {
        const { OPEN_DRAWER, SET_RENDER, CLEAR_RENDER, SET_PRODUCT, item } = this.props
        if (!this.hasPermission()) {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
            return;
        }
        SET_PRODUCT(item)
        CLEAR_RENDER()
        switch (component) {
            case 'EDIT_PRODUCT':
                SET_RENDER(EditProduct);
                OPEN_DRAWER()
                break;
            case 'ADVANCED_PRODUCT_DESPACHO':
                SET_PRODUCT({ ...item, type: 'DESPACHO' })
                SET_RENDER(AdvancedProduct);
                OPEN_DRAWER()
                break;
            case 'ADVANCED_PRODUCT_RETIRO':
                SET_PRODUCT({ ...item, type: 'RETIRO' })
                SET_RENDER(AdvancedProduct);
                OPEN_DRAWER()
                break;
            case 'ADVANCED_PRODUCT_RELATED':
                SET_PRODUCT({ ...item, type: 'RELATED' })
                SET_RENDER(AdvancedProduct);
                OPEN_DRAWER()
                break;
            case 'PRODUCT_IMAGE':
                SET_RENDER(ProductFormats);
                OPEN_DRAWER()
                break;
            case 'DOWNLOAD_EXCEL':
                const a = document.createElement('a')
                a.href = (await getProductsExcel(this.props.id)).data
                a.download = item.nombre+'.xlsx'
                a.click()
                break;
            case 'UPLOAD_EXCEL' :
                const { auth } = this.props
                let inputFile= []
                inputFile = document.createElement('input')
                inputFile.type = 'file'
                inputFile.accept = '.xls,.xlsx'
                inputFile.click()
                inputFile.onchange = async (e) => {
                    if(inputFile.files.length !== 0){
                        await updateProductsExcel(auth.session.token, {data: await toBase64(inputFile.files[0])}, this.props.id)
                    }else{
                        Notification.warning({ description: "No se ha cargado ningún archivo", title: "Subir Excel" });
                    }
                }
                break;
            default:
                break;
        }
    }

    render() {
        return (
            <div className="container-product-actions">
                <i dataToggle="tooltip" title="Editar" className="fa fa-pencil" onClick={() => this.handleRender('EDIT_PRODUCT')}></i>
                <i dataToggle="tooltip" title="Despachos" className="fa fa-truck" onClick={() => this.handleRender('ADVANCED_PRODUCT_DESPACHO')}></i>
                <i dataToggle="tooltip" title="Retiros" className="fa fa-building" onClick={() => this.handleRender('ADVANCED_PRODUCT_RETIRO')}></i>
                <i dataToggle="tooltip" title="Formatos" className="fa fa-stack-overflow" onClick={() => this.handleRender('PRODUCT_IMAGE')}></i>
                <i dataToggle="tooltip" title="Productos Relacionados" className="fa fa-cubes" onClick={() => this.handleRender('ADVANCED_PRODUCT_RELATED')}></i>
                <i dataToggle="tooltip" title="Descargar Excel" className="fa fa-file-excel-o" onClick={() => this.handleRender('DOWNLOAD_EXCEL')}></i>
                <i dataToggle="tooltip" title="Subir Excel" className="fa fa-upload" onClick={() => this.handleRender('UPLOAD_EXCEL')}></i>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
})
const mapDispatchToProps = (dispatch) => ({
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    CLEAR_RENDER: () => dispatch(CLEAR_RENDER()),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
    SET_PRODUCT: (payload) => dispatch(SET_PRODUCT(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductActions)
