import { Component } from "react";
import { connect } from "react-redux";
import Input from "../Input";
import Title from "../Title";
import { categorySchema } from '../../../utils/validations';
import validate from '../../../utils/validate';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import toolbar from '../../../utils/toolbars';
import { doEditText } from "../../../redux/actions/text";
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";

class EditCategory extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputs: {},
            errors: {},
            editor: null,
            end_loading_data: false
        }
    }

    componentDidMount() {
        const { contract: inputs } = this.props
        this.setState({ inputs })
    }

    componentDidUpdate() {
        const { editor, end_loading_data, inputs } = this.state
        if (editor && !end_loading_data) {
            editor.setData(inputs.texto);
            this.setState({ end_loading_data: true })
        }
    }

    handleSubmit = async () => {
        const { inputs } = this.state
        const validations = await validate(categorySchema, inputs, this._editText)
        if (validations) {
            this.setState({ errors: validations })
        }
    }

    _editText = async () => {
        const { editText, auth, drawer, CLOSE_DRAWER, START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const { inputs } = this.state
        await editText({ nombre: inputs.nombre, texto: inputs.texto }, inputs.id, auth.session.token)
        END_LOADING()
        CLOSE_DRAWER(drawer.RENDER);
    }

    handleChange = ({ target }) => {
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [target.name]: target.value } }, () => {
            const { errors } = this.state
            Object.keys(errors).forEach((key) => {
                if (key === target.name) {
                    delete errors[key]
                }
            })
            this.setState({ errors })
        })
    }

    render() {
        const { inputs, errors } = this.state
        const { nombre } = inputs
        return (
            <div className="container-component-category size mt-5">
                <Title text="Editar Textos Legales" />
                <div className="row justify-content-center px-md-2 px-1 mb-5">
                    <div className="col-12 mb-2">
                        <Input
                            label="Nombre"
                            placeholder="Nombre Texto Legale"
                            name="nombre"
                            errors={errors}
                            value={nombre}
                            onChange={this.handleChange}
                            disabled
                        />
                    </div>
                </div>
                <div className="row justify-content-center px-md-2 px-1 mb-5">
                    <div className="col-12 mb-2">
                        <CKEditor
                            editor={ClassicEditor}
                            data=""
                            config={{
                                toolbar: toolbar,
                            }}
                            onReady={editor => {
                                this.setState({ editor })
                            }}
                            onChange={(event, editor) => {
                                const { inputs } = this.state
                                const data = editor.getData();
                                this.setState({ inputs: { ...inputs, texto: data } }, () => {
                                    const { errors } = this.state
                                    Object.keys(errors).forEach((key) => {
                                        if (key === 'texto') {
                                            delete errors[key]
                                        }
                                    })

                                    this.setState({ errors })
                                })
                            }}
                        />
                    </div>
                </div>
                <div className="row justify-content-center px-md-2 px-1 mb-2">
                    <div className="col-12 mb-2 d-flex justify-content-end">
                        <button className="btn btn-rioclaro" onClick={this.handleSubmit}>Guardar</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    contract: state.contract,
    auth: state.auth,
    drawer: state.drawer,
})
const mapDispatchToProps = (dispatch) => ({
    editText: (data, id, token) => dispatch(doEditText(data, id, token)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING())
})
export default connect(mapStateToProps, mapDispatchToProps)(EditCategory)