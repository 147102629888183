import { handleActions } from 'redux-actions';
import { SaveCart, GetCart } from '../../services/session';
import { cart_update_list_items, cart_update_amounts, cart_apply_discount_code, cart_update_shipping, cart_reset } from '../actions/cart';
import { updateGlobalState } from '../../services/session';

export const DEFAULT_STATE = {
	items: [
	],
    shipping: null,
	total_discount_items:0,
	total_amount_items:0,
	total_discount_coupon:0,
	total_amount_payment:0,
	discount_code: null
};

const INITIAL_STATE = GetCart() || DEFAULT_STATE;

export default handleActions({
    [cart_update_list_items]: (state, action) => {
        state.items = action.payload;
        updateGlobalState('cart', state);
        return {...state}
    },
    [cart_update_amounts]: (state, action) => {
        let data = {
            ...state,
            ...action.payload
        };
        SaveCart(data);
        updateGlobalState('cart', data);
        return data;
    },
    [cart_apply_discount_code]: (state, action) => {
        let data = {
            ...state,
            total_discount_coupon:action.payload.total_discount_coupon,
            discount_code: action.payload.discount_code
        };
        SaveCart(data);
        updateGlobalState('cart', data);
        return data;
    },
    [cart_update_shipping]: (state, action) => {
        let data = {
            ...state,
            shipping: action.payload.shipping
        }
        SaveCart(data);
        updateGlobalState('cart', data);
        return data;
    },
    [cart_reset]: () => {
        return {
            items: [],
            shipping: null,
            total_discount_items:0,
            total_amount_items:0,
            total_discount_coupon:0,
            total_amount_payment:0,
            discount_code: null
        };
    }
}, INITIAL_STATE);
