import { Component } from "react";
import { v4 } from 'uuid'
class Switch extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ref: ''
        }
    }

    componentDidMount() {
        this.setState({ ref: v4() })
    }

    handleEmpty = () => {
        console.info('OnChange function not found')
    }

    render() {
        const { checked, onChange, label, id, name, ...rest } = this.props
        const { ref } = this.state
        return (
            <div>
                {label && (
                    <div className="form-label" htmlFor={id || ref}>{label}</div>
                )}
                <div className="form-check form-switch">

                    <input className="form-check-input" id={id || ref} name={name} type="checkbox" checked={checked || false} onChange={onChange || this.handleEmpty} {...rest} />
                </div>
            </div>
        )
    }
}

export default Switch