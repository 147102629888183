import React from 'react';
import AssetsImg from '../../assets/image';
import { Link } from 'react-router-dom';

const FooterMain = (props) => (
    <footer className="footer" style={props.styles}>
    <div className="main-footer d-sm-none">
        <div className="row bg-orange p-3 d-flex justify-content-center">
            <div className="col-5 p-4">
                <img src={AssetsImg.Logo_W} className="img-fluid2" alt="rio-claro"/>
            </div>
        </div>
        {
            props.main && (
                <>
                    <div className="d-none d-md-inline row bg-grey text-center text-white p-4 text-center">
                        <h5 className="font-weight-bold w-100 mb-4">PELLET RÍO CLARO</h5>
                        <p className="font-weight-light w-75 mx-auto" style={{ fontSize: '.9em' }}>Rio CLaro Pellet Bioenergía nace en el corazón de la región del Maule con el objetivo de elaborar un producto limpio,
                            estandarizado y eficiente, empleando un proceso altamente tecnológico.
                        </p>
                    </div>
                    <div className="row p-4 text-center">
                        <h5 className="mb-3 w-100 text-black" style={{ fontWeight: '750' }}>SÍGUENOS</h5>
                        <div className="col-12 d-flex justify-content-center">
                            <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/pelletrioclaro" className="px-4">
                                <img src={AssetsImg.Facebook} className="logo-social" alt="facebook"/>
                            </a>
                            <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/pelletrioclaro" className="px-4">
                                <img src={AssetsImg.Instagram} className="logo-social" alt="instagram"/>
                            </a>
                            <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCHNi2BynmoyCEv7a44cD30w" className="px-4">
                                <img src={AssetsImg.Youtube} className="logo-social" alt="youtube"/>
                            </a>
                        </div>
                    </div>
                    <div className="d-none d-md-inline row mb-2 px-4">
                        <div className="col-12 col-sm-6 d-flex justify-content-center align-items-center mb-2">
                            <Link to="/" className="btn bg-grey w-100 text-white">Productos</Link>
                        </div>
                        <div className="col-12 col-sm-6 d-flex justify-content-center align-items-center">
                            <Link to="/puntos-de-venta" className="btn bg-grey w-100 text-white">Puntos de Venta</Link>
                        </div>
                    </div>
                    <div className="d-none d-md-inline row pb-4 px-4">
                        <div className="col-12 d-flex justify-content-center  align-items-center">
                            <Link to="/terminos-y-condiciones" className="btn bg-grey w-100 text-white">Terminos y Condiciones</Link>
                        </div>
                    </div>
                </>
            )
        }
        <div className="row bg-i-gray p-4 text-center">
            <h5 className="mb-3 w-100 text-black" style={{ fontWeight: '750' }}>MEDIOS DE PAGOS</h5>
            <img src={AssetsImg.Webpay} className="w-50 mx-auto" alt="webpay" style={{ objectFit: 'contain' }}/>
        </div>
        <div className="row my-1 mb-5">
            <div className="col-6">
                <p className="m-0">© Rio Claro Bioenergía</p>
            </div>
            <div className="col-6 text-right">
                <p className="m-0">Miembro de AChBIOM</p>
            </div>
        </div>
    </div>
    <div className="row bg-dark text-white d-none d-sm-flex">
			<div className="col-12 py-5 border-bottom">
				<div className="container-lg">
				  <div className="row justify-content-between">
				    <div className="col-5 d-flex flex-column">
                        <img className="img-fluid2" src={AssetsImg.Logo_W} alt="..." style={{ width: '150px' }}/>
				    </div>
				    <div className="col-3">
                        <p className="font-weight-bold mb-2">Legal</p>
				     	<ul className="list-group list-group-flush">
				    		<Link to="/terminos-y-condiciones" className="bg-transparent px-0 py-1 text-white" style={{ fontSize: '.9em', fontWeight: '200' }}>Términos y Condiciones</Link>
				    		<Link to="/politicas-de-cambios-y-devoluciones" className="bg-transparent px-0 py-1 text-white" style={{ fontSize: '.9em', fontWeight: '200' }}>Políticas de Cambios y Devoluciones</Link>
                            <Link to="/politicas-de-privacidad" className="bg-transparent px-0 py-1 text-white" style={{ fontSize: '.9em', fontWeight: '200' }}>Políticas de Privacidad</Link>
				    		<Link to="/despachos" className="bg-transparent px-0 py-1 text-white" style={{ fontSize: '.9em', fontWeight: '200' }}>Despachos</Link>
				    		<a href="https://pelletrioclaro.cl/distribuidores/" className="bg-transparent px-0 py-1 text-white" style={{ fontSize: '.9em', fontWeight: '200' }}>Distribuidores</a>        
                        </ul>
				    </div>
				    <div className="col-2 d-flex flex-column">
                        <p className="font-weight-bold mb-2">Contacto</p>
                        <p className="m-0" style={{ fontSize: '.9em', fontWeight: '200' }}>
                            <img src={AssetsImg.Phone} className="img-fluid2 mr-2 icon-small" alt="..."/>
                            224105100
                        </p>
                        <p className="mb-0" style={{ fontSize: '.9em', fontWeight: '200' }}>
                            <img src={AssetsImg.Envelope} className="img-fluid2 mr-2 icon-small" alt="..."/>
                            pellet@gfrc.cl
                        </p>
				    </div>
                    <div className="col-2">
                        <p className="font-weight-bold mb-2">Síguenos</p>
                        <div className="row d-flex">
                            <div className="col-4">
                                <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/pelletrioclaro" className="px-2">
                                    <img src={AssetsImg.Facebook_w} alt="facebook" style={{  width: '30px'}}/>
                                </a>
                            </div>
                            <div className="col-4">
                                <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/pelletrioclaro" className="px-2">
                                    <img src={AssetsImg.Instagram_w} alt="instagram" style={{  width: '30px'}}/>
                                </a>
                            </div>
                            <div className="col-4">
                                <a rel="noopener noreferrer" target="_blank" href="https://www.youtube.com/channel/UCHNi2BynmoyCEv7a44cD30w" className="px-2">
                                    <img src={AssetsImg.Youtube_w} alt="linkedin" style={{  width: '30px'}}/>
                                </a>
                            </div>
                        </div>
                    </div>
				  </div>
			  </div>
			</div>
			<div className="col-12 py-3">
				<div className="container-lg">
				  <div className="row justify-content-between">
				    <div className="col-6">
				    © Rio Claro Bioenergía
				    </div>
				    <div className="col-6 text-right">
				    Miembro de AChBIOM
				    </div>
				  </div>
				</div>
			</div>
		</div>
    </footer>
)

export default FooterMain;