import { createAction } from 'redux-actions';
import { AllProducts, CreateProduct, EditProduct, ProductDetails, Products, RemoveProduct } from '../../services/api/endpoints';
import { SaveCategories, SaveOffices, SaveRegions, SaveComunas } from '../../services/session';
import { SET_PRODUCT } from './product_admin';

export const fetch_start_products = createAction('fetch_start_products');
export const fetch_success_products = createAction('fetch_success_products');
export const fetch_error_products = createAction('fetch_error_products');

export const getProductsList = (all, page, pageSize) => async (dispatch) => {
    dispatch(fetch_start_products());
    try {
        let response;
        if (all) {
            response = await AllProducts({});
        } else {
            response = await Products({}, page, pageSize);
        }
        console.log(response, 'RESPUESTA DE LISTADO DE PRODUCTOS');
        const { data, last_page, categorias, regiones, sucursales, comunas } = response;
        categorias && SaveCategories(categorias);
        sucursales && SaveOffices(sucursales);
        regiones && SaveRegions(regiones);
        comunas && SaveComunas(comunas);
        dispatch(fetch_success_products({ data, last_page }))
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_products());
    }
};

export const doGetProduct = (slug) => async (dispatch) => {
    dispatch(fetch_start_products());
    try {
        const response = await ProductDetails(slug);
        console.log(response, 'RESPUESTA DE PRODUCTO');
        const { data } = response;
        dispatch(SET_PRODUCT({ ...data }));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_products());
    }
}

export const doCreateProduct = (params, token) => async (dispatch) => {
    dispatch(fetch_start_products());
    try {
        await CreateProduct(params, token);
        dispatch(getProductsList(false, 1, 15));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_products());
        return false;
    }
}

export const doEditProduct = (params, id, token) => async (dispatch) => {
    dispatch(fetch_start_products());
    try {
        await EditProduct(params, id, token);
        dispatch(getProductsList(false, 1, 15));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_products());
        return false;
    }
}

export const doRemoveProduct = (params, id, token) => async (dispatch) => {
    dispatch(fetch_start_products());
    try {
        await RemoveProduct(params, id, token);
        dispatch(getProductsList(false, 1, 15));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_products());
    }
}
