import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'rsuite';
import history from '../../services/history';
import NavRsuite from '../../components/Navs/Nav_Rsuite';
import { CloseSession } from '../../services/session';
import { logout } from '../../redux/actions/auth';
import AssetsImg from '../../assets/image';
import { checkout_reset } from '../../redux/actions/checkout';

const tabs = [
	{name:'Mis datos', key:'/perfil', disabled:false},
	{name:'Direcciones', key:'/perfil/direcciones', disabled:false},
	{name:'Pedidos', key:'/perfil/pedidos', disabled:false},
];

const responsiveTabs = [
	{name:'Mis datos', key:'/perfil/datos', disabled:false},
	{name:'Direcciones', key:'/perfil/direcciones', disabled:false},
	{name:'Pedidos', key:'/perfil/pedidos', disabled:false},
]

class ProfileNav extends Component {
	state = {
		active: history.location.pathname === '/perfil' ? '/perfil' : history.location.pathname
	}

	_handleSelect = (activeKey) => {
    	this.setState({active: activeKey});
    	history.push(activeKey);
	}

	_handleCloseSession = () => {
		const { logout, checkout_reset } = this.props;
		checkout_reset()
		CloseSession();
		logout();
	}

	render(){
		const { auth: { session: { usuario } } } = this.props;
		const { active } = this.state;
		return(
			<>
			<div className="d-none d-sm-block card-profile p-3 shadow-sm">
				<p className="mb-0 ml-2"><small className="font-weight-bold">{`Hola ${usuario.nombres} ${usuario.apellidos}!`}</small></p>
			</div>
			<div className="d-block d-sm-none text-center">
				<img src={AssetsImg.Profile} className="my-4" alt="..."/>
				<h5 className="font-weight-bold">{`!Hola ${usuario.nombres} ${usuario.apellidos}!`}</h5>
			</div>
			<div className="d-none d-sm-block">
				<NavRsuite
					vertical
					className="pt-3"
					active={active}
					onSelect={this._handleSelect}
					list={tabs}
				/>
			</div>
			<div className="d-block d-sm-none">
				<NavRsuite
					vertical
					className="pt-3"
					active={active}
					onSelect={this._handleSelect}
					list={responsiveTabs}
				/>
			</div>
			<Button
				color="red"
				className="close-session btn-block shadow-sm"
				onClick={this._handleCloseSession}
			>
				Salir
			</Button>
		</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    logout: () => dispatch( logout() ),
	checkout_reset: () => dispatch( checkout_reset() )
});

export default connect(mapStateToProps, mapDispatchToProps)(ProfileNav);