import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getProductDetail } from '../../redux/actions/detail';
import { updateItems, cart_update_shipping } from '../../redux/actions/cart';
import ProductDescription from '../../components/Products/Product_Description';
import ProductSliderSlick from '../../components/Products/Product_SliderSlick';
import ProductOptions from '../../components/Products/Product_Options';
import LoaderRsuite from '../../components/Loaders/Loader_Rsuite';
import FooterMain from '../../components/Footers/Footer_Main';
import history from '../../services/history';

const initial = { shipping: null, region: null, store: null, commune: null, format: null }

class Detail extends Component {
    async componentDidMount() {
        const { match, getProductDetail } = this.props;
        getProductDetail(match.params.slug_name);
    }

    _addProductToCart = (selected, formats, regions, callback) => {
        if(selected.shipping===2){
            selected.commune=null
        }else{
            selected.store=null
        }
        const { cart: { items, discount_code, shipping }, detail: { data: item }, updateItems, updateShipping } = this.props;
        let sRegion = regions.find(x => x.id === selected.region);
        let comuna = selected.commune ? sRegion.comunas_disponibles.find(y => y.id === parseInt(selected.commune)) : null;
        let sucursal = selected.store ? sRegion.sucursales_disponibles.find(y => y.id === parseInt(selected.store)) : null;

        if (comuna) {
            comuna.region_nombre = sRegion.region_nombre;
        }

        if (!shipping) {
            updateShipping({ shipping: { comuna, sucursal } });
        } else {
            if (!shipping.comuna && selected.shipping === 1) {
                updateShipping({ shipping: { ...shipping, comuna } });
            } else if (!shipping.sucursal && selected.shipping === 2) {
                updateShipping({ shipping: { ...shipping, sucursal } });
            }
        }

        let findedIndex = items.findIndex(x => (x.id_producto === item.id && x.id_formato === selected.format && x.shipping === selected.shipping));
        if (findedIndex !== -1) {
            let finded = items[findedIndex];
            finded.cantidad += 1;
            items.splice(findedIndex, 1);
            updateItems([...items, finded], discount_code);
        } else {
            let formato = formats.find(w => w.id === selected.format);
            let despacho = item.despacho.find(x => x.id === selected.region);
            let retiro = item.retiro.find(y => y.id === selected.region);
            let product = {
                id_producto: item.id,
                nombre_producto: item.nombre,
                imagen_producto: item.imagenes[0],
                id_formato: formato.id,
                nombre_formato: formato.nombre,
                formatos: formats,
                shipping: selected.shipping,
                precio: selected.shipping === 1 ? parseInt(Math.round(formato.valor_despacho_iva)) : parseInt(Math.round(formato.valor_retiro_tienda_iva)),
                sucursales_disponible: retiro ? retiro.sucursales_disponibles : null,
                comunas_disponible: despacho ? despacho.comunas_disponibles : null,
                id_lista_precio: formato.id_lista_precio,
                region: selected.region,
                store: selected.store,
                commune: selected.commune,
                despacho: item.despacho,
                retiro: item.retiro,
                cantidad: 1,
                item,
            }

            updateItems([...items, product], discount_code);
        }

        callback();
        history.push('/finalizar-compra');
    }

    render() {
        const { detail, cart: { shipping }, match: { params } } = this.props;
        return (
            <LoaderRsuite
                loading={{
                    run: detail.loading,
                    title: "Cargando Producto..."
                }}
                fail={{
                    run: detail.data.id === undefined,
                    title: "Producto no encontrado!",
                    description: "Es posible que ya no se encuentre publicado."
                }}>
                <ProductOptions
                    item={detail.data}
                    initial={initial}
                    shipping={shipping}
                    action={this._addProductToCart}
                    title={'IR A COMPRAR'}
                    params={params}
                />
                {/* <ProductDetail item={detail.data}/> */}
                <div className="container">
                    <ProductDescription item={detail.data} />
                    {
                        detail.data.productos_relacionados && detail.data.productos_relacionados.length > 0 ? (
                            <div className="col">
                                <div className="m-row py-md-5">
                                    <div className="container-lg p-4">
                                        <h3 className="mb-0 text-center">Productos Relacionados</h3>
                                        <ProductSliderSlick products={detail.data.productos_relacionados} />
                                    </div>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
                <FooterMain main />
            </LoaderRsuite>
        )
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart,
    detail: state.detail
});

const mapDispatchToProps = dispatch => ({
    getProductDetail: (data) => dispatch(getProductDetail(data)),
    updateItems: (data, discount_code) => dispatch(updateItems(data, discount_code)),
    updateShipping: (data) => dispatch(cart_update_shipping(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Detail);