import { handleActions } from 'redux-actions';
import { fetch_start_payment, fetch_error_payment, fetch_success_payment } from '../actions/payment';

export const INITIAL_STATE = {
    data: null,
    loading: false,
    error: null,
    success: false
}

export default handleActions({
    [fetch_start_payment]: (state) => {
        return {...state, loading: true}
    },
    [fetch_error_payment]: (state) => {
        return {...state, loading: false, error: true}
    },
    [fetch_success_payment]: (state, action) => {
        return {...state, success: true, loading: false, error: false, data: action.payload}
    },
}, INITIAL_STATE);
