import React, { Component } from 'react';
import BannerTitle from '../../../components/Banners/Banner_Title';
import FooterMain from '../../../components/Footers/Footer_Main';
import * as RulesValidate from '../../../utils/validations';
import Delivery from './delivery';
import Form from './form';
import { connect } from 'react-redux';
import { setOrder, setAnonUser, _maskRut, _formatRut } from '../../../utils/helpers';
import { checkUserRut, update_session, checkUserEmail } from '../../../redux/actions/auth';
import * as Api from '../../../services/api/endpoints';
import { checkout_update_form, checkout_reset } from '../../../redux/actions/checkout';
import { Alert, Notification } from 'rsuite';
import { GetRegions, SaveCategories, SaveOffices, SaveRegions } from '../../../services/session';
import { create_order, create_transbank } from '../../../redux/actions/payment';
import SetupForm from './TransbankForm';
import { updateItems, cart_update_shipping } from '../../../redux/actions/cart';
const linkApi = process.env.REACT_APP_ENV_API_URL
class Desktop extends Component {
	constructor(props) {
		super(props);
		this.formInfo = React.createRef();
		this.formAddress = React.createRef();
		const currentBanner = this.props.banners.data.find((t) => t.pagina === window.location.pathname);
		this.state = {
			inputs: { ...this.props.checkout.form },
			communes: [],
			loading: false,
			transbank: null,
			error: null,
			remove: false,
			invalidations: {},
			banner: currentBanner,
			noPhone: false
		}
	}
	async componentDidMount(){
		const { inputs } = this.state;
		Object.keys(inputs).forEach((key) => {
			if (key === 'rut' || key === 'bill_rut') {
				inputs[key] = _formatRut(inputs[key])
			}
		})
		this.setState({ inputs })
		document.addEventListener('keydown', this._detectDelete, false);
		if (!GetRegions()) {
			this.setState({ loading: true });
			try {
				const response = await Api.AllProducts({});
				const { categorias, regiones, sucursales } = response;
				categorias && SaveCategories(categorias);
				sucursales && SaveOffices(sucursales);
				regiones && SaveRegions(regiones);
				this.setState({ loading: false });
			} catch (e) {
				this.setState({ loading: false, error: true });
			}
		}

		let communes = GetRegions()?.filter(x => x.id === inputs.bill_region_id);
		if (inputs.bill_region_id && communes && communes.length > 0) {
			communes = communes[0].comunas
			communes = communes.map(y => ({ value: y.id, label: y.comuna_nombre }));
			this.setState({ communes })
		}
	}
	componentDidUpdate(prevProps) {
		const { inputs } = this.state;
		if (inputs.phone !== this.props.checkout.form.phone) {
			let communes = [];
			let regions = GetRegions();
			let region_id = this.props.checkout.form.bill_region_id;
			if (regions.length && region_id) {
				let finded = regions.find(x => x.id === region_id);
				communes = finded && finded.comunas ? finded.comunas.map(y => ({ value: y.id, label: y.comuna_nombre })) : communes;
			}
			if (this.props.checkout.form.rut) {
				this.props.checkout.form.rut = _formatRut(this.props.checkout.form.rut)
			}

			if (this.props.checkout.form.bill_rut) {
				this.props.checkout.form.bill_rut = _formatRut(this.props.checkout.form.bill_rut)
			}

			this.setState({ inputs: { ...this.props.checkout.form }, communes })
		}
	}
	_handlePhone = (e) => {
		const { remove } = this.state;
		if (remove) {
			this._handleInput(e);
		} else {
			if (e.target.value.match(/^\d+$/)) {
				this._handleInput(e);
			}
		}
	}

	_handleBill = (val) => {
		const { inputs } = this.state;
		const { updateCheckout } = this.props;
		let res = val === 'true' ? true : false;
		const update = { ...inputs, bill: res };
		this.setState({ inputs: update });
		updateCheckout({ ...update });
	}

	_handleInput = async (e) => {
		const inputs = this.props.checkout.form;
		const { checkUserEmail, updateCheckout } = this.props;
		const update = { ...inputs, [e.target.name]: e.target.value };

		this.setState({ inputs: update }, async () => {
			updateCheckout({ ...update });
			if (e.target.name === 'email') {
				if (RulesValidate.CheckEmail(e.target.value)) {
					const checkemailResponse = await checkUserEmail({ correo: e.target.value, rut: null }, inputs.buy_without_account);
					const { error } = checkemailResponse
					if (error) {
						this._clearFields()
					}
				}
			}
		});
	}

	_handleDeliveryType = (e) => {
		const { inputs } = this.state;
		const { cart: { shipping } } = this.props;
		let message = 'Todos los productos con';
		message = parseInt(e) === 2 ? `${message} despacho, serán enviados dentro de la comuna que selecciones.` : `${message} retiro, serán entregados en el punto de venta seleccionado.`;
		if (shipping) {
			if (!shipping.comuna && parseInt(e) === 2) {
				Alert.info(message, 5000);
			}

			if (!shipping.sucursal && parseInt(e) === 1) {
				Alert.info(message, 5000);
			}
		} else {
			Alert.info(message, 5000);
		}
		this.setState({ inputs: { ...inputs, format: inputs.format, delivery_type: parseInt(e) }, finded: { sucursal: false, comuna: false } });
	}

	_handlePayment = async () => {
		const { inputs } = this.state;
		const { createOrder, createTransbank, cart, checkout, auth, updateSession } = this.props;
		if ((!auth.session || auth.session.usuario.id_rol !== undefined) && !inputs.buy_without_account) {
			Alert.error(`Inicia sesión, regístrate o selecciona "Comprar como invitado"`, 5000)
			return false;
		}
		let invalidations = await RulesValidate.StepInfo(inputs);
		if (invalidations !== null) {
			Api.FormValidator.validate(this.formInfo.current, invalidations);
			this.setState({ invalidations })
			return false;
		}

		invalidations = await RulesValidate.StepDesktopDelivery(inputs, cart.shipping.comuna);
		if (invalidations !== null) {
			Api.FormValidator.validate(this.formAddress.current, invalidations);
			this.setState({ invalidations })
			if (invalidations["address_id"] && invalidations["address_id"][0]) {
				Notification['error']({
					title: 'Error',
					description: invalidations["address_id"][0]
				})
			}
			return false;
		}

		if ((!auth.session || auth.session.usuario.id_rol !== undefined) && inputs.buy_without_account === false) {
			Alert.error(`Inicia sesión, regístrate o selecciona "Comprar como invitado"`, 5000)
			return false;
		}

		this.setState({ inputs: { ...inputs, danger: '', success: '', loading: true } });
		try {
			let checked = null, anon = null, address = null, payment;
			Object.keys(checkout.form).forEach(async (key) => {
				if (key === 'rut' || key === 'bill_rut' || key === 'third_rut') {
					checkout.form[key] = checkout.form[key].replace(/[^\dkK-]/g, "");
				}
			})
			if (!auth.session || auth.session.usuario.id_rol !== undefined) {
				checked = await Api.CheckEmail({ correo: checkout.form.email, rut: checkout.form.rut });
				anon = await Api.AnonLogin({ correo: checked.data.correo });
				await Api.ProfileUpdate(setAnonUser(checkout.form), anon.token);
				if (cart.shipping.comuna) {
					address = await Api.CreateAddress({ id_comuna: cart.shipping.comuna.id, id_cliente: checked.data.id, oficina: '', direccion: checkout.form.description, referencia: '' }, anon.token);
				}

				payment = setOrder(cart, checkout, null, checked, address);
			} else {
				address = checkout.form.address_id;
				let userData = setAnonUser(checkout.form);
				await Api.ProfileUpdate(userData, auth.session.token)
				await updateSession({ user: userData })
				payment = setOrder(cart, checkout, auth, null, address);
			}
			const { data } = await createOrder(payment, anon ? anon.token : auth.session.token);
			checkout_reset();
			const { to_url, data: { token } } = await createTransbank({ id_pedido: data.id }, anon ? anon.token : auth.session.token);
			this.setState({ transbank: { to_url, token }, inputs: { ...inputs, loading: true } });

		} catch (e) {
			Alert.error(`Se ha producido un error al proceder al pago inténtalo más tarde`, 5000);
			this.setState({ inputs: { ...inputs, loading: true } });
		}
	}
	_handleRegion = (e, listRegions) => {
		if (e.target.value) {
			let selectedCommunes = listRegions.filter(x => x.id.toString() === e.target.value)[0].comunas;
			selectedCommunes = selectedCommunes.map(y => ({ value: y.id, label: y.comuna_nombre }));
			this.setState({ communes: selectedCommunes })
			this._handleInput(e);
		} else {
			e.target.value = ''
			this._handleInput(e);
		}


	}
	_handleQuantity = (index, value) => {
		const { cart, updateItems } = this.props;
		if (cart.items[index].cantidad + value !== 0) {
			cart.items[index].cantidad += value;
			updateItems(cart.items);
		}
	}
	_detectDelete = (e) => {
		if (e.key === "Delete" || e.key === "Backspace") {
			this.setState({ remove: true })
		} else {
			this.setState({ remove: false });
		}
	}

	_clearFields = (isRut) => {
		if(!isRut){
			return;
		}
		if (this.state.inputs.buy_without_account) {
			return;
		}
		const fieldsToClear = [
			'bill_business_name',
			'bill_commune_id',
			'bill_phone',
			'bill_region_id',
			'bill_rut',
			'bill_turn',
			'last_name',
			'name',
			'phone',
			'direccion_empresa',
			'direccion',
			'description',
			'department_number',
			'bill_address_description',
			'note'
		];
		isRut ? fieldsToClear.push('email') : fieldsToClear.push('rut')
		const { inputs } = this.state;
		const { updateCheckout } = this.props

		Object.keys(inputs).forEach((item) => {
			if (fieldsToClear.indexOf(item) !== -1) {
				inputs[item] = ''
			}
		})

		this.setState({ inputs })
		updateCheckout({ ...inputs });

	}

	_handleRut = async (e) => {
		let bool= e.target.name === 'third_rut' || e.target.name=== 'bill_rut' ? false : true
		this.setState({ inputs: _maskRut(e, this.state, () => this._clearFields(bool)) }, async () => {
			const { inputs, invalidations } = this.state;
			const { checkUserRut, updateCheckout } = this.props;
			updateCheckout({ ...inputs });
			if (RulesValidate.rutEsValido(inputs[e.target.name])) {
				if (invalidations[e.target.name]) {
					delete invalidations[e.target.name]
					this.setState({ invalidations })
					Api.FormValidator.validate(this.formInfo.current, invalidations);
					Api.FormValidator.validate(this.formAddress.current, invalidations);
				}
				if (e.target.name === 'rut') {
					const checkrutResponse = await checkUserRut(e.target.value.replace(/[^\dkK-]/g, ''), inputs.buy_without_account);
					const { error } = checkrutResponse
					if (error) {
						this._clearFields(true)
					}
				}
			} else {
				if (e.target.value.length > 10) {
					const rutError = { [e.target.name]: ['El rut no es válido'] }
					const errors = { ...invalidations, ...rutError };
					if (e.target.name === 'third_rut') {
						Api.FormValidator.validate(this.formAddress.current, errors);
					} else {
						Api.FormValidator.validate(this.formInfo.current, errors);
					}
					this.setState({ invalidations: errors }, () => {
						if (e.target.name === 'rut') {
							this._clearFields(true);
						}
					})
				}
			}
		})
	}

	_handleAddress = (id) => {
		const { inputs } = this.state;
		this.setState({ inputs: { ...inputs, address_id: id } })
	}
	componentWillUnmount() {
		document.removeEventListener('keydown', this._detectDelete, false);
	}
	render() {
		const listRegions = GetRegions();
		const handleInput = this._handleInput;
		const handlePayment = this._handlePayment;
		const handleRut = this._handleRut;
		const handlePhone = this._handlePhone;
		const handleRegion = this._handleRegion;
		const handleQuantity = this._handleQuantity;
		const handleDeliveryType = this._handleDeliveryType;
		const handleBill = this._handleBill;
		const handleAddress = this._handleAddress;
		const { auth, checkout, updateItems, updateShipping, updateCheckout } = this.props;
		const { inputs, communes, transbank, noPhone } = this.state;
		return (
			<>
				<BannerTitle title={this.state.banner?.texto} image={`${linkApi}/imagenes/banners/${this.state.banner?.imagen_path[0]}`} checkout />
				<div className="container mb-5">
					<h5 className="my-5 text-grey text-center">Completa los siguientes campos para realizar tu pedido</h5>
					<div className="row">
						<div className="col-4">
							<form ref={this.formInfo}>
								<Form {...{ inputs, auth, communes, listRegions, handleInput, handleRut, handlePhone, handleRegion, handleBill, noPhone }} />
							</form>
						</div>
						<div className="col-8">
							<form ref={this.formAddress}>
								<Delivery {...{ inputs, handleInput, handlePhone, handleRut, handlePayment, handleQuantity, handleDeliveryType, checkout, updateItems, updateShipping, updateCheckout, handleAddress }} />
							</form>
						</div>
						{transbank ? <SetupForm token={transbank.token} url={transbank.to_url} /> : null}
					</div>
				</div>
				<FooterMain main />
			</>
		)
	}
}
const mapStateToProps = (state) => ({
	auth: state.auth,
	checkout: state.checkout,
	payment: state.payment,
	cart: state.cart,
	banners: state.banners
});

const mapDispatchToProps = dispatch => ({
	createOrder: (data, token) => dispatch(create_order(data, token)),
	checkUserRut: (data, without_account) => dispatch(checkUserRut(data, without_account)),
	checkUserEmail: (data, without_account) => dispatch(checkUserEmail(data, without_account)),
	updateCheckout: (data) => dispatch(checkout_update_form(data)),
	updateSession: (data) => dispatch(update_session(data)),
	createTransbank: (data, token) => dispatch(create_transbank(data, token)),
	updateItems: (data, discount_code) => dispatch(updateItems(data, discount_code)),
	updateShipping: (data) => dispatch(cart_update_shipping(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Desktop);
