import { handleActions } from 'redux-actions';
import { fetch_start_register, fetch_error_register, fetch_success_register, register_resert } from '../actions/register';

export const INITIAL_STATE = {
    loading: false,
    error: null,
    success: false,
}

export default handleActions({
    [fetch_start_register]: (state) => {
        return {...state, loading: true}
    },
    [fetch_error_register]: (state) => {
        return {...state, loading: false, error: true}
    },
    [fetch_success_register]: (state, action) => {
        return {...state, success: true, loading: false, error: false}
    },
    [register_resert]: (...state) => {
        return {...state, success: false, loading: false, error: null}
    }
}, INITIAL_STATE);
