import { createAction } from 'redux-actions';
import { CreateOrder, CreateTransbank } from '../../services/api/endpoints';

export const fetch_start_payment = createAction('fetch_start_payment');
export const fetch_success_payment = createAction('fetch_success_payment');
export const fetch_error_payment = createAction('fetch_error_payment');

export function create_order(data, token) {
    return async (dispatch) => {
        dispatch(fetch_start_payment());
        try {
            const response = await CreateOrder(data, token);
            await dispatch(fetch_success_payment(response));
            return response;
        } catch (e) {
            dispatch(fetch_error_payment());
            return false;
        }
    }
}

export function create_transbank(data, token) {
    return async (dispatch) => {
        dispatch(fetch_start_payment());
        try {
            const response = await CreateTransbank(data, token);
            await dispatch(fetch_success_payment(response));
            return response;
        } catch (e) {
            dispatch(fetch_error_payment());
            return false;
        }
    }
}