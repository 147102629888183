import { Component } from 'react'
import { connect } from 'react-redux'
import Input from '../../../components/Admin/Input'
import Table from '../../../components/Admin/Table'
import Title from '../../../components/Admin/Title'
import ProductActions from '../../../components/Admin/Actions/Product'
import AddProducts from '../../../components/Admin/Products/AddProducts'
import { OPEN_DRAWER, SET_RENDER } from '../../../redux/actions/drawer'
import { END_LOADING, START_LOADING } from '../../../redux/actions/loader'
import '../../../styles/page.admin.products.scss'
import { getProductsList } from '../../../redux/actions/product'
import history from '../../../services/history'
import { productGridHeaders } from '../../../utils/constants'
import { Notification } from 'rsuite'

class Products extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputs: {
                search: ''
            },
            data: {
                headers: [],
                items: []
            }
        }
    }

    async componentDidMount() {
        await this._showProductList();
    }

    init() {
        const { START_LOADING, END_LOADING } = this.props
        START_LOADING();
        const responseApi = this.props.products;
        const items = [];
        responseApi.data.forEach((item) => {
            const actions = <ProductActions id={item.id} item={item} />
            items.push({ id: item.id, sku: item.sku, name: item.nombre, actions });
        })
        this.setState({ data: { headers: productGridHeaders, items: items.sort((a, b) => a.id - b.id) } })
        END_LOADING();
    }

    _showProductList = async () => {
        const { START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const { getProducts } = this.props;
        await getProducts(history.location.search, 1, 15);
        END_LOADING()
    }

    hasPermission = () => {
        return JSON.parse(sessionStorage.getItem('modules'))['productos'].edit;
    }

    handleAdd = () => {
        if (this.hasPermission()) {
            const { SET_RENDER, OPEN_DRAWER } = this.props
            SET_RENDER(AddProducts)
            OPEN_DRAWER()
        } else {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
        }
    }

    componentDidUpdate(prevProp) {
        if (prevProp.products?.data !== this.props.products?.data) this.init();
    }

    filterProducts = ({ target }) => {
        const { products } = this.props
        const { value } = target
        const items = []
        products.data.filter((p) => {
            if (Number(p.id) === Number(value) || p.nombre.toLowerCase().indexOf(value.toLowerCase()) !== -1 || p.sku.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                return p
            }
            return false
        }).forEach((item) => {
            const actions = <ProductActions id={item.id} item={item} />
            items.push({ id: item.id, sku: item.sku, name: item.nombre, actions });
        })
        this.setState({ inputs: { search: value }, data: { headers: productGridHeaders, items } })
    }

    cleanInput = () => {
        const { products } = this.props
        const items = products.data.map((item) => {
            const actions = <ProductActions id={item.id} item={item} />
            return { id: item.id, sku: item.sku, name: item.nombre, actions }
        })
        this.setState({ inputs: { search: '' }, data: { headers: productGridHeaders, items } })
    }

    render() {
        const { inputs, data } = this.state
        const { search } = inputs
        return (
            <div className="container-products">
                <Title text="Productos" />
                <div className="container-products__actions p-md-2">
                    <div className="container-products__actions__search">
                        <Input
                            type="text"
                            value={search}
                            name="search"
                            placeholder="Buscar id, sku o nombre..."
                            onChange={this.filterProducts}
                        />
                    </div>

                    <div className="container-products__actions__buttons">
                        <button onClick={this.cleanInput} className="btn d-none d-sm-block"><i className="fa fa-times-circle"></i>Limpiar</button>
                        <button className="btn" onClick={this.handleAdd}><i className="fa fa-plus-circle"></i>Agregar</button>
                    </div>
                </div>

                {(data.items.length !== 0)&& (
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-2">
                        <Table data={data} />
                    </div>
                )}
                {(data.items.length === 0) && (
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-3 text-center">
                        No hay productos registrados...
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.products
})
const mapDispatchToProps = (dispatch) => ({
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
    getProducts: (data, page, pageSize) => dispatch(getProductsList(data, page, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(Products)