import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import FooterMain from '../../components/Footers/Footer_Main';
import history from '../../services/history';
import * as QueryString from 'query-string';
import { ShowPayment } from '../../services/api/endpoints';
import { cart_reset } from '../../redux/actions/cart';
import { get_currency } from '../../utils/currency';
import AssetsImg from '../../assets/image';
import { Loader } from 'rsuite';
import moment from 'moment';

class PurchaseDetails extends Component {
    state = {
        loading: true,
        msg: '',
        payment: {},
    }

    componentDidMount = async () => {
        const { cart_reset } = this.props;
        let transaction = QueryString.parse(history.location.search).transaction;
        cart_reset();
        if (transaction) {
            await ShowPayment(transaction)
                .then(response => {
                    if (response.buy_order) {
                        this.setState({ payment: response, loading: false });
                    } else {
                        this.setState({ payment: { status: 'no_found' }, loading: false });
                    }
                })
                .catch(e => {
                    this.setState({ payment: { status: 'error' }, loading: false })
                })
        }
    }

    discountCalc = (items, discount) => {
        let totalValue = 0;
        items.forEach((item) => {
            totalValue += item.cantidad * item.valor_iva;
        })
        const discountValue = Math.round(totalValue * discount / 100);
        return { totalValue, discountValue }
    }

    render() {
        const { loading, payment } = this.state;
        let discount = null;
        let total = payment.total;
        if (payment && payment.pedido && payment.pedido.cupon && payment.pedido.items) {
            const { discountValue, totalValue } = this.discountCalc(payment.pedido.items, payment.pedido.cupon.valor)
            total = totalValue;
            discount = discountValue
        }
        if (loading) {
            return (
                <>
                    <div className="row bg-i-gray py-5">
                        <div className="col py-5">
                            <div className="container-lg bg-white rounded px-lg-5 py-5">
                                <h2 className="text-center font-weight-bold">
                                    <Loader size="md" content={false} />
                                    <br />
                                    <span className="my-2">Cargando..</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </>
            )
        }
        let msg = '';

        switch (payment.status) {
            case "not_processed":
                msg = "No se pudo procesar tu pago."
                break;
            case "finished":
                msg = "Anulaste tu compra."
                break;
            case "error":
                msg = "Ocurriro un error."
                break;
            case "no_found":
                msg = "Ordene de pago no encontrada.."
                break;
            case "FAILED":
                msg = "No se pudo procesar tu pago.";
                break;
            default:
                msg = '';
                break;
        }
        if (msg !== '') {
            return (
                <>
                    <div className="row bg-i-gray py-5">
                        <div className="col py-5">
                            <div className="container-lg bg-white rounded px-lg-5 py-5">
                                <h2 className="text-center font-weight-bold">
                                    {msg}
                                    <br />
                                    <Link to="/" className="btn btn-one px-3 mt-1">Volver al inicio</Link>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <FooterMain />
                </>
            )
        }
        console.log(payment);
        return (
            <>
                <div className="row bg-i-gray">
                    <div className="col p-sm-4">
                        <div className="container-lg bg-white rounded px-5 pt-4">
                            <div className="d-none d-sm-block">
                                <h2 className="text-center font-weight-bold">¡TU COMPRA HA SIDO REALIZADA!</h2>
                                <p className="font-weight-bold text-center text-orange">
                                    Entregas a domicilio en 5 a 10 días hábiles. Retiro en sucursales al día hábil siguiente de la compra.
                            </p>
                            </div>
                            <div className="d-block d-sm-none text-center">
                                <img src={AssetsImg.Success_Sale} alt="..." className="img-fluid icon-msg" />
                                <p className="text-center bg-orange py-1 text-white">
                                    <strong>Su compra ha sido<br />procesada exitosamente</strong>
                                </p>
                            </div>
                            <div className="d-block d-sm-none text-center">
                                <img src={AssetsImg.Success_Sale} alt="..." className="img-fluid icon-msg" />
                                <p className="text-center bg-orange py-1 text-white">
                                    <strong>Su compra ha sido<br />procesada exitosamente</strong>
                                </p>
                            </div>
                            <p className="text-center bg-i-gray py-1">
                                <strong>DETALLE DE TU COMPRA</strong>
                            </p>
                            <div className="row">
                                <div className="col-12 col-sm-4">
                                    <div className="row">
                                        <div className="col-6">Número de pedido</div>
                                        <div className="col-6 font-weight-bold text-right" style={{ fontSize: '.8em' }}>{payment.codigo_pedido}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6">Fecha de pago</div>
                                        <div className="col-6 font-weight-bold text-right" style={{ fontSize: '.8em' }}>{moment(payment.transaction_date).format('DD - MM - YYYY hh:mm:ss')}</div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <div className="row">
                                        <div className="col-6 col-sm-8">Código de autorizacíon</div>
                                        <div className="col-6 col-sm-4 font-weight-bold text-right" style={{ fontSize: '.8em' }}>{payment.authorization_code}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-sm-8">N° de cuotas</div>
                                        <div className="col-6 col-sm-4 font-weight-bold text-right" style={{ fontSize: '.8em' }}>{payment.installments_number ? payment.installments_number : '0'}</div>
                                    </div>
                                </div>
                                <div className="col-12 col-sm-4">
                                    <div className="row">
                                        <div className="col-6 col-sm-5">Estado</div>
                                        <div className="col-6 col-sm-7 font-weight-bold text-right" style={{ fontSize: '.8em' }}>{payment.transaction_status}</div>
                                    </div>
                                    <div className="row">
                                        <div className="col-6 col-sm-5">N° de tarjeta</div>
                                        <div className="col-6 col-sm-7 font-weight-bold text-right" style={{ fontSize: '.8em' }}>XXXXXXXXX{payment.card_number}</div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <strong>Productos:</strong>
                            {
                                payment.pedido.items.map((item, i) => {
                                    return (
                                        <div className="row mb-1" key={`details-products-${i}`}>
                                            <div className="col-8">
                                                <p key={`details-products-${i}`}>
                                                    <span className="text-dark">{item.nombre}</span>
                                                    <br />
                                                    <span className="text-grey">{item.formato}</span>
                                                    <br />
                                                    <span className="text-grey">{item.retiro_tienda ? 'Retiro en Tienda' : 'Despacho a domicilio'}</span>
                                                </p>
                                            </div>
                                            <div className="col-4 text-right">
                                                <p className="text-dark">{get_currency(Math.round(item.valor_iva * item.cantidad))}</p>
                                            </div>
                                        </div>
                                    );
                                })
                            }
                            <div className="row justify-content-end pb-3">
                                <div className="col-sm-4 col-6">
                                    <div className="row">
                                        <div className="col-4 col-sm-6"><small>Valor</small></div>
                                        <div className="col-8 col-sm-6 font-weight-bold text-right"><small>{get_currency(Math.round(total))}</small></div>
                                    </div>
                                    {
                                        discount ? (
                                            <>
                                                <div className="row">
                                                    <div className="col-4 col-sm-6"><small>{`Descuento ${payment.pedido.cupon.valor}%`}</small></div>
                                                    <div className="col-8 col-sm-6 text-orange font-weight-bold text-right"><small>{get_currency(Math.ceil(discount))}</small></div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-4 col-sm-6"><small>Total</small></div>
                                                    <div className="col-8 col-sm-6 font-weight-bold text-right"><small>{get_currency(Math.round(payment.total))}</small></div>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="row">
                                                <div className="col-7 col-sm-6"><small>Total</small></div>
                                                <div className="col-5 col-sm-6 text-orange font-weight-bold text-right"><small>{get_currency(Math.round(payment.total))}</small></div>
                                            </div>
                                        )
                                    }
                                    <div className="row">
                                        <div className="col-7 col-sm-6"><small>Medio de pago</small></div>
                                        <div className="col-5 col-sm-6 text-right"><small><strong>Webpay</strong></small></div>
                                    </div>
                                </div>
                            </div>
                            <hr className="my-3" />
                            <div className="d-none d-sm-block">
                                <div className="row justify-content-between pb-3">
                                    <div className="col-2">
                                        <img src={AssetsImg.Logo} className="w-100" alt="Logo RioClaro" />
                                    </div>
                                    <div className="col-5">
                                        <p className="mb-0">Revisa tu correo para ver el detalle</p>
                                        <p className="font-weight-bold text-orange my-0">¡GRACIAS POR ELEGIR RIO CLARO!</p>
                                    </div>
                                    <div className="col-5 text-right">
                                        <Link to="/" className="btn px-2 btn-success font-weight-bold">Volver al inicio</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-block d-sm-none">
                            <div className="row justify-content-between pb-3">
                                <div className="col-12 text-center mb-2">
                                    <img src={AssetsImg.Logo} className="w-50" alt="Logo RioClaro" />
                                </div>
                                <div className="col-12 text-center mb-4">
                                    <p className="font-weight-bold mb-0">Revisa tu correo para ver el detalle</p>
                                    <p className="font-weight-bold text-orange mb-0">Entregas a domicilio en 5 a 10 días hábiles. Retiro en sucursales al día hábil siguiente de la compra.</p>
                                </div>
                                <div className="col-12 text-center">
                                    <Link to="/" className="btn px-2 btn-success font-weight-bold">Volver al inicio</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    cart_reset: () => dispatch(cart_reset())
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseDetails);
