import React from 'react';
import PageWrapper from '../../components/Common/Page_Wrapper';

const Us = () => (
    <PageWrapper title="Nosotros" image={null}>
        <div className="row mb-4">
            <div className="col-md-7 col-12">
                <h2 className="text-black mb-4 d-none d-sm-block">HISTORIA</h2>
                <h5 className="text-black d-block d-sm-none">HISTORIA</h5>
                <p className="font-weight-light" style={{ fontSize: '.8em', letterSpacing: 0.5 }}>
                    <i className="font-weight-bold">Rio Claro Pellet Bioenergía</i>
                    nace en el corazón de la región del Maule con el objetivo de elaborar un producto limpio,
                    estandarizado y eficiente, empleando un proceso altamente tecnológico.<br /><br />
                    Somos un equipo profesional incentivados en aportar con un producto que permita reducir
                    las emisiones de CO2 a la atmósfera y contribuir con un aire menos saturado, entregando
                    un producto elaborado bajo procesos de última generación tecnológica en producción.
                    Sistemas computarizados controlan los procesos de nuestra planta permitiendo mantener un exhaustivo control sobre el producto final,
                    asegurando una calidad estandarizada desde el inicio hasta el final. Como resultado obtenemos un pellet de alta calidad de estándar europeo, garantizando un producto confiable para su uso.<br /><br />
                    Trabajamos para conseguir un producto de muy alta calidad, para el que sólo se utilizan partículas de madera de pino procedentes del aserrado. Estamos enfocados en fabricar un combustible
                    ecológico de la forma más sostenible posible. Todo el diseño del proceso se ha planteado tratando de minimizar el consumo energético en todas sus fases.<br /><br />
                    La fabricación del pellet se lleva a cabo con la única aplicación de calor, comprimiendo las micropartículas mediante rodillos y forzando su paso 
                    por las matrices granuladoras, en ausencia total de cualquier aditivo químico o colas aglutinantes. Energía limpia y 100 % natural. Realizamos análisis 
                    continuo en nuestro laboratorio interno para garantizar que el producto fabricado cumple con los altos estándares de calidad que exigimos.
                </p>
                <h2 className="text-black mb-4 d-none d-sm-block">EFICIENCIA ENERGÉTICA</h2>
                <h5 className="text-black d-block d-sm-none">EFICIENCIA ENERGÉTICA</h5>
                <p className="font-weight-light" style={{ fontSize: '.8em', letterSpacing: 0.5 }}>
                    La inversión en tecnología nos ha permitido potenciar los procesos para la fabricación de este producto a través de:<br /><br />
                    <ul>
                        <li>Maquinaria digital automatizada en base a estándar europeo.</li>
                        <li>Monitoreo y trabajo continuo 24 horas al día, los siete días de la semana.</li>
                        <li>Laboratorio de muestreo y medición en control de calidad.</li>
                        <li>Horno de combustión de alta eficiencia, que utiliza como combustible biomasa.</li>
                        <li>Sistema de secado por trómel, con recirculación de gases, disminuyendo las necesidades energéticas y generación de material particulado, con sistema de abatimiento para limpiar el aire antes de su emisión a la atmósfera.</li>
                        <li>Silos intermedios de almacenamiento de material seco, que permiten autonomía entre las distintas fases del proceso, minimizando los tiempos de trabajo improductivos de los equipos.</li>
                    </ul>
                </p>
            </div>
            <div className="col-md-5 col-12">
                <img className="img-fluid2 w-100" src={null} alt="..." />
            </div>
        </div>
    </PageWrapper>
);

export default Us;