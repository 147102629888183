import React from 'react';
import Slider from 'react-slick';
import AssetsImg from '../../assets/image';
import ProductCard from './Product_Card';

function NextArrow(props){
    const { className, style, onClick } = props;
    return(
        <div
            className={className}
            style={style}
            onClick={onClick}
        >
            <img src={AssetsImg.Arrow_Right} className="img-fluid2" alt="..." />
        </div>
    )
}

function PrevArrow(props){
    const { className, style, onClick } = props;
    return(
        <div
            className={className}
            style={style}
            onClick={onClick}
        >
            <img src={AssetsImg.Arrow_Left} className="img-fluid2" alt="..." />
        </div>
    )
}

let settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
        {
            breakpoint: 960, // large
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 720, // medium
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 576, // small
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
    ]
};

const ProductSliderSlick = (props) => {
    settings = props.products.length > 3;
    return (
        <div className="row">
            <div className="col px-0 custom-slick custom-slick-products py-5">
                <Slider {...settings}>
                    {props.products.map((e, i) => (
                        <div key={`product-slick-${i}`}>
                            <div className="row justify-content-center">
                                {
                                    props.products.length === 1 ? (
                                        <div className="col-12 col-sm-6 col-md-4 col-lg-3 px-4">
                                            <ProductCard {...e} center/>
                                        </div>
                                    ) : (
                                        <div className="col-auto px-4">
                                            <ProductCard {...e} center/>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    );
}

export default ProductSliderSlick;