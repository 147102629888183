import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { checkout_update_form } from '../../../redux/actions/checkout';
import { getListAddress } from '../../../redux/actions/address';
import SelectSimple from '../../../components/Selects/Select_Simple';
import LoaderRsuite from '../../../components/Loaders/Loader_Rsuite';
import { Alert } from 'rsuite';

class SelectAddress extends Component {
	componentDidMount = async () => {
		const { auth: { session }, getAddresses } = this.props;
		const { token } = session;
		if (session && session.usuario && session.usuario.confirmado) {
			await getAddresses(token);
		}
	}

	_selectAddress = id => {
		const { cart: { shipping }, checkout, updateCheckout, address: { data }, callback } = this.props;
		let item = data.find(x => x.id === parseInt(id));
		if (id) {
			if (shipping.comuna.id !== parseInt(item.id_comuna)) {
				Alert.warning('La dirección seleccionada, no coincide con la comuna de despacho', 5000);
			} else {
				updateCheckout({ ...checkout.form, address: item, address_id: item.id, description: item.direccion });
				callback && callback(item.id_comuna)
			}
		}
	}

	render() {
		const { address, checkout } = this.props;
		return (
			<LoaderRsuite
				loading={{ run: address.loading, title: "Cargando Direcciones..." }}
				fail={{ run: address.error, title: "Se ha producido un error", description: "Inténtalo más tarde." }}>
				{
					address.data.length > 0 ? (
						<div className="form-group mb-1 col-12">
							<SelectSimple
								type="simple"
								data={address.data.map(z => ({ label: z.direccion, value: z.id }))}
								className="form-select"
								name="address_id"
								placeholder="Seleccionar dirección"
								runChange={e => this._selectAddress(e.target.value)}
								initValue={checkout.form.address_id}
							/>
							<Link to="/perfil/direcciones" className="font-small text-orange">Agregar nueva dirección</Link>
						</div>
					) : (
						<div className="col-12 d-flex justify-content-between">
							<p>No tienes direcciones</p>
							<Link to="/perfil/direcciones" className="font-min font-weight-bold text-orange">Agregar dirección</Link>
						</div>
					)
				}
			</LoaderRsuite>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	cart: state.cart,
	address: state.address,
	checkout: state.checkout
});

const mapDispatchToProps = dispatch => ({
	getAddresses: (token) => dispatch(getListAddress(token)),
	updateCheckout: (data) => dispatch(checkout_update_form(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SelectAddress);