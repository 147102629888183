import { Component } from "react";
import { v4 } from 'uuid'
import '../../../styles/component.admin.autocomplete.scss'
class AutoComplete extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ref: ''
        }
    }

    componentDidMount() {
        this.setState({ ref: v4() })
    }

    render() {
        const { value, className, style, onChange, handleClick, name, id, placeholder, label, type, data, ...rest } = this.props
        const { ref } = this.state
        return (
            <div className="container-autocomplete w-100">
                {label && (
                    <label htmlFor={id || ref} className="form-label">{label}</label>
                )}
                <input
                    type={type || 'text'}
                    className={`form-control ${className}`}
                    id={id || ref}
                    name={name || ''}
                    placeholder={placeholder || ''}
                    value={value || ''}
                    onChange={(e) => onChange(e)}
                    {...rest}
                />
                {data && data.length > 0 && (
                    <div className="container-fluid container-autocomplete__content-searched m-0 p-0">
                        {data.map((item) => (
                            <div className="row m-0" key={v4()}>
                                <div className="col-12 px-4 py-2 container-autocomplete__content-searched__item" onClick={() => handleClick(item.id)}> {item.value} </div>
                            </div>
                        ))}
                    </div>
                )}

            </div>
        )
    }
}

export default AutoComplete