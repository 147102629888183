import { createAction } from 'redux-actions';
import { ProfileUpdate } from '../../services/api/endpoints';
import { update_session } from './auth';

export const fetch_start_profile = createAction('fetch_start_profile');
export const fetch_success_profile = createAction('fetch_success_profile');
export const fetch_error_profile = createAction('fetch_error_profile');

export function updateProfile(data, token) {
    return async (dispatch) => {
        dispatch(fetch_start_profile());
        try {
            const response = await ProfileUpdate(data, token);
            await dispatch(fetch_success_profile());
            await dispatch(update_session({ user: response.data }));
            return response;
        } catch (e) {
            console.log(e, 'error');
            dispatch(fetch_error_profile());
            return false;
        }
    }
}