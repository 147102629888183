import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AssetsImg from '../../assets/image';
import ModalRsuite from '../Modals/Modal_Rsuite';
import AuthCollapse from '../Auth/AuthCollapse';
import history from '../../services/history';
import CartEmpty from '../Carts/Cart_Empty';
import { show } from '../../redux/actions/authModal';
import { Drawer, Notification } from 'rsuite';
import FollowUp from '../FollowUp';
import { doGetDespacho } from '../../services/api/endpoints';

class NavMain extends Component {
	state = {
		handler: { sidebarMobile: false, sidebarCart: false, modal: false },
		inputs: {
			follow_up: ''
		},
		follow_up: {
			show: false,
			data: undefined
		}
	}

	_handlerVisibility(elem) {
		const { handler } = this.state;
		this.setState({ handler: { ...handler, [elem]: !handler[elem] } });
	}

	_handleSidebarCart() {
		const { cart } = this.props;
		if (cart.items.length > 0) {
			history.push("/finalizar-compra")
		} else {
			this._handlerVisibility('modal')
		}
	}

	_handleFollowupSubmit = async (e, value = null) => {
		const { inputs } = this.state
		e.preventDefault();
		try {
			let code = value ? parseInt(value) : parseInt(inputs.follow_up);
			if (isNaN(code)) {
				Notification['error']({
					title: 'Error',
					description: 'Debe ingresar un código de despacho válido.'
				})
				return;
			}
			const despacho = await doGetDespacho(code)
			if (despacho.error) {
				Notification['error']({
					title: 'Error',
					description: 'Hubo un error en la solicitud, inténtelo mas tarde.'
				})
				return;
			}
			if (despacho.estado) {
				Notification['error']({
					title: 'Error',
					description: 'Hubo un error en la solicitud, inténtelo mas tarde.'
				})
				return;
			}
			if (despacho.tracking && despacho.pedido.data) {
				const follow_up = despacho.tracking.filter((d) => d.fecha !== "").map((d) => ({ status: d.estado, created_at: d.fecha }))
				this.setState({ follow_up: { status: follow_up, id: despacho.pedido.data.codigo_pedido, search: false, show: true } })
			} else {
				Notification['error']({
					title: 'Error',
					description: 'No se ha encontrado el código de seguimiento informado'
				})
			}
		} catch (error) {
			Notification['error']({
				title: 'Error',
				description: 'Hubo un error en la solicitud, inténtelo mas tarde.'
			})
		}
	}

	_handleFollowUpMobile = (e, code) => {
		this._handleFollowupSubmit(e, code);
	}

	render() {
		const { handler, inputs, follow_up } = this.state;
		const { auth: { session }, cart, authModal: { visible }, show } = this.props;
		return (
			<>
				<FollowUp
					show={follow_up.show}
					data={follow_up}
					handleClose={() => this.setState({ follow_up: { ...follow_up, show: false, search: false } })}
					handleSearch={this._handleFollowUpMobile}
					search={follow_up.search}
				/>
				<nav className="navbar navbar-dark bg-white main-nav w-100" id={"main-nav"}>
					<div className="container-lg px-4">
						<ul className="nav px-0">
							<li className="nav-item">
								<Link to="/" className="nav-link py-0 px-0">
									<img src={AssetsImg.Logo} className="img-fluid2 logo-main d-flex" alt="Logo RioClaro" />
								</Link>
							</li>
						</ul>
						{/* nav desktop and tablet */}
						<ul className="nav justify-content-end d-none d-sm-flex align-items-center">
							<li className="nav-item">
								<div className="main-nav__search d-flex justify-content-center align-items-center">
									<form onSubmit={this._handleFollowupSubmit}>
										<input
											type="number"
											className="numeric-without-arrow"
											value={inputs.follow_up}
											onChange={(e) => this.setState({ inputs: { ...inputs, follow_up: e.target.value } })}
											placeholder="Código de seguimiento"
										/>
										<span onClick={this._handleFollowupSubmit}><i className="fa fa-search"></i></span>
									</form>
								</div>
							</li>
							<li className="nav-item">
								<Link to="/" className="nav-link px-2 pl-md-3 py-3 d-flex align-items-center" href="#">
									<img src={AssetsImg.Product} className="img-fluid2 icon-small mr-1" alt="..." />
									Productos
								</Link>
							</li>
							<li className="nav-item">
								<Link to="/puntos-de-venta" className="nav-link px-1 pl-md-3 py-3" href="#">Puntos de venta</Link>
							</li>
							<li className="nav-item">
								<button className="btn btn-cart nav-link px-2 pl-md-3 py-3 d-flex align-items-center bg-white" onClick={() => this._handleSidebarCart()}>
									{cart.items.length > 0 ? <span className="btn-cart-count">{cart.items.length}</span> : null}
									<img src={AssetsImg.Cart} className="img-fluid2 icon-small mr-1" alt="..." style={{ width: 20 }} />
								</button>
							</li>
							<li className="nav-item">
								{
									(session && session.usuario.id_perfil === undefined) ?
										<Link className="btn-user nav-link pl-md-3 pr-0 py-2" to="/perfil">
											<img src={AssetsImg.User} className="img-fluid2" alt="..." style={{ height: '20px' }} />
										</Link>
										: <button className="nav-link py-1 text-white btn btn-one btn-sm ml-2" onClick={() => show()}>Inicia Sesión</button>
								}
							</li>
						</ul>
						{/* nav mobile */}
						<ul className="nav justify-content-end d-flex d-sm-none">
							<li className="nav-item">
								<button className="btn btn-user nav-link pl-2 pl-md-3 pr-0 py-2" type="button" onClick={() => this._handlerVisibility('sidebarMobile')}>
									<img src={AssetsImg.Toggler} className="img-fluid2" alt="..." />
								</button>
							</li>
						</ul>
					</div>
				</nav>

				{/* Sidebar mobile */}
				<Drawer
					size="xs"
					show={handler.sidebarMobile}
					onHide={() => this._handlerVisibility('sidebarMobile')}
					backdrop={true}
					className="sidebar-mobile bg-orange"
					style={{ display: 'block' }}>
					<button type="button" className="btn-close" aria-label="Close" onClick={() => this._handlerVisibility('sidebarMobile')}></button>
					<div className="row h-100 align-items-center">
						<div className="col-12 mt-4">
							<div className="list-group list-group-flush px-3">
								<Link to="/" className="text-white bg-transparent px-0 py-2" onClick={() => this._handlerVisibility('sidebarMobile')}>
									Productos
								</Link>
								<Link to="/puntos-de-venta" className="text-white bg-transparent px-0 py-2" onClick={() => this._handlerVisibility('sidebarMobile')}>
									Puntos de Venta
								</Link>
								<Link to="/terminos-y-condiciones" className="text-white bg-transparent px-0 py-2" onClick={() => this._handlerVisibility('sidebarMobile')}>
									Términos y Condiciones
								</Link>
								<span
									className="text-white bg-transparent px-0 py-2"
									onClick={() => this.setState({ follow_up: { ...follow_up, show: true, search: true } }, () => this._handlerVisibility('sidebarMobile'))}
								>
									Seguimiento
								</span>
								<a href="https://pelletrioclaro.cl/distribuidores/" className="text-white bg-transparent px-0 py-2" onClick={() => this._handlerVisibility('sidebarMobile')}>
									Distribuidores
								</a>
							</div>
						</div>
						<div className="col-12">
							<div className="col px-3">
								<img className="img-fluid mb-5 sidebar-logo" src={AssetsImg.Logo_W} alt="RioClaro" />
								<p className="m-0 text-white">Ruta 5 Sur, km 266</p>
								<p className="m-0 text-white">3530000 Maule, Chile</p>
								<p className="m-0 text-white">www.pelletrioclaro.cl</p>
							</div>
						</div>
					</div>
				</Drawer>
				<ModalRsuite
					open={handler.modal}
					handleModal={() => this._handlerVisibility('modal')}
					propsModal={{ size: 'xs', overflow: true }}
				>
					<CartEmpty action={() => {
						this._handlerVisibility('modal');
						history.push("/")
					}} />
				</ModalRsuite>
				<ModalRsuite
					open={visible}
					handleModal={() => show()}
					propsModal={{ size: 'xs', overflow: false }}
					idCall="auth-modal-in-layout"
				>
					<AuthCollapse handleAuth={() => show()} />
				</ModalRsuite>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	authModal: state.authModal,
	cart: state.cart,
	checkout: state.checkout,
});

const mapDispatchToProps = dispatch => ({
	show: () => dispatch(show()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NavMain);