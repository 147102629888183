import { Component } from "react";
import { connect } from "react-redux";
import { categorySchema } from '../../../utils/validations';
import Input from "../Input";
import Switch from "../Switch";
import Title from "../Title";
import validate from '../../../utils/validate';
import '../../../styles/component.admin.roles.scss';
import { Modules } from "../../../services/api/endpoints";
import { doEditRole } from "../../../redux/actions/role";
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { v4 } from "uuid";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";
class EditRole extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputs: { nombre: '' },
            data: [],
            headers: [],
            errors: {},
            modulesData: [],
            modulesView: []
        }
    }

    async componentDidMount() {
        const { role, auth } = this.props
        const headers = [
            {
                name: "Nombre",
                target: "name"
            },
            {
                name: "Ver",
                target: "view"
            },
            {
                name: "Editar",
                target: "edit"
            }
        ]
        const modulesData = await Modules(auth.session.token);
        const inputs = {}
        const n = [];
        const checkedData = [];
        modulesData.data.forEach((item) => {
            const view = this.getCheckedProp(item.nombre, 'view')
            const edit = this.getCheckedProp(item.nombre, 'edit')
            n.push({ ...item, view, edit });
            checkedData.push({ ...item, view, edit });
        })
        inputs.data = n
        inputs.nombre = role.nombre
        this.setState({
            headers,
            inputs,
            modulesData,
            data: checkedData
        })
    }

    getCheckedProp = (module, prop) => {
        const { role } = this.props
        const { data } = role
        const f = Object.keys(data).find((k) => k === module.toLowerCase());
        if (f) {
            return data[f][prop]
        }
        return false
    }

    handleToggleSwitch = (module, prop, e) => {
        const { data } = this.state
        const item = data.findIndex((m) => m.nombre === module);
        data[item][prop] = e.target.checked
        this.setState({ data })
    }

    handleChange = ({ target }) => {
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [target.name]: target.value } }, () => {
            const { errors } = this.state
            Object.keys(errors).forEach((key) => {
                if (key === target.name) {
                    delete errors[key]
                }
            })
            this.setState({ errors })
        })
    }

    handleSwitch = ({ target }) => {
        const { inputs } = this.state
        const { modulos } = inputs
        this.setState({ inputs: { ...inputs, modulos: { ...modulos, [target.name]: target.checked } } })
    }

    handleSubmit = async () => {
        const { inputs } = this.state
        const validations = await validate(categorySchema, inputs, this._editRole)
        if (validations) {
            this.setState({ errors: validations })
        }
    }

    _editRole = async () => {
        const { editRole, auth, drawer, CLOSE_DRAWER, role, START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const { inputs, data } = this.state
        const permissions = {}
        data.forEach((m) => {
            permissions[m.nombre.toLowerCase()] = { view: m.view, edit: m.edit }
        })
        await editRole({ nombre: inputs.nombre, data: permissions }, role.id, auth.session.token)
        END_LOADING()
        CLOSE_DRAWER(drawer.RENDER);
    }

    render() {
        const { inputs, errors, headers, data } = this.state
        const { nombre } = inputs
        return (
            <div className="container-assign-user size mt-5">
                <Title text="Editar Role" />
                <div className="row justify-content-center px-md-2 px-1 mb-2">
                    <div className="col-12 mb-2">
                        <Input
                            label="Nombre"
                            placeholder="Nombre Role"
                            name="nombre"
                            errors={errors}
                            value={nombre}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>

                <Title text="Modulos" />

                <div className="row justify-content-center px-md-2 px-1 mb-2">
                    <div className="container-table">
                        <table className="table table-striped table-hover table-bordered" style={{ overflow: 'auto' }}>
                            <thead>
                                <tr>
                                    {headers && headers.map((header, index) => (
                                        <th className="text-center" scope="col" key={v4()}>
                                            {header.name}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.length > 0
                                    ? data.map((item, index) => (
                                        <tr key={v4()}>
                                            <td className="text-center" key={v4()}>
                                                {item.nombre === "Despachos" ? "Reparto" : item.nombre}
                                            </td>
                                            <td className="text-center role-check" key={v4()}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}><Switch checked={item.view} onChange={(event) => this.handleToggleSwitch(item.nombre, 'view', event)} /></div>
                                            </td>
                                            <td className="text-center role-check" key={v4()}>
                                                <div style={{ display: 'flex', justifyContent: 'center' }}><Switch checked={item.edit} onChange={(event) => this.handleToggleSwitch(item.nombre, 'edit', event)} /></div>
                                            </td>
                                        </tr>
                                    ))
                                    : (<tr><td className="text-center">'Sin Data'</td></tr>)}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="row justify-content-center px-md-2 px-1 mb-2">
                    <div className="col-12 mb-2 d-flex justify-content-end">
                        <button className="btn btn-rioclaro" onClick={this.handleSubmit}>Guardar</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    role: state.role,
    auth: state.auth,
    drawer: state.drawer
})
const mapDispatchToProps = (dispatch) => ({
    editRole: (data, id, token) => dispatch(doEditRole(data, id, token)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING())
})
export default connect(mapStateToProps, mapDispatchToProps)(EditRole)