import React from 'react';
import history from '../../services/history';
import { Icon } from 'rsuite';

const BackLink = (props) => {
	return(
		<p className="d-block">
			<button onClick={() => history.goBack()} className="text-dark" style={{ background: 'transparent' }}>
				<Icon icon='chevron-left' /> {`Inicio / ${props.actual}`}
			</button>
		</p>
	);
}

export default BackLink;