export const validateScreens = (width, size) => {
	const list = {
		sm:576,
		md:768,
		lg:992,
		xl:1200,
		xxl:1400,
	}
	if ( typeof list[size] !== undefined ) {
		return width<=list[size];
	}else{
		return undefined;
	}
}