import { handleActions } from 'redux-actions';
import { fetch_start_detail, fetch_success_detail, fetch_error_detail } from '../actions/detail';

export const INITIAL_STATE = {
    data: [],
    loading: false,
    success: false,
    error: null
};

export default handleActions({
    [fetch_start_detail]: (state) => {
        return {...state, loading: true};
    },
    [fetch_success_detail]: (state, action) => {
        const {data} = action.payload;
        return {...state, loading: false, success: true, data};
    },
    [fetch_error_detail]: (state) => {
        return {...state, loading: false, error: true};
    }
}, INITIAL_STATE);

