import React, { Component } from "react";
import { connect } from "react-redux";
import { CLOSE_DRAWER, OPEN_DRAWER, SET_RENDER } from "../../../redux/actions/drawer";
import Input from "../Input";
import Select from "../Select";
import Title from "../Title";
import CardProduct from "./CardProduct";
import '../../../styles/component.admin.products.scss'
import { GetOffices, GetRegions } from "../../../services/session";
import { doEditProduct, doGetProduct } from "../../../redux/actions/product";
import { getFormatosList } from "../../../redux/actions/formato";
import AutoComplete from "../AutoComplete";
import { v4 } from "uuid";
import validate from '../../../utils/validate';
import { priceSchemaDelivery, priceSchemaWithdraw } from "../../../utils/validations";
import { GetLocalidades, AllProducts, Defontana, doCreatePriceList, doEditPriceList } from "../../../services/api/endpoints";
import Table from "../Table";
import { paginate } from "../../../utils/helpers";
import { Toggle } from "rsuite";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";
import Dialogs from "../Dialogs";
const linkApi = process.env.REACT_APP_ENV_API_URL

const initialState = {
    format_id: -1,
    defontana_code: '',
    stock: false,
    region_id_delivery: -1,
    commune_id_delivery: -1,
    amount_delivery: '',
    region_id_withdraw: -1,
    commune_id_withdraw: -1,
    sucursal_id: -1,
    amount_withdraw: '',
    despacho: [],
    retiro: []
}

const headers = [
    {
        name: 'id',
        target: 'id_formato_disponible'
    },
    {
        name: 'Región',
        target: 'region'
    },
    {
        name: 'Comuna',
        target: 'comuna'
    },
    {
        name: 'Nombre',
        target: 'nombre'
    },
    {
        name: 'Cantidad',
        target: 'cantidad'
    },
    {
        name: 'Código defontana',
        target: 'defontana'
    },
    {
        name: 'Valor Neto',
        target: 'valor_retiro_tienda'
    },
    {
        name: 'Valor Bruto',
        target: 'valor_retiro_tienda_iva'
    },
    {
        name: 'Acciones',
        target: 'actions'
    }
]

const PAGE_SIZE = 20;
class AdvancedProduct extends Component {

    constructor(props) {
        super(props)
        this.state = {
            inputs: {},
            regions: [],
            citiesDelivery: [],
            citiesWithdraw: [],
            allRegions: [],
            offices: [],
            formatos: [],
            relatedProducts: [],
            filtered: [],
            productsData: [],
            relatedProductsIds: "",
            retreat: {},
            delivery: {},
            editId: -1,
            filteredDelivery: [],
            filteredRetreat: [],
            pageNumber: 1,
            errors: {},
            editActive: true,
            defontana: [],
            toogleStatus: {
                showModal: false,
                checkedItem: false,
                idItem: -1,
                typeItem: '',
                comuneItem: {}
            },
            loading: false
        }
    }

    async componentDidMount() {
        const { START_LOADING, END_LOADING, product } = this.props
        START_LOADING()
        if (product.type !== "RELATED") {
            await this._getFormatos(this.props.product.id_categoria)
            await this._getProduct()
        }
        try {
            this.init();
        } catch (error) {
            console.log(error);
        }
        setTimeout(() => {
            END_LOADING()
        }, 200);
    }

    async init() {
        const { formato, product, products, START_LOADING, END_LOADING, auth } = this.props
        setTimeout(() => {
            START_LOADING()
        }, 200);
        const allRegions = GetRegions()
        //(await GetLocalidades(this.props.auth.session.token) || []).comunas
        const officesResp = GetOffices()
        const offices = officesResp === null ? [] : officesResp.map((o) => ({ id: o.id, value: o.nombre }))
        //TODO: filtro no muestra ninguna región const regions = allRegions.map((region) => ({ id: region.id, value: region.region_nombre })).filter((region) => !!region.id_punto_venta)
        const regions = allRegions.filter((region) => region.comunas?.some((comuna) => !!comuna.id_punto_venta)).map((region) => ({ id: region.id, value: region.region_nombre }))
        //const regions = allRegions.map((region) => ({ id: region.id, value: region.region_nombre }))
        const data = product.type === "RELATED" ? (await AllProducts({})).data.map((p) => ({ id: p.id, value: `${p.id} ${p.sku} ${p.nombre}` })) : [];
        let relatedProducts = [];
        let relatedProductsIds = "";
        if (product.type === "RETIRO" && product.retiro) {
            this.getTableDataRetreat(product.retiro)
        }
        if (product.type === "DESPACHO" && product.despacho) {
            this.getTableDataDelivery(product.despacho)
        }
        if (product.productos_relacionados && Array.isArray(product.productos_relacionados)) {
            /* eslint-disable */
            relatedProducts = product.productos_relacionados.map((p) => {
                const product = products.data.find((pro) => {
                    return p.id === pro.id
                });
                if (product) {
                    if (relatedProductsIds === '') {
                        relatedProductsIds = relatedProductsIds + product.id;
                    } else {
                        relatedProductsIds = relatedProductsIds + "," + product.id;
                    }
                    return product;
                }
            });
            /* eslint-enable */
        }
        const defontana = product.type !== "RELATED" ? (await Defontana(auth.session.token)).data.filter(d => d.id_producto === product.id) : [];
        const formatos = [];
        formato.data.forEach(f => {
            const find = defontana.find(d => d.id_formato === f.id);
            if (find) {
                formatos.push({ id: f.id, value: f.nombre })
            }
        });
        this.setState({ inputs: { ...initialState }, regions, allRegions, offices, formatos, relatedProducts: relatedProducts || [], productsData: data, relatedProductsIds, defontana, citiesDelivery: [] })
        setTimeout(() => {
            END_LOADING()
        }, 200);
    }

    getTableDataRetreat(retiro) {
        let items = []
        retiro.forEach((item) => {
            const { sucursales_disponibles } = item;
            sucursales_disponibles.forEach((comuna) => {
                const { formatos } = comuna
                const newItems = formatos.map((formato) => this.retreatItem(formato, item, comuna))
                items = items.concat(newItems);
            })
        })
        const totalPages = Math.ceil(items.length / 20);
        this.setState({ retreat: items, totalPages }, () => {
            const items = paginate(this.state.retreat, PAGE_SIZE, this.state.pageNumber);
            this.setState({ filteredRetreat: items });
        })
    }


    getTableDataDelivery(delivery) {
        let items = []
        delivery.forEach((item) => {
            const { comunas_disponibles } = item;
            comunas_disponibles.forEach((comuna) => {
                const { formatos } = comuna
                const newItems = formatos.map((formato) => this.deliveryItem(formato, item, comuna))
                items = items.concat(newItems)
            })
        })
        const totalPages = Math.ceil(items.length / 20);
        this.setState({ delivery: items, totalPages }, () => {
            const items = paginate(this.state.delivery, PAGE_SIZE, this.state.pageNumber);
            this.setState({ filteredDelivery: items });
        })
    }

    retreatItem = (formato, item, comuna) => ({
        id_formato_disponible: formato.id_lista_precio,
        region: item.region_nombre,
        comuna: comuna.nombre,
        nombre: formato.nombre,
        cantidad: formato.cantidad,
        codigo_defontana: formato.codigo_producto_defontana,
        valor_retiro_tienda: formato.valor_retiro_tienda,
        valor_retiro_tienda_iva: formato.valor_retiro_tienda_iva,
        actions: (<>
            <i className="fa fa-pencil" onClick={() => this.setEditData(formato.id_lista_precio, item, comuna, formato.valor_retiro_tienda, "retreat", formato.status, formato.codigo_producto_defontana, formato.id)} style={{ marginRight: 8, color: formato.id_lista_precio === this.state.editId ? "red" : "" }}></i>
            <Toggle
                onChange={checked => this.setState({
                    toogleStatus: {
                        showModal: true,
                        checkedItem: checked,
                        idItem: formato.id_lista_precio,
                        typeItem: 'retreat',
                        comuneItem: comuna
                    }
                })}
                className="ml-2 mb-1"
                checked={formato.status}
                size="lg"
                checkedChildren="Activo"
                unCheckedChildren="Inactivo" />
        </>)
    })
    deliveryItem = (formato, item, comuna) => ({
        id_formato_disponible: formato.id_lista_precio,
        region: item.region_nombre,
        comuna: comuna.comuna_nombre,
        nombre: formato.nombre,
        cantidad: formato.cantidad,
        codigo_defontana: formato.codigo_producto_defontana,
        valor_despacho: formato.valor_despacho,
        valor_despacho_iva: formato.valor_despacho_iva,
        actions: (
            <>
                <i className="fa fa-pencil" onClick={() => this.setEditData(formato.id_lista_precio, item, comuna, formato.valor_despacho, "delivery", formato.status, formato.codigo_producto_defontana, formato.id)} style={{ marginRight: 8, color: formato.id_lista_precio === this.state.editId ? "red" : "" }}></i>
                <Toggle
                    onChange={checked => this.setState({
                        toogleStatus: {
                            showModal: true,
                            checkedItem: checked,
                            idItem: formato.id_lista_precio,
                            typeItem: 'delivery',
                            comuneItem: comuna
                        }
                    })}
                    className="ml-2 mb-1"
                    checked={formato.status}
                    size="lg"
                    checkedChildren="Activo"
                    unCheckedChildren="Inactivo" />
            </>
        )
    })
    componentDidUpdate(prevProp, prevState) {
        if (prevState.pageNumber !== this.state.pageNumber) {
            if (this.props.product.type === "DESPACHO") {
                const items = paginate(this.state.delivery, PAGE_SIZE, this.state.pageNumber);
                this.setState({ filteredDelivery: items })
            } else {
                const items = paginate(this.state.retreat, PAGE_SIZE, this.state.pageNumber);
                this.setState({ filteredRetreat: items })
            }
        }
    }

    async _getProduct() {
        const { getProduct, product } = this.props
        await getProduct(product.id);
    }

    async _getFormatos(id_categoria) {
        const { getFormatos } = this.props
        await getFormatos(id_categoria)
    }

    handleChange = ({ target }) => {
        let { name, value } = target
        const { inputs } = this.state
        if (name === "defontana_code" && isNaN(value)) {
            return;
        }
        if ((name === "amount_delivery" || name === "amount_withdraw") && value !== "") {
            value = this.format(value?.toString().replace(/\D/g, ''));
        }

        if (name === "commune_id_delivery" && inputs?.format_id) {
            const comuna = this.state.citiesDelivery.find((i) => Number(i.id) === Number(value))
            const formato = this.state.formatos.find((i) => Number(i.id) === Number(inputs?.format_id))
            const item = this.state.delivery.find((i) => i.nombre === formato?.value && i.comuna === comuna?.value)
            if (item) {
                this.setState({ editId: item.id_formato_disponible, editActive: true }, async () => {
                    await this.handleChange({ target: { name: "amount_delivery", value: item.valor_despacho } })
                    this.handleChange({ target: { name: "defontana_code", value: item.codigo_defontana } })
                })
            } else {
                this.setState({ editId: -1, editActive: false }, async () => {
                    await this.handleChange({ target: { name: "amount_delivery", value: "" } })
                })
            }
        }
        if (name === "sucursal_id" && inputs?.format_id) {
            const sucursal = this.state.offices.find((i) => Number(i.id) === Number(value))
            const formato = this.state.formatos.find((i) => Number(i.id) === Number(inputs?.format_id))
            const item = this.state.retreat.find((i) => i.nombre === formato?.value && i.comuna === sucursal?.value)
            if (item) {
                this.setState({ editId: item.id_formato_disponible, editActive: true }, async () => {
                    await this.handleChange({ target: { name: "amount_withdraw", value: item.valor_retiro_tienda } })
                    this.handleChange({ target: { name: "defontana_code", value: item.codigo_defontana } })
                })
            } else {
                this.setState({ editId: -1, editActive: false }, async () => {
                    await this.handleChange({ target: { name: "amount_withdraw", value: "" } })
                })
            }
        }

        return new Promise(async (resolve) => {
            if (name === "region_id_delivery" || name === "region_id_withdraw") {
                const cities = (await GetLocalidades(this.props.auth.session.token) || []).comunas?.filter((i) => Number(i.region_id) === Number(value) && !!i.id_punto_venta).map((c) => ({ id: c.id, value: c.comuna_nombre }));
                name === "region_id_delivery" ? this.setState({ citiesDelivery: cities }) : this.setState({ citiesWithdraw: cities });
            }
            if (name === "format_id") {
                const defontana = this.state.defontana.find(d => d.id_formato.toString() === value);

                this.setState({ inputs: { ...inputs, [name]: value, defontana_code: defontana?.codigo_producto_defontana } }, () => {
                    let item
                    if (inputs.sucursal_id !== -1) {
                        const sucursal = this.state.offices.find((i) => Number(i.id) === Number(inputs?.sucursal_id))
                        const formato = this.state.formatos.find((i) => Number(i.id) === Number(value))
                        item = this.state.retreat.find((i) => i.nombre === formato?.value && i.comuna === sucursal?.value)
                        if (item) {
                            this.setState({ editId: item.id_formato_disponible, editActive: true }, async () => {
                                await this.handleChange({ target: { name: "amount_withdraw", value: item.valor_retiro_tienda } })
                                this.handleChange({ target: { name: "defontana_code", value: item.codigo_defontana } })
                            })
                        } else {
                            this.setState({ editId: -1, editActive: false }, async () => {
                                await this.handleChange({ target: { name: "amount_withdraw", value: "" } })
                            })
                        }
                    } else if (inputs.commune_id_delivery !== -1) {
                        const comuna = this.state.citiesDelivery.find((i) => Number(i.id) === Number(inputs?.commune_id_delivery))
                        const formato = this.state.formatos.find((i) => Number(i.id) === Number(value))
                        item = this.state.delivery.find((i) => i.nombre === formato?.value && i.comuna === comuna?.value)
                        if (item) {
                            this.setState({ editId: item.id_formato_disponible, editActive: true }, async () => {
                                await this.handleChange({ target: { name: "amount_delivery", value: item.valor_despacho } })
                                this.handleChange({ target: { name: "defontana_code", value: item.codigo_defontana } })
                            })
                        } else {
                            this.setState({ editId: -1, editActive: false }, async () => {
                                await this.handleChange({ target: { name: "amount_delivery", value: "" } })
                            })
                        }
                    }
                    console.log(item)
                    resolve()
                })
            } else {
                this.setState({ inputs: { ...inputs, [name]: value } }, () => {
                    resolve()
                })
            }
        })
    }

    format = (nStr) => {
        nStr += '';
        let x = nStr.split('.');
        let x1 = x[0];
        let x2 = x.length > 1 ? '.' + x[1] : '';
        var rgx = /(\d+)(\d{3})/;
        while (rgx.test(x1)) {
            /* eslint-disable */
            x1 = x1.replace(rgx, '$1' + '.' + '$2');
            /* eslint-enable */
        }
        return x1 + x2;
    }

    handleChangeCheckbox = ({ target }) => {
        const { name, checked } = target
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [name]: checked } })
    }

    handleClick = async (id) => {
        const { relatedProducts } = this.state
        const { products } = this.props
        const productIndex = relatedProducts.findIndex(p => p.id === id)
        if (productIndex === -1) {
            const product = products.data.find(p => p.id === id)
            if (product) {
                let relatedProductsIds = this.state.relatedProductsIds
                if (relatedProductsIds === '') {
                    relatedProductsIds = relatedProductsIds + product.id
                } else {
                    relatedProductsIds = relatedProductsIds + ',' + product.id
                }
                this.setState({ relatedProductsIds }, () => {
                    this.editRelatedProducts()
                })
                this.setState(prevState => ({
                    relatedProducts: [...prevState.relatedProducts, product]
                }))
            }
        }
        this.setState({ filtered: [], inputs: { search: '' } })
    }

    handleAutocompleteChange = async ({ target }) => {
        const { name, value } = target
        const { inputs, productsData } = this.state
        let filtered = [];
        if (value && value !== '') {
            filtered = productsData.filter((item) => {
                if (item.value.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    return item
                }
                return false
            })
        }
        this.setState({ inputs: { ...inputs, [name]: value }, filtered })
    }

    RenderProductoRelacionado = ({ title, img }) => (
        <div className="col-6 col-md-3 mt-2">
            <CardProduct name={title} images={img} />
        </div>
    )

    handleSave = async (type) => {
        const { inputs } = this.state
        const validations = await validate(type === "despacho" ? priceSchemaDelivery : priceSchemaWithdraw, { ...inputs, state: true }, () => this.save(inputs, type))
        if (validations) {
            this.setState({ errors: validations })
        }else{
            this.setState({ errors: [] })
        }
    }

    save = async ({ commune_id_delivery, format_id, sucursal_id, amount_delivery, amount_withdraw, defontana_code }, type) => {
        const { auth, product, START_LOADING, END_LOADING } = this.props
        START_LOADING()
        await doCreatePriceList(auth.session.token, {
            "id_comuna": type === "despacho" ? commune_id_delivery : null,
            "id_formato_producto": format_id,
            "id_producto": product.id,
            "id_punto_venta": type === "retiro" ? sucursal_id : null,
            "retiro_tienda": type === "retiro",
            "valor_despacho": type === "despacho" ? amount_delivery.replace(/\D/g, '') : null,
            "valor_retiro_tienda": type === "retiro" ? amount_withdraw.replace(/\D/g, '') : null,
            "despacho": type === "despacho",
            "fecha": new Date(),
            "codigo_defontana": defontana_code,
            "status": true
        })
        await this._getProduct()
        try {
            this.init()
        } catch (error) {
            console.log(error);
        }
        this.setState({
            inputs: {
                format_id: format_id,
                defontana_code: defontana_code,
                stock: false,
                region_id_delivery: -1,
                commune_id_delivery: -1,
                amount_delivery: '',
                region_id_withdraw: -1,
                commune_id_withdraw: -1,
                sucursal_id: -1,
                amount_withdraw: '',
                despacho: [],
                retiro: [],
                citiesDelivery: []
            }
        })
        END_LOADING()
    }

    deleteProductCallback = async (p) => {
        const { relatedProductsIds } = this.state
        let newids = ''
        relatedProductsIds.split(',').filter(id => p.id.toString() !== id).forEach(id => {
            if (newids === '') {
                newids = newids + id
            } else {
                newids = newids + ',' + id
            }
        })
        this.setState({ relatedProductsIds: newids }, () => {
            this.setState({
                relatedProducts: this.state.relatedProducts.filter(pro => pro.id !== p.id)
            })
            this.editRelatedProducts()
        })
    }

    editRelatedProducts = async () => {
        const { editProduct, auth, product, START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const { relatedProductsIds } = this.state
        const data = { ...product }
        delete data.despacho
        delete data.retiro
        delete data.product_relacionados
        delete data.type
        data.productos_relacionados = relatedProductsIds
        await editProduct(data, data.id, auth.session.token)
        END_LOADING()
    }

    setEditData = async (id, region, comuna, precio, type, active, codeFontana, id_formato) => {
        if (id === this.state.editId) {
            this.setState({
                inputs: {
                    ...this.state.inputs,
                    stock: false,
                    region_id_delivery: -1,
                    commune_id_delivery: -1,
                    amount_delivery: '',
                    region_id_withdraw: -1,
                    commune_id_withdraw: -1,
                    sucursal_id: -1,
                    amount_withdraw: '',
                    despacho: [],
                    retiro: [],
                },
                editId: -1
            })
        } else {
            if (type === "delivery") {
                await this.handleChange({ target: { name: "region_id_delivery", value: region.id } })
                this.handleChange({ target: { name: "commune_id_delivery", value: comuna.id } })
                this.handleChange({ target: { name: "amount_delivery", value: precio } })
                this.handleChange({ target: { name: "defontana_code", value: codeFontana } })
                this.handleChange({ target: { name: "format_id", value: id_formato.toString() } })
            } else {
                await this.handleChange({ target: { name: "region_id_withdraw", value: region.id } })
                this.handleChange({ target: { name: "sucursal_id", value: comuna.id } })
                this.handleChange({ target: { name: "amount_withdraw", value: precio } })
                this.handleChange({ target: { name: "defontana_code", value: codeFontana } })
                this.handleChange({ target: { name: "format_id", value: id_formato.toString() } })
            }
            this.setState({ editId: id, editActive: active })
        }
    }

    handleEdit = async (type) => {
        const { inputs } = this.state
        const validations = await validate(type === "despacho" ? priceSchemaDelivery : priceSchemaWithdraw, { ...inputs }, () => this.edit())
        if (validations) {
            this.setState({ errors: validations })
        }else{
            this.setState({ errors: [] })
        }
    }

    edit = async () => {
        const { START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const { commune_id_delivery, amount_delivery, sucursal_id, amount_withdraw } = this.state.inputs
        const { type } = this.props.product;
        let data = {
            "id_comuna": type === "DESPACHO" ? commune_id_delivery : null,
            "id_punto_venta": type === "RETIRO" ? sucursal_id : null,
            "retiro_tienda": type === "RETIRO",
            "valor_despacho": type === "DESPACHO" ? amount_delivery.replace(/\D/g, '') : null,
            "valor_retiro_tienda": type === "RETIRO" ? amount_withdraw.replace(/\D/g, '') : null,
            "despacho": type === "DESPACHO",
            "status": this.state.editActive
        }
        const resp = await doEditPriceList(this.props.auth.session.token, data, this.state.editId);
        if (resp.success) {
            this.setState({
                inputs: {
                    ...this.state.inputs, stock: false,
                    region_id_delivery: -1,
                    commune_id_delivery: -1,
                    amount_delivery: '',
                    region_id_withdraw: -1,
                    commune_id_withdraw: -1,
                    sucursal_id: -1,
                    amount_withdraw: '',
                    despacho: [],
                    retiro: [],
                },
                editId: -1
            })
            await this._getProduct()
            try {
                this.init()
            } catch (error) {
                console.log(error);
            }
        }
        END_LOADING()
    }

    handleDeletePrice = async (id, type, comuna, active) => {
        const { auth, START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const find = comuna.formatos.find(i => i.id_lista_precio === id);
        console.log(find)
        await doEditPriceList(auth.session.token, {
            "id_comuna": find.id_comuna,
            "id_punto_venta":find.id_punto_venta,
            "retiro_tienda": type === "retreat",
            "valor_despacho": type === "delivery" ? find.valor_despacho : null,
            "valor_retiro_tienda": type === "retreat" ? find.valor_retiro_tienda : null,
            "despacho": type === "delivery",
            "status": active
        }, id)
        await this._getProduct()
        END_LOADING()
        try {
            this.init()
        } catch (error) {
            console.log(error);
        }
    }

    render() {
        const { inputs, errors, filtered, relatedProducts, filteredRetreat, filteredDelivery, totalPages, toogleStatus } = this.state
        const { checkedItem, idItem, showModal, typeItem, comuneItem } = toogleStatus
        const { product } = this.props
        const {
            format_id,
            defontana_code,
            region_id_delivery,
            commune_id_delivery,
            amount_delivery,
            amount_withdraw,
            sucursal_id
        } = inputs;
        return (
            <div className="container-component-products advanced">
                {product && product.type !== "RELATED" && (
                    <>
                        <Dialogs handleAcept={() => {
                            this.setState({ toogleStatus: { ...this.state.toogleStatus, showModal: false } })
                            this.handleDeletePrice(idItem, typeItem, comuneItem, checkedItem)
                        }} handleClose={() => this.setState({ toogleStatus: { showModal: false } })} message={`¿Desea ${checkedItem ? 'activar' : 'desactivar'} este item?`} isOpen={showModal} />
                        <Title text={`Producto: ${product.nombre}`} size="md" />
                        <div className="row">
                            <div className="col-6">
                                <Title text="Detalle del Formato" size="sm" noBorder />
                                <div className="row justify-content-center px-md-5 px-1 mb-2">
                                    <div className="col-12 col-md-6 mb-2">
                                        <Select
                                            label="Formato"
                                            value={format_id}
                                            name="format_id"
                                            onChange={this.handleChange}
                                            data={this.state.formatos}
                                            errors={errors}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-2">
                                        <Input
                                            label="Código Defontana"
                                            name="defontana_code"
                                            placeholder="Código defontana"
                                            disabled
                                            value={defontana_code}
                                            onChange={this.handleChange}
                                            errors={errors}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-6">
                                {product && product.type === "DESPACHO" ? (
                                    <>
                                        <Title
                                            text="Valor Producto - Despacho"
                                            size="sm"
                                            noBorder
                                        />
                                        <div className="row justify-content-center px-md-5 px-1 mb-2">
                                            <div className="col-12 col-md-4 mb-2">
                                                <Select
                                                    label="Región"
                                                    value={region_id_delivery}
                                                    name="region_id_delivery"
                                                    onChange={this.handleChange}
                                                    data={this.state.regions}
                                                    errors={errors}
                                                />
                                            </div>
                                            <div className="col-12 col-md-4 mb-2">
                                                <Select
                                                    label="Comuna"
                                                    value={commune_id_delivery}
                                                    name="commune_id_delivery"
                                                    onChange={this.handleChange}
                                                    data={this.state.citiesDelivery}
                                                    errors={errors}
                                                />
                                            </div>

                                            <div className="col-12 col-md-4 mb-2">
                                                <Input
                                                    label="Valor $"
                                                    name="amount_delivery"
                                                    placeholder="xx"
                                                    value={amount_delivery}
                                                    onChange={this.handleChange}
                                                    errors={errors}
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-end px-md-5 px-1 mb-2">
                                            <div className="col d-flex justify-content-end mt-2">
                                                <button className="btn btn-rioclaro" type="button" onClick={() => this.state.editId === -1 ? this.handleSave('despacho') : this.handleEdit('despacho')}>{this.state.editId === -1 ? 'Agregar' : 'Guardar'}</button>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <Title
                                            text="Valor Producto - Retiro"
                                            size="sm"
                                            noBorder
                                        />
                                        <div className="row justify-content-center px-md-5 px-1 mb-2" >
                                            <div className="col-12 col-md-6 mb-2">
                                                <Select
                                                    label="Sucursal"
                                                    value={sucursal_id}
                                                    name="sucursal_id"
                                                    onChange={this.handleChange}
                                                    data={this.state.offices}
                                                    errors={errors}
                                                />
                                            </div>
                                            <div className="col-12 col-md-6 mb-2">
                                                <Input
                                                    label="Valor $"
                                                    name="amount_withdraw"
                                                    placeholder="xx"
                                                    value={amount_withdraw}
                                                    onChange={this.handleChange}
                                                    errors={errors}
                                                />
                                            </div>
                                        </div>

                                        <div className="row justify-content-end px-md-5 px-1 mb-2">
                                            <div className="col d-flex justify-content-end mt-2">
                                                <button className="btn btn-rioclaro" type="button" onClick={() => this.state.editId === -1 ? this.handleSave('retiro') : this.handleEdit('retiro')}>{this.state.editId === -1 ? 'Agregar' : 'Guardar'}</button>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </>
                )}
                {product && product.type === "DESPACHO" && (
                    <div className="row">
                        <div className="col-12">
                            {product && product.despacho && (
                                <>
                                    <Title text="Sucursales Disponibles Despacho" />
                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <Table totalPages={totalPages} onPageChangeCallback={(page) => this.setState({ pageNumber: page })} hasPagination page={this.state.pageNumber} data={{ headers, items: filteredDelivery }} />
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
                {product && product.type === "RETIRO" && (
                    <div className="row">
                        <div className="col-12">
                            {product && product.retiro && (
                                <div>
                                    <Title text="Sucursales Disponibles Retiro" />
                                    <div className="row mt-5">
                                        <div className="col-12">
                                            <Table totalPages={totalPages} onPageChangeCallback={(page) => this.setState({ pageNumber: page })} hasPagination page={this.state.pageNumber} data={{ headers, items: filteredRetreat }} />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {product && product.type === "RELATED" && (
                    <>
                        <Title text="Productos Relacionados" />

                        <div className="row justify-content-center px-md-5 px-1 mb-2">
                            <div className="col mt-2">
                                <AutoComplete
                                    label="Buscar Producto"
                                    placeholder="Buscar id, sku o nombre"
                                    name="search"
                                    data={filtered}
                                    onChange={this.handleAutocompleteChange}
                                    value={inputs.search}
                                    handleClick={this.handleClick}
                                />
                            </div>
                        </div>
                        <div className="row justify-content-start px-md-5 px-1 mb-5">
                            {relatedProducts?.filter(p => p.activo).map((p) => {
                                return (<div key={v4()} className="col-6 col-md-3 mt-2"><CardProduct deleteProductCallback={() => this.deleteProductCallback(p)} name={p.nombre} images={p.imagenes.map((i) => ({ src: `${linkApi}/imagenes/productos/${i}` }))}></CardProduct></div>)
                            })}
                        </div>
                    </>
                )}
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    drawer: state.drawer,
    product: state.product,
    formato: state.formato,
    products: state.products,
    auth: state.auth,
    loader: state.loader
})
const mapDispatchToProps = (dispatch) => ({
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    getProduct: (slug) => dispatch(doGetProduct(slug)),
    getFormatos: (id_categoria) => dispatch(getFormatosList(id_categoria)),
    editProduct: (data, id, token) => dispatch(doEditProduct(data, id, token)),
    END_LOADING: () => dispatch(END_LOADING()),
    START_LOADING: () => dispatch(START_LOADING())
})
export default connect(mapStateToProps, mapDispatchToProps)(AdvancedProduct)
