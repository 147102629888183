import { Component } from "react";
import { connect } from "react-redux";
import { categorySchema } from '../../../utils/validations';
import Input from "../Input";
import Title from "../Title";
import validate from '../../../utils/validate'
import { doCreateCategory } from "../../../redux/actions/category";
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";
import { formatsGridHeaders } from "../../../utils/constants";
import { v4 } from "uuid";

const initialState = {
    nombre: '',
    formatos: [{
        nombre: '',
        cantidad: ''
    }]
}

class AddCategory extends Component {

    constructor(props) {
        super(props);
        this.state = {
            inputs: { ...initialState },
            errors: {},
            formatos: []
        }
    }

    handleFormatInputChange = (evt, index, type) => {
        evt.preventDefault()
        let values = [...this.state.formatos];
        const { target } = evt
        if (type === 0) {
            values[index].nombre = target.value
        } else {
            values[index].cantidad = target.value
        }
        this.setState({ formatos: values });
    }

    handleChange = ({ target }) => {
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [target.name]: target.value } }, () => {
            const { errors } = this.state
            Object.keys(errors).forEach((key) => {
                if (key === target.name) {
                    delete errors[key]
                }
            })
            this.setState({ errors })
        })
    }
    onFormatInputChange(input) {
        const formats = this.state.inputs.formatos;
        const key = Object.keys(input)[0];
        formats[0] = { ...formats[0], [key]: input[key] }
        this.setState({ inputs: { ...this.state.inputs, formatos: formats } });
    }

    handleSubmit = async () => {
        const { inputs } = this.state
        const validations = await validate(categorySchema, inputs, () => this._createCategory(inputs))
        if (validations) {
            this.setState({ errors: validations })
        }
    }

    _createCategory = async (inputs) => {
        const { createCategory, auth, drawer, CLOSE_DRAWER, START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const formats = this.state.formatos.filter((f) => f.nombre !== "" && f.cantidad !== "");
        const i = inputs;
        i.formatos = formats;
        await createCategory(i, auth.session.token)
        END_LOADING()
        CLOSE_DRAWER(drawer.RENDER);
    }

    addFormatInputs = () => {
        const item = {
            nombre: '',
            cantidad: ''
        }
        this.setState({ formatos: [...this.state.formatos, item] })
    }

    render() {
        const { inputs, errors } = this.state
        const { nombre } = inputs
        return (
            <div className="row justify-content-center px-md-2 px-1 mb-5">
                <div className="col-6">
                    <div className="container-component-category size mt-5">
                        <Title text="Agregar Categoría" />
                        <div className="row justify-content-center px-md-2 px-1 mb-5">
                            <div className="col-12 mb-2">
                                <Input
                                    label="Nombre"
                                    placeholder="Nombre Categoría"
                                    name="nombre"
                                    errors={errors}
                                    value={nombre}
                                    onChange={this.handleChange}
                                />
                            </div>
                        </div>
                        <div className="row justify-content-center px-md-2 px-1 mb-2">
                            <div className="col-12 mb-2 d-flex justify-content-end">
                                <button className="btn btn-rioclaro" onClick={this.handleSubmit}>Guardar</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6">
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-3">
                        <i onClick={this.addFormatInputs} class="fa fa-plus-circle fa-2x" style={{ color: "#c23e1f", cursor: "pointer" }}></i>
                        <div className="container-table">
                            <table className="table table-striped table-hover table-bordered" style={{ overflow: 'auto' }}>
                                <thead>
                                    <tr>
                                        {formatsGridHeaders && formatsGridHeaders.map((header,) => (
                                            <th id={header.target} className="text-center" scope="col" key={v4()}>
                                                {header.name}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.formatos && this.state.formatos.length > 0
                                        ? this.state.formatos.map((item, index) => (
                                            <tr>
                                                <td className="text-center">
                                                    <div className="col-12 mb-2">
                                                        <Input
                                                            name={`nombre-${index}`}
                                                            value={item.nombre}
                                                            placeholder="Nombre del formato"
                                                            onChange={(evt) => this.handleFormatInputChange(evt, index, 0)}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="col-12 mb-2">
                                                        <Input
                                                            name={`cantidad-${index}`}
                                                            value={item.cantidad}
                                                            type="number"
                                                            placeholder="Cantidad"
                                                            onChange={(evt) => this.handleFormatInputChange(evt, index, 1)}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>

                                        ))
                                        : (<tr><td className="text-center" ></td><td className="text-center" ></td></tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    drawer: state.drawer,
})
const mapDispatchToProps = (dispatch) => ({
    createCategory: (data, token) => dispatch(doCreateCategory(data, token)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING())
})
export default connect(mapStateToProps, mapDispatchToProps)(AddCategory)