import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssetsImg from '../../assets/image';

class NavTop extends Component {
    render(){
        const { auth: { session } } = this.props;
        return(
            <nav className="navbar navbar-dark bg-dark py-1 top-nav d-none d-sm-flex w-100">
            <div className="container-lg">
                <ul className="nav">
                        <li className="nav-item">
                            <span className="nav-link px-2 py-1 text-white">{(session && session.usuario.id_perfil === undefined) ? `Bienvenido, ${session.usuario.correo}` : 'Bienvenido, invitado'}</span>
                        </li>
                </ul>
                <ul className="nav justify-content-end">
                    <li className="nav-item">
                        <a className="nav-link px-2 py-1 text-white" href="tel:224105100">
                        <img src={AssetsImg.Phone} className="img-fluid2 mr-2 icon-small" alt="..."/>
                        224105100
                        </a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link px-2 py-1 text-white" href="mailto:pellet@gfrc.cl">
                            <img src={AssetsImg.Envelope} className="img-fluid2 mr-2 icon-small" alt="..."/>
                        pellet@gfrc.cl
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(NavTop);