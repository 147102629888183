import React from 'react';

import HelperCoin from '../../utils/currency';

const ProductPrice = (props) => {
	let { precio, discount, get, appendText } = props;
	const { discount_float, apply_discount, new_price } = HelperCoin.calcProductDiscount(precio, discount);
	switch (get) {
		case "discount":
			return(
				<span className="format-discount">
					{
				  	apply_discount ?
				  		<span className="discount"><strong>{HelperCoin.getDiscount(discount_float)}</strong></span> :
				  			null
				  }
				  {appendText}
				</span>
			);
		default:
			return(
				<>
					<strong className={apply_discount ? 'previous-price' : 'price'}>
			    	{HelperCoin.format(Math.round(precio))}
			    </strong>
			    {
			    	apply_discount
			    		? <strong className="new-price">{HelperCoin.format(Math.round(new_price))}</strong>
			    			: null
			    }
		    </>
			);
	}

}
export default ProductPrice;