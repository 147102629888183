import { Component } from "react";
import { connect } from "react-redux";
import { v4 } from "uuid";
import Title from "../Title";
import '../../../styles/component.admin.banners.scss'

const linkApi = process.env.REACT_APP_ENV_API_URL
class BannerImage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            images: []
        }
    }


    handleSelect = (id) => {
        const { images } = this.state
        images.map((image) => {
            if (image.id === id) {
                image.selected = true
                return image
            }

            image.selected = false
            return image
        })
        this.setState({ images })
    }
    render() {
        const { banner } = this.props
        return (
            <div className="container-component-banner__banner-image mt-5 p-3">
                <Title text="Seleccione una imagen" />
                <div className="row mt-2">
                    {banner.banner && (
                        <div className={`col-md-4 col-6 d-flex justify-content-center align-items-center ${banner ? 'selected' : ''}`} key={v4()}>
                            <img className="p-3" src={`${linkApi}/imagenes/banners/${banner.banner}`} alt="product_image" onClick={() => this.handleSelect(banner.id)} />
                        </div>
                    )}
                </div>

                {/*                 <div className="row mt-2">
                    {banner && banner.banner === 0 && (
                        <div className={`col d-flex justify-content-center `}>
                            No se encontró ninguna imagen asociada con este banner.
                        </div>
                    )}
                </div> */}

                <div className="row mt-5">
                    <div className="col d-flex justify-content-end">
                        <button className="btn btn-rioclaro">Guardar</button>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    banner: state.banner
})
const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(BannerImage)