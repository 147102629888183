import { handleActions } from 'redux-actions';
import { checkout_update_form, checkout_update_login, checkout_reset } from '../actions/checkout';
import { SaveFormCheckout, GetFormCheckout, updateGlobalState } from '../../services/session';

export const DEFAULT_STATE = {
    form: {
        // step1
        email: '',
        buy_without_account: false,
        delivery: false,
        received_by_a_third_party: false,
        address: null,
        address_id: null,
        description: '',
        third_name: '',
        third_phone: '',
        third_rut: '',

        // step 2 factura
		bill:false,
		bill_business_name:'',
		bill_turn:'',
		bill_rut:'',
		bill_phone:'',
		bill_region_id:'',
		bill_commune_id:'',
        bill_address_description: '',
        // step 2 boleta
		name:'',
		last_name:'',
		rut:'',
		phone:'',

        // step 3
		payment_method:true,
    }
};

const INITIAL_STATE = GetFormCheckout() || DEFAULT_STATE;

export default handleActions({
    [checkout_update_form]: (state, action) => {
        state.form = action.payload;
        SaveFormCheckout(state);
        updateGlobalState('checkout', state);
        return {...state}
    },
    [checkout_update_login]: (state, action) => {
        state.form = {...state.form, ...action.payload};
        SaveFormCheckout(state);
        updateGlobalState('checkout', state);
        return {...state}
    },
    [checkout_reset]: (state) => {
        return {...state, ...DEFAULT_STATE}
    }
}, INITIAL_STATE);
