import { handleActions } from 'redux-actions'
import { fetch_error_formatos, fetch_start_formatos, fetch_success_formatos } from "../actions/formato";

export const INITIAL_STATE = {
    data: [],
    last_page: null,
    loading: false,
    success: false,
    error: null
};

export default handleActions({
    [fetch_start_formatos]: (state) => {
        return {...state, loading: true};
    },
    [fetch_success_formatos]: (state, action) => {
        const {data, last_page} = action.payload;
        console.log({...state, loading: false, success: true, data, last_page});
        return {...state, loading: false, success: true, data, last_page};
    },
    [fetch_error_formatos]: (state) => {
        return {...state, loading: false, error: true};
    }
}, INITIAL_STATE)