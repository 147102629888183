import { Component } from 'react'
import { connect } from 'react-redux'
import Table from '../../../components/Admin/Table'
import Title from '../../../components/Admin/Title'
import BannerAction from '../../../components/Admin/Actions/Banners'
import { END_LOADING, START_LOADING } from '../../../redux/actions/loader'
import '../../../styles/page.admin.banners.scss'
import { getBannerList } from '../../../redux/actions/banner'
import { bannerHeaders } from "../../../utils/constants";
import history from '../../../services/history'
import { CLOSE_DRAWER, SET_RENDER } from '../../../redux/actions/drawer'
import { Button, Modal } from 'rsuite'
const linkApi = process.env.REACT_APP_ENV_API_URL
class Banners extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {},
            img: '',
            show: false
        }
    }

    async componentDidMount() {
        await this._showBannerList()
        this.init()
    }

    showImage = (path) => {
        this.setState({ img: path, show: true });
    }

    async init() {
        const { START_LOADING, END_LOADING } = this.props
        START_LOADING();
        const responseApi = this.props.banners?.data
        const resp = responseApi?.map((item) => {
            const _link = item.link;
            item.banner = <Button onClick={() => this.showImage(`${linkApi}/imagenes/banners/${item.imagen_path[0]}`)} target="_blank" rel="noreferrer">Banner</Button>;
            item.actions = <BannerAction id={item.id} item={{
                id: item.id,
                name: item.pagina,
                text: item.texto,
                link: <a href={_link} target="_blank" rel="noreferrer">Link</a>,
                banner: item.imagen_path
            }} />
            return {
                id: item.id,
                name: item.pagina,
                text: item.texto,
                link: <a href={_link} target="_blank" rel="noreferrer">Link</a>,
                banner: item.banner,
                actions: item.actions
            }
        })
        this.setState({ data: { headers: bannerHeaders, items: resp } })
        END_LOADING()
    }

    componentDidUpdate(prevProp) {
        if (prevProp.banners?.data !== this.props.banners?.data) this.init();
    }

    _showBannerList = async () => {
        const { getBanner, START_LOADING, END_LOADING } = this.props
        START_LOADING();
        await getBanner(history.location.search)
        END_LOADING();
    }

    render() {
        const { data } = this.state
        return (
            <div className="container-configs">
                <Title text="Páginas" />
                <Modal size='md' show={this.state.show} onHide={() => this.setState({ show: false })}>
                    <Modal.Body style={{ height: "100%", overflow: "hidden", maxHeight: "auto" }}>
                        <img className="img-fluid" alt="banner" src={this.state.img}></img>
                    </Modal.Body>
                    <Modal.Footer>
                        <a href={this.state.img} target="_blank" rel="noreferrer">
                            <Button appearance="primary">
                                Descargar
                            </Button>
                        </a>
                        <Button onClick={() => this.setState({ show: false })} appearance="subtle">
                            Cancel
                        </Button>
                    </Modal.Footer>
                </Modal>
                {data && data.items && data.items.length && (
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-2">
                        <Table data={data} />
                    </div>
                )}
                {(data !== {} && data.items && data.items.length === 0) && (
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-2 text-center">
                        No hay banners registrados...
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    drawer: state.drawer,
    banners: state.banners
})
const mapDispatchToProps = (dispatch) => ({
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
    getBanner: (data) => dispatch(getBannerList(data)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Banners)