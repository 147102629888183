import { handleActions } from 'redux-actions';
import { fetch_start_products, fetch_success_products, fetch_error_products } from '../actions/product';

export const INITIAL_STATE = {
    data: [],
    last_page: null,
    loading: false,
    success: false,
    error: null
};

export default handleActions({
    [fetch_start_products]: (state) => {
        return {...state, loading: true};
    },
    [fetch_success_products]: (state, action) => {
        const {data, last_page} = action.payload;
        return {...state, loading: false, success: true, data, last_page};
    },
    [fetch_error_products]: (state) => {
        return {...state, loading: false, error: true};
    }
}, INITIAL_STATE);

