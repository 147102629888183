import React from 'react';
import AssetsImg from '../../assets/image';

const CartEmpty = (props) => (
    <div className="row mx-0 justify-content-center text-center">
        <div className="col-md-7 mb-2">
            <img src={AssetsImg.Empty_Cart} height={'250'} className="img-fluid" alt="..."/>
        </div>
        <h5 className="font-weight-bold text-orange">Su carrito esta vacío</h5>
        <p className="font-weight-light text-grey">Te invitamos a conocer nuestros productos en nuestra tienda</p>
        <button onClick={props.action} className="btn bg-orange text-white font-weight-bold">Productos</button>
    </div>
);

export default CartEmpty;