import { Component } from "react";
import { connect } from "react-redux";
import Title from "../Title";
import '../../../styles/component.admin.products.scss'
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { doEditProduct } from "../../../redux/actions/product";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";
import Input from "../Input";
import { CategoryDetails, CreateDefontana, Defontana, DeleteDefontana, UpdateDefontana } from "../../../services/api/endpoints";
import Select from "../Select";
import { defontanaGridHeaders } from "../../../utils/constants";
import { v4 } from "uuid";
import { Notification } from "rsuite";

class ProductFormats extends Component {
    constructor(props) {
        super(props)
        this.state = {
            images: [],
            selectedImage: "",
            formatos: [],
            defontana: []
        }
    }

    componentDidMount() {
        const { product } = this.props
        this.init(product.id_categoria, product.id)
    }

    async init(id, id_producto) {
        const { START_LOADING, END_LOADING } = this.props;
        START_LOADING();
        const formatos = (await CategoryDetails(id)).data.formatos.map((f) => ({ id: f.id, value: f.nombre }));
        const defontana = (await Defontana(this.props.auth.session.token)).data.filter(d => d.id_producto === id_producto).map(d => ({ ...d, new: false, changed: false }));
        this.setState({ formatos, defontana })
        END_LOADING()
    }

    addFormatInputs = () => {
        const item = {
            codigo_producto_defontana: "",
            id_formato: -1,
            id_producto: this.props.product.id,
            new: true,
            changed: true
        }
        this.setState({ defontana: [...this.state.defontana, item] })
    }

    handleSubmit = () => {
        const { product, auth, drawer, CLOSE_DRAWER, START_LOADING, END_LOADING } = this.props;
        START_LOADING();
        const defontana = this.state.defontana.filter((f) => f.codigo_producto_defontana !== "" && f.id_formato !== -1 && f.changed);
        const token = auth.session.token;
        const id_producto = product.id;
        defontana.forEach(d => {
            if (d.new) {
                CreateDefontana(token, { codigo_producto_defontana: d.codigo_producto_defontana, id_formato: d.id_formato, id_producto })
            } else {
                UpdateDefontana(token, d.id, { codigo_producto_defontana: d.codigo_producto_defontana, id_formato: d.id_formato, id_producto })
            }
        });
        END_LOADING();
        defontana.length > 0 && Notification.success({ title: "Éxito", description: "Operación realizada correctamente." })
        CLOSE_DRAWER(drawer.RENDER);
    }

    handleFormatInputChange = (evt, index, type) => {
        let values = [...this.state.defontana];
        const { target } = evt
        if (type === 0) {
            values[index].id_formato = target.value
        } else {
            values[index].codigo_producto_defontana = target.value.replace(/\D/g, '');
        }
        values[index].changed = true;
        this.setState({ defontana: values });
    }

    async removeFormatInputs(index, id) {
        const { auth, START_LOADING, END_LOADING } = this.props;
        START_LOADING();
        try {
            const resp = await DeleteDefontana(auth.session.token, id);
            if (resp.success) {
                Notification.success({ title: "Éxito", description: "Formato eliminado correctamente." })
            }
            const defontana = this.state.defontana.filter((_d, i) => i !== index);
            this.setState({ defontana })
            END_LOADING();
        } catch (error) {
            Notification.error({ title: "Error", description: error })
            END_LOADING();
        }
    }

    render() {
        return (
            <div className="container-component-products__product-image p-3">
                <Title text="Formatos" />
                <div className="row">
                    <div className="col-12">
                        <div className="container-products__content p-0 p-md-3">
                            <i onClick={this.addFormatInputs} class="fa fa-plus-circle fa-2x" style={{ color: "#c23e1f", cursor: "pointer" }}></i>
                            <div className="container-table">
                                <table className="table table-striped table-hover table-bordered" style={{ overflow: 'auto' }}>
                                    <thead>
                                        <tr>
                                            {defontanaGridHeaders && defontanaGridHeaders.map((header,) => (
                                                <th id={header.target} className="text-center" scope="col" key={v4()}>
                                                    {header.name}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.defontana && this.state.defontana.length > 0
                                            ? this.state.defontana.map((item, index) => (
                                                <tr>
                                                    <td className="text-center">
                                                        <div className="col-12 mb-2">
                                                            <Select
                                                                value={item.id_formato}
                                                                name={`id_formato-${index}`}
                                                                onChange={(evt) => this.handleFormatInputChange(evt, index, 0)}
                                                                data={this.state.formatos}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td className="text-center">
                                                        <div className="col-12 mb-2">
                                                            <Input
                                                                name={`defontana-code-${index}`}
                                                                value={item.codigo_producto_defontana}
                                                                type="number"
                                                                placeholder="Código defontana"
                                                                onChange={(evt) => { evt.target.value !== "" && this.handleFormatInputChange(evt, index, 1) }}
                                                            />
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <i onClick={() => this.removeFormatInputs(index, item.id)} class="fa fa-minus-circle" style={{ color: "#c23e1f", cursor: "pointer" }}></i>
                                                    </td>
                                                </tr>

                                            )
                                            )
                                            : (<tr><td className="text-center" ></td><td className="text-center" ></td></tr>)}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                        <button className="btn btn-rioclaro mr-3" onClick={this.handleSubmit}>Guardar</button>
                    </div>
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    product: state.product,
    auth: state.auth,
    drawer: state.drawer,
})
const mapDispatchToProps = (dispatch) => ({
    editProduct: (data, id, token) => dispatch(doEditProduct(data, id, token)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductFormats)