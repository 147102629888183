import { createAction } from 'redux-actions';
import { SignIn, CheckRut, CheckEmail, UserSignIn } from '../../services/api/endpoints';
import { AuthToCheckout, _formatRut } from '../../utils/helpers';
import { checkout_update_login } from './checkout';

export const fetch_start_login = createAction('fetch_start_login');
export const fetch_success_login = createAction('fetch_success_login');
export const fetch_error_login = createAction('fetch_error_login');
export const update_session = createAction('update_session');
export const clean_fetch = createAction('clean_fetch');
export const logout = createAction('logout');

export function login(data, isClient) {
    return async (dispatch) => {
        dispatch(fetch_start_login());
        try {
            const response = isClient ? await SignIn(data) : await UserSignIn(data);
            if (!response.usuario) {
                dispatch(fetch_error_login());
                return false;
            }
            await dispatch(fetch_success_login(response));
            if (isClient) {
                await dispatch(checkout_update_login(AuthToCheckout(response.usuario)));
            }
            return response;
        } catch (e) {
            dispatch(fetch_error_login(e));
            return false;
        }
    }
}

export function checkUserRut(data, buy_without_account, mobile = false) {
    return async (dispatch) => {
        try {
            const response = await CheckRut(data);
            if (!response.error) {
                Object.keys(response.data).forEach(async (key) => {
                    if (key === 'rut' || key === 'rut_empresa') {
                        response.data[key] = response.data[key] ? _formatRut(response.data[key]) : response.data[key]
                    }
                })
                const updatedFields = AuthToCheckout(response.data, buy_without_account);
                if (mobile) {
                    delete updatedFields.description
                }

                await dispatch(checkout_update_login(updatedFields));
            }

            return response;
        } catch (e) {
            return false;
        }
    }
}
export function checkUserEmail(data, buy_without_account, mobile = false) {
    return async (dispatch) => {
        try {
            const response = await CheckEmail(data);
            if (!response.error && response?.data?.confirmado) {
                Object.keys(response.data).forEach(async (key) => {
                    if (key === 'rut' || key === 'rut_empresa') {
                        response.data[key] = response.data[key] ? _formatRut(response.data[key]) : response.data[key]
                    }
                })
                const updatedFields = AuthToCheckout(response.data, buy_without_account);
                if (mobile) {
                    delete updatedFields.description
                }
                console.log(updatedFields);
                await dispatch(checkout_update_login(updatedFields));
            }

            return response;
        } catch (e) {
            return false;
        }
    }
}
