import React from 'react';
import ContentTitle from '../../components/Common/Content_Title';

const Description = (props) => {
    return (
        <div className="p-4 mb-4 bg-white rounded">
            <ContentTitle title="Descripción" />
            <div className="row">
                <div className="col-12 col-sm-12 px-0">
                    <p className="font-weight-light mt-3" style={{ fontSize: '.8em' }}>
                        <p dangerouslySetInnerHTML={{ __html: props.item.descripcion_corta }}></p>
                        <p dangerouslySetInnerHTML={{ __html: props.item.descripcion_larga }}></p>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default Description;