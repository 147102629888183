export const get_total_products = (items) => {
    return items.map(x => parseInt(x.precio)).reduce((a, b) => a + b, 0);
}

export const get_currency = (value) => {
    let dataLocal = { locale:'es-CL', currency:'CLP' };
    let amount = value === null ? 0 : value;
    amount = dataLocal.locale === 'es-CL' ? parseInt(amount) : parseFloat(amount);

    const formatter = new Intl.NumberFormat(dataLocal.locale, {
        style: 'currency',
        currency: dataLocal.currency,
    });

    let amount_string = formatter.format(amount);
    amount_string = amount_string.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    return amount_string;
}

export const isFloat = (n) => {
    return Number(n) === n && n % 1 !== 0;
}
export const getDiscount = (n) => {
    let data=(Number(n) * 100);
    data=parseInt(data)
    return `${data}% `;
}

export const calcProductDiscount = (price, discount) => {
let discount_float = HelperCoin.isFloat(parseFloat(discount)) ? parseFloat(discount) : 0;
let new_price = price-(price * discount_float);
let apply_discount = discount_float > 0;
return {
    discount_float,
    new_price,
    apply_discount,
};
}

const HelperCoin = {
    format:get_currency,
    isFloat:isFloat,
    getDiscount:getDiscount,
    calcProductDiscount
}

export default HelperCoin

