import { handleActions } from 'redux-actions';
import { fetch_start_addresses, fetch_success_addresses, fetch_error_addresses, address_reset } from '../actions/address';

export const INITIAL_STATE = {
    data: [],
    loading: false,
    success: false,
    error: null
};

export default handleActions({
    [fetch_start_addresses]: (state) => {
        return {...state, loading: true};
    },
    [fetch_success_addresses]: (state, action) => {
        return {...state, loading: false, success: true, data: action.payload.data};
    },
    [fetch_error_addresses]: (state) => {
        return {...state, loading: false, error: true};
    },
    [address_reset]: (state) => {
        return {...state, ...INITIAL_STATE}
    }
}, INITIAL_STATE);

