import { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import '../../../styles/DashboardStyles/Menu.scss'

class Menu extends Component {
    render() {
        const { items } = this.props
        return (
            <div className="container-menu d-none d-md-block">
                <ul>
                    {items && items.map((item) => (
                        <Fragment key={item.name}>
                            <Link to={item.to} className={item.selected ? 'selected' : ''} >
                                <li>
                                    <i className={item.icon}></i>
                                    {item.label}
                                </li>
                            </Link>
                        </Fragment>
                    ))}

                </ul>
            </div>
        )
    }
}

export default Menu