import React, { Component } from 'react';
import SelectSimple from '../../components/Selects/Select_Simple';
import { get_currency } from '../../utils/currency';
import { setSelectData } from '../../utils/helpers';
const linkApi = process.env.REACT_APP_ENV_API_URL
class ProductOptions extends Component {
    state = {
        selected: null,
        formats: null,
        regions: null,
        communes: null,
        stores: null
    }

    async componentDidMount() {
        const { initial, params, item, edit } = this.props;
        if (params === null && !edit) {
            return;
        }
        if (!edit && params.tipo_entrega && (params.tipo_entrega === '1' || params.tipo_entrega === '2')) {
            const { comuna, formato, region, tipo_entrega } = params
            tipo_entrega !== undefined && await this._setSelectedShipping(Number(tipo_entrega))
            let _region = [];
            if (region !== undefined) {
                _region = item[tipo_entrega === '1' ? "despacho" : "retiro"].find(x => x.id.toString() === region);
                this._setCommunneOrStore(_region.id)
            }
            let _comuna = [];
            if (comuna !== undefined) {
                _comuna = _region ? _region[tipo_entrega === '1' ? "comunas_disponibles" : "sucursales_disponibles"].find(y => y.id.toString() === comuna) : null;
            }
            
            let _formato = [];
            if (comuna !== undefined) {
                this._setFormats('commune', _comuna.id)
                _formato = _comuna ? _comuna.formatos.find(z => z.id.toString() === formato) : null;
            }
            this.setState({
                selected:
                {
                    ...this.state.selected,
                    store: _comuna ? _comuna.id : null,
                    commune: _comuna ? _comuna.id : null,
                    format: _formato ? _formato.id : null
                }
            })
        } else {
            if (edit) {
                if (initial.shipping === 1) {
                    let region = item.despacho.find(x => x.id === initial.region);
                    let comuna = region.comunas_disponibles.find(y => y.id === initial.commune);
                    this.setState({ regions: item.despacho, communes: region.comunas_disponibles, formats: comuna.formatos });
                } else {
                    let region = item.retiro.find(x => x.id === initial.region);
                    let sucursal = region.sucursales_disponibles.find(y => y.id === initial.store);
                    this.setState({ regions: item.retiro, stores: region.sucursales_disponibles, formats: sucursal.formatos });
                }
            }
            this.setState({ selected: { ...initial } })
        }
    }

    _setCommunneOrStore = (region_id) => {
        return new Promise((resolve, reject) => {
            if (isNaN(region_id)) {
                reject("no region_id")
            }
            const { selected } = this.state;
            const { item } = this.props;
            if (selected.shipping === 1) {
                let regiones = item.despacho;
                let region = regiones.find(x => x.id === region_id);
                this.changeUrlState("region", region);
                this.setState({ selected: { ...selected, region: region.id, commune: null, store: null, format: null }, communes: region.comunas_disponibles, formats: null }, () => resolve());
            } else {
                let regiones = item.retiro;
                let region = regiones.find(x => x.id === region_id);
                this.changeUrlState("region", region);
                this.setState({ selected: { ...selected, region: region.id, commune: null, store: null, format: null }, stores: region.sucursales_disponibles, formats: null }, () => resolve());
            }
        });
    }

    _setSelectedShipping = (val) => {
        return new Promise((resolve) => {
            const { item, initial, edit } = this.props
            const sRegions = val === 1 ? item.despacho : item.retiro;
            this.changeUrlState("shipping", val);
            if (edit) {
                this.setState({ selected: { region: null, store: null, commune: null, format: null, shipping: val }, regions: sRegions, formats: null }, () => resolve());
            } else {
                this.setState({ selected: { ...initial, shipping: val }, regions: sRegions, formats: null }, () => resolve());
            }
        })
    }

    changeUrlState(type, val) {
        const path = window.location.pathname.split('/');
        if (path[1] !== "productos") {
            return
        }
        switch (type) {
            case "shipping":
                path[4] = "metodo_entrega";
                path[5] = val;
                delete path[6];
                delete path[7];
                delete path[8];
                delete path[9];
                delete path[10];
                delete path[11];
                break;
            case "region":
                const region = encodeURIComponent(val.id);
                path[6] = "region";
                path[7] = region;
                delete path[8];
                delete path[9];
                delete path[10];
                delete path[11];
                break;
            case "comuna":
                const comuna = encodeURIComponent(val.id);
                path[8] = path[5] === "1" ? "comuna" : "sucursal";
                path[9] = comuna;
                delete path[10];
                delete path[11];
                break;
            case "formato":
                path[10] = "formato";
                path[11] = val;
                break;
            default:
                break;
        }
        window.history.pushState('', '', `/${path.filter(i => i).join('/')}`);
    }

    _setFormats = (data, val) => {
        const { selected, regions } = this.state;
        if (val) {
            let list = selected.shipping === 1 ? 'comunas_disponibles' : 'sucursales_disponibles';
            let region = regions.filter(x => x.id === selected.region);
            let arr = region[0][list].find(y => y.id === parseInt(val));
            this.changeUrlState("comuna", arr);
            this.setState({ selected: { ...selected, [data]: parseInt(val) }, formats: arr.formatos });
        } else {
            this.setState({ selected: { ...selected, [data]: null }, formats: null });
        }
    }

    _setSelectedFormat = (val, item) => {
        this.changeUrlState("formato", val);
        const { selected } = this.state;
        const { shipping, edit } = this.props;
        if (item.retiro_tienda || item.despacho) {
            if (edit) {
                this.setState({ selected: { ...selected, format: val } });
            } else if (!shipping) {
                this.setState({ selected: { ...selected, format: val } });
            } else if (shipping.comuna && selected.shipping === 1 && parseInt(selected.commune) === shipping.comuna.id) {
                this.setState({ selected: { ...selected, format: val } });
            } else if (shipping.sucursal && selected.shipping === 2 && parseInt(selected.store) === shipping.sucursal.id) {
                this.setState({ selected: { ...selected, format: val } });
            } else if (selected.shipping === 1 && shipping && !shipping.comuna) {
                this.setState({ selected: { ...selected, format: val } });
            } else if (selected.shipping === 2 && shipping && !shipping.sucursal) {
                this.setState({ selected: { ...selected, format: val } });
            } else {
                return null;
            }
        } else {
            return null;
        }
    }

    _resetState = () => {
        const { initial } = this.props;
        this.setState({ selected: { ...initial }, formats: null, communes: null, stores: null, regions: null });
    }

    render() {
        const { action, item, shipping, title, dontShowImage } = this.props;
        const { selected, regions, communes, stores, formats } = this.state;
        const { imagenes } = item;
        return (
            <div className="container">
                <div className="row">
                    {
                        !dontShowImage && (
                            <div className="d-none d-sm-block col-5 offset-sm-1 text-center px-2">
                                <img className="w-50" src={imagenes[0] ? `${linkApi}/imagenes/productos/${imagenes[0]}` : null} alt="..." style={{ height: '100%', width: '100%', objectFit: 'contain' }} />
                            </div>
                        )
                    }
                    <div className="col-12 col-sm-5 py-2">
                        <div className="my-1">
                            <h5 className="font-weight-bold mt-4 text-dark">Método de entrega</h5>
                            <hr className="m-0" />
                            <button
                                onClick={() => this._setSelectedShipping(1)}
                                className={`btn font-weight-bold text-white text-center mr-1 rounded-pill font-small py-1 my-1 ${selected && selected.shipping === 1 ? 'bg-orange' : 'bg-grey'}`}
                            >Despacho a domicilio</button>
                            <button
                                onClick={() => this._setSelectedShipping(2)}
                                className={`btn font-weight-bold text-white text-center mr-1 rounded-pill font-small py-1 ${selected && selected.shipping === 2 ? 'bg-orange' : 'bg-grey'}`}
                            >Retiro en tienda</button>
                        </div>
                        {
                            regions && (
                                <div className="my-1">
                                    <h5 className="font-weight-bold mt-4 text-dark">{(selected && selected.shipping === 1 ? 'Región y comuna de despacho' : 'Región y sucursal de retiro')}</h5>
                                    <hr className="m-0" />
                                    <div className="d-flex">
                                        <SelectSimple
                                            name="region"
                                            className={`form-select text-white font-weight-bold rounded-pill shadow-none border-0 mr-1 my-1 w-50 ${selected && selected.region ? 'bg-orange' : 'bg-grey'}`}
                                            placeholder={'Seleccionar región'}
                                            data={selected && selected.shipping === 1 ? setSelectData(item.despacho, 'id', 'region_nombre') : setSelectData(item.retiro, 'id', 'region_nombre')}
                                            runChange={(e) => this._setCommunneOrStore(parseInt(e.target.value))}
                                            initValue={selected.region}
                                            style={{ textAlign: 'center' }}
                                            alphabetically
                                            type="simple"
                                        />
                                        {
                                            selected && selected.region && (
                                                <SelectSimple
                                                    name="format"
                                                    className={`form-select text-white font-weight-bold rounded-pill shadow-none border-0 mr-1 my-1 w-50 ${selected && (selected.store || selected.commune) ? 'bg-orange' : 'bg-grey'}`}
                                                    placeholder={(selected.shipping === 1 ? 'Seleccionar comuna' : 'Seleccionar sucursal')}
                                                    data={selected && selected.shipping === 1 ? setSelectData(communes, 'id', 'comuna_nombre') : setSelectData(stores, 'id', 'nombre')}
                                                    runChange={(e) => selected && selected.shipping === 1 ? this._setFormats('commune', e.target.value) : this._setFormats('store', e.target.value)}
                                                    initValue={selected && selected.shipping === 1 ? selected.commune : selected.store}
                                                    style={{ textAlign: 'center' }}
                                                    alphabetically
                                                    type="simple"
                                                />
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            formats && (
                                <div className="mt-4 mb-2">
                                    <div className="row">
                                        {
                                            formats.filter(y => y.status).map((y, index) => {
                                                let disabled = ((selected && selected.shipping === 1 && !y.despacho) || (selected && selected.shipping === 2 && !y.retiro_tienda)) ? true : false;
                                                let value = selected && selected.shipping === 1 ? 'valor_despacho_iva' : 'valor_retiro_tienda_iva';
                                                if (!disabled) {
                                                    return (
                                                        <div className="col-6 col-sm-4 mb-4" key={`formats-${index}`}>
                                                            <button className={`p-1 text-center w-100 rounded ${selected && selected.format === y.id ? 'selected-format' : 'non-selected-format'}`} onClick={() => this._setSelectedFormat(y.id, y)}>
                                                                <p className={`m-0 font-small ${selected && selected.format === y.id ? 'text-white' : 'text-dark'}`}>{y.nombre.replace("Pack ", '')}</p>
                                                                <p className={`m-0 font-weight-bold ${selected && selected.format === y.id ? 'text-white' : 'text-green'}`}>{get_currency(Math.round(y[value]))}</p>
                                                            </button>
                                                        </div>
                                                    )
                                                } else {
                                                    return (null)
                                                }
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            selected && selected.format && (
                                <div className="my-1">
                                    <div className="col-12 col-sm-4 offset-sm-8 text-right px-0">
                                        <button className="btn bg-orange rounded-pill text-white font-weight-bold w-100" style={{ fontSize: '.9em' }} onClick={() => action(selected, formats, regions, this._resetState)}>{title}</button>
                                    </div>
                                </div>
                            )
                        }
                        {
                            selected?.store && shipping?.sucursal && parseInt(selected.store) !== shipping.sucursal.id && (
                                <p className="text-center font-weight-bold">{`No puedes seleccionar una sucursal de retiro distina a ${shipping.sucursal.nombre}`}</p>
                            )
                        }
                        {
                            selected?.commune && shipping?.comuna && parseInt(selected.commune) !== shipping.comuna.id && (
                                <p className="text-center font-weight-bold">{`No puedes seleccionar una comuna distinta a ${shipping.comuna.comuna_nombre}`}</p>
                            )
                        }
                    </div>
                    {
                        !dontShowImage && (
                            <div className="d-block d-sm-none col-12 offset-sm-1 text-center px-2 our-pellet">
                                <img className="w-25" src={imagenes[0] ? `${linkApi}/imagenes/productos/${imagenes[0]}` : null} alt="..." />
                            </div>
                        )
                    }
                </div>
            </div>
        )
    }
}

export default ProductOptions;