import React from 'react';
import ReactDOM from 'react-dom';
import 'rsuite/dist/styles/rsuite-default.css';
import './styles/main.scss';
import { App } from './App';
import 'bootstrap';

/*<React.StrictMode>*/
ReactDOM.render(
    <App />,
  document.getElementById('root')
);
