import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import ProductPrice from '../../../components/Products/Product_Price'

class ListItems extends Component{
    render(){
        const { cart: { items } } = this.props;
        return(
            <React.Fragment>
				<p className="font-weight-bold" style={{ fontSize: '0.9em' }}>Productos</p>
				<ul className="list-group list-group-flush">
				{
					items.length > 0 ? items.map((item, i) => {
						return(
							<li className="list-group-item p-0 my-2" style={{ border: 'none' }}key={`prosp-${i}`}>
								<div className="row">
									<div className="col-8">
										<p className="my-0 font-weight-bold" style={{ fontSize: '0.9em' }}>{item.nombre_producto}</p>
										<p className="my-0" style={{ fontSize: '0.9em' }}>{item.nombre_formato}</p>
									</div>
									<div className="col-4">
										<small><ProductPrice {...item} /></small>
									</div>
								</div>
							</li>
						);
					}) :
					<div className="col-12 text-center">
						<p>Sin productos en el carro</p>
						<Link to="/" className="btn-one btn-sm text-white py-2 px-4">
						 Volver a productos
						</Link>
					</div>
				}
				</ul>
		</React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart
});

export default connect(mapStateToProps)(ListItems)