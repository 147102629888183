import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoaderRsuite from '../Loaders/Loader_Rsuite';
import { GetCategories } from '../../services/session';
import ProductCard from './Product_Card';
import Paginator from '../Paginator';
import Select from '../Admin/Select';

class ProductList extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedCategory: 'Todos',
            categories: GetCategories(),
            selectedCategoryId: 0,
            products: this.props.products,
            filteredProducts: this.props.products
        }
    }

    componentDidMount() {
        this.setState({
            products: this.props.products,
            filteredProducts: this.props.products
        })
    }

    handleCategoryChange = async (evt) => {
        if (evt.target.value === "-1") {
            this.setState({ selectedCategoryId: evt.target.value, filteredProducts: this.state.products })
            return;
        }
        const category = this.state.categories.find(c => c.id.toString() === evt.target.value)
        const data = this.state.products.data.filter(p => p.id_categoria.toString() === evt.target.value)
        this.setState({ selectedCategory: category.nombre, selectedCategoryId: evt.target.value, filteredProducts: { data } })
    }

    render() {
        const { products } = this.props;
        return (
            <>
                <div className="row">
                    <LoaderRsuite
                        loading={{
                            run: products.loading,
                            title: "Cargando Productos...",
                        }}
                        fail={{
                            run: products.data.length === 0,
                            title: "Sin productos!",
                            description: "Es posible que no se encuentre publicado ningun producto.",
                        }}
                    >
                        <div className="container my-4 py-4">
                            {this.state.categories ?
                                (
                                    <>
                                        <h3 className="font-weight-light d-none d-sm-flex">{this.state.selectedCategoryId === "-1" ? "Todos" : this.state.selectedCategory}</h3>
                                        <div className="bg-i-gray">
                                            <div className={`row ${this.state.selectedCategoryId} mb-3 p-2`}>
                                                <div className="col-sm-3 col-6">
                                                    <Select
                                                        customClassName="custom-select  custom-select-sm form-select"
                                                        value={this.state.selectedCategoryId}
                                                        name="id_categoria"
                                                        onChange={this.handleCategoryChange}
                                                        data={this.state.categories.map(x => ({ id: x.id, value: x.nombre }))}
                                                    />
                                                </div>
                                            </div>
                                        </div></>
                                ) : null
                            }
                            <div className="row my-1 ps-2 ps-md-0">
                                {this.state.filteredProducts.data.filter(p => p.activo).map((e, i) =>
                                (
                                    <div className="col-12 col-sm-6 col-lg-4 pb-4" key={`product-list-${i}`}>
                                        <ProductCard {...e} center />
                                    </div>
                                )
                                )}
                            </div>
                        </div>
                    </LoaderRsuite>
                </div>
                <div className="d-flex justify-content-end">
                    {this.state.filteredProducts.last_page > 1 && (
                        <Paginator {...products} action={() => this._showProductList()} className="products-pagination text-orange" />
                    )}
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    products: state.products
});

const mapDispatchToProps = dispatch => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);