import { Component } from "react";
import Image from "../../../components/Admin/Image";
import Images from "../../../assets/image";
import Input from "../../../components/Admin/Input";
import { connect } from "react-redux";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";
import history from '../../../services/history';
import { login, update_session } from "../../../redux/actions/auth";
import { Notification } from "rsuite";
import { getRolesList } from "../../../redux/actions/role";

class SigIn extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {
                username: '',
                password: ''
            },
            errors: {},
            user: undefined
        }
    }

    componentDidMount() {
        sessionStorage.clear();
    }

    handleChange = ({ target }) => {
        const { name, value } = target
        const { data } = this.state
        this.setState({ data: { ...data, [name]: value } })
    }

    handleSignin = async (e) => {
        const { START_LOADING, END_LOADING } = this.props
        START_LOADING();
        e.preventDefault()
        const { data } = this.state
        const { login } = this.props
        const response = await login({ usuario: data.username, contrasena: data.password })
        if (response.token) {
            await this.props.getRoles(response.token);
            this.setState({ user: response.usuario })
        } else {
            Notification['error']({
                title: "Error",
                description: "Usuario o contraseña incorrecto."
            })
            END_LOADING();
        }
    }

    componentDidUpdate(prevProp, prevState) {
        if (prevState.user === undefined && this.state.user && this.props.actionsRoles) {
            const { auth, updateSession, END_LOADING } = this.props
            const userRole = this.props.actionsRoles.data.find((r) => r.id === this.state.user.id_rol).data;
            updateSession({ ...auth, role: userRole });
            if (userRole.repartos.view) {
                history.push('/admin/dashboard/router')
            } else if (userRole.productos.view) {
                history.push('/admin/dashboard/products')
            } else if (userRole.categorias.view) {
                history.push('/admin/dashboard/categories')
            } else if (userRole.descuentos.view) {
                history.push('/admin/dashboard/discounts')
            } else if (userRole.banners.view) {
                history.push('/admin/dashboard/banners')
            } else if (userRole.textos.view) {
                history.push('/admin/dashboard/contracts')
            } else if (userRole.roles.view) {
                history.push('/admin/dashboard/users')
            } else if(userRole.cobertura){
                history.push('/admin/dashboard/cobertura')
            } else {
                Notification.error({ title: "Rol sin permisos", description: "El rol al que usted está signado no posee ningún permiso" })
            }
            END_LOADING();
        }
    }

    render() {

        const { Logo } = Images
        const { data, errors } = this.state
        const { username, password } = data;
        return (
            <div className="container-lg h-100">
                <div className="row h-100 justify-content-center align-items-center px-5" style={{ minHeight: '100vh' }}>
                    <div className="col-12 col-md-5 d-flex justify-content-center align-item-center flex-column border rounded shadow py-5 px-5">
                        <div className="row justify-content-center mb-5">
                            <div className="col-12 col-md-8">
                                <Image src={Logo} alt="logo" style={{ maxWidth: '100%' }} />
                            </div>

                        </div>
                        <form onSubmit={this.handleSignin}>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-8 mb-3 has-validation">
                                    <Input
                                        id="username"
                                        name="username"
                                        value={username}
                                        placeholder="Nombre de usuario"
                                        label="Usuario"
                                        type="text"
                                        onChange={this.handleChange}
                                        errors={errors}
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-12 col-md-8 mb-3">
                                    <Input
                                        id="password"
                                        name="password"
                                        value={password}
                                        placeholder="*************"
                                        label="Contraseña"
                                        type="password"
                                        onChange={this.handleChange}
                                        errors={errors}
                                    />
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-12 col-md-8 text-center">
                                    <button type="submit" className="btn btn-rioclaro w-100">Entrar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }


}

const mapStateToProps = (state) => ({
    auth: state.auth,
    actionsRoles: state.actionsRoles
})
const mapDispatchToProps = (dispatch) => ({
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
    login: (data) => dispatch(login(data, false)),
    getRoles: (token) => dispatch(getRolesList(token)),
    updateSession: (payload) => dispatch(update_session(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SigIn)
