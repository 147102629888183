import { handleActions } from "redux-actions";
import { fetch_error_discounts, fetch_start_discounts, fetch_success_discounts } from "../actions/discount";

export const INITIAL_STATE = {
    data: [],
    last_page: null,
    loading: false,
    success: false,
    error: null
};

export default handleActions({
    [fetch_start_discounts]: (state) => {
        return {...state, loading: true};
    },
    [fetch_success_discounts]: (state, action) => {
        const {data, last_page} = action.payload;
        return {...state, loading: false, success: true, data, last_page};
    },
    [fetch_error_discounts]: (state) => {
        return {...state, loading: false, error: true};
    }
}, INITIAL_STATE);