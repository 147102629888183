import { Component } from "react";
import { connect } from "react-redux";
import { CLEAR_RENDER, OPEN_DRAWER, SET_RENDER } from "../../../redux/actions/drawer";
import { SET_ROLE } from "../../../redux/actions/role";
import EditRole from "../Roles/EditRole";
import AssignUser from "../Roles/AssignUser";
import '../../../styles/component.admin.actions.scss'
import Dialogs from "../Dialogs/index";
import { Notification } from "rsuite";

class RolesActions extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false
        }
    }

    hasPermission = () => {
        return JSON.parse(sessionStorage.getItem('modules'))['roles'].edit;
    }

    handleEdit = () => {
        if (this.hasPermission()) {
            const { OPEN_DRAWER, SET_RENDER, CLEAR_RENDER, SET_ROLE, item } = this.props
            SET_ROLE(item)
            CLEAR_RENDER()
            SET_RENDER(EditRole)
            OPEN_DRAWER()
        } else {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
        }
    }

    handleAssign = () => {
        if (!this.hasPermission()) {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos de lectura de usuarios." });            
            return;
        }
        if (this.hasPermission()) {
            const { OPEN_DRAWER, SET_RENDER, CLEAR_RENDER, SET_ROLE, item } = this.props
            SET_ROLE(item)
            CLEAR_RENDER()
            SET_RENDER(AssignUser)
            OPEN_DRAWER()
        } else {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
        }
    }

    handleOpenDialog = () => {
        if (this.hasPermission()) {
            this.setState({ isModalOpen: true })
        } else {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
        }
    }

    ownsRole = (id_role) => {
        return id_role === this.props.auth.session.usuario.id_rol;
    }

    showNotification = () => {
        Notification.error({ title: 'Error', description: 'Está tratando de eliminar un rol al cuál usted pertenece.' })
    }

    render() {
        const { item } = this.props;
        const messageDialog = `¿Está seguro de querer eliminar el rol ${item.nombre}? \n Una vez eliminado no lo prodrá recuperar.`;
        return (
            <div className="container-product-actions">
                <Dialogs handleAcept={() => {
                    this.props.handleDelete(item.id)
                    this.setState({ isModalOpen: false })
                }} handleClose={() => this.setState({ isModalOpen: false })} message={messageDialog} isOpen={this.state.isModalOpen} />
                <i dataToggle="tooltip" title="Editar" className="fa fa-pencil" onClick={this.handleEdit}></i>
                <i dataToggle="tooltip" title="Asignar Usuario" className="fa fa-user" style={{ fontSize: '1.3em' }} onClick={this.handleAssign}></i>
                <i dataToggle="tooltip" title="Eliminar Rol" className="fa fa-trash" style={{ fontSize: '1.3em' }} onClick={() => !this.ownsRole(item.id) ? this.handleOpenDialog() : this.showNotification()}></i>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
})
const mapDispatchToProps = (dispatch) => ({
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    CLEAR_RENDER: () => dispatch(CLEAR_RENDER()),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
    SET_ROLE: (payload) => dispatch(SET_ROLE(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RolesActions)