export const productGridHeaders = [
    {
        name: "#",
        target: 'id'
    }, {
        name: "SKU",
        target: 'sku'
    }, {
        name: "Nombre",
        target: 'name'
    }, {
        name: "Acciones",
        target: "actions"
    }
]

export const categoryGridHeaders = [
    {
        name: "#",
        target: 'id'
    }, {
        name: "Nombre",
        target: 'name'
    }, {
        name: "Acciones",
        target: "actions"
    }
]

export const discountGridHeaders = [
    {
        name: '#',
        target: 'id'
    }, {
        name: 'Cantidad',
        target: 'quantity'
    }, {
        name: 'Código',
        target: 'code'
    }, {
        name: 'Inicio',
        target: 'fecha_creacion'
    }, {
        name: 'Fin',
        target: 'fecha_expiracion'
    }, {
        name: 'Porcentaje',
        target: 'valor'
    }, {
        name: 'Acciones',
        target: 'actions'
    },
]

export const bannerHeaders = [
    {
        name: "#",
        target: "id"
    },
    {
        name: "Nombre",
        target: "name"
    },
    {
        name: "Texto",
        target: "text"
    },
    {
        name: "Link",
        target: "link"
    },
    {
        name: "Banner",
        target: "banner"
    },
    {
        name: "Acciones",
        target: "actions"
    }
]

export const textheaders = [
    {
        name: '#',
        target: 'id'
    },
    {
        name: 'Nombre',
        target: 'nombre'
    },
    {
        name: "texto",
        target: "texto"
    }, {
        name: "Acción",
        target: "actions"
    }
]

export const rolesHeaders = [
    {
        name: "#",
        target: 'id'
    },
    {
        name: "Nombre",
        target: 'nombre'
    },{
        name: "Acciones",
        target: 'actions'
    }
]

export const formatsGridHeaders = [
    {
        name: "Formato",
        target: "format"
    }, {
        name: "Cantidad",
        target: "cantidad"
    }
]

export const defontanaGridHeaders = [
    {
        name: "Formato",
        target: "format"
    }, {
        name: "Código defontana",
        target: "defontana"
    }
]

export const salepointsGridHeaders = [
    {
        name: "Región"
    },
    {
        name: "Comuna"
    }
]
