import axios from 'axios';
import { Notification } from 'rsuite';
import { CloseSession } from '../session';
const linkApi = process.env.REACT_APP_ENV_API_URL

const hookup = async (method, url, data, token = null, formData, preventNotification, preventNotificationError) => {
  let mainResponse = {
    data: [],
    validations: null
  };
  if (!formData) {
    data = Object.keys(data).length ? data : '';
  } else {
    mainResponse = {
      archivo: '',
      success: ''
    }
  }
  let config = {
    method: method,
    baseURL: linkApi,
    url: url,
    data: data,
    headers: {
      'Authorization': token ? `Bearer ${token}` : null,
    },
  }
  mainResponse = await axios(config)
    .then(function (response) {
      if (response.status === 201 && !preventNotification) {
        Notification.success({ title: "Éxito", description: "Operación realizada correctamente." })
      }
      return response.data
    })
    .catch((error) => {
      const response = error.response
      if (response && response.status === 401 && response.msg === "No tienes acceso a estas funciones.") {
        console.log('no hay acceso');
      }
      if (response && response.status === 401 && response?.data?.msg === "Token has expired") {
        Notification.error({ title: "Error", description: "Su sessión ha expirado." })
        CloseSession();
        setTimeout(() => {
          window.location.href = "/ingresar"
        }, 1500);
        throw response.data.error
      }
      console.info(
        `Ups! ocurrio un error toma un capture y reportalo: \n\n[${method}] ${linkApi}${url}\n\n${error}`
      );
      if (response && response.status === 422) {
        mainResponse.validations = response.data;
        return mainResponse;
      }
      if (response && response.status === 500) {
        if (response.data && response.data.error && response.data.error === "No se ha encontrado el usuario.") {
          throw response.data.error;
        }
        if (response.data && response.data.error && response.data.error === "No se ha podido encontrar el usuario.") {
          Notification.error({ title: "Error", description: "Hubo un error en la operación." })
          CloseSession()
          window.location.href = '/'
          throw response.data.error;
        }
        if (!preventNotificationError) {
          Notification.error({ title: "Error", description: "Hubo un error en la operación." })
        }
        throw response.data.error;
      }
      if (response && response.status === 401 && response.data.msg === "No tienes acceso a estas funciones.") {
        Notification.error({ title: "Error", description: "No tienes los permisos suficientes " })
      }
      return mainResponse
    });

  return mainResponse
}
export default hookup;