import { handleActions } from 'redux-actions'
import { SET_BANNER } from '../actions/banner'

export const INITIAL_STATE = {
    id: "",
    banner: "",
    link: "",
    name: "",
    text: "",
}

export default handleActions({
    [SET_BANNER]: (state, action) => {
        return { ...state, ...action.payload }
    }
}, INITIAL_STATE)