import { handleActions } from 'redux-actions'
import { fetch_error_banners, fetch_start_banners, fetch_success_banners } from "../actions/banner";

export const INITIAL_STATE = {
    data: [],
    last_page: null,
    loading: false,
    success: false,
    error: null
};

export default handleActions({
    [fetch_start_banners]: (state) => {
        return {...state, loading: true};
    },
    [fetch_success_banners]: (state, action) => {
        const {data, last_page} = action.payload;
        return {...state, loading: false, success: true, data, last_page};
    },
    [fetch_error_banners]: (state) => {
        return {...state, loading: false, error: true};
    }
}, INITIAL_STATE)