import { Notification } from 'rsuite';
import * as Yup from 'yup';

export const truncateString = (str, num) => {
	if (str.length > num) {
		return str.slice(0, num) + "...";
	} else {
		return str;
	}
}

export const helperValidate = async (rules, item) => {
	const validations = {};
	for (const key in rules) {
		if (typeof item[key] === 'string') {
			item[key] = item[key].trim()
		}
		let error = await rules[key].validate(item[key]).catch(function (err) {
			return err.errors;
		});
		if (typeof error === 'object') {
			validations[key] = error;
		}
	}
	if (Object.entries(validations).length === 0) {
		return null;
	}
	return validations;
}

export const staticRules = {
	/* eslint-disable */
	email: Yup.string()
		.matches(
			/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/,
			'Ingresa un correo electronico valido'
		),
	/* eslint-enable */
}

export const setSelectData = (arr, id, value) => {
	let data = arr?.map(x => ({ value: parseInt(x[id]), label: x[value] }));
	return data?.filter((item, index, self) => index === self.findIndex((t) => (t.value === item.value && t.label === item.label)));
}

export const AuthToCheckout = (data, buy_without_account = false) => {
	return {
		last_name: data.apellidos ? data.apellidos : '',
		email: data.correo ? data.correo : '',
		bill_business_name: data.nombre_empresa ? data.nombre_empresa : '',
		name: data.nombres ? data.nombres : '',
		rut: data.rut ? data.rut : '',
		phone: data.telefono ? data.telefono : '.',
		description: data.direccion ? data.direccion : '',
		bill_turn: data.giro ? data.giro : '',
		bill_rut: data.rut_empresa ? data.rut_empresa : '',
		bill_phone: data.telefono_empresa ? data.telefono_empresa : '',
		bill_region_id: data.id_region_empresa ? data.id_region_empresa : null,
		bill_commune_id: data.id_comuna_empresa ? data.id_comuna_empresa : null,
		bill_address_description: data.direccion_empresa ? data.direccion_empresa : '',
		buy_without_account,
	}
}

export const setOrder = (cart, checkout, cliente = null, anonimo = null, direccion) => {
	const n_items = cart.items.map(x => {
		let despacho = x.shipping === 1 ? true : false;
		return ({ id_lista_precio: x.id_lista_precio, despacho, retiro_tienda: !despacho, valor: x.precio, cantidad: x.cantidad });
	})

	let s_direccion = direccion ? (direccion.data ? direccion.data.id : direccion) : null;
	let neto = cart.total_amount_payment / 1.19;
	let coupon = neto * (cart.total_discount_coupon / 100);
	let iva = Math.round(0.19 * (neto - coupon));
	return {
		id_direccion: s_direccion,
		id_pedido_estado: 6,
		id_cliente: cliente ? cliente.session.usuario.id : anonimo.data.id,
		cupon: cart.discount_code,
		tipo_dte: checkout.form.bill ? 33 : 39,
		folio: null,
		pdf_path: null,
		pdf_nombre: null,
		neto,
		iva,
		total: Math.round(neto - coupon + iva),
		items: n_items,
		nombre_tercero: checkout.form.third_name ? checkout.form.third_name : null,
		rut_tercero: checkout.form.third_rut ? checkout.form.third_rut.split('-')[0] : null,
		despacho_tercero: checkout.form.received_by_a_third_party,
		telefono_tercero: checkout.form.third_phone ? checkout.form.third_phone : null
	}
}

export const setAnonUser = (data) => {
	return {
		nombres: data.name,
		apellidos: data.last_name,
		direccion: data.description ? data.description : '',
		nombre_empresa: data.bill_business_name,
		rut: data.rut,
		telefono: data.phone,
		giro: data.bill_turn === '' ? null : data.bill_turn,
		rut_empresa: data.bill_rut ? data.bill_rut.split('-')[0] : null,
		telefono_empresa: data.bill_phone,
		direccion_empresa: data.bill_address_description,
		id_region_empresa: data.bill_region_id,
		id_comuna_empresa: data.bill_commune_id ? data.bill_commune_id : null,
		correo: data.email
	}
}

export const dgv = (T) => {
	let M = 0, S = 1;
	for (; T; T = Math.floor(T / 10))
		S = (S + T % 10 * (9 - M++ % 6)) % 11;
	return S ? S - 1 : 'k';
}

export const compareItems = (values, item) => {
	return (
		values.format === item.id_formato &&
		values.shipping === item.shipping
	);
}

export function dynamicSort(property) {
	var sortOrder = 1;

	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substr(1);
	}

	return function (a, b) {
		if (sortOrder === -1) {
			return b[property].localeCompare(a[property]);
		} else {
			return a[property].localeCompare(b[property]);
		}
	}
}

export const _maskRut = (e, state, callback = null) => {
	const { target } = e;
	const { value } = target
	const { inputs } = state
	let rut = value.length >= 11 ? e.target.value.replace(/[^\dkK]/g, "") : e.target.value.replace(/[^\d]/g, "");
	if (inputs[e.target.name] && inputs[e.target.name].length > e.target.value.length) {
		if (callback) {
			callback()
		}
		return { ...inputs, [e.target.name]: e.target.value }
	} else {
		switch (rut.length) {
			case 1:
				rut = e.target.value.replace(/[^\d]/g, "");
				return { ...inputs, [e.target.name]: rut.replace(/(\d{1,2})/, "$1.") }
			case 4:
				rut = e.target.value.replace(/[^\d]/g, "");
				return { ...inputs, [e.target.name]: rut.replace(/(\d{1})(\d{3})/, "$1.$2.") }
			case 7:
				rut = e.target.value.replace(/[^\d]/g, "");
				return { ...inputs, [e.target.name]: rut.replace(/(\d{1})(\d{3})(\d{3})/, "$1.$2.$3-") }
			case 8:
				rut = e.target.value.replace(/[^\dkK]/g, "");
				return { ...inputs, [e.target.name]: rut.replace(/(\d{1})(\d{3})(\d{3})([\dkK]{1})/, "$1.$2.$3-$4") }
			case 9:
				rut = e.target.value.replace(/[^\dkK]/g, "");
				if (/(\d{2})(\d{3})(\d{3})([\dkK]{1})/.test(rut)) {
					return { ...inputs, [e.target.name]: rut.replace(/(\d{2})(\d{3})(\d{3})([\dkK]{1})/, "$1.$2.$3-$4") }
				} else {
					return { ...inputs }
				}
			default:
				if (e.target.value.length <= 12) {
					e.target.value = e.target.value.replace(/[^\d.-]/g, "");
					return { ...inputs, [e.target.name]: e.target.value }
				} else {
					return inputs
				}
		}
	}

}

export const _formatRut = (value) => {
	let rut = value.replace(/[^\dkK]/g, "");;
	switch (rut.length) {
		case 8:
			return rut.replace(/(\d{1})(\d{3})(\d{3})([\dkK]{1})/, "$1.$2.$3-$4");
		case 9:
			return rut.replace(/(\d{2})(\d{3})(\d{3})([\dkK]{1})/, "$1.$2.$3-$4")
		default:
			return value
	}
}

export function toBase64(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => resolve(reader.result);
		reader.onerror = (error) => reject(error);
	});
}

export function toBinary(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		const fileByteArray = [];
		reader.readAsArrayBuffer(file);
		reader.onloadend = function (evt) {
			if (evt.target.readyState === FileReader.DONE) {
				var arrayBuffer = evt.target.result,
					array = new Uint8Array(arrayBuffer);
				for (var i = 0; i < array.length; i++) {
					fileByteArray.push(array[i]);
				}
				resolve(fileByteArray);
			}
		}
	})
}


export const paginate = (array, page_size, page_number) => (array.slice((page_number - 1) * page_size, page_number * page_size))

export const handleDownloadPdf = async (data, pdfName) => {
	try {
		const response = await fetch(data);
		const file = await response.blob();
		const a = document.createElement("a");
		const url = window.URL.createObjectURL(file);
		a.href = url;
		a.download = pdfName;
		a.click();
	} catch (error) {
		console.log('Error:', error);
		Notification.error({ title: "Error", description: "Hubo un error, inténtelo mas tarde." })
	}
}

export function getRandomString(length) {
	var randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var result = '';
	for (var i = 0; i < length; i++) {
		result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
	}
	return result;
}