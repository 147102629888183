import React from 'react';
import history from '../../services/history';

const linkApi = process.env.REACT_APP_ENV_API_URL

const ProductCard = (props) => {
    const { nombre, descripcion_corta, center, id, imagenes } = props;
    const goToProduct = (url) => {
        history.push(url)
    }
    return (
        <div className="product-card card" style={{ width: '100%' }}>
            <div className="p-4">
                {imagenes && (
                    <>
                        <img src={imagenes[0] ? `${linkApi}/imagenes/productos/${imagenes[0]}` : null} alt="..." className="w-50" style={{ marginLeft: '25%' }} />
                        <p className={center ? 'text-orange text-center mb-0' : 'text-black mb-0'}><strong>{nombre}</strong></p>
                        <p className={center ? 'text-grey text-center mb-0' : 'text-grey mb-0'} style={{ fontWeight: '300', fontSize: '.8em' }} dangerouslySetInnerHTML={{ __html: descripcion_corta }}></p>
                    </>
                )}
            </div>
            <div className="border-top px-4 py-2 d-flex">
                <button onClick={() => goToProduct(`/productos/id_producto/${id}`)} to={``} className="btn btn-success w-100 mx-auto font-weight-bold" style={{ borderRadius: 25, fontSize: '.8em', fontWeight: '500' }}>
                    Ver producto
                </button>
            </div>
        </div>
    );
}

export default ProductCard;