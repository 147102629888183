import React from 'react';
import PropTypes from 'prop-types';
import {dynamicSort} from '../../utils/helpers';

const SelectSimple = (props) => {
    const {
        data, placeholder, className, name,
        size, type, runChange, initValue, parent, nameParentId, disabled,
        alphabetically, onClick
    } = props;

    const onChangeValue = (e) => {
        runChange(e)
    };

    let sortedData = data.sort((a,b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
    if (alphabetically) {
        sortedData = sortedData.sort(dynamicSort("label"));
    }
    switch (type) {
        case 'simple':
            return (
                <select
                    className={`custom-select  custom-select-sm ${className} ${size}`}
                    name={name}
                    value={initValue ? initValue.toString() : ''}
                    onChange={onChangeValue}
                    onClick={onClick && onClick}
                    disabled={disabled}
                >
                    <option value="">{placeholder}</option>
                    {
                        sortedData.length > 0 ? sortedData.map(((t, i) => {
                            const label = t.label;
                            const value = t.value;
                            const parentValue = nameParentId ? t[`${nameParentId}`] : undefined;
                            if (parentValue!==parent) {
                                return true;
                            }
                            if (t.disabled) {
                                return (
                                    <option key={`select-${name}-${i}`} value={value.toString()} disabled={true}>
                                        { label } disabled
                                    </option>
                                );
                            }
                            if (`${value}`===`${initValue}`) {
                                 return (
                                    <option key={`select-${name}-${i}`} value={value.toString()}>{ label }</option>
                                );
                            }
                            return (
                                <option key={`select-${name}-${i}`} value={value.toString()}>{ label }</option>
                            );
                        })) : null
                    }

                </select>
            );
        default:
            return (
                <strong className="d-block px-3">Define el tipo de select</strong>
            )
    }
}

SelectSimple.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
    })),
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.string,
    type: PropTypes.string,
};

SelectSimple.defaultProps = {
    data: [],
    placeholder: 'Elije una opcion',
    name: 'input',
    size: 'md-b',
    type: ''
};

export default SelectSimple;