import { Component } from "react";
import { connect } from "react-redux";
import { CLEAR_RENDER, OPEN_DRAWER, SET_RENDER } from "../../../redux/actions/drawer";
import { SET_DISCOUNT } from "../../../redux/actions/discount";
import EditDiscount from '../Discounts/EditDiscount'
import '../../../styles/component.admin.actions.scss'
import { Notification } from "rsuite";
class DiscountsAction extends Component {

    hasPermission = () => {
        return JSON.parse(sessionStorage.getItem('modules'))['textos'].edit;
    }

    handleEdit = () => {
        const { OPEN_DRAWER, SET_RENDER, CLEAR_RENDER, SET_DISCOUNT, item } = this.props
        if (!this.hasPermission()) {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
            return;
        }
        SET_DISCOUNT(item)
        CLEAR_RENDER()
        SET_RENDER(EditDiscount)
        OPEN_DRAWER()
    }

    render() {
        return (
            <div className="container-discounts-actions">
                <i dataToggle="tooltip" title="Editar" className="fa fa-pencil" onClick={this.handleEdit}></i>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    CLEAR_RENDER: () => dispatch(CLEAR_RENDER()),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
    SET_DISCOUNT: (payload) => dispatch(SET_DISCOUNT(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscountsAction)