import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssetsImg from '../../assets/image';
import {get_currency} from '../../utils/currency';
import SelectSimple from '../Selects/Select_Simple';
import { updateItems, cart_update_shipping } from '../../redux/actions/cart';
import CartEdit from './Cart_Edit';

const ProductInfo = ({item, i, removeItem, editItem, shipping }) => (
    <>
        {/** movil **/}
        <div className="d-block d-sm-none">
            <SelectSimple
                type="simple"
                name="delivery_type"
                className="form-select mb-2"
                placeholder="Seleccione entrega"
                data={[{value: 1, label: `Retiro en tienda / ${shipping.sucursal ? shipping.sucursal.nombre_punto_venta : null}` }, {value: 2, label: `Despacho / ${shipping.comuna ? shipping.comuna.comuna_nombre : null}`}]}
                runChange={() => console.log('tipo entrega')}
                initValue={item.shipping}
                disabled
            />
            <div className="w-100 d-flex justify-content-between">
                <p className="font-weight-light my-0" style={{fontSize: '.8em'}}></p>
                <button type="button" className="btn p-0" onClick={() => editItem(i)}>
                    <p className="font-weight-light my-0 border-bottom" style={{fontSize: '.8em'}}>Editar</p>
                </button>
            </div>
            <hr className="my-1"/>
            <div className="w-100 d-flex justify-content-between">
                <p className="font-weight-bold text-dark my-0" style={{fontSize: '.9em'}}>Precio:</p>
                <p className="font-weight-bold text-orange my-0" style={{fontSize: '.9em'}}>{get_currency(parseInt(item.precio) * item.cantidad)}</p>
            </div>
        </div>
        {/** escritorio **/}
        <div className="d-none d-sm-block">
            <div className="row">
                <div className="col-5">
                    <p className="font-weight-bold mb-1" style={{fontSize: '.8em'}}>Tipo de entrega</p>
                    <SelectSimple
                        type="simple"
                        name="delivery_type"
                        className="form-select mb-2"
                        placeholder="Seleccione entrega"
                        data={[{value: 1, label: `Retiro en tienda / ${shipping.sucursal ? shipping.sucursal.nombre_punto_venta : null}` }, {value: 2, label: `Despacho / ${shipping.comuna ? shipping.comuna.comuna_nombre : null}`}]}
                        runChange={() => console.log('tipo entrega')}
                        initValue={item.shipping}
                        disabled
                    />
                </div>
                <div className="col-5">
                    <p className="font-weight-bold mb-1" style={{fontSize: '.8em'}}>Formato</p>
                    <SelectSimple
                        type="simple"
                        name="delivery_type"
                        className="form-select mb-2"
                        placeholder="Seleccione entrega"
                        data={item.formatos.map(x => ({value: x.id, label: x.nombre}))}
                        runChange={() => console.log('tipo entrega')}
                        initValue={item.id_formato}
                        disabled
                    />
                </div>
                <div className="col-2">
                    <button type="button" className="btn p-0" onClick={() => editItem(i)}>
                        <img src={AssetsImg.Edit} className="w-100" alt="..." style={{ width: '15px', height: '15px' }}/>
                    </button>
                    <button className="btn w-100" onClick={() => removeItem(i)}>
                        <img src={AssetsImg.Remove} alt="..." style={{ width: 15 }}/>
                    </button>
                </div>
            </div>
            <div className="row mt-4 pr-2">
                <div className="col-4">
                    <p className="mb-0 text-grey"><small>{`Cantidad: ${item.cantidad}`}</small></p>
                </div>
                <div className="col-4">
                    <p className="mb-0 text-right font-weight-bold">
                        Subtotal:
                    </p>
                </div>
                <div className="col-4">
                    <p className="mb-0 text-right text-orange font-weight-bold">
                        {get_currency(parseInt(item.precio) * item.cantidad)}
                    </p>
                </div>
            </div>
        </div>
    </>
)

class CartItems extends Component {

    state =  {
        edit: null,
    }

    _removeItem = (index) => {
        const { cart, updateItems, updateShipping } = this.props;
        if (cart.items[index].cantidad > 1) {
            cart.items[index].cantidad -= 1;
        } else {
            cart.items.splice(index, 1);
        }

        updateItems(cart.items);
        let comunaShipping = cart.items.some(x => x.shipping === 2) ? cart.shipping.comuna : null;
        let sucursalShipping = cart.items.some(x => x.shipping === 1) ? cart.shipping.sucursal : null;
        updateShipping({ shipping: { comuna: comunaShipping, sucursal: sucursalShipping } });
    }

    render(){
        const { edit } = this.state;
        const { cart: { items, shipping } } = this.props;
        return(
            <div className="container bg-white py-4 px-2 mb-2">
                {
                    items.length > 0 ? items.map((item, i) => (
                        <div className="row" key={`cart-items-micro-${i}`}>
                            <div className="col-sm-3 col-4 d-flex align-items-center">
                                <img src={item.imagen_producto} alt="..." className="w-100"/>
                            </div>
                            <div className="col-sm-9 col-8">
                                <div className="row h-100">
                                    <div className="col-12 col-sm-3 d-flex justify-content-between">
                                        <div>
                                            <p className="font-weight-light" style={{fontSize: '.8em'}}>{item.nombre_producto}</p>
                                        </div>
                                        <div className="d-sm-none d-block">
                                            <button className="btn w-100" onClick={() => this._removeItem(i)}>
                                                <img src={AssetsImg.Remove} alt="..." style={{ width: 15 }}/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-12 col-sm-9">
                                    {
                                        edit === i ? (
                                            <CartEdit i={i} item={item} items={items} cancel={() => this.setState({ edit: null })}/>
                                        ) : (
                                            <ProductInfo i={i} item={item} removeItem={this._removeItem} editItem={() => this.setState({ edit: i })} shipping={shipping} />
                                       )
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : <h4 className="text-center py-5">Tu carro se encuentra vacío</h4>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart
});

const mapDispatchToProps = dispatch => ({
    updateItems: (data) => dispatch( updateItems(data) ),
    updateShipping: (data) => dispatch( cart_update_shipping(data) )
});

export default connect(mapStateToProps, mapDispatchToProps)(CartItems);