import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelectSimple from '../../../components/Selects/Select_Simple';
import AssetsImg from '../../../assets/image';
import { show } from '../../../redux/actions/authModal';
import { Checkbox } from 'rsuite';


const Bill = ({ inputs, handleInput, handleRegion, communes, listRegions, handleRut, handlePhone, auth: { session } }) => {
    return (
        <>
            <div className="border-bottom pb-1 pt-4">
                <div className="d-inline ">
                    <p className="d-inline pr-4 mb-0 ml-2">Datos de Facturación</p>
                </div>
            </div>
            <div className="form-group my-1 col-12 px-0 pt-2">
                <label className="font-small">Razón social o nombre</label>
                <input value={inputs.bill_business_name} name="bill_business_name" type="text" className="form-control" onChange={handleInput} disabled={((session && session.usuario.id_rol === undefined && session.usuario.nombre_empresa) || inputs.loading) ? true : false} />
            </div>
            <div className="form-group my-1 col-12 px-0">
                <label className="font-small">Giro</label>
                <input value={inputs.bill_turn} name="bill_turn" type="text" className="form-control" onChange={handleInput} disabled={((session && session.usuario.id_rol === undefined && session.usuario.giro) || inputs.loading) ? true : false} />
            </div>
            <div className="form-group my-1 col-12 px-0">
                <label className="font-small">RUT tributario</label>
                <input value={inputs.bill_rut} name="bill_rut" type="text" className="form-control" onChange={handleRut} autoComplete="off" disabled={((session && session.usuario.id_rol === undefined && session.usuario.rut_empresa) || inputs.loading) ? true : false} />
            </div>
            <div className="form-group my-1 col-12 px-0">
                <label className="font-small">Teléfono de oficina</label>
                <input value={inputs.bill_phone} name="bill_phone" type="text" className="form-control" onChange={handlePhone} autoComplete="off" disabled={((session && session.usuario.id_rol === undefined && session.usuario.telefono_empresa) || inputs.loading) ? true : false} />
            </div>
            <div className="border-bottom pb-1 pt-4">
                <div className="d-inline ">
                    <p className="d-inline pr-4 mb-0 ml-2">Dirección Legal</p>
                </div>
            </div>
            <div className="form-group my-1 col-12 px-0 pt-2">
                <label className="font-small">Región</label>
                <SelectSimple
                    type="simple"
                    data={listRegions !== null ? listRegions.map(x => ({ value: x.id, label: x.region_nombre })): []}
                    className="form-select"
                    name="bill_region_id"
                    placeholder="Región"
                    runChange={e => handleRegion(e, listRegions)}
                    initValue={inputs.bill_region_id}
                    disabled={((session && session.usuario.id_rol === undefined && session.usuario.id_region_empresa) || inputs.loading) ? true : false}
                />
            </div>
            <div className="form-group my-1 col-12 px-0">
                <label className="font-small">Comuna</label>
                <SelectSimple
                    type="simple"
                    data={communes}
                    className="form-select"
                    name="bill_commune_id"
                    placeholder="Comunas"
                    runChange={handleInput}
                    initValue={inputs.bill_commune_id}
                    disabled={((session && session.usuario.id_rol === undefined && session.usuario.id_comuna_empresa) || inputs.loading) ? true : false}
                    alphabetically
                />
            </div>
            <div className="form-group my-1 col-12 px-0">
                <label className="font-small">Dirección</label>
                <input value={inputs.bill_address_description} name="bill_address_description" type="text" className="form-control" onChange={handleInput} disabled={((session && session.usuario.id_rol === undefined && session.usuario.direccion_empresa) || inputs.loading) ? true : false} />
            </div>
        </>
    );
}

class Form extends Component {

    render() {
        const { inputs, auth: { session }, show, handleInput, communes, listRegions, handleRut, handlePhone, handleRegion, handleBill } = this.props;
        return (
            <div className="bg-i-gray p-4 rounded">
                <div className="border-bottom pb-1">
                    <img src={AssetsImg.User_f} alt="..." style={{ width: '20px' }} />
                    <div className="d-inline ">
                        <p className="d-inline pr-4 mb-0 ml-2">Tus datos</p>
                        {
                            (!session || session.usuario.id_rol !== undefined) ?
                                <p style={{ cursor: 'pointer' }} className="d-inline pl-4 ml-4 font-small font-weight-bold mb-0 text-right" onClick={() => show()}><ins>Ya estás registrado?</ins></p>
                                : null
                        }
                    </div>
                </div>
                <div className="form-group my-1 col-12 px-0 pt-2">
                    <label className="mb-0 font-small">Rut de compra: *</label>
                    <input value={inputs.rut} name="rut" type="text" className="form-control" onChange={handleRut} disabled={(session && session.usuario.id_rol === undefined) || inputs.loading ? true : false} autoComplete="off" />
                </div>
                <div className="form-group my-1 col-12 px-0">
                    <label className="mb-0 font-small">Nombres: *</label>
                    <input autoComplete='off' value={inputs.name} name="name" type="text" className="form-control" onChange={handleInput} disabled={(session && session.usuario.id_rol === undefined) || inputs.loading ? true : false} />
                </div>
                <div className="form-group my-1 col-12 px-0">
                    <label className="mb-0 font-small">Apellidos: *</label>
                    <input autoComplete='off' value={inputs.last_name} name="last_name" type="text" className="form-control" onChange={handleInput} disabled={(session && session.usuario.id_rol === undefined) || inputs.loading ? true : false} />
                </div>
                <div className="form-group my-1 col-12 px-0">
                    <label className="mb-0 font-small">Correo electrónico: *</label>
                    <input autoComplete='off' value={inputs.email} name="email" type="text" className="form-control" onChange={handleInput} disabled={(session && session.usuario.id_rol === undefined) || inputs.loading ? true : false} />

                </div>
                <div className="form-group my-1 col-12 px-0">
                    <label className="mb-0 font-small">Teléfono: *</label>
                    <input autoComplete='off' value={inputs.phone.replace('.', '')} name="phone" type="number" className="form-control numeric-without-arrow" onChange={handlePhone} disabled={(session && session.usuario.id_rol === undefined && this.props.auth.session.usuario.telefono === "") || inputs.loading ? true : false} />

                </div>

                <div className="form-group mb-1 col-12 px-0">
                    <label className="mb-0 font-small">Tipo documento: *</label>
                    <SelectSimple
                        type="simple"
                        data={[{ value: true, label: 'Factura' }, { value: false, label: 'Boleta' }]}
                        className="form-select"
                        name="bill"
                        placeholder="Documento Electrónico"
                        initValue={inputs.bill.toString()}
                        runChange={(e) => handleBill(e.target.value)}
                        disabled={inputs.loading}
                    />
                </div>
                {
                    (!session || session.usuario.id_rol !== undefined) ?
                        <div className="form-group my-1 col-12 px-0 ml-0 mt-2">
                            <Checkbox
                                defaultChecked={inputs.buy_without_account}
                                className="checkbox-not-account"
                                value={true}
                                onChange={(value, event) => {
                                    handleInput({
                                        target: {
                                            name: 'buy_without_account',
                                            value: event
                                        }
                                    })
                                }}><span className="mb-0 font-small">Comprar como invitado</span></Checkbox>
                        </div> : null
                }
                {inputs.bill === true ? <Bill {...{ inputs, auth: { session }, handleInput, handleRut, handlePhone, communes, listRegions, handleRegion }} /> : null}
            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    cart: state.cart
});

const mapDispatchToProps = dispatch => ({
    show: () => dispatch(show()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Form);
