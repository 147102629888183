import React, { Component } from 'react';
import { connect } from 'react-redux';
import AllSteps from './All_Steps';
import FooterMain from '../../../components/Footers/Footer_Main';
import HelperCoin from '../../../utils/currency';
import ListItems from './List_Items';

class Mobile extends Component {
    render() {
        const { cart } = this.props;
        return (
            <>
                <div className="row d-block d-sm-none bg-i-gray" style={{ minHeight: '100vh' }}>
                    <div className="col-12 py-5">
                        <div className="container-lg px-3">
                            <div className="row checkout-steps">
                                {
                                    cart.items.length > 0 && (
                                        <div className="col-12 col-lg-8 pl-lg-0 mb-3 d-block d-sm-none">
                                            <div className="bg-white px-3 pb-lg-4 px-lg-4 pb-3">
                                                <AllSteps />
                                            </div>
                                        </div>
                                    )
                                }
                                <div className="col-12 b-block d-sm-none">
                                    <div className="bg-white p-3">
                                        <p className="font-weight-bold text-grey">Resumen de tu pedido</p>
                                        <hr />
                                        <ListItems />
                                        <hr />
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="font-weight-bold mb-0" style={{ fontSize: '.8em' }}>Valor</p>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <p className="font-weight-bold mb-0" style={{ fontSize: '.8em' }}>{HelperCoin.format(Math.round(cart.total_amount_payment))}</p>
                                            </div>
                                        </div>
                                        <div className="w-100 bg-light" style={{ height: 1 }} />
                                        {
                                            cart.discount_code ? (
                                                <>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <p className="font-weight-bold mb-0 text-dark" style={{ fontSize: '.8em' }}>{`Descuento cupón ${cart.total_discount_coupon}%`}</p>
                                                        </div>
                                                        <div className="col-6 d-flex justify-content-end">
                                                            <p className="font-weight-bold mb-0 text-orange" style={{ fontSize: '.8em' }}>{HelperCoin.format(Math.round(cart.total_amount_payment * cart.total_discount_coupon / 100))}</p>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : null
                                        }
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="font-weight-bold mb-0" style={{ fontSize: '.8em' }}>Subtotal</p>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <p className="font-weight-bold mb-0" style={{ fontSize: '.8em' }}>{HelperCoin.format(Math.round(cart.total_amount_payment - (cart.total_amount_payment * (cart.total_discount_coupon / 100))))}</p>
                                            </div>
                                        </div>
                                        <hr />
                                        <div className="row">
                                            <div className="col-6">
                                                <p className="font-weight-bold mb-0 text-dark" style={{ fontSize: '.8em' }}>Total a pagar</p>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <p className="font-weight-bold mb-0 text-orange" style={{ fontSize: '.8em' }}>{HelperCoin.format(Math.round(cart.total_amount_payment))}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <FooterMain main />
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart
});

export default connect(mapStateToProps)(Mobile);