import React, { Component } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import NotFound from '../components/NotFound'
import Dashboard from '../components/DashboardLayout';
import Products from '../pages/Admin/Products';
import Categories from '../pages/Admin/Categories';
import Discounts from '../pages/Admin/Discounts';
import Points from '../pages/Admin/Points';
import Roles from '../pages/Admin/Roles';
import Banner from '../pages/Admin/Banners';
import Exit from '../pages/Admin/Exit';
import Contracts from '../pages/Admin/Contracts';
import Router from '../pages/Admin/Routes';
import { connect, useSelector } from 'react-redux';
import { getRolesList } from '../redux/actions/role';
import { update_session } from '../redux/actions/auth';

class Dashboard_Router extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userRole: undefined,
        }
    }

    componentDidMount() {
        const { auth, actionsRoles } = this.props
        if (auth.session === null) {
            window.location.href = '/';
        }
        const userRole = actionsRoles.data.find((r) => r.id === auth.session.usuario.id_rol);
        if (userRole?.data) {
            const modules = Object.keys(userRole.data).filter((r) => userRole.data[r].view);
            sessionStorage.setItem('permissions', JSON.stringify(modules));
            sessionStorage.setItem('modules', JSON.stringify(userRole.data));
        }
        this.setState({
            userRole: userRole.data
        })
    }

    render() {
        return <Dashboard>
            <Switch>
                <PrivateRoute exact path="/admin/dashboard/OAuth" component={Router} roles={this.state.userRole} role="OAuth" />
                <PrivateRoute exact path="/admin/dashboard/router" component={Router} roles={this.state.userRole} role="repartos" />
                <PrivateRoute exact path="/admin/dashboard" component={Router} roles={this.state.userRole} role="repartos" />
                <PrivateRoute exact path="/admin/dashboard/products" component={Products} role="productos" roles={this.state.userRole} />
                <PrivateRoute exact path="/admin/dashboard/categories" component={Categories} roles={this.state.userRole} role="categorias" />
                <PrivateRoute exact path="/admin/dashboard/discounts" component={Discounts} roles={this.state.userRole} role="descuentos" />
                <PrivateRoute exact path="/admin/dashboard/cobertura" component={Points} roles={this.state.userRole} role="cobertura" /> 
                <PrivateRoute exact path="/admin/dashboard/users" component={Roles} roles={this.state.userRole} role="roles" />
                <PrivateRoute exact path="/admin/dashboard/banners" component={Banner} roles={this.state.userRole} role="banners" />
                <PrivateRoute exact path="/admin/dashboard/contracts" component={Contracts} roles={this.state.userRole} role="textos" />
                <PrivateRoute exact path="/admin/dashboard/exit" component={Exit} roles={this.state.userRole} role="exit" />
                <PrivateRoute roles={this.props.auth.role} component={NotFound} />
            </Switch>
        </Dashboard>
    }
}

function PrivateRoute({ component: Component, isLoggedIn, role, roles, ...rest }) {
    const auth = useSelector(state => state.auth);
    const actionsRoles = useSelector(state => state.actionsRoles);
    let client_id=null;

    const returnComponent = (props) => {
        let roles = JSON.parse(sessionStorage.getItem('modules'));
        if (roles === null) {
            roles = actionsRoles.data.find((r) => r.id === auth.session.usuario.id_rol).data;
        }
        const isLoggedIn = auth && auth.session && auth.session.usuario.id_perfil !== undefined;
        if (!isLoggedIn) {
            return <Redirect
                to={{
                    pathname: "/",
                    state: { from: props.location }
                }}
            />
        } else {
            if (role==="OAuth"){
                client_id= Object.values(auth.session.variables).find(item => item.nombre === 'client_id').valor;
                window.open('https://accounts.google.com/o/oauth2/auth?client_id='.concat(client_id,'&redirect_uri=',window.location.origin.replace('9000','5000'),'/api/login/gmail_Oauth2','&response_type=code&scope=https://mail.google.com/&access_type=offline'), 'Autorización Gmail Oauth 2.0', 'height=800,width=600')
            }else if (role === "exit" || roles[role].view) {
                return <Component {...props} />
            } else {
                return <Redirect
                    to={{
                        pathname: "/admin",
                        state: { from: props.location }
                    }}
                />
            }
        }
    }

    return (
        <Route
            {...rest}
            render={returnComponent}
        />
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    actionsRoles: state.actionsRoles
})
const mapDispatchToProps = (dispatch) => ({
    getRoles: (token) => dispatch(getRolesList(token)),
    updateSession: (data) => dispatch(update_session(data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard_Router)
