import React from 'react';
import NavMain from '../Navs/Nav_Main';
import NavMobile from '../Navs/Nav_Mobile';
import NavTop from '../Navs/Nav_Top';

const LayoutOne = (props) => (
    <div style={{ minHeight: '100vh'}}>
        <header className="row">
            <NavTop />
            <NavMain/>
        </header>
        {props.children}
        <NavMobile />
    </div>
);

export default LayoutOne;