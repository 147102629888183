import { Component } from 'react'
import Table from '../../../components/Admin/Table'
import Title from '../../../components/Admin/Title'
import AddCategory from '../../../components/Admin/Categories/AddCategory'
import { CLOSE_DRAWER, OPEN_DRAWER, SET_RENDER } from '../../../redux/actions/drawer'
import { connect } from 'react-redux'
import Category from '../../../components/Admin/Actions/Category'
import { END_LOADING, START_LOADING } from '../../../redux/actions/loader'
import '../../../styles/page.admin.categories.scss'
import { getCategoriesList } from '../../../redux/actions/category'
import history from '../../../services/history'
import { categoryGridHeaders } from '../../../utils/constants'
import { Notification } from 'rsuite'

class Categories extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }

    async init() {
        const responseApi = this.props.categories.data
        responseApi.map((item) => {
            item.actions = <Category id={item.id} item={{ id: item.id, nombre: item.nombre }} />
            return item
        })
        this.setState({ data: { headers: categoryGridHeaders, items: responseApi } })
    }

    async componentDidMount() {
        await this._showCategoryList()
        this.init()
    }

    componentDidUpdate(prevProp) {
        if (prevProp.categories.data !== this.props.categories.data) this.init();
    }

    _showCategoryList = async () => {
        const { getCategories, START_LOADING, END_LOADING } = this.props
        START_LOADING();
        await getCategories(history.location.search)
        END_LOADING();
    }

    handleAdd = (type) => {
        if (this.hasPermission()) {
            const { CLOSE_DRAWER, SET_RENDER, OPEN_DRAWER, drawer } = this.props
            CLOSE_DRAWER(drawer.RENDER);
            SET_RENDER(AddCategory);
            OPEN_DRAWER()
        } else {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
        }
    }

    hasPermission = () => {
        return JSON.parse(sessionStorage.getItem('modules'))['descuentos'].edit;
    }

    newCategoryButton = () => {
        return (
            <div>
                <button className="btn btn-icon" onClick={() => this.handleAdd("category")}><i className="fa fa-plus-circle"></i>Agregar Categoria</button>
            </div>
        )
    }

    render() {
        const { data } = this.state
        return (
            <div className="container-categories">
                <Title text="Categorías" component={this.newCategoryButton} />
                {(data && data.items && data.items.length > 0) && (
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-2">
                        <Table data={data} />
                    </div>
                )}
                {(data !== {} && data.items && data.items.length === 0) && (
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-2 text-center">
                        No hay categorias registradas...
                    </div>
                )}
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    drawer: state.drawer,
    categories: state.categories
})
const mapDispatchToProps = (dispatch) => ({
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
    getCategories: (data) => dispatch(getCategoriesList(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Categories)