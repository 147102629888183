import React, { Component } from 'react'
import { connect } from 'react-redux'
import { doGetReparto } from '../../../services/api/endpoints'
import '../../../styles/component.admin.cardmaps.scss'
import Select from '../Select'
class CardMaps extends Component {
    constructor(props) {
        super(props)
        this.state = {
            show: props.show,
            inputs: {
                status_filter: -1
            }
        }
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                inputs: {
                    status_filter: this.props.data.id_estado
                }
            })
        }, 800);
    }

    async getDataReparto() {
        const { data, auth, openCameraWithData } = this.props
        if (data && data.id_pedido && data.id_tipo_venta) {
            const { id_tipo_venta, id_pedido } = data
            const resp = await doGetReparto(auth.session.token, {
                id_tipo_venta,
                id_pedido
            })
            if (resp && resp.length > 0) {
                this.handleChange({ target: { name: "notes", value: resp[0].observacion_entrega } })
                openCameraWithData(`https://${resp[0].path_entrega}`)
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.id_pedido !== this.props.data.id_pedido) {
            this.getDataReparto()
        }
        if (this.state.show !== this.props.show) {
            this.setState({ show: this.props.show })
        }
    }

    closeWindow = () => {
        const { handleClose } = this.props
        handleClose()
    }

    handleChange = ({ target }) => {
        const { onChange } = this.props
        onChange(this.props.data.id_pedido, target)
    }

    handleSave = (id) => {
        const { onSave } = this.props
        onSave(id);
    }

    handleCamera = () => {
        const { openCamera } = this.props
        openCamera()
    }

    handleImageView = () => {
        const { openImageView } = this.props
        openImageView()
    }

    handleInputChange = ({ target }) => {
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [target.name]: target.value } })
    }

    render() {

        const { show, inputs } = this.state
        const { data, camera, disabled } = this.props
        const { status_filter } = inputs
        return (
            <div className={show ? "container-card-maps" : "container-card-maps d-none"}>
                <div className="container-card-maps__header p-3">
                    <div className="container-card-maps__header__title">
                        Delivery
                    </div>
                    <div className="container-card-maps__header__close px-3" onClick={this.closeWindow}>
                        x
                    </div>
                </div>
                {(data && data.detalle) && (<div className="container-card-maps__content p-3">
                    <div className="container-card-maps__content__item mb-3">
                        <span>ID:</span> {data.codigo_pedido}
                    </div>
                    <div className="container-card-maps__content__item mb-3">
                        <span>Direccion:</span> {data.direccion}
                    </div>
                    <div className="container-card-maps__content__item mb-3">
                        <span>Productos:</span>
                        <ul>
                            {data.detalle.map(p => <li>{p.cantidad_pack} x {p.producto}</li>)}
                        </ul>
                    </div>
                    {camera && camera.value && (
                        <div className="container-card-maps__content__item mb-3">
                            <span>Foto:</span> <button className="btn" onClick={this.handleImageView}>Ver Foto</button>
                        </div>
                    )}
                    {!disabled && (<div className="container-card-maps__content__item mb-3">
                        <div className="input-group mb-3">
                            <Select
                                label="Estado"
                                value={status_filter}
                                name="status_filter"
                                disabled={disabled}
                                onChange={this.handleInputChange}
                                data={[{ value: "Despacho, No Entregado", id: 4 }, { value: "Entregado, Entrega Parcial", id: 3 }]}
                            />
                        </div>
                    </div>)}
                    <div className="container-card-maps__content__item mb-3">
                        <span>Nota:</span>
                        <textarea disabled={disabled} name="notes" className="form-control" value={data.notes} onChange={this.handleChange} />
                    </div>
                </div>)}

                <div className="container-card-maps__actions p-3">
                    <div className="container-card-maps__actions_item d-flex justify-content-around align-items-center">
                        {!disabled && (<>
                            <button className="btn btn-icon-orange-large" onClick={this.handleCamera}>
                                <i className="fa fa-camera"></i>
                            </button>
                            <button type="button" className="btn btn-rioclaro" onClick={() => this.handleSave({ ...data, status_filter })}>Guardar</button></>
                        )}
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
})
const mapDispatchToProps = () => ({})

export default connect(mapStateToProps, mapDispatchToProps)(CardMaps)