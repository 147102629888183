import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import AssetsImg from '../../assets/image';

class NavMobile extends Component {
	render(){
		const { auth, cart } = this.props;
		return(
			<>
			<nav className="mobile-nav d-flex d-sm-none bg-white border-top row mx-0">
			<div className="col-12 py-2">
				<div className="row">
				<div className="col-4 px-1 text-center">
					<Link to="/" className="nav-link px-0 text-dark">
						<img src={AssetsImg.Product} className="img-fluid2" alt="..."/>
						<br/>
						  Productos
					</Link>
				</div>
				<div className="col-4 px-1 text-center">
					<Link to={cart.items.length > 0 ? '/finalizar-compra' : '/carrito'} className="nav-link px-0 text-dark">
						{cart.items.length > 0 ? <span className="btn-cart-count">{cart.items.length}</span> : null}
						<img src={AssetsImg.Cart} className="img-fluid2" alt="..."/>
						<br/>
						  Carrito
					</Link>
				</div>
				<div className="col-4 px-1 text-center">
					{auth.session ? (
						<Link to="/perfil" className="nav-link px-0 text-dark">
							<img src={AssetsImg.User} className="img-fluid2" alt="..."/>
							<br/>
							Perfil
					   </Link>
					) : (
						<Link to="/ingresar" className="nav-link px-0 text-dark">
							<img src={AssetsImg.User} className="img-fluid2" alt="..."/>
							<br/>
							Ingresar
				   		</Link>
					)}
				</div>
			  </div>
			  </div>
			</nav>
		</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	cart: state.cart
});

export default connect(mapStateToProps)(NavMobile);