import { Component } from "react";

class Image extends Component {
    render() {
        const { src, alt, className, style, ...rest } = this.props
        return (
            <img src={src} alt={alt} className={className || ''} style={style || {}} {...rest} />
        )
    }
}

export default Image