import { Component } from "react";
import { connect } from "react-redux";
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";
import Input from "../Input";
import Title from "../Title";
import Table from '../../../components/Admin/Table'
import { formatsGridHeaders } from "../../../utils/constants";

const initialState = {
    nombre: '',
    cantidad: ''
}
class AddFormat extends Component {

    constructor(props) {
        super(props)
        this.state = {
            inputs: {},
            errors: {},
            data: []
        }
        this.clearInputs = this.clearInputs.bind(this);
    }

    clearInputs() {
        this.setState({
            inputs: { ...initialState }
        })
    }

    componentDidMount() {
        if (this.props.setClearInputs) {
            this.props.setClearInputs(this.clearInputs);
        }
        this.setState({
            inputs: { ...initialState }
        })
        if (this.props.formatos) {
            this.init()
        }
    }

    componentDidUpdate(prevProp) {
        if (prevProp.formatos !== this.props.formatos) this.init();
    }

    async init() {
        const data = this.props.formatos.map(i => ({ nombre: i.nombre, cantidad: i.cantidad }));
        this.setState({ data })
    }

    handleChange = ({ target }) => {
        let { name, value } = target
        if (name === "cantidad") {
            const reg = new RegExp(/^\d+$/);
            if (!reg.test(value)) {
                return
            }
        }
        const { inputs } = this.state
        this.props.onFormatInputChange({ [name]: value })
        this.setState({ inputs: { ...inputs, [name]: value } })
    }

    render() {
        const { inputs, errors, data } = this.state
        const { nombre, cantidad } = inputs
        return (
            <div className="mt-5">
                <Title text="Formatos" />
                <div className="row justify-content-center px-md-2 px-1 mb-5">
                    <div className="col-12 mb-2">
                        <Input
                            name="nombre"
                            value={nombre}
                            label="Nombre"
                            placeholder="Nombre del formato"
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                    <div className="col-12 mb-2">
                        <Input
                            name="cantidad"
                            value={cantidad}
                            label="Cantidad"
                            placeholder="Cantidad"
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                </div>
                {data && data.length > 0 && (
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-3">
                        <Table data={{ items: data, headers: formatsGridHeaders }} />
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    drawer: state.drawer,
    auth: state.auth,
    category: state.category
})
const mapDispatchToProps = (dispatch) => ({
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
})
export default connect(mapStateToProps, mapDispatchToProps)(AddFormat)