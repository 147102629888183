import { createAction } from 'redux-actions'
import { CreateRole, EditRole, Roles } from '../../services/api/endpoints';

export const SET_ROLE = createAction('SET_ROLE');

export const fetch_start_roles = createAction('fetch_start_roles');
export const fetch_success_roles = createAction('fetch_success_roles');
export const fetch_error_roles = createAction('fetch_error_roles');

export const fetch_start_roles_v2 = createAction('fetch_start_roles_v2');
export const fetch_success_roles_v2 = createAction('fetch_success_roles_v2');
export const fetch_error_roles_v2 = createAction('fetch_error_roles_v2');

export const getRolesList = (token) => async (dispatch) => {
    dispatch(fetch_start_roles());
    try {
        const response = await Roles(token);
        console.log(response, 'RESPUESTA DE LISTADO DE ROLES');
        const { data } = response;
        dispatch(fetch_success_roles({ data }))
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_roles());
    }
};

export const doCreateRole = (params, token) => async (dispatch) => {
    dispatch(fetch_start_roles());
    try {
        await CreateRole(params, token);
        dispatch(getRolesList(token));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_roles());
    }
}

export const doEditRole = (params, id, token) => async (dispatch) => {
    dispatch(fetch_start_roles());
    try {
        await EditRole(params, id, token);
        dispatch(getRolesList(token));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_roles());
    }
}

