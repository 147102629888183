import React, { Component } from 'react';
import { connect } from 'react-redux';
import NavRsuite from '../../../components/Navs/Nav_Rsuite';
import { checkUserRut, update_session, checkUserEmail } from '../../../redux/actions/auth';
import { checkout_update_form, checkout_reset } from '../../../redux/actions/checkout';
import { create_order, create_transbank } from '../../../redux/actions/payment';
import { show } from '../../../redux/actions/authModal';
import * as RulesValidate from '../../../utils/validations';
import * as Api from '../../../services/api/endpoints';
import { Button, Alert, Loader, Icon } from 'rsuite';
import { GetRegions, SaveCategories, SaveOffices, SaveRegions } from '../../../services/session';
import LoaderRsuite from '../../../components/Loaders/Loader_Rsuite';
import StepClientData from './Step_ClientData';
import { setOrder, setAnonUser, _maskRut, _formatRut } from '../../../utils/helpers';
import StepDelivery from './Step_Delivery';
import StepPayment from './Step_Payment';
import SetupForm from './Form';

const tabs = [
	{ name: "Entrega", key: 'tab-entrega', disabled: true },
	{ name: 'Datos de Cliente', key: 'tab-cliente', disabled: true },
	{ name: 'Pago', key: 'tab-pago', disabled: true },
];

const ViewStep = (props) => {
	switch (props.active) {
		case 'tab-entrega':
			return <StepDelivery {...props} />;
		case 'tab-cliente':
			return <StepClientData {...props} />;
		case 'tab-pago':
			return <StepPayment {...props} />;
		default:
			return null;
	}
};

class AllSteps extends Component {
	constructor(props) {
		super(props);
		this.formSteps = React.createRef();
		this.state = {
			active: 'tab-entrega',
			stepBtn: { backClass: 'd-none', nextClass: '', next: 0, back: 0 },
			inputs: { ...this.props.checkout.form },
			communes: [],
			selectedCommunes: null,
			transbank: null,
			loadingPayment: false,
			loading: false,
			error: null,
			remove: false,
			invalidations: {}
		}
	}

	componentDidUpdate(prevProps) {
		const { inputs } = this.state;
		if (inputs.phone !== this.props.checkout.form.phone) {
			let communes = [];
			let regions = GetRegions();
			let region_id = this.props.checkout.form.bill_region_id;
			if (regions?.length && region_id) {
				let finded = regions.find(x => x.id === region_id);
				communes = finded && finded.comunas ? finded.comunas.map(y => ({ value: y.id, label: y.comuna_nombre })) : communes;
			}

			this.setState({ inputs: { ...this.props.checkout.form }, communes })
		}
	}


	componentDidMount = async () => {
		const { inputs } = this.state;
		Object.keys(inputs).forEach((key) => {
			if (key === 'rut' || key === 'bill_rut') {
				inputs[key] = _formatRut(inputs[key])
			}
		})
		this.setState({ inputs })
		document.addEventListener('keydown', this._detectDelete, false);
		if (!GetRegions()) {
			this.setState({ loading: true });
			try {
				const response = await Api.AllProducts({});
				const { categorias, regiones, sucursales } = response;
				categorias && SaveCategories(categorias);
				sucursales && SaveOffices(sucursales);
				regiones && SaveRegions(regiones);
				this.setState({ loading: false });
			} catch (e) {
				this.setState({ loading: false, error: true });
			}
		}

		let communes = GetRegions()?.find(x => x.id === inputs.bill_region_id);
		if (communes) {
			communes = communes.comunas
			communes = communes.map(y => ({ value: y.id, label: y.comuna_nombre }));
			this.setState({ communes })
		}
	}

	_handleSelect = (activeKey) => {
		this.setState({ active: activeKey });
	}

	_handlePhone = (e) => {
		const { remove } = this.state;
		if (remove) {
			this._handleInput(e);
		} else {
			if (e.target.value.match(/^\d+$/)) {
				this._handleInput(e);
			}
		}
	}

	_handleInput = async (e) => {
		const { inputs } = this.state;
		const { updateCheckout } = this.props;
		const update = { ...inputs, [e.target.name]: e.target.value };
    this.setState({ inputs: update }, async () => {
        if (e.target.name === 'email') {
          if(RulesValidate.CheckEmail(e.target.value)) {
            const checkemailResponse = await checkUserEmail({ correo: e.target.value, rut: null }, inputs.buy_without_account);
            const { error } = checkemailResponse
            if (error) {
              this._clearFields()
            }
          }
        }
    });
		updateCheckout({ ...update });
	}

	_handlePosition = (p = 0) => {
		this._handleSelect(tabs[p].key);
	}

	_next = async () => {
		const { auth: { session }, cart, checkout } = this.props;
		const { stepBtn, inputs } = this.state;
		if (session && !checkout.form.address_id && cart.shipping.comuna) {
			Alert.error(`Debes seleccionar una dirección de despacho.`, 5000)
			return false;
		}
		const n = stepBtn.next + 1;
		let invalidations = null;
		switch (tabs[n - 1].key) {
			case "tab-entrega":
				invalidations = await RulesValidate.StepDelivery(inputs, cart.shipping.comuna, checkout.form.buy_without_account);
				break;
			case "tab-cliente":
				invalidations = await RulesValidate.StepClient(inputs);
				this.setState({ invalidations })
				break;
			default:
				break;
		}

		if (invalidations !== null) {
			Api.FormValidator.validate(this.formSteps.current, invalidations);
			return false;
		}

		if (!session && inputs.buy_without_account === false) {
			Alert.error(`Inicia sesión, regístrate o selecciona "Comprar como invitado"`, 5000)
			return false;
		}

		this.setState({ stepBtn: { ...stepBtn, next: n, nextClass: tabs[n + 1] === undefined ? 'd-none' : '', back: stepBtn.next + 1, backClass: '' } });
		this._handlePosition(n);
	}

	_back = () => {
		const { stepBtn } = this.state;
		const n = stepBtn.back - 1;
		this.setState({ stepBtn: { ...stepBtn, back: n, backClass: tabs[n - 1] === undefined ? 'd-none' : '', next: stepBtn.next - 1, nextClass: '' } });
		this._handlePosition(n);
	}

	_handleRegion = (e, listRegions) => {
		let selectedCommunes = listRegions.filter(x => x.id.toString() === e.target.value)[0].comunas;
		selectedCommunes = selectedCommunes.map(y => ({ value: y.id, label: y.comuna_nombre }));
		this.setState({ communes: selectedCommunes })
		this._handleInput(e);
	}

	_handlePayment = async () => {
		const { createOrder, createTransbank, cart, checkout, auth, updateSession } = this.props;
		this.setState({ loadingPayment: true });
		try {
			let checked = null, anon = null, address = null, payment;
			Object.keys(checkout.form).forEach(async (key) => {
				if (key === 'rut' || key === 'bill_rut' || key === 'third_rut') {
					checkout.form[key] = checkout.form[key].replace(/[^\dkK-]/g, "");
				}
			})
			if (!auth.session) {
				checked = await Api.CheckEmail({ correo: checkout.form.email, rut: checkout.form.rut });
				anon = await Api.AnonLogin({ correo: checked.data.correo });
				await Api.ProfileUpdate(setAnonUser(checkout.form), anon.token);
				if (cart.shipping.comuna) {
					address = await Api.CreateAddress({ id_comuna: cart.shipping.comuna.id, id_cliente: checked.data.id, oficina: '', direccion: checkout.form.description, referencia: '' }, anon.token);
				}

				payment = setOrder(cart, checkout, null, checked, address);
			} else {
				address = checkout.form.address_id;
				let userData = setAnonUser(checkout.form);
				await Api.ProfileUpdate(userData, auth.session.token)
				await updateSession({ user: userData })
				payment = setOrder(cart, checkout, auth, null, address);
			}

			const { data } = await createOrder(payment, anon ? anon.token : auth.session.token);
			checkout_reset();
			const { to_url, data: { token } } = await createTransbank({ id_pedido: data.id }, anon ? anon.token : auth.session.token);
			this.setState({ transbank: { to_url, token }, loadingPayment: false });
		} catch (e) {
			Alert.error(`Se ha producido un error al proceder al pago inténtalo más tarde`, 5000);
			this.setState({ loadingPayment: false });
		}
	}

	_detectDelete = (e) => {
		if (e.key === "Delete" || e.key === "Backspace") {
			this.setState({ remove: true })
		} else {
			this.setState({ remove: false });
		}
	}

	_clearFields = () => {
		const fieldsToClear = [
			'bill_business_name',
			'bill_commune_id',
			'bill_phone',
			'bill_region_id',
			'bill_rut',
			'bill_turn',
			'last_name',
			'name',
			'phone',
			'direccion_empresa',
			'direccion',
			'bill_address_description'
		];
		const { inputs } = this.state;
		const { updateCheckout } = this.props

		Object.keys(inputs).forEach((item) => {
			if (fieldsToClear.indexOf(item) !== -1) {
				inputs[item] = ''
			}
		})

		this.setState({ inputs })
		updateCheckout({ ...inputs });

	}

	_handleRut = async (e) => {
		this.setState({ inputs: _maskRut(e, this.state, this._clearFields) }, async () => {
			const { inputs, invalidations } = this.state;
			const { checkUserRut, updateCheckout } = this.props;
			updateCheckout({ ...inputs });
			if (RulesValidate.rutEsValido(inputs[e.target.name])) {
				if (invalidations[e.target.name]) {
					delete invalidations[e.target.name]
					this.setState({ invalidations })
					Api.FormValidator.validate(this.formSteps.current, invalidations);
				}
				if (e.target.name === 'rut') {
					const checkrutResponse = await checkUserRut(e.target.value.replace(/[^\dkK-]/g, ''), inputs.buy_without_account, true);
					const { error } = checkrutResponse
					if (error) {
						this._clearFields()
					}
				}
			} else {
				if (e.target.value.length > 10) {
					const rutError = { [e.target.name]: ['El rut no es válido'] }
					const errors = { ...invalidations, ...rutError };
					Api.FormValidator.validate(this.formSteps.current, errors);
					this.setState({ invalidations: errors }, () => {
						if (e.target.name === 'rut') {
							this._clearFields();
						}
					})
				}
			}
		})
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this._detectDelete, false);
	}

	render() {
		const { auth, checkout, setupRegions, show } = this.props;
		const listRegions = GetRegions();
		const handleInput = this._handleInput;
		const handleRegion = this._handleRegion;
		const handleRut = this._handleRut;
		const handlePhone = this._handlePhone;
		const { active, inputs, communes, stepBtn, transbank, loadingPayment } = this.state;
		return (
			<LoaderRsuite
				loading={{
					run: this.state.loading,
					title: "Cargando...",
				}}
				fail={{
					run: this.state.error,
					title: "Se ha producido un error!",
				}}
			>
				<NavRsuite
					className="text-center pt-3"
					appearance="subtle"
					active={active}
					onSelect={this._handleSelect}
					justified
					list={tabs}
				/>
				<form ref={this.formSteps}>
					<ViewStep {...{ active, handleInput, inputs, auth, handleRegion, communes, listRegions, checkout, setupRegions, show, handleRut, handlePhone }} />
				</form>
				<div className="row justify-content-between">
					<div className="col-auto">
						<button
							className={`text-white ${stepBtn.backClass} text-dark`}
							onClick={this._back}
							style={{ background: 'transparent' }}
						>
							<Icon icon='chevron-left' /> Volver
					</button>
					</div>
					<div className="col-auto">
						{stepBtn.nextClass === '' ?
							<Button
								appearance="default"
								className={`col btn-one btn-sm text-white py-1 ${stepBtn.nextClass}`}
								onClick={this._next}
							>
								Continuar
							</Button> :
							<Button appearance="default" className="col btn-one btn-sm text-white py-1 px-4" onClick={() => this._handlePayment()}>
								PAGAR
							</Button>
						}
					</div>
					{loadingPayment && <Loader backdrop content="Cargando..." vertical />}
					{transbank ? <SetupForm token={transbank.token} url={transbank.to_url} /> : null}
				</div>
			</LoaderRsuite>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	checkout: state.checkout,
	payment: state.payment,
	cart: state.cart
});

const mapDispatchToProps = dispatch => ({
	createOrder: (data, token) => dispatch(create_order(data, token)),
	createTransbank: (data, token) => dispatch(create_transbank(data, token)),
	updateCheckout: (data) => dispatch(checkout_update_form(data)),
	updateSession: (data) => dispatch(update_session(data)),
	checkUserRut: (data, without_account, mobile = false) => dispatch(checkUserRut(data, without_account, mobile)),
	checkUserEmail: (data, without_account) => dispatch(checkUserEmail(data, without_account)),
	show: () => dispatch(show())
});


export default connect(mapStateToProps, mapDispatchToProps)(AllSteps);
