import { Component } from "react";
import { connect } from "react-redux";
import { toBase64 } from "../../../utils/helpers";
import Input from "../Input";
import Select from "../Select";
import Title from "../Title";
import Uploader from "../Uploader";
import { v4 } from 'uuid';
import { productSchema } from '../../../utils/validations';
import validate from '../../../utils/validate';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toolbar from '../../../utils/toolbars';
import { doEditProduct, doGetProduct } from "../../../redux/actions/product";

import '../../../styles/component.admin.products.scss';
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";
import { Categories, ProductDetails, UploadFile } from "../../../services/api/endpoints";
import { GetCategories } from "../../../services/session";
import { Toggle } from "rsuite";
class EditProduct extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputs: {},
            errors: {},
            editor_descripcion_corta: null,
            editor_descripcion_larga: null,
            end_loading_data: false,
            firstImage: -1
        }
        this.props.START_LOADING()
    }

    async componentDidMount() {
        this.init();
    }

    async init() {
        const { product, START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const storedCategories = GetCategories();
        let categories = [];
        if (storedCategories) {
            categories = GetCategories().map((category) => {
                return { id: category.id, value: category.nombre };
            });
        } else {
            categories = (await Categories({})).data?.map((category) => {
                return { id: category.id, value: category.nombre };
            });
        }
        const { data } = await ProductDetails(product.id);
        data.images = data.imagenes.map((i) => {
            return { src: i, new: false, id: v4() }
        });
        data.state = true;
        this.setState({
            inputs: data,
            categories,
        }, () => {
            const { inputs } = this.state
            this.setState({ inputs: { ...inputs } })
        })
        END_LOADING()
    }

    componentDidUpdate() {
        const { editor_descripcion_corta, editor_descripcion_larga, end_loading_data, inputs } = this.state
        if (editor_descripcion_corta && editor_descripcion_larga && inputs.descripcion_corta && inputs.descripcion_larga && !end_loading_data) {
            editor_descripcion_corta.setData(inputs.descripcion_corta);
            editor_descripcion_larga.setData(inputs.descripcion_larga);
            this.setState({ end_loading_data: true })
        }
    }

    handleChange = ({ target }) => {
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [target.name]: target.value } }, () => {
            const { errors } = this.state
            Object.keys(errors).forEach((key) => {
                if (key === target.name) {
                    delete errors[key]
                }
            })

            this.setState({ errors })
        })
    }

    handleChangeCheckbox = ({ target }) => {
        const { name, checked } = target
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [name]: checked } })
    }

    _updateQuantity = (value) => {
        const { inputs } = this.state
		if (inputs.cantidad_disponible + value !== 0) {
			inputs.cantidad_disponible += value;
            this.setState({ inputs: { ...inputs}})
		}
	}

    handleChangeimage = (e) => {
        const { target } = e
        const { files } = target;
        const { inputs } = this.state
        Array.from(files).forEach((item) => {
            toBase64(item)
                .then((response) => {
                    if (e.target.dataset.reference) {
                        inputs.images.map((item) => {
                            if (item.id === e.target.dataset.reference) {
                                item.src = response
                                return item
                            }

                            return item
                        })
                        this.setState({ inputs }, () => {
                            const { errors } = this.state
                            Object.keys(errors).forEach((key) => {
                                if (key === target.name) {
                                    delete errors[key]
                                }
                            })

                            this.setState({ errors })
                        })
                    } else {
                        inputs.images.push({ src: response, id: v4(), new: true })
                        this.setState({ inputs }, () => {
                            const { errors } = this.state
                            Object.keys(errors).forEach((key) => {
                                if (key === target.name) {
                                    delete errors[key]
                                }
                            })

                            this.setState({ errors })
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        })
    }

    handleRemoveImage = (id) => {
        const { inputs } = this.state
        const images = []
        inputs.images.forEach((item) => {
            if (item.id !== id) {
                images.push(item)
            }
        })

        this.setState({ inputs: { ...inputs, images } })
    }

    handleSubmit = async () => {
        const { inputs } = this.state
        const validations = await validate(productSchema, inputs, this._editProduct)
        if (validations) {
            this.setState({ errors: validations })
        }
    }

    _editProduct = async () => {
        const { START_LOADING, END_LOADING, editProduct, auth, drawer, CLOSE_DRAWER, products } = this.props
        START_LOADING()
        const { inputs } = this.state
        const imagenes = []
        const imagenesFiltradas = []
        for (const m of inputs.images) {
            if (m.new) {
                const response = await UploadFile({ file: m.src }, auth.session.token);
                if (response) {
                    imagenes.push(response.archivo);
                }
            } else {
                imagenes.push(m.src)
            }
        }
        if (this.state.firstImage !== -1) {
            imagenes.forEach((i, index) => {
                if (index === this.state.firstImage) {
                    imagenesFiltradas.unshift(i)
                } else {
                    imagenesFiltradas.push(i)
                }
            })
        }
        let productos_relacionados = ""
        inputs.productos_relacionados.forEach((p) => {
            const product = products.data.find(pro => pro.nombre === p.nombre);
            if (product) {
                productos_relacionados = productos_relacionados + "," + product.id;
            }
        })
        inputs.productos_relacionados = productos_relacionados.substring(1);
        delete inputs.despacho;
        delete inputs.images;
        delete inputs.retiro;
        inputs["imagenes"] = this.state.firstImage !== -1 ? imagenesFiltradas : imagenes;
        await editProduct(inputs, inputs.id, auth.session.token)
        END_LOADING()
        CLOSE_DRAWER(drawer.RENDER);
    }

    ButtonSave = () => {
        return (
            <div className="row justify-content-center px-md-2 px-1">
                <div className="col-12 mb-2 d-flex justify-content-end">
                    <button className="btn btn-rioclaro" onClick={this.handleSubmit}>Guardar</button>
                </div>
            </div>
        )
    }

    render() {

        const { inputs, errors } = this.state
        const { nombre, sku, id_categoria, images, activo, cantidad_disponible} = inputs
        return (
            <div className="container-component-products">
                <Title text="Editar Produto" />
                <div className="row justify-content-center px-md-5 px-1 mb-2">
                    <div className="col-12 col-md-12 mb-2">
                        <Toggle className="ms-5" checked={activo} onChange={(checked) => this.setState({ inputs: { ...inputs, activo: checked } })} size="lg" checkedChildren="Activo" unCheckedChildren="Inactivo" />
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <Select
                            label="Categoria"
                            value={id_categoria}
                            name="id_categoria"
                            onChange={this.handleChange}
                            data={this.state.categories}
                            errors={errors}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <label>Cantidad disponible</label>
                        <div>
                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                <button type="button" className="btn btn-outline-secondary px-2 py-1" onClick={() => this._updateQuantity(-1)} disabled={cantidad_disponible === 1 ? true : false}>-</button>
                                <button type="button" className="btn btn-outline-secondary p-1"><small>{cantidad_disponible}</small></button>
                                <button type="button" className="btn btn-outline-secondary px-2 py-1" onClick={() => this._updateQuantity(1)}>+</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center px-md-5 px-1 mb-2">
                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="Nombre"
                            name="nombre"
                            placeholder="Nombre Producto"
                            value={nombre}
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="SKU"
                            name="sku"
                            placeholder="SKU"
                            value={sku}
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                </div>
                <div className="row justify-content-center px-md-5 px-1 mb-2">
                    <div className="col-12">
                        <span>Descripción Corta</span>
                        <CKEditor
                            editor={ClassicEditor}
                            data=""
                            config={{
                                toolbar: toolbar
                            }}
                            onReady={editor => {
                                this.setState({ editor_descripcion_corta: editor })
                            }}
                            onChange={(event, editor) => {
                                const { inputs } = this.state
                                const data = editor.getData();
                                this.setState({ inputs: { ...inputs, descripcion_corta: data } }, () => {
                                    const { errors } = this.state
                                    Object.keys(errors).forEach((key) => {
                                        if (key === 'descripcion_corta') {
                                            delete errors[key]
                                        }
                                    })

                                    this.setState({ errors })
                                })
                            }}
                        />
                        {errors.descripcion_corta && errors.descripcion_corta.length > 0 && errors.descripcion_corta.map((item) => (
                            <div className="invalid" key={v4()}>
                                {item}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="row justify-content-center px-md-5 px-1 mb-2">
                    <div className="col-12">
                        <span>Descripción Larga</span>
                        <CKEditor
                            editor={ClassicEditor}
                            data=""
                            config={{
                                toolbar: toolbar
                            }}
                            onReady={editor => {
                                this.setState({ editor_descripcion_larga: editor })
                            }}
                            onChange={(event, editor) => {
                                const { inputs } = this.state
                                const data = editor.getData();
                                this.setState({ inputs: { ...inputs, descripcion_larga: data } }, () => {
                                    const { errors } = this.state
                                    Object.keys(errors).forEach((key) => {
                                        if (key === 'descripcion_larga') {
                                            delete errors[key]
                                        }
                                    })

                                    this.setState({ errors })
                                })
                            }}
                        />
                        {errors.descripcion_larga && errors.descripcion_larga.length > 0 && errors.descripcion_larga.map((item) => (
                            <div className="invalid" key={v4()}>
                                {item}
                            </div>
                        ))}
                    </div>
                </div>

                <Title text="Subir Imágenes" size="sm" />

                <div className="row justify-content-center px-md-5 px-1 mb-2">
                    <div className="col-12">
                        <Uploader
                            selectFirst
                            onFirstChange={(i) => this.setState({ firstImage: i })}
                            data={images}
                            onChange={this.handleChangeimage}
                            handleRemove={this.handleRemoveImage}
                            name="images"
                            errors={errors}
                        />
                    </div>
                </div>
                <div className="px-md-2 px-1 mb-2 mt-5 mr-4">
                    <this.ButtonSave />
                </div>

            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    product: state.product,
    auth: state.auth,
    drawer: state.drawer,
    products: state.products
})
const mapDispatchToProps = (dispatch) => ({
    editProduct: (data, id, token) => dispatch(doEditProduct(data, id, token)),
    getProduct: (slug) => dispatch(doGetProduct(slug)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
})
export default connect(mapStateToProps, mapDispatchToProps)(EditProduct)