import { Component } from "react";
import { connect } from "react-redux";
import { CLEAR_RENDER, OPEN_DRAWER, SET_RENDER } from "../../../redux/actions/drawer";
import { SET_CATEGORY } from "../../../redux/actions/category";
import EditCategory from '../Categories/EditCategory'
import '../../../styles/component.admin.actions.scss'
import AddFormat from "../Categories/AddFormat";
import { Notification } from "rsuite";
class CategoriesAction extends Component {

    hasPermission = () => {
        return JSON.parse(sessionStorage.getItem('modules'))['categorias'].edit;
    }
    
    handleEdit = (type) => {
        if (!this.hasPermission()) {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
            return;
        }
        const { OPEN_DRAWER, SET_RENDER, CLEAR_RENDER, SET_CATEGORY, item } = this.props
        SET_CATEGORY(item)
        CLEAR_RENDER()
        if (type === "EDIT") {
            SET_RENDER(EditCategory)
        } else {
            SET_RENDER(AddFormat)
        }
        OPEN_DRAWER()
    }
    render() {
        return (
            <div className="container-categories-actions">
                <i dataToggle="tooltip" title="Editar" className="fa fa-pencil" onClick={() => this.handleEdit("EDIT")}></i>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    CLEAR_RENDER: () => dispatch(CLEAR_RENDER()),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
    SET_CATEGORY: (payload) => dispatch(SET_CATEGORY(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesAction)