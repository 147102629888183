// Handle Auth
export const InfoSession = () => {
  return {
    authorization: sessionStorage.getItem('authorization'),
    fullName: sessionStorage.getItem('full_name'),
    isLogged: ('authorization' in sessionStorage),
    user: sessionStorage.getItem('user'),
  };
}

export const OpenSession = (data) => {
  return {
    authorization: sessionStorage.setItem('authorization', data.token),
    fullName: sessionStorage.setItem('full_name', `${data.usuario.nombres} ${data.usuario.apellidos}`),
    user: sessionStorage.setItem('user', data.usuario.id)
  };
}

export const UpdateSession = (data) => {
  return {
    fullName: sessionStorage.setItem('full_name', `${data.usuario.nombres} ${data.usuario.apellidos}`),
  };
}

export const CloseSession = () => {
  sessionStorage.removeItem("global_state");
}

// FormChekout
export const SaveFormCheckout = (data) => {
  sessionStorage.setItem('rioclaro_checkout', JSON.stringify(data))
}
export const GetFormCheckout = () => {
  return JSON.parse(sessionStorage.getItem("rioclaro_checkout") || 'null');
}

// Cart
export const SaveCart = (cart) => {
  sessionStorage.setItem('cart', JSON.stringify(cart))
}
export const GetCart = () => {
  return JSON.parse(sessionStorage.getItem("cart") || 'null');
}

// Categories
export const SaveCategories = (categories) => {
  sessionStorage.setItem("categories", JSON.stringify(categories));
}
export const GetCategories = () => {
  return JSON.parse(sessionStorage.getItem("categories") || 'null');
}

// Offices
export const SaveOffices = (offices) => {
  sessionStorage.setItem("offices", JSON.stringify(offices));
}
export const GetOffices = () => {
  return JSON.parse(sessionStorage.getItem("offices") || 'null');
}

// Regions
export const SaveRegions = (regions) => {
  sessionStorage.setItem("regions", JSON.stringify(regions));
}
export const GetRegions = () => {
  return JSON.parse(sessionStorage.getItem("regions") || 'null');
}

// Comunas
export const SaveComunas = (comunas) => {
  sessionStorage.setItem("comunas", JSON.stringify(comunas));
}
export const GetComunas = () => {
  return JSON.parse(sessionStorage.getItem("comunas") || 'null');
}
// Global State
export const updateGlobalState = (param, payload) => {
  let store = JSON.parse(sessionStorage.getItem("global_state"));
  sessionStorage.setItem("global_state", JSON.stringify({ ...store, [param]: payload }))
}
