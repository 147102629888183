import React, { Component } from 'react';
import PageWrapper from '../../components/Common/Page_Wrapper';
import LoaderRsuite from '../../components/Loaders/Loader_Rsuite';
import { Marker, GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import * as Api from '../../services/api/endpoints';
import AssetsImg from '../../assets/image';
import { connect } from 'react-redux';
const linkApi = process.env.REACT_APP_ENV_API_URL

const Store = (props) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    })

    const { title, mainAddress, phone, latitud, longitud } = props;
    return (
        <div className="container my-4 pos-container">
            <div className="d-block d-sm-none">
                <p className="text-center mb-2 font-weight-bold">
                    <img src={AssetsImg.Localization} alt="..." />
                    {` ${title}`}
                </p>
            </div>
            {
                isLoaded ? (
                    <GoogleMap
                        zoom={15}
                        center={{ lat: parseFloat(latitud), lng: parseFloat(longitud) }}
                        mapContainerStyle={{ height: '250px' }}
                        onClick={() => window.open("https://maps.google.com?q=" + latitud + "," + longitud)}
                    >
                        <Marker
                            position={{ lat: parseFloat(latitud), lng: parseFloat(longitud) }}
                        />
                    </GoogleMap>
                ) : null
            }
            <div className="d-none d-sm-block bg-orange py-2 px-4" style={{ height: '100px' }}>
                <p className="mb-2 font-weight-bold text-white">{title}</p>
                <p className="mb-0 address mt-1 text-white">{mainAddress}</p>
            </div>
            <div className="d-block d-sm-none">
                <p className="mb-0 address mt-1">
                    <strong>{mainAddress}</strong>
                </p>
                <div className="row mt-3">
                    <div className="col-1">
                        <img src={AssetsImg.Email} alt="..." />
                    </div>
                    <div className="col-10 ml-2">
                        <p className="font-weight-bold mb-0">Teléfono</p>
                        <p className="font-weight-light email mb-0">{phone}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

class Contact extends Component {

    state = {
        loading: true,
        stores: [],
        banner: {}
    }

    async componentDidMount() {
        let { data } = await Api.ListStores();
        const { banners } = this.props
        const currentBanner = banners.data.find((b) => b.pagina === window.location.pathname);
        this.setState({ loading: false, stores: data, banner: currentBanner });
    }

    render() {
        const { loading, stores } = this.state;
        return (
            <PageWrapper title={this.state.banner?.texto} image={this.state.banner?.imagen_path === undefined ? null : `${linkApi}/imagenes/banners/${this.state.banner?.imagen_path[0]}`}>
                <LoaderRsuite
                    loading={{
                        run: loading,
                        title: "Cargando...",
                    }}
                    fail={{
                        run: stores.length === 0,
                        title: "No hay puntos de venta",
                    }}
                >
                    <div className="row">
                        {
                            stores.map((item, i) => (
                                <div className="col-12 col-sm-4" key={`store-location-${i}`}>
                                    <Store
                                        title={item.nombre}
                                        mainAddress={item.direccion}
                                        phone={item.telefono}
                                        latitud={item.latitud}
                                        longitud={item.longitud}
                                    />
                                </div>
                            ))
                        }
                    </div>
                </LoaderRsuite>
            </PageWrapper>
        )
    }
}

const mapStateToProps = (state) => ({
    banners: state.banners
})
const mapDispatchToProps = () => ({})
export default connect(mapStateToProps, mapDispatchToProps)(Contact)