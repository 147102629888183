import { handleActions } from 'redux-actions'
import { SET_CONTRACT } from '../actions/contract'

export const INITIAL_STATE = {
    id: '',
    name: '',
    contract: ''
}

export default handleActions({
    [SET_CONTRACT]: (state, action) => {
        return { ...state, ...action.payload }
    }
}, INITIAL_STATE)