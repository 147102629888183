// png
import logo from './png/logo.png';
import empty_cart from './png/no_product.png';
import webpay_logo from './png/webpay_logo.png';
import discount_coupon from './png/discount_coupon.png';
import mail_reset from './png/mail_reset.png';
import user_f from './png/user.png';
import truck_f from './png/truck.png';
import location_f from './png/location.png';
import check_f from './png/check.png';
import card_f from './png/credit-card.png';
import delivery from './png/delivery-truck.png';
import start_delivery from './png/start.png';
import end_delivery from './png/end.png';
import not_found from './png/not_found.png';

//svg
import toggler from './svg/icon_menu.svg';
import arrow_left from './svg/icon_arrow_left.svg';
import arrow_right from './svg/icon_arrow_right.svg';
import logo_w from './svg/logow.svg';
import facebook from './svg/icon_facebook.svg';
import instagram from './svg/icon_instagram.svg';
import youtube from './svg/icon_youtube.svg';
import checked from './svg/icon_checked.svg';
import recycle from './svg/icon_recycle.svg';
import plant from './svg/icon_plant.svg';
import cpu from './svg/icon_cpu.svg';
import home from './svg/icon_home.svg';
import product from './svg/icon_product.svg';
import cart from './svg/icon_cart.svg';
import user from './svg/icon_user.svg';
import localization from './svg/icon_localization.svg';
import email from './svg/icon_email.svg';
import truck from './svg/icon_truck.svg';
import store from './svg/icon_store.svg';
import profile from './svg/icon_profile.svg';
import success_register from './svg/icon_success_register.svg';
import success_sale from './svg/icon_success_sale.svg';
import envelope from './svg/icon_envelope.svg';
import remove from './svg/icon_remove.svg';
import edit from './svg/icon_edit.svg';
import facebook_w from './svg/icon_facebook_w.svg';
import instagram_w from './svg/icon_instagram_w.svg';
import youtube_w from './svg/icon_youtube_w.svg';
import phone from './svg/icon_phone.svg';
import menu from './svg/menu.svg';

const AssetsImg = {
	Logo: logo,
	Logo_W: logo_w,
	Toggler: toggler,
	Arrow_Left: arrow_left,
	Arrow_Right: arrow_right,
	Facebook: facebook,
	Instagram: instagram,
	Webpay: webpay_logo,
	Checked: checked,
	Recycle: recycle,
	Plant: plant,
	Cpu: cpu,
	Home: home,
	Product: product,
	Cart: cart,
	User: user,
	Empty_Cart: empty_cart,
	Localization: localization,
	Email: email,
	Truck: truck,
	Store: store,
	Profile: profile,
	Success_Register: success_register,
	Success_Sale: success_sale,
	Envelope: envelope,
	Remove: remove,
	Instagram_w: instagram_w,
	Facebook_w: facebook_w,
	DiscountCoupon: discount_coupon,
	MailReset: mail_reset,
	Edit: edit,
	Youtube_w: youtube_w,
	Youtube: youtube,
	Phone: phone,
	User_f: user_f,
	Truck_f: truck_f,
	Location_f: location_f,
	Check_f: check_f,
	Card_f: card_f,
	Menu: menu,
	Delivery: delivery,
	StartDelivery: start_delivery,
	EndDelivery: end_delivery,
	NotFound: not_found
}

export default AssetsImg;