import { createAction } from 'redux-actions'
import { EditText, Texts } from '../../services/api/endpoints';
export const fetch_start_texts = createAction('fetch_start_texts');
export const fetch_success_texts = createAction('fetch_success_texts');
export const fetch_error_texts = createAction('fetch_error_texts');

export const getTextList = (query = {}) => async (dispatch) => {
    dispatch(fetch_start_texts());
    try {
        const response = await Texts(query);
        console.log(response, 'RESPUESTA DE LISTADO DE TEXTOS');
        const { data } = response;
        dispatch(fetch_success_texts({ data }))
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_texts());
    }
};

export const doEditText = (params, id, token) => async (dispatch) => {
    dispatch(fetch_start_texts());
    try {
        await EditText(params, id, token);
        dispatch(getTextList({}));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_texts());
    }
}
