import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateItems, cart_update_shipping } from '../../redux/actions/cart';
import SelectSimple from '../Selects/Select_Simple';
import { setSelectData, compareItems } from '../../utils/helpers';
import { get_currency } from '../../utils/currency';
import { Alert, Notification } from 'rsuite';

const options = {format: null, delivery_type: null, region_id: null, commune_id: null, sale_point_id: null, price: null};

class CartEdit extends Component {

    componentDidMount(){
        const {cart: {shipping}, item} = this.props;
        this.setState({
            inputs: {
                format: item.id_formato,
                delivery_type: item.shipping,
                region_id: shipping.comuna ? shipping.comuna.id_region : null,
                commune_id: shipping.comuna ? shipping.comuna.id_comuna : null,
                sale_point_id: shipping.sucursal ? shipping.sucursal.id_punto_venta : null,
                price: item.precio
            },
            selectedFormat: {
                sucursales_disponible: item.sucursales_disponible,
                comunas_disponible: item.comunas_disponible
            }
        });
    }

    state = {
        inputs: options,
        selectedFormat: null
    }

    _handleInput = (e) => {
        let { inputs } = this.state;
        this.setState({ inputs: {...inputs, [e.target.name] : parseInt(e.target.value)} })
    }

    _handlePrice = (price) => {
        let { inputs } = this.state;
        this.setState({ inputs: {...inputs, price: price } })
    }

    _handleSelectFormat = (e) => {
        const { item } = this.props;
        const formato = item.formatos.find(x => x.id.toString() === e.target.value);
        if (formato) {
            this.setState({ selectedFormat: formato, finded:{ sucursal: null, comuna: null }, inputs: {...options, format: parseInt(e.target.value)} })
        } else {
            this._resetSelects();
        }
    }

    _handleDeliveryType = (e) => {
        const { inputs } = this.state;
        this.setState({ inputs: {...options, format: inputs.format, delivery_type : parseInt(e.target.value)}, finded: { sucursal: false, comuna: false } });
    }

    _resetSelects = () => {
        this.setState({ inputs: {...options} });
    }

    _handleRegion = (e) => {
        let { inputs } = this.state;
        if (e.target.value) {
            this.setState({ inputs: { ...inputs, [e.target.name] : parseInt(e.target.value), price: null }});
        }
    }

    _handleLastStep = (e, type) => {
        const { selectedFormat, inputs } = this.state;
        const { cart: { items }, i } = this.props;
        let cartItems = [...items];
        if (items.length > 1) {
            cartItems.splice(i, 1);
            let message = inputs.delivery_type === 1 ? 'retirar en la sucursal seleccionada' : 'despachar a la comuna seleccionada';
            let toCheck = inputs.delivery_type === 1 ?  cartItems.map(x => (x.sucursales_disponible.map(y => y.id_punto_venta))) : cartItems.map(x => (x.comunas_disponible.map(y => y.id_comuna)));
            let search = toCheck.map(z => z.includes(parseInt(e.target.value))).some(x => !x);
            if (search) {
                Alert.error(`Uno o más productos no se pueden ${message}`, 5000);
                this.setState({inputs: {...inputs, [e.target.name]: parseInt(e.target.value), price: null}});
                return false;
            }
        }

        let data  = selectedFormat[type === 'comuna' ? 'comunas_disponible' : 'sucursales_disponible'];
        let selected = data.filter(x => (x[`id_${type}`].toString() === e.target.value))[0];
        let price = selected[type === 'comuna' ? 'despacho' : 'retiro_tienda'] ? selected[type === 'comuna' ? 'valor_despacho' : 'valor_retiro_tienda'] : 'Producto sin stock';
        this.setState({inputs: {...inputs, [e.target.name]: parseInt(e.target.value), price}});
    }

    _addProductToCart = () => {
        const { inputs } = this.state;
        const { cart: { items }, cancel, i } = this.props;
        let formato = items[i].formatos.find(y => y.id === inputs.format);
        const sucursal = inputs.sale_point_id ? formato.sucursales_disponible.find(y => y.id_punto_venta === inputs.sale_point_id) : null;
        const id_lista_precio = sucursal ? sucursal.id_lista_precio : formato.comunas_disponible.find(z => z.id_comuna === inputs.commune_id).id_lista_precio;
        if (compareItems(inputs, items[i])) {
            // actualizo
            items[i].precio = parseInt(inputs.price);
            items[i].id_lista_precio = id_lista_precio;
        } else {
            // encuentro otro
            let findedIndex = items.findIndex(x => (compareItems(inputs, x)));
            if (findedIndex > -1) {
                items[findedIndex].cantidad += items[i].cantidad;
                items.splice(i, 1);
            } else {
                // agrego nuevo
                items[i].id_formato = inputs.format;
                items[i].nombre_formato = formato.nombre;
                items[i].shipping = inputs.delivery_type;
                items[i].precio = parseInt(inputs.price);
                items[i].sucursales_disponible = formato.sucursales_disponible;
                items[i].comunas_disponible = formato.comunas_disponible;
                items[i].id_lista_precio = id_lista_precio;
            }

            Notification['success']({
                title: 'El producto ha sido actualizado',
                placement: 'bottomEnd',
            });
        }

        cancel(i);
        this._updateActualShipping(inputs.commune_id, inputs.sale_point_id, formato, items);
    }

    _updateActualShipping = (comuna_id, punto_venta_id, formato, items) => {
        const { cart: { shipping, discount_code }, updateShipping, updateItems } = this.props;
        let n_sucursal = items.some(x => x.shipping === 1) ? shipping.sucursal : null;
        let n_comuna = items.some(x => x.shipping === 2) ? shipping.comuna : null;
        if (comuna_id) {
            n_comuna = formato.comunas_disponible.find(x => x.id_comuna === comuna_id);
        }
        if (punto_venta_id) {
            n_sucursal = formato.sucursales_disponible.find(y => y.id_punto_venta === punto_venta_id);
        }

        let n_items = items.map(z => {
            if (z.shipping === 1) {
                let sucursal = z.sucursales_disponible.find( w => w.id_punto_venta === n_sucursal.id_punto_venta);
                z.precio = sucursal.valor_retiro_tienda;
            } else {
                let comuna = z.comunas_disponible.find( w => w.id_comuna === n_comuna.id_comuna);
                z.precio = comuna.valor_despacho;
            }

            return z;
        });

        updateItems([...n_items], discount_code);
        updateShipping({ shipping: { comuna: n_comuna, sucursal: n_sucursal } });
    }

    render(){
        const { inputs, selectedFormat } = this.state;
        const { item, cancel, i } = this.props;
        return (
            <div className="col-12 mb-2">
                <div className="row px-2 d-sm-block d-none">
                    <div className="col-12 px-0">
                        <h5 className="font-weight-bold mb-0">{`${item.nombre_producto}`}</h5>
                    </div>
                </div>
                <div className="w-100 d-flex justify-content-end">
                    <button type="button" className="btn p-0 border-bottom" onClick={() => cancel(i)} style={{ fontSize: '.8em' }}>
                        Cancelar
                    </button>
                </div>
                <div className="row px-2 mb-1">
                    <div className="col-12 px-0">
                        <p className="mb-0 font-weight-bold mb-1" style={{ fontSize: '.8em' }}>Selecciona el formato</p>
                        <SelectSimple
                            name="format"
                            className="form-select"
                            placeholder="Selecciona el formato"
                            data={item.formatos.map(x => ({value: x.id, label: x.nombre}))}
                            runChange={this._handleSelectFormat}
                            type="simple"
                            initValue={inputs.format}
                        />
                    </div>
                </div>
                {
                    inputs.format && (
                        <div className="row px-2 mb-1">
                            <div className="col-12 px-0">
                                <p className="mb-0 font-weight-bold" style={{ fontSize: '.8em' }}>Tipo de entrega</p>
                                <SelectSimple
                                    type="simple"
                                    name="delivery_type"
                                    className="form-select mb-2"
                                    placeholder="Seleccione entrega"
                                    data={[{value: 1, label: 'Retiro en tienda'}, {value: 2, label: 'Despacho'}]}
                                    runChange={this._handleDeliveryType}
                                    initValue={inputs.delivery_type}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    inputs.delivery_type === 1 && (
                        <div className="row px-2 mb-1">
                            <div className="col-12 px-0">
                                <p className="mb-0 font-weight-bold" style={{ fontSize: '.8em' }}>Seleccione sucursal</p>
                                <SelectSimple
                                    type="simple"
                                    name="sale_point_id"
                                    className="form-select mb-2"
                                    placeholder="Seleccione sucursal"
                                    data={setSelectData(selectedFormat.sucursales_disponible, 'id_punto_venta', 'nombre_punto_venta')}
                                    runChange={e => this._handleLastStep(e, 'punto_venta')}
                                    initValue={inputs.sale_point_id}
                                    alphabetically
                                />
                            </div>
                        </div>
                    )
                }
                {
                    inputs.delivery_type === 2 && (
                        <div className="row px-2 mb-1">
                            <div className="col-12 px-0">
                                <p className="mb-0 font-weight-bold" style={{ fontSize: '.8em' }}>Seleccione región</p>
                                <SelectSimple
                                    type="simple"
                                    name="region_id"
                                    className="form-select mb-2"
                                    placeholder="Seleccione región"
                                    data={setSelectData(selectedFormat.comunas_disponible, 'id_region', 'region_nombre')}
                                    runChange={this._handleRegion}
                                    initValue={inputs.region_id}
                                />
                            </div>
                        </div>
                    )
                }
                {
                    inputs.region_id && inputs.delivery_type === 2 && (
                        <div className="row px-2 mb-1">
                            <div className="col-12 px-0">
                                <p className="mb-0 font-weight-bold" style={{ fontSize: '.8em' }}>Seleccione comuna</p>
                                <SelectSimple
                                    type="simple"
                                    name="commune_id"
                                    className="form-select mb-2"
                                    placeholder="Comuna"
                                    data={setSelectData(selectedFormat.comunas_disponible.filter(x => x.id_region === inputs.region_id), 'id_comuna', 'comuna_nombre')}
                                    initValue={inputs.commune_id}
                                    runChange={e => this._handleLastStep(e, 'comuna')}
                                    alphabetically
                                />
                            </div>
                        </div>
                    )
                }
                {
                    inputs.price && (
                        <>
                        <div className="row mb-1">
                            <div className="col-4">
                                <p className="font-weight-bold text-dark mb-0">Precio:</p>
                            </div>
                            <div className="col-8">
                                <p className="font-weight-bold text-orange text-right mb-0">{get_currency(inputs.price)}</p>
                            </div>
                        </div>
                        <button onClick={() => this._addProductToCart()} className="btn btn-success w-100" style={{borderRadius: 25, fontSize: '.8em'}} disabled={!inputs.price}>
                            Actualizar producto
                        </button>
                        </>
                    )
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart
});

const mapDispatchToProps = dispatch => ({
    updateItems: (data, discount_code) => dispatch( updateItems(data, discount_code) ),
    updateShipping: (data) => dispatch( cart_update_shipping(data) )
});

export default connect(mapStateToProps, mapDispatchToProps)(CartEdit);