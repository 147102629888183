import { handleActions } from 'redux-actions';
import { OPEN_DRAWER, CLOSE_DRAWER, SET_RENDER, CLEAR_RENDER } from '../actions/drawer';

export const INITIAL_STATE = {
    OPEN: false,
    RENDER: null,
    LAST_RENDERED: null
};

export default handleActions({
    [OPEN_DRAWER]: (state) => {
        return { ...state, OPEN: true };
    },
    [CLOSE_DRAWER]: (state, action) => {
        return { ...state, OPEN: false, LAST_RENDERED: action.payload };
    },
    [SET_RENDER]: (state, action) => {
        return { ...state, RENDER: action.payload }
    },
    [CLEAR_RENDER]: (state) => {
        return { ...state, RENDER: null }
    }
}, INITIAL_STATE);