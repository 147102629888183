import { handleActions } from "redux-actions";
import { fetch_error_file, fetch_start_file, fetch_success_file } from "../actions/file";

export const INITIAL_STATE = {
    data: [],
    loading: false,
    success: false,
    error: null
};

export default handleActions({
    [fetch_start_file]: (state) => {
        return {...state, loading: true};
    },
    [fetch_success_file]: (state, action) => {
        const {data} = action.payload;
        return {...state, loading: false, success: true, data};
    },
    [fetch_error_file]: (state) => {
        return {...state, loading: false, error: true};
    }
}, INITIAL_STATE);