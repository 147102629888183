import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Loader, IconButton, Icon } from 'rsuite';
import { cart_apply_discount_code } from '../../redux/actions/cart';
import ModalRsuite from '../Modals/Modal_Rsuite';
import AssetsImg from '../../assets/image';
import * as Api from '../../services/api/endpoints';

const defaultInputs = {
    code: '',
    danger: '',
    success: '',
    loading: false
}

class DiscountForm extends Component {
    constructor(props) {
        super(props);
        this.form = React.createRef();
        this.state = {
            inputs: defaultInputs,
            modal: false
        }
    }

    _handleInput = (e) => {
        const { inputs } = this.state;
        Api.FormValidator.validate(this.form.current, []);
        this.setState({ inputs: { ...inputs, danger: '', [e.target.name]: e.target.value.toUpperCase() } });
    }

    _removeCode = async () => {
        const { cart_apply_discount_code } = this.props;
        await cart_apply_discount_code({ total_discount_coupon: 0, discount_code: null });
    }

    _handleSubmit = async () => {
        const { inputs } = this.state;
        const { cart_apply_discount_code, allow } = this.props;
        let show_modal = false;

        this.setState({ inputs: { ...inputs, danger: '' } })

        if (allow) {
            this.setState({ inputs: { ...inputs, danger: '', success: '', loading: true } });
            if (inputs.code.trim() !== '') {
                await Api.DiscountCodeSearch(inputs.code.trim())
                    .then(response => {
                        if (response.data.es_usable) {
                            show_modal = true;
                            cart_apply_discount_code({ total_discount_coupon: parseInt(response.data.valor), discount_code: inputs.code });
                        } else {
                            inputs.danger = 'Cupón no válido.';
                        }
                    })
                    .catch(e => {
                        inputs.danger = e;
                    })
            } else {
                inputs.danger = 'Ingresa el código';
            }

            inputs.loading = false;
            this.setState({ inputs: { ...inputs }, modal: show_modal });
        }
    }

    render() {
        const { inputs, modal } = this.state;
        const { cart } = this.props;
        return (
            <>
                <div className="pb-1">
                    <div ref={this.form} className="">
                        <div className="col-12 px-0">
                            {inputs.loading ? <Loader backdrop content="loading..." vertical style={{ zIndex: 4 }} /> : null}
                            <hr />
                            <p className="m-0 text-red small text-center">{inputs.danger}</p>
                            <p className="m-0 text-success small text-center">{inputs.success}</p>
                            {!cart.total_discount_coupon ?
                                <div>
                                    <p className="mb-1 ml-1 mr-4 font-small">Si tienes un cupón de descuento, ingresa el código aquí:</p>
                                    <div className="btn-group w-100" role="group" aria-label="Basic outlined example">
                                        <input value={inputs.code} name="code" type="text" className="form-control" onChange={this._handleInput} />
                                        <button type="button" className="btn btn-success py-0 font-weight-bold" onClick={this._handleSubmit}>Agregar</button>
                                    </div>

                                </div>
                                :
                                <div className="d-flex justify-content-between">
                                    <p className="d-inline mb-0 ml-1 mr-4 font-weight-bold font-small">Cupón en uso:</p>
                                    <p className="my-0">
                                        {cart.total_discount_coupon ?
                                            <span className="badge rounded-pill bg-red d-block">
                                                {cart.discount_code}{' '}
                                                <IconButton disabled={this.props.loading} icon={<Icon icon="close" />} circle size="xs" onClick={this._removeCode} />
                                            </span>
                                            : null
                                        }
                                    </p>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <ModalRsuite
                    open={modal}
                    handleModal={() => this.setState({ modal: !modal })}
                    propsModal={{ size: 'xs', overflow: false }}
                >
                    <div className="text-center">
                        <img src={AssetsImg.DiscountCoupon} height={'250'} className="img-fluid" alt="..." />
                        <p className="font-weight-light text-grey">Cupón agregado!</p>
                        <button onClick={() => this.setState({ modal: !modal })} className="btn bg-orange text-white font-weight-bold">Aceptar</button>
                    </div>
                </ModalRsuite>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart
});

const mapDispatchToProps = (dispatch) => ({
    cart_apply_discount_code: (data) => dispatch(cart_apply_discount_code(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DiscountForm);