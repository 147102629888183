import { handleActions } from 'redux-actions';
import { fetch_start_profile, fetch_error_profile, fetch_success_profile } from '../actions/profile';

export const INITIAL_STATE = {
    data: null,
    loading: false,
    error: null,
    success: false
}

export default handleActions({
    [fetch_start_profile]: (state) => {
        return {...state, loading: true}
    },
    [fetch_error_profile]: (state) => {
        return {...state, loading: false, error: true}
    },
    [fetch_success_profile]: (state, action) => {
        return {...state, success: true, loading: false, error: false, data: action.payload}
    },
}, INITIAL_STATE);
