import { Component } from "react";
import { connect } from "react-redux";
import { toBase64 } from "../../../utils/helpers";
import Input from "../Input";
import Select from "../Select";
import Title from "../Title";
import Uploader from "../Uploader";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import toolbar from '../../../utils/toolbars';
import { v4 } from 'uuid';
import { productSchema } from '../../../utils/validations';
import validate from '../../../utils/validate';
import '../../../styles/component.admin.products.scss';
import { doCreateProduct } from "../../../redux/actions/product";
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { UploadFile } from "../../../services/api/endpoints";
import { GetCategories } from "../../../services/session";
import { Toggle } from "rsuite";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";

const initialState = {
    nombre: '',
    imagen: '',
    descripcion_corta: '',
    descripcion_larga: '',
    sku: '',
    id_categoria: -1,
    cantidad_disponible: 1,
    images: [],
    categories: [],
    activo: false
}
class AddProduct extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputs: {},
            errors: {},
            categories: [],
            selectedImage: null,
            saving: false
        }
    }

    componentDidMount() {
        const categories = GetCategories().map((category) => {
            return { id: category.id, value: category.nombre };
        })
        this.setState({
            inputs: { ...initialState },
            categories: categories
        })
    }

    handleChange = ({ target }) => {
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [target.name]: target.value } }, () => {
            const { errors } = this.state
            Object.keys(errors).forEach((key) => {
                if (key === target.name) {
                    delete errors[key]
                }
            })

            this.setState({ errors })
        })
    }

    handleChangeCheckbox = ({ target }) => {
        const { name, checked } = target
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [name]: checked } })
    }

    handleChangeimage = (e) => {
        const { target } = e
        const { files } = target;
        const { inputs } = this.state
        this.setState({ selectedImage: e.target.files[0] });
        Array.from(files).forEach((item) => {
            toBase64(item)
                .then((response) => {
                    if (e.target.dataset.reference) {
                        inputs.images.map((item) => {
                            if (item.id === e.target.dataset.reference) {
                                item.src = response
                                return item
                            }

                            return item
                        })
                        this.setState({ inputs }, () => {
                            const { errors } = this.state
                            Object.keys(errors).forEach((key) => {
                                if (key === target.name) {
                                    delete errors[key]
                                }
                            })

                            this.setState({ errors })
                        })
                    } else {
                        inputs.images.push({ src: response, id: v4(), new: true })
                        this.setState({ inputs }, () => {
                            const { errors } = this.state
                            Object.keys(errors).forEach((key) => {
                                if (key === target.name) {
                                    delete errors[key]
                                }
                            })

                            this.setState({ errors })
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        })


    }

    handleRemoveImage = (id) => {
        const { inputs } = this.state
        const images = []
        inputs.images.forEach((item) => {
            if (item.id !== id) {
                images.push(item)
            }
        })

        this.setState({ inputs: { ...inputs, images } })
    }

    handleSubmit = async () => {
        const { inputs } = this.state
        const validations = await validate(productSchema, { ...inputs, state: true }, () => this._createProducts(inputs))
        if (validations) {
            this.setState({ errors: validations })
        }
    }

    _createProducts = async (inputs) => {
        this.setState({ saving: true })
        const { createProduct, auth, drawer, CLOSE_DRAWER, START_LOADING, END_LOADING } = this.props
        START_LOADING();
        const imagenes = [];
        for (const m of inputs.images) {
            const response = await UploadFile({ file: m.src }, auth.session.token);
            if (response) {
                imagenes.push(response.archivo);
            }
        }
        delete inputs.categories;
        delete inputs.imagen;
        delete inputs.images;
        const data = {
            ...inputs,
            productos_relacionados: ""
        }
        data["imagenes"] = imagenes;
        END_LOADING();
        CLOSE_DRAWER(drawer.RENDER);
        this.setState({ saving: false })
        await createProduct(data, auth.session.token)
    }

    _updateQuantity = (value) => {
        const { inputs } = this.state
		if (inputs.cantidad_disponible + value !== 0) {
			inputs.cantidad_disponible += value;
            this.setState({ inputs: { ...inputs}})
		}
	}

    render() {
        const { inputs, errors } = this.state
        const { nombre, sku, id_categoria, images, activo, cantidad_disponible } = inputs
        return (
            <div className="container-component-products">
                <Title text="Agregar Produto" />
                <div className="row justify-content-center px-md-5 px-1 mb-2">
                    <div className="col-12 col-md-12 mb-2">
                        <Toggle checked={activo} onChange={(checked) => this.setState({ inputs: { ...inputs, activo: checked } })} size="lg" checkedChildren="Activo" unCheckedChildren="Inactivo" />
                    </div>                    
                    <div className="col-12 col-md-6 mb-2">
                        <Select
                            label="Categoria"
                            value={id_categoria}
                            name="id_categoria"
                            onChange={this.handleChange}
                            data={this.state.categories}
                            errors={errors}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <label>Cantidad disponible</label>
                        <div>
                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                <button type="button" className="btn btn-outline-secondary px-2 py-1" onClick={() => this._updateQuantity(-1)} disabled={cantidad_disponible === 1 ? true : false}>-</button>
                                <button type="button" className="btn btn-outline-secondary p-1"><small>{cantidad_disponible}</small></button>
                                <button type="button" className="btn btn-outline-secondary px-2 py-1" onClick={() => this._updateQuantity(1)}>+</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row justify-content-center px-md-5 px-1 mb-2">
                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="Nombre"
                            name="nombre"
                            placeholder="Nombre Producto"
                            value={nombre}
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="SKU"
                            name="sku"
                            placeholder="SKU"
                            value={sku}
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                </div>
                <div className="row justify-content-center px-md-5 px-1 mb-2">
                    <div className="col-12">
                        <span>Descripción Corta</span>
                        <CKEditor
                            editor={ClassicEditor}
                            data=""
                            config={{
                                toolbar: toolbar
                            }}
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const { inputs } = this.state
                                const data = editor.getData();
                                this.setState({ inputs: { ...inputs, descripcion_corta: data } }, () => {
                                    const { errors } = this.state
                                    Object.keys(errors).forEach((key) => {
                                        if (key === 'descripcion_corta') {
                                            delete errors[key]
                                        }
                                    })

                                    this.setState({ errors })
                                })
                            }}
                        />
                        {errors.descripcion_corta && errors.descripcion_corta.length > 0 && errors.descripcion_corta.map((item) => (
                            <div className="invalid" key={v4()}>
                                {item}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="row justify-content-center px-md-5 px-1 mb-2">
                    <div className="col-12">
                        <span>Descripción Larga</span>
                        <CKEditor
                            editor={ClassicEditor}
                            data=""
                            config={{
                                toolbar: toolbar
                            }}
                            onReady={editor => {
                                console.log('Editor is ready to use!', editor);
                            }}
                            onChange={(event, editor) => {
                                const { inputs } = this.state
                                const data = editor.getData();
                                this.setState({ inputs: { ...inputs, descripcion_larga: data } }, () => {
                                    const { errors } = this.state
                                    Object.keys(errors).forEach((key) => {
                                        if (key === 'descripcion_larga') {
                                            delete errors[key]
                                        }
                                    })

                                    this.setState({ errors })
                                })
                            }}
                        />
                        {errors.descripcion_larga && errors.descripcion_larga.length > 0 && errors.descripcion_larga.map((item) => (
                            <div className="invalid" key={v4()}>
                                {item}
                            </div>
                        ))}
                    </div>
                </div>

                <Title text="Subir Imagenes" size="sm" />

                <div className="row justify-content-center px-md-5 px-1 mb-2">
                    <div className="col-12">
                        <Uploader
                            name="images"
                            data={images}
                            onChange={this.handleChangeimage}
                            handleRemove={this.handleRemoveImage}
                            errors={errors}
                        />
                    </div>
                </div>

                <div className="row justify-content-center mb-2">
                    <div className="col px-5 mt-4 d-flex justify-content-end">
                        <button disabled={this.state.saving} className="btn btn-rioclaro" onClick={this.handleSubmit}>Guardar</button>
                    </div>
                </div>


            </div>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth,
    drawer: state.drawer,
    categories: state.categories
})
const mapDispatchToProps = (dispatch) => ({
    createProduct: (data, token) => dispatch(doCreateProduct(data, token)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
})
export default connect(mapStateToProps, mapDispatchToProps)(AddProduct)