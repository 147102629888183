import { Component } from "react";
import { connect } from "react-redux";
import Title from "../../../components/Admin/Title";
import '../../../styles/component.admin.products.scss'
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { doEditProduct } from "../../../redux/actions/product";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";
import { ListStores, GetLocalidades, UpdateLocalidad } from "../../../services/api/endpoints";
import Select from "../../../components/Admin/Select";
import { salepointsGridHeaders } from "../../../utils/constants";
import { v4 } from "uuid";
import { Notification } from "rsuite";
import Dialogs from '../../../components/Admin/Dialogs';
import '../../../styles/page.admin.points.scss'
class SalePoints extends Component {
    constructor(props) {
        super(props)
        this.state = {
            images: [],
            selectedImage: "",
            salepoints: [],
            region: "",
            regions: [],
            selectedRegion: "",
            addComuna: "",
            comuna: [],
            comunas: [],
            isModalOpen: false,
            deleteComuna:[]
        }
    }
    hasPermission = () => {
        return JSON.parse(sessionStorage.getItem('modules'))['cobertura'].edit;
    }
    componentDidMount() {
        const { product } = this.props
        this.init(product.id_categoria, product.id)
    }

    async init(id, id_producto) {
        await this.refreshSalePoints();
    }

    refreshSalePoints = async () => {
        const { START_LOADING, END_LOADING } = this.props;
        START_LOADING();
        const localidades = await GetLocalidades(this.props.auth.session.token) || []
        const regions = localidades.regiones
        const comuna = localidades.comunas?.map((i) => ({ ...i, value: i.comuna_nombre }));
        const salepoints = (await ListStores()).data.map(d => ({ ...d, value: d.nombre }));
        this.setState({ salepoints, comuna, regions })
        END_LOADING()
    }

    handleRegion = (evt) => {
        const comunas = this.state.comuna?.filter((i) => Number(i.id_punto_venta) === Number(evt.target.value))
        this.setState({ region: evt.target.value, comunas })
    }

    addComuna = async () => {
        if (!this.hasPermission()) {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
            return;
        } else if (this.state.addComuna === "") {
            Notification.warning({ description: "Debe seleccionar una comuna.", title: "Sin comuna." });
            return;
        } else {
            const { product } = this.props
            const item = this.state.comuna?.find((i) => Number(i.id) === Number(this.state.addComuna))
            let productos = item.productos?.split(',') || []
            productos = [...productos, product.id].join(',')
            const comunas = [item, ...this.state.comunas]
            this.setState({ comunas })
            await this.updateComuna({ ...item, productos }, this.state.region)
        }
    }

    validateComuna = async (item) => {
        if (!this.hasPermission()) {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
            return;
        }
        if(!item.removable){
            Notification.warning({ description: "No puede borrar la comuna ya que tiene un valor asignado en la lista de despacho.", title: "No puede realizar esta acción." });
            return;
        }
        this.setState({deleteComuna:item,isModalOpen: true})
    }

    removeComuna = async () => {
        const item = this.state.deleteComuna
        const { product } = this.props
        let productos = item.productos?.split(',') || []
        productos = productos.filter((item) => Number(item) !== Number(product.id)).join(',')
        this.setState({deleteComuna:[], isModalOpen: false})
        await this.updateComuna({ ...item, productos }, null)
    }

    updateComuna = async (item, id_punto_venta) => {
        const data = { comuna: { ...item, id_punto_venta: id_punto_venta, id_comuna: item.id } }
        await UpdateLocalidad(this.props.auth.session.token, data)
        if (id_punto_venta == null) {            
            const comunas = this.state.comunas.filter((i) => i.id !== item.id)
            this.setState({ comunas })
        }
        Notification.success({ title: "Éxito", description: "Operación realizada correctamente!!" })
        await this.refreshSalePoints();
    }

    render() {
        return (
            <div className="container-points">
                <Title text="Cobertura" />
                <div className="container-formats__content p-md-2">
                    <Select
                        style={{ width: "auto" }}
                        label="Punto de Venta"
                        value={this.state.region}
                        onChange={(evt) => this.handleRegion(evt)}
                        data={this.state.salepoints}
                    />
                    {(this.state.region !== "" && this.state.region !== "-1") && (
                        <div className="container-table pt-4">
                            <Dialogs handleAcept={() => this.removeComuna()} handleClose={() => this.setState({ isModalOpen: false })} message={'¿Esta seguro de continuar con la eliminación?'} isOpen={this.state.isModalOpen} />
                            <table className="table table-striped table-hover table-bordered" style={{ overflow: 'auto' }}>
                                <thead>
                                    <tr>
                                        {salepointsGridHeaders && this.state.region && salepointsGridHeaders.map((header,) => (
                                            <th id={header.target} className="text-center" scope="col" key={v4()}>
                                                {header.name}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.region ? (
                                        <>
                                            <tr>
                                                <td className="text-center">
                                                    <div className="mb-2">
                                                        <Select
                                                            value={this.state.selectedRegion}
                                                            onChange={(evt) => this.setState({ selectedRegion: evt.target.value })}
                                                            data={this.state.regions?.map(d => ({ ...d, value: d.region_nombre }))}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="mb-2">
                                                        <Select
                                                            value={this.state.addComuna}
                                                            onChange={(evt) => this.setState({ addComuna: evt.target.value })}
                                                            data={this.state.comuna.filter((i) => !i.id_punto_venta && Number(this.state.selectedRegion) === Number(i.region_id))}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <i onClick={() => this.addComuna()} className="fa fa-plus-circle" style={{ color: "#c23e1f", cursor: "pointer" }}></i>
                                                </td>
                                            </tr>
                                        </>
                                    ) : null}
                                    {(this.state.comunas && this.state.comunas.length > 0)
                                        && this.state.comunas.map((item) => (
                                            <tr>
                                                <td className="text-center">
                                                    <div className="col-12 mb-2">
                                                        {item.region_nombre}
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <div className="col-12 mb-2">
                                                        {item.comuna_nombre}
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <i onClick={() => this.validateComuna(item)} className="fa fa-minus-circle" style={{ color: "#c23e1f", cursor: "pointer" }}></i>
                                                </td>
                                            </tr>

                                        ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>
        )
    }

}

const mapStateToProps = (state) => ({
    product: state.product,
    auth: state.auth,
    drawer: state.drawer,
})
const mapDispatchToProps = (dispatch) => ({
    editProduct: (data, id, token) => dispatch(doEditProduct(data, id, token)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
})

export default connect(mapStateToProps, mapDispatchToProps)(SalePoints)
