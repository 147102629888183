import React from 'react';

const BannerTitle = (props) => {
    const { title, image, children, checkout } = props;
    return (
        <div className={`row ${checkout ? 'banner-top-checkout' : 'banner-top-container'}`}>
            <div className="col-12 px-0 banner-top">
                {(image && (image.slice(-1) !== "/")) && <img src={image} alt="..." />}
                <div
                    className="row mx-0 position-absolute w-100 h-100 align-items-center"
                    style={{
                        top: '0px',
                        zIndex: 1,
                    }}
                >
                    {
                        title !== '' && (
                            <div className="mx-auto justify-content-center text-center d-none d-sm-flex" style={{ overflow: 'hidden' }}>
                                <h1 className="font-weight-bold bg-orange mx-auto text-white mb-0 p-2">{title}</h1>
                            </div>
                        )
                    }
                    {children}
                </div>
            </div>
            {
                title !== '' && (
                    <div className="mx-auto justify-content-center text-center d-flex d-sm-none" style={{ zIndex: 1 }}>
                        <h4 className="font-weight-bold p-2 bg-orange mx-auto text-white mb-0">{title}</h4>
                    </div>
                )
            }
        </div>
    )
}

export default BannerTitle;