import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Radio, RadioGroup, Checkbox, Message } from 'rsuite';
import { updateItems, cart_update_shipping } from '../../../redux/actions/cart';
import { checkout_update_form } from '../../../redux/actions/checkout';
import ModalRsuite from '../../../components/Modals/Modal_Rsuite';
import ProductOptions from '../../../components/Products/Product_Options';
import DiscountForm from '../../../components/Discounts/Discount_Form';
import { compareItems } from '../../../utils/helpers';
import AssetsImg from '../../../assets/image';
import { Alert, Notification } from 'rsuite';
import SelectAddress from './Select_Address';

const initial = { shipping: null, region: null, store: null, commune: null, format: null }

const ReceivedByAThird = ({ inputs, handleInput, handlePhone, handleRut }) => {
	return (
		<>
			<div className="col-12 title-page">
				<strong>Ingresa los datos de quién recibe</strong>
				<hr className="mt-1" />
			</div>
			<div className="form-group mb-0 col-6">
				<label>Nombre</label>
				<input value={inputs.third_name} name="third_name" type="text" className="form-control" onChange={handleInput} />
			</div>
			<div className="form-group mb-0 col-6">
				<label>Teléfono</label>
				<input value={inputs.third_phone} name="third_phone" type="text" className="form-control" onChange={handlePhone} />
			</div>
			<div className="form-group mb-0 col-6">
				<label>Rut (ej: 11111111-1)</label>
				<input value={inputs.third_rut} name="third_rut" type="text" className="form-control" onChange={handleRut} autoComplete="off" />
			</div>
		</>
	);
}

export const Address = ({ inputs, handleInput, cart }) => {
	return (
		<>
			<div className="form-group mb-1 col-12">
				<Message
					closable
					type="warning"
					title="Importante"
					description="La dirección debe estar en la comuna de despacho seleccionada."
				/>
			</div>
			<div className="form-group mb-1 col-6">
				<label className="font-small">Región</label>
				<input value={cart.shipping.comuna.region_nombre} name="region" type="text" className="form-control" disabled />
			</div>
			<div className="form-group mb-1 col-6">
				<label className="font-small">Comuna</label>
				<input value={cart.shipping.comuna.comuna_nombre} name="comuna" type="text" className="form-control" disabled />
			</div>
			<div className="form-group mb-1 col-12">
				<label className="font-small">Dirección</label>
				<input value={inputs.description === null ? '' : inputs.description} name="description" type="text" className="form-control" onChange={handleInput} />
			</div>
			<div className="form-group mb-1 col-6">
				<label className="font-small">Depto/Oficina</label>
				<input value={inputs.department_number} name="department_number" type="text" className="form-control" onChange={handleInput} />
			</div>
			<div className="form-group mb-1 col-6">
				<label className="font-small">Información adicional(opcional)</label>
				<input value={inputs.note} name="note" type="text" className="form-control" onChange={handleInput} />
			</div>
		</>
	);
}

class StepDelivery extends Component {
	state = {
		edit: 0,
		initial: null,
		modal: false
	}

	_removeItem = (index) => {
		const { cart, updateItems, updateShipping, checkout, updateCheckout } = this.props;
		cart.items.splice(index, 1);
		updateItems(cart.items);
		let comunaShipping = cart.items.some(x => x.shipping === 2) ? cart.shipping.comuna : null;
		let sucursalShipping = cart.items.some(x => x.shipping === 1) ? cart.shipping.sucursal : null;
		updateShipping({ shipping: { comuna: comunaShipping, sucursal: sucursalShipping } });
		if (cart.items.length === 0) {
			updateCheckout({ ...checkout.form, address: null, address_id: null, description: null });
		}
	}

	_updateQuantity = (index, value) => {
		const { cart, updateItems } = this.props;
		if (cart.items[index].cantidad + value !== 0) {
			cart.items[index].cantidad += value;
			updateItems(cart.items);
		}
	}

	_showEdit = (index) => {
		this.setState({ modal: true, edit: index });
	}

	_updateItemOnCart = (selected, formats, regions) => {
		const { edit } = this.state;
		const { cart: { items } } = this.props;
		let sRegions = regions.find(x => x.id === selected.region);
		let values = selected.shipping === 1 ? { list: 'comunas_disponibles', name: 'comuna_nombre', selected: 'commune', price: 'valor_despacho_iva' } : { list: 'sucursales_disponibles', name: 'nombre', selected: 'store', price: 'valor_retiro_tienda_iva' };
		let sFormato = formats.find(z => z.id === selected.format);
		if (compareItems(selected, items[edit])) {
			items[edit].precio = parseInt(sFormato[values.price])
			items[edit].id_lista_precio = sFormato.id_lista_precio;
			items[edit].comunas_disponible = sRegions.comunas_disponibles;
			items[edit].sucursales_disponible = sRegions.sucursales_disponibles;
			items[edit].region = selected.region;
			items[edit].store = selected.store;
			items[edit].commune = selected.commune;
		} else {
			// encuentro otro
			let findedIndex = items.findIndex(x => (compareItems(selected, x)));
			if (findedIndex > -1) {
				items[findedIndex].cantidad += items[edit].cantidad;
				this.setState({ edit: 0 })
				items.splice(edit, 1);
			} else {
				let despacho = items[edit].despacho.find(x => x.id === selected.region);
				let retiro = items[edit].retiro.find(y => y.id === selected.region);
				items[edit].id_formato = selected.format;
				items[edit].nombre_formato = sFormato.nombre;
				items[edit].formatos = sFormato;
				items[edit].precio = parseInt(sFormato[values.price]);
				items[edit].comunas_disponible = despacho ? despacho.comunas_disponibles : null;
				items[edit].sucursales_disponible = retiro ? retiro.sucursales_disponibles : null;
				items[edit].id_lista_precio = sFormato.id_lista_precio;
				items[edit].region = selected.region;
				items[edit].store = selected.store;
				items[edit].commune = selected.commune;
				items[edit].shipping = selected.shipping;
			}

			Notification['success']({
				title: 'El producto ha sido actualizado',
				placement: 'bottomEnd',
			});
		}

		this.setState({ modal: false, initial: null });
		this._updateActualShipping(selected.commune, selected.store, items, edit, sRegions);
	}

	_updateActualShipping = (comuna_id, punto_venta_id, items, edit, sRegions) => {
		const { cart: { shipping, discount_code }, updateShipping, updateItems } = this.props;
		let n_comuna = items.some(x => x.shipping === 1) ? shipping.comuna : null;
		let n_sucursal = items.some(x => x.shipping === 2) ? shipping.sucursal : null;
		if (comuna_id && items[edit]) {
			n_comuna = items[edit].comunas_disponible.find(x => x.id === comuna_id);
			n_comuna.region_nombre = sRegions.region_nombre;
		}

		if (punto_venta_id && items[edit]) {
			n_sucursal = items[edit].sucursales_disponible.find(y => y.id === punto_venta_id);
		}

		let n_items = items.map(z => {
			if (z.shipping === 1) {
				let region = z.despacho.find(x => x.id === sRegions.id);
				if (region) {
					let comuna = region.comunas_disponibles.find(y => y.id === n_comuna.id);
					let formato = comuna.formatos.find(x => x.id === z.id_formato);
					z.precio = formato.valor_despacho_iva;
					z.region = sRegions.id;
					z.commune = n_comuna.id;
				} else {
					return null;
				}
			} else {
				let region = z.retiro.find(x => x.id === sRegions.id);
				if (region) {
					let comuna = region.sucursales_disponibles.find(y => y.id === n_sucursal.id);
					let formato = comuna.formatos.find(x => x.id === z.id_formato);
					z.precio = formato.valor_retiro_tienda_iva;
					z.region = sRegions.id;
					z.store = n_sucursal.id;
				} else {
					return null;
				}
			}

			return z;
		});

		if (n_items.some(x => x === null)) {
			Alert.error(`Uno o más productos no pueden ser enviados a la comuna o sucursal seleccionada`, 5000);
		} else {
			updateShipping({ shipping: { comuna: n_comuna, sucursal: n_sucursal } });
			updateItems([...n_items], discount_code);
		}
	}

	render() {
		const { edit, modal } = this.state;
		const { auth: { session }, cart: { items, shipping, total_amount_items }, inputs, handleInput, show } = this.props;
		return (
			<>
				<div className="py-3">
					<div className="form-chekout row">
						<div className="form-group mb-1 col-12">
							{
								items.length > 0 && (
									<>
										<div className="row">
											<div className="col-12 title-page mt-2 mb-1">
												<strong>Detalle de productos</strong>
											</div>
										</div>
										<div className="table-responsive">
											<table className="table">
												<thead className='finalizar-compra'>
													<tr>
														<th scope="col"><small className="font-weight-bold">Formato</small></th>
														<th scope="col"><small className="font-weight-bold">Cantidad</small></th>
														<th scope="col"><small className="font-weight-bold">Entrega</small></th>
														<th scope="col"><small className="font-weight-bold">Acciones</small></th>
													</tr>
												</thead>
												<tbody>
													{
														items.map((item, i) => (
															<React.Fragment key={`key-fragment-${i}`}>
																<tr key={`key-item-checkout-${i}-header`}>
																	<td colSpan="3" className='finalizar-compra'><small>{`${item.nombre_producto}`}</small></td>
																</tr>
																<tr key={`key-item-checkout-${i}`}>
																	<td className="w-25 finalizar-compra"><small>{item.nombre_formato}</small></td>
																	<td className="w-25 finalizar-compra">
																		<div className="btn-group" role="group" aria-label="Basic outlined example">
																			<button type="button" className="btn btn-outline-secondary px-2 py-1" onClick={() => this._updateQuantity(i, -1)} disabled={item.cantidad === 1 ? true : false}>-</button>
																			<button type="button" className="btn btn-outline-secondary p-1"><small>{item.cantidad}</small></button>
																			<button type="button" className="btn btn-outline-secondary px-2 py-1" onClick={() => this._updateQuantity(i, 1)} disabled={item.cantidad >= item.item.cantidad_disponible ? true : false}>+</button>
																		</div>
																	</td>
																	<td className="w-25 finalizar-compra"><small>{item.shipping === 2 ? `Retiro / Sucursal de ${shipping.sucursal ? shipping.sucursal.nombre : null}` : `Despacho / Comuna de ${shipping.comuna ? shipping.comuna.comuna_nombre : null}`}</small></td>
																	<td className="w-25 finalizar-compra">
																		<button type="button" className="btn p-0 d-inline" onClick={() => this._showEdit(i)}>
																			<img src={AssetsImg.Edit} className="w-100" alt="..." style={{ width: '15px', height: '15px' }} />
																		</button>
																		<button type="button" className="btn w-100 d-" onClick={() => this._removeItem(i)}>
																			<img src={AssetsImg.Remove} alt="..." style={{ width: 15 }} />
																		</button>
																	</td>
																</tr>
															</React.Fragment>
														))
													}
												</tbody>
											</table>
										</div>
									</>
								)
							}
							{
								shipping.comuna ? (
									<>
										<div className="row mt-2">
											<div className="col-12 title-page mt-2">
												<strong>¿A qué dirección despachamos?</strong>
												<hr className="my-2" />
											</div>
											{!session ?
												<Address {...this.props} /> :
												<SelectAddress />
											}
										</div>
										<div className="row mt-2">
											<div className="col-12 title-page mt-2">
												<strong>¿Quién recibirá el despacho?</strong>
												<hr className="my-2" />
											</div>
											<div className="form-group mb-0 col-12">
												<RadioGroup
													name="received_by_a_third_party"
													className="type-checkbox"
													inline
													value={inputs.received_by_a_third_party}
													onChange={(value) => {
														handleInput({
															target: {
																name: 'received_by_a_third_party',
																value
															}
														})
													}}
												>
													<Radio value={false} className="col">Lo recibiré personalmente</Radio>
													<Radio value={true} className="col">Lo recibirá un tercero</Radio>
												</RadioGroup>
											</div>
											{inputs.received_by_a_third_party ?
												<ReceivedByAThird {...this.props} /> :
												<div className="col-12">
													<p><small>El repartidor llegará preguntando por ti.</small></p>
												</div>
											}
										</div>
									</>
								) : null
							}
							{
								!session ?
									<>
										<hr className="my-2" />
										<div className="row mt-1">
											<div className="col-6 col-sm-4 d-flex align-items-center">
												<button onClick={(e) => {
													e.preventDefault();
													show();
												}} style={{ background: 'transparent' }}>
													<small className="font-weight-bold text-dark">Inicia Sesión / Regístrate</small>
												</button>
											</div>
											<div className="col-6 col-sm-8 pl-0">
												<Checkbox
													defaultChecked={inputs.buy_without_account}
													className="checkbox-not-account"
													value={true}
													onChange={(value, event) => {
														handleInput({
															target: {
																name: 'buy_without_account',
																value: event
															}
														})
													}}><span className="font-weight-bold text-dark">Comprar como invitado</span></Checkbox>
											</div>
										</div>
									</> : null
							}
							{total_amount_items ? (<DiscountForm allow={true} />) : null}
						</div>
					</div>
				</div>
				<ModalRsuite
					open={modal}
					handleModal={() => this.setState({ modal: false, initial: null })}
					propsModal={{ size: 'sm', overflow: true }}>
					<ProductOptions
						item={items[edit] ? items[edit].item : {}}
						initial={items[edit] ? { shipping: items[edit].shipping, region: items[edit].region, store: items[edit].store, commune: items[edit].commune, format: items[edit].id_formato } : initial}
						shipping={shipping}
						action={this._updateItemOnCart}
						title={'ACTUALIZAR'}
						params={null}
						dontShowImage
						edit
					/>
				</ModalRsuite>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
	cart: state.cart,
	checkout: state.checkout
});

const mapDispatchToProps = dispatch => ({
	updateItems: (data) => dispatch(updateItems(data)),
	updateShipping: (data) => dispatch(cart_update_shipping(data)),
	updateCheckout: (data) => dispatch(checkout_update_form(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepDelivery);