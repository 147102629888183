import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssetsImg from '../../assets/image';
import { login, clean_fetch } from '../../redux/actions/auth';
import history from '../../services/history';
import { Link } from 'react-router-dom';
import { Loader  } from 'rsuite';

class SignIn extends Component {
    state = {
        inputs: {
            correo: '',
            password: ''
        },
        btnDisabled: false
    }

    _handleInput = (e) => {
        let { inputs } = this.state;
        this.setState({ inputs: {...inputs, [e.target.name] : e.target.value} })
    }

    _handleSubmit = async (e) => {
        e.preventDefault();
        const { login, handleAuth } = this.props;
        const { inputs } = this.state;
        inputs.correo = inputs.correo.toLowerCase();
        this.setState({ btnDisabled: true });
        const response = await login(inputs);
        if (response.token) {
            if (handleAuth) {
                handleAuth();
            } else {
                history.push('/');
            }
        }

        this.setState({ btnDisabled: false });
    }

    componentWillUnmount(){
        const {cleanFetch} = this.props;
        cleanFetch();
    }

    render(){
        let { auth: { loading, error }, collapse } = this.props;
        let { inputs, btnDisabled } = this.state;
        return(
            <div className="row justify-content-lg-center mx-auto">
            <div className="col-8 col-sm-12 mx-auto text-center mb-4">
                <img src={AssetsImg.Logo} alt="..." className="w-75"/>
            </div>
            <h4 className="text-center my-4 font-weight-bold w-100">Inicia Sesión</h4>
            <form className="mx-auto col-lg-8 mt-4" onSubmit={this._handleSubmit}>
                <div className="mb-3">
                    <input
                        name="correo"
                        type="email"
                        className="form-control"
                        placeholder="Correo electrónico"
                        onChange={this._handleInput}
                        value={inputs.correo}
                    />
                </div>
                <div className="mb-3">
                    <input
                        name="contrasena"
                        type="password"
                        className="form-control"
                        placeholder="Contraseña"
                        onChange={this._handleInput}
                    />
                </div>
                <button disabled={btnDisabled} type="submit" className="btn btn-success w-100 font-weight-bold">ENTRAR</button>
                {error && <p className="text-center mt-1 mb-0">{error}</p>}
                {!collapse && (
                    <>
                        <Link to="/recuperar-contraseña" className="text-center w-100">
                            <p className="mt-4" style={{ fontSize: '.9em' , color: 'black'}}>Recuperar contraseña</p>
                        </Link>
                        <Link to="/registro" className="text-center w-100">
                            <p className="mt-2" style={{ fontSize: '.9em' , color: 'black'}}>Crear una cuenta</p>
                        </Link>
                    </>
                )}
                {loading && <Loader backdrop content="Cargando..." vertical style={{zIndex:4}}/>}
            </form>
        </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    login: (data) => dispatch( login(data, true) ),
    cleanFetch: () => dispatch( clean_fetch() )
});

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);
