import { handleActions } from 'redux-actions'
import { SET_ROLE, fetch_success_roles, fetch_start_roles, fetch_error_roles } from '../actions/role'

export const INITIAL_STATE = {
    id: '',
    nombre: '',
    data: {},
    users: []
}

const INITIAL_STATE_ROLES = {
    data: [],
    last_page: null,
    loading: false,
    success: false,
    error: null
};

export const actionsRoles = handleActions({
    [fetch_start_roles]: (state) => {
        return { ...state, loading: true };
    },
    [fetch_success_roles]: (state, action) => {
        const { data, last_page } = action.payload;
        return { ...state, loading: false, success: true, data, last_page };
    },
    [fetch_error_roles]: (state) => {
        return { ...state, loading: false, error: true };
    }
}, INITIAL_STATE_ROLES)

export default handleActions({
    [SET_ROLE]: (state, action) => {
        return { ...state, ...action.payload }
    }
}, INITIAL_STATE)