import React from 'react';
import SignIn from '../../components/Auth/SignIn';
import SignUp from '../../components/Auth/SignUp';
import Reset from '../../components/Auth/Reset';
import Recovery from '../../components/Auth/Recovery';

export const Login = () => (
    <div className="bg-i-white d-flex justify-content-center align-items-center">
        <div className="container w-75 bg-white p-4 my-4">
            <SignIn />
        </div>
    </div>
);

export const ResetPass = () => (
    <div className="bg-i-white d-flex justify-content-center align-items-center container-signin">
        <div className="container w-75 bg-white p-4">
            <Reset mobile/>
        </div>
    </div>
);


export const Register = () => (
    <div className="bg-i-white d-flex justify-content-center align-items-center container-signin">
        <div className="container w-75 bg-white p-4">
            <SignUp mobile/>
        </div>
    </div>
);

export const RecoveryPass = (props) => (
    <div className="bg-i-white d-flex justify-content-center align-items-center container-signin">
        <div className="container w-50 bg-white p-4">
            <Recovery {...props}/>
        </div>
    </div>
);


