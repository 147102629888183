import React, { Component } from 'react';
import AssetsImg from '../../assets/image';
import * as Api from '../../services/api/endpoints';
import * as RulesValidate from '../../utils/validations';
import { Loader } from 'rsuite';

class Recovery extends Component{
    constructor(props) {
        super(props);
        this.formRecovery = React.createRef();
        this.state = {
            inputs: {
                nueva_contrasena: '',
            },
            btnDisabled: false,
            loading: false,
            success: false,
            error: false
        }
    }


    _handleInput = (e) => {
        let { inputs } = this.state;
        this.setState({ inputs: {...inputs, [e.target.name] : e.target.value} })
    }

    _handleSubmit = async () => {
        const { inputs } = this.state;
        const { match } = this.props;
        let invalidations = await RulesValidate.RecoveryPass(inputs);
		if (invalidations !== null ) {
			Api.FormValidator.validate(this.formRecovery.current, invalidations);
			return false;
		}

        this.setState({ btnDisabled: true, loading: true });
        await Api.RecoveryPassword({nueva_contrasena: inputs.nueva_contrasena, token: match.params.token})
        .then((response) => {
            this.setState({ btnDisabled: false, success: true, loading: false });
        })
        .catch(e => {
            if (e === 'Not enough segments') {
                e = 'Token no válido.';
            }
            this.setState({ btnDisabled: false, success: false, loading: false, error: e });
        })
    }

    render(){
        const { inputs, btnDisabled, error, success, loading } = this.state;
        if (success) {
            return(
                <div className="row justify-content-lg-center">
                    <div className="col-12 text-center mb-0">
                        <img src={AssetsImg.Success_Register} alt="..." className="img-fluid icon-msg"/>
                        <h5 className="mb-2">
                            <strong>Contraseña actualizada</strong>
                            <br/>
                            <small>Ahora puedes iniciar sesión con tu nueva contraseña</small>
                        </h5>
                    </div>
                </div>
            )
        }

        return(
            <div className="row justify-content-lg-center">
                <div className="d-block d-sm-none col-12 text-center mb-4">
                    <img src={AssetsImg.Logo} alt="..." className="w-75"/>
                </div>
                <div className="d-none d-sm-block col-12 text-center mb-4">
                    <img src={AssetsImg.Logo} alt="..." className="w-50"/>
                </div>
                <h4 className="text-center my-4 font-weight-bold w-100">Ingresa tu nueva contraseña</h4>
                <form className="col-12 col-lg-6 mt-4" onSubmit={e => e.preventDefault()} ref={this.formRecovery}>
                    <div className="mb-3">
                        <input
                            name="nueva_contrasena"
                            type="password"
                            className="form-control"
                            placeholder="Nueva contraseña"
                            onChange={this._handleInput}
                            value={inputs.nueva_contrasena}
                        />
                    </div>
                    <button disabled={btnDisabled} type="submit" className="btn btn-success w-100 font-weight-bold" onClick={this._handleSubmit}>Actualizar</button>
                    {error && <p className="text-center mt-1 mb-0">{error}</p>}
                    {loading && <Loader backdrop content="Cargando..." vertical style={{zIndex:4}}/>}
                </form>
            </div>
        )
    }
}

export default Recovery;