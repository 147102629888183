import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from './routes/App_Router';
import store from './redux/store/store';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';
import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import { Loader } from 'rsuite';

export const App = () => {
    const isProduction = process.env.NODE_ENV === 'production';
    return (
        <CacheBuster
            currentVersion={version}
            isEnabled={isProduction}
            isVerboseMode={false}
            loadingComponent={<Loader />}
        >
            <Provider store={store}>
                <AppRouter />
            </Provider>
        </CacheBuster>
    );
};

ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID })