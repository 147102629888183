import { Component } from 'react'
import { connect } from 'react-redux'
import { END_LOADING, START_LOADING } from '../../../redux/actions/loader'
import Table from '../../../components/Admin/Table'
import Title from '../../../components/Admin/Title'
import RoleActions from '../../../components/Admin/Actions/Roles'
import AddRole from '../../../components/Admin/Roles/AddRole'
import { CLOSE_DRAWER, OPEN_DRAWER, SET_RENDER } from '../../../redux/actions/drawer'
import '../../../styles/page.admin.roles.scss'
import { getRolesList } from '../../../redux/actions/role'
import { rolesHeaders } from '../../../utils/constants'
import { doRemoveRole } from '../../../services/api/endpoints'
import { Notification } from 'rsuite'

class Roles extends Component {

    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }

    hasPermission = () => {
        return JSON.parse(sessionStorage.getItem('modules'))['roles'].edit;
    }

    handleDelete = async (id) => {
        const { auth, START_LOADING, END_LOADING } = this.props
        START_LOADING()
        await doRemoveRole(id, auth.session.token);
        this._getRolesList()
        this.init();
        END_LOADING()
    }

    async init() {
        const { START_LOADING, END_LOADING, actionsRoles } = this.props
        START_LOADING();
        const responseApi = actionsRoles.data.map((item) => {
            item.actions = <RoleActions id={item.id} item={{ nombre: item.nombre, data: item.data, id: item.id }} handleDelete={this.handleDelete} />
            delete item.data
            delete item.users
            return item
        })
        END_LOADING();
        this.setState({ data: { headers: rolesHeaders, items: responseApi } })
    }

    componentDidMount() {
        this._getRolesList()
        this.init();
    }

    componentDidUpdate(prevProp) {
        if (prevProp.actionsRoles.data !== this.props.actionsRoles.data) this.init();
    }

    async _getRolesList() {
        const { auth, getRoles, START_LOADING, END_LOADING } = this.props
        START_LOADING();
        await getRoles(auth.session.token)
        END_LOADING();
    }

    handleAddRole = () => {
        if (this.hasPermission()) {
            const { CLOSE_DRAWER, SET_RENDER, OPEN_DRAWER, drawer } = this.props
            CLOSE_DRAWER(drawer.RENDER);
            SET_RENDER(AddRole);
            OPEN_DRAWER()
        } else {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
        }
    }

    newRoleButton = () => {
        return (
            <button className="btn btn-icon" onClick={this.handleAddRole}><i className="fa fa-plus-circle"></i>Agregar Rol</button>
        )
    }

    render() {
        const { data } = this.state
        return (
            <div className="container-users">
                <Title text="Roles" component={this.newRoleButton} />
                {data && data.items && data.items.length && (
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-2">
                        <Table data={data} />
                    </div>
                )}
                {(data !== {} && data.items && data.items.length === 0) && (
                    <div className="container-products__content mt-md-5 mt-4 p-0 p-md-2 text-center">
                        No hay roles registrados...
                    </div>
                )}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    drawer: state.drawer,
    auth: state.auth,
    actionsRoles: state.actionsRoles
})
const mapDispatchToProps = (dispatch) => ({
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
    getRoles: (token) => dispatch(getRolesList(token))
})

export default connect(mapStateToProps, mapDispatchToProps)(Roles)