import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import history from '../../../services/history'
import { MenuMobile as MenuMobileData } from '../MenuItems'
import '../../../styles/DashboardStyles/MenuMobile.scss'

class MenuMobile extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pathname: '',
            data: []
        }
    }

    componentDidMount() {
        const { location } = history
        const { pathname } = location
        const modules = JSON.parse(sessionStorage.getItem('permissions'));
        this.setState({ data: MenuMobileData.filter(m => (modules.includes(m.name)) || m.name === "exit") }, () => {
            const { data } = this.state
            const updatedData = data.map((item) => {
                if (item.path === pathname) {
                    item.selected = true
                    return item;
                }
                item.selected = false
                return item
            })
            this.setState({ data: updatedData, pathname })
        })
    }

    push = (to) => {
        const { CLOSE_DRAWER } = this.props
        const { data } = this.state
        data.map((item) => {
            if (item.path === to) {
                item.selected = true
                return item
            }
            item.selected = false
            return item
        })

        this.setState({ data })
        CLOSE_DRAWER();
        history.push(to)
    }

    render() {
        const { data } = this.state
        return (
            <div className="container-menumobile">
                <ul>
                    {data && data.map((item) => (
                        <Fragment key={item.name}>
                            <li className={item.selected ? 'selected' : ''} onClick={() => this.push(item.to)}>
                                <i className={item.icon}></i>
                                {item.label}
                            </li>
                        </Fragment>
                    ))}

                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    drawer: state.drawer
})
const mapDispatchToProps = (dispatch) => ({
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component))
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuMobile)