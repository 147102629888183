import { Component } from "react";
import { connect } from "react-redux";
import Input from "../Input";
import Title from "../Title";
import Uploader from "../Uploader";
import '../../../styles/component.admin.banners.scss';
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { doEditBanner } from "../../../redux/actions/banner";
import { UploadBannerFile } from "../../../services/api/endpoints";
import { toBase64 } from "../../../utils/helpers";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";
import { Notification } from "rsuite";
import { v4 } from "uuid";
class EditBanner extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputs: { images: [] },
            errors: {},
            imageChanged: false,
            imageChangedB64: undefined,
            firstImage: -1
        }
    }

    componentDidMount() {
        const { banner } = this.props
        const paths = banner.banner;
        const images = Array.isArray(paths) ? paths.map((i) => {
            return { src: i, new: false, id: v4() }
        }) : [{ src: paths, new: false, id: v4() }];
        this.setState({ inputs: { ...banner, link: banner.link.props.href, images }, firstImage: 0 })
    }

    handleChange = ({ target }) => {
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [target.name]: target.value } }, () => {
            const { errors } = this.state
            Object.keys(errors).forEach((key) => {
                if (key === target.name) {
                    delete errors[key]
                }
            })
            this.setState({ errors })
        })
    }

    validateFormat = (f) => f === "image/jpeg" || f === "image/jpg" || f === "image/png" || f === "image/gif";

    handleUploader = async (e) => {
        if (e.target.files[0]) {
            if (this.validateFormat(e.target.files[0].type)) {
                this.handleChangeimage(e);
            } else {
                Notification.error({ title: "error", description: "Debe seleccionar un formato válido." })
            }
        }
    }

    _editBanner = async () => {
        const { editBanner, auth, drawer, CLOSE_DRAWER, START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const { link, name, text, id, images } = this.state.inputs
        let currentBanners = []
        const mBanners = []
        for (const m of images) {
            if (m.new) {
                const formdata = new FormData();
                formdata.append('file', m.src);
                const response = await UploadBannerFile(formdata, auth.session.token);
                if (response.archivo !== '') {
                    currentBanners.push(response.archivo);
                } else {
                    Notification.error({ title: 'Error', description: 'Hubo un error al tratar de subir una imagen.' })
                }
            } else {
                currentBanners.push(m.src)
            }
        }
        if (this.state.firstImage !== -1) {
            currentBanners.forEach((i, index) => {
                if (index === this.state.firstImage) {
                    mBanners.unshift(i)
                } else {
                    mBanners.push(i)
                }
            })
        }

        await editBanner({ pagina: name, texto: text, link, imagen_path: mBanners }, id, auth.session.token)
        END_LOADING()
        CLOSE_DRAWER(drawer.RENDER);
    }

    handleChangeimage = async (e) => {
        const { target } = e
        const { inputs } = this.state
        const file = e.target.files[0];
        const imageChangedB64 = await toBase64(e.target.files[0]);
        inputs.images.push({ src: file, id: v4(), new: true, base64: imageChangedB64 })
        this.setState({ inputs }, () => {
            const { errors } = this.state
            Object.keys(errors).forEach((key) => {
                if (key === target.name) {
                    delete errors[key]
                }
            })

            this.setState({ errors })
        })
    }

    handleRemoveImage = (id) => {
        const { inputs } = this.state
        const images = []
        inputs.images.forEach((item) => {
            if (item.id !== id) {
                images.push(item)
            }
        })

        this.setState({ inputs: { ...inputs, images } })
    }

    render() {
        const { inputs, errors } = this.state
        const { name, text, link, images } = inputs
        return (
            <div className="size mt-5">
                <Title text="Editar Banner" />
                <div className="row justify-content-center px-md-2 px-1 mb-md-2">
                    <div className="col mb-2">
                        <Input
                            label="Nombre Página"
                            placeholder="Nombre Página"
                            name="name"
                            value={name}
                            onChange={this.handleChange}
                            errors={errors}
                            disabled
                        />
                    </div>
                </div>

                <div className="row justify-content-center px-md-2 px-1 mb-md-2">
                    <div className="col mb-2">
                        <Input
                            label="Texto"
                            placeholder="Texto del Banner"
                            value={text}
                            onChange={this.handleChange}
                            name="text"
                            errors={errors}
                        />
                    </div>
                </div>

                <div className="row justify-content-center px-md-2 px-1 mb-md-2">
                    <div className="col mb-2">
                        <Input
                            label="Link"
                            placeholder="Url Pagina"
                            value={link}
                            onChange={this.handleChange}
                            name="link"
                            errors={errors}
                            disabled
                        />
                    </div>
                </div>

                <div className="row justify-content-center px-md-2 px-1 mb-md-2">
                    <div className="col mb-2">
                        <Uploader
                            isBanner
                            selectFirst
                            onFirstChange={(i) => this.setState({ firstImage: i })}
                            label="Seleccionar imagen"
                            placeholder="Texto del Banner"
                            data={images.map(i => i.new ? { ...i, src: i.base64 } : i)}
                            onChange={this.handleUploader}
                            handleRemove={this.handleRemoveImage}
                            errors={errors}
                            name="banner"
                        />
                    </div>
                </div>

                <div className="row justify-content-center px-md-2 px-1 mb-2">
                    <div className="col-12 mb-2 d-flex justify-content-end">
                        <button className="btn btn-rioclaro" onClick={this._editBanner}>Guardar</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    banner: state.banner,
    auth: state.auth,
    drawer: state.drawer
})
const mapDispatchToProps = (dispatch) => ({
    editBanner: (data, id, token) => dispatch(doEditBanner(data, id, token)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING())
})
export default connect(mapStateToProps, mapDispatchToProps)(EditBanner)