import { Component } from "react";
import Images from '../../../assets/image'
import Image from '../Image'
class CardProduct extends Component {
    render() {
        const { images, deleteProductCallback } = this.props
        const { NotFound } = Images
        return (
            <div className="container-cardproduct">
                <div className="container-cardproduct__image">
                    <Image className="img-fluid" src={images && images.length > 0 ? images[0].src : NotFound} alt="thumb-product" />
                    <i className="fa fa-times-circle" onClick={deleteProductCallback}></i>
                </div>
                <div className="container-cardproduct__text p-2">
                    {this.props.name || ''}
                </div>
            </div>
        )
    }
}

export default CardProduct