import { createAction } from 'redux-actions';
import HelperCoin from '../../utils/currency';

export const cart_update_list_items = createAction('cart_update_list_items');
export const cart_update_amounts = createAction('cart_update_amounts');
export const cart_apply_discount_code = createAction('cart_apply_discount_code');
export const cart_update_shipping = createAction('cart_update_shipping');
export const cart_reset = createAction('cart_reset');

export const updateItems = (items) => async (dispatch) => {
    const {total_discount_items, total_amount_items, total_amount_payment, total_discount_code} = updateAmount(items);
    dispatch(cart_update_amounts({total_discount_items, total_amount_items, total_amount_payment, total_discount_code}));
    dispatch(cart_update_list_items(items));
}

export const updateAmount = (items, discount_code = null) => {
    let total_discount_items = 0;
    let total_amount_items = 0;
    let total_amount_payment = 0;
    let total_discount_code = 0;

    for (var i = 0; i < items.length; ++i) {
        let price = items[i].precio * items[i].cantidad;
        let discount = 0;
        const { apply_discount, new_price } = HelperCoin.calcProductDiscount(price, discount);
        if (apply_discount) {
            total_discount_items += price-new_price;
        }

        total_amount_payment += price;
        total_amount_items += items[i].cantidad;
    }

    if ( discount_code !== null ) {
        total_discount_code = total_amount_payment *  discount_code.percentage_discount;
        total_amount_payment = total_amount_payment-total_discount_code;
    }

    return {total_discount_items, total_amount_items, total_amount_payment, total_discount_code};
}