import React, { useState, useEffect } from 'react';
import * as QueryString from 'query-string';
import { withRouter,useHistory } from 'react-router-dom';
import { Pagination } from 'rsuite';

const Paginator = (props) => {
	const history = useHistory();
	const query = QueryString.parse(history.location.search);
	const [activePage,setActivePage]=useState(1);

	const handleSelect = (eventKey) => {
		query['page'] = eventKey;
		history.push({
			pathname: history.location.pathname,
			search:QueryString.stringify(query),
			state: { page: eventKey }
		})
		setActivePage(eventKey);
		props.action();
	}

	useEffect(()=>{
		if (activePage!==query.page) {
			setActivePage(parseInt(query.page))
		}
	},[setActivePage, activePage, query]);

	return (
		<Pagination
			className={props.className}
			next
			size="lg"
			pages={props.last_page}
			maxButtons={3}
            ellipsis={false}
            boundaryLinks={false}
			activePage={activePage}
			onSelect={handleSelect}
		/>
	);
}

export default withRouter(Paginator);