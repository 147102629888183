import React, { Component } from 'react';
import { connect } from 'react-redux';
import { RadioGroup } from 'rsuite';
import { Link } from 'react-router-dom';
import { checkout_update_form } from '../../redux/actions/checkout';
import { getListAddress, updateAddresses, postAddresses, address_reset } from '../../redux/actions/address';
import LoaderRsuite from '../../components/Loaders/Loader_Rsuite';
import ModalRsuite from '../../components/Modals/Modal_Rsuite';
import { GetRegions } from '../../services/session';
import AssetsImg from '../../assets/image';
import BackLink from './BackLink';
import Address from './Address';
import { Alert, Notification } from 'rsuite';
import * as RulesValidate from '../../utils/validations';

const template = {
    main_default: false,
    number: '00',
    street: '00',
}

class DetailAddresses extends Component {
    constructor (props) {
        super(props);
        this.refForm = React.createRef();
        this.state = {
            openModal: false,
            mainAddressId: null,
            inputs: { ...template }
        }
    }

    componentDidMount = async () => {
        const { auth: { session: { token } }, getAddresses } = this.props;
        await getAddresses(token);       
    }

    componentWillUnmount = async () => {
        const { address_reset } = this.props;
        await address_reset();
    }

    _handleModal = () => {
        const { openModal } = this.state;
        this.setState({ openModal: !openModal })
    }

    _isKeyExists(obj,key){
        if( obj[key] === undefined ){
            return false;
        }else{
            return true;
        }
    }

    _handleInput = (e) => {
        const { inputs } = this.state;
        this.setState({ inputs: { ...inputs, [e.target.name]: e.target.value } });
    }

    _validateAddress = async (inputs) => {
        /*
        let message_address = 'Debe ingresar una dirección válida.';
        let message_region = 'Debe seleccionar una región válida.';
        let message_comuna = 'Debe seleccionar una comuna válida.';
        */
        let bvalidate =  true;
        let invalidations = await RulesValidate.updateAddresses(inputs);

        if(invalidations !== null ) {
            let elements = document.querySelectorAll('.invalid-feedback');
            elements.forEach((e) => e.remove());

            Object.keys(invalidations).map( (index) => {
                let attribute = document.getElementsByName(index);
                attribute[0].className = 'form-control is-invalid';
                
                var div_message = document.createElement("div");
                div_message.className = 'invalid-feedback';
                div_message.innerText = invalidations[index];
                attribute[0].insertAdjacentElement("afterend", div_message);
                return true;
            });

            bvalidate =  false;

        }
        

        return bvalidate;

    }

    _submitAddress = async (e) => {
        e.preventDefault();

        const { inputs } = this.state;
        const { auth: { session: { token, usuario } }, postAddresses } = this.props;
        inputs.id_cliente = usuario.id;

        let result  = await this._validateAddress(inputs);

        if(result === false) {
            return false;
        }


        const res = await postAddresses(inputs, token)
        if (res) {
            Notification['success']({
                title: 'Nueva dirección agregada!',
                placement: 'bottomEnd',
            });
        } else {
            Alert.error('Se ha producido un error al agregar una nueva dirección', 3000);
        }

        this._handleModal();
    }

    _handleDelete = async (id) => {
        const { address, auth: { session: { token } }, updateAddresses } = this.props;
        let n_addresses = address.data.filter(x => x.id !== id);
        await updateAddresses(id, n_addresses, token)
            .then(() => {
                Notification['success']({
                    title: 'Dirección eliminada!',
                    placement: 'bottomEnd',
                });
            })
            .catch(e => {
                Alert.error('Se ha producido un error al eliminar la dirección', 3000);
            })
    }

    _selectAddress = item => {
        const { cart: { shipping }, checkout, updateCheckout } = this.props;
        if (shipping.comuna.id_comuna !== parseInt(item.id_comuna)) {
            Alert.warning('La dirección seleccionada, no coincide con la comuna de despacho', 5000);
        } else {
            updateCheckout({ ...checkout.form, address: item, address_id: item.id, description: item.direccion });
        }
    }

    _updateAddress = (item) => {
        this.setState({ inputs: { ...item } });
        this._handleModal();
    }

    _updateSelectedAddress = async (e) => {
        e.preventDefault();
        const { inputs } = this.state;
        const { auth: { session: { token } }, updateAddresses, address, checkout, updateCheckout } = this.props;
        let n_addresses = address.data.map(x => {
            if (x.id === inputs.id) {
                x = { ...inputs }
            }

            return x;
        });
        let result  = await this._validateAddress(inputs);

        if(result === false) {
            return false;
        }


        this._handleModal();
        updateAddresses(inputs.id, n_addresses, token, true, inputs)
            .then((response) => {
                updateCheckout({ ...checkout.form, address_id: null });
                if (response) {
                    Notification['success']({
                        title: 'Dirección actualizada!',
                        placement: 'bottomEnd',
                    });
                } else {
                    Alert.error('Se ha producido un error al actualizar la dirección', 3000);
                }
            })
    }

    render() {
        const { cart, address } = this.props;
        const { inputs, mainAddressId, openModal} = this.state;
        let listRegions= GetRegions();
        return (
            <div>
                <BackLink actual="Mi Cuenta" />
                <div className="title-page">
                    <h3 className="mb-0">Direcciones</h3>
                    <hr className="my-3" />
                </div>
                <LoaderRsuite
                    loading={{ run: address.loading, title: "Cargando Direcciones..." }}
                    fail={{ run: address.error, title: "Se ha producido un error", description: "Inténtalo más tarde." }}>
                    <div className="text-right mb-3">
                        <div className="d-none d-sm-block">
                            {(cart.shipping && cart.shipping.comuna !== null) ? <Link to="/finalizar-compra" className="btn btn-one">finalizar compra</Link> : null}{' '}
                            <button className="btn btn-one" onClick={() => this.setState({ inputs: { ...template }, openModal: true })}>
                                Añadir Dirección
                            </button>
                        </div>
                        <ModalRsuite
                            open={openModal}
                            handleModal={this._handleModal}
                            propsModal={{ size: 'xs', overflow: false }}
                        >
                            <form className="row font-min" ref={this.refForm} onSubmit={(e) => inputs.id ? this._updateSelectedAddress(e) : this._submitAddress(e)}>
                                <div className="col-12 px-1">
                                    <h3>{inputs.id ? 'Editar' : 'Nueva'} dirección</h3>
                                    <hr />
                                </div>
                                <div className="row px-2">
                                    <Address inputs={inputs} handleInput={this._handleInput} edit={inputs.id ? true : false} />
                                </div>
                                <div className="col text-right pt-3">
                                    <button className="btn btn-one" type="submit">Guardar</button>
                                </div>
                            </form>
                        </ModalRsuite>
                    </div>
                    <RadioGroup
                        name="main_address_id"
                        value={mainAddressId}
                        onChange={value => console.log(value)}> 
                        {listRegions !== null && address.data !== null && address.data.length > 0 ? address.data.map((item, i) => {
                            let region = listRegions.map(x => ({ region_id: x.id, region_nombre: x.region_nombre, comunas: x.comunas }));
                            region = region.filter(y => (y.comunas.find(z => z.id === parseInt(item.id_comuna))));
                            let comuna = region[0].comunas.find(z => z.id === parseInt(item.id_comuna));
                            return (
                                <div className="container-fluid bg-white py-2 rounded shadow-sm px-lg-5 mb-3" key={`address-key-${i}`}>
                                    <div className="row">
                                        <div className="col-6">
                                            <p className="py-0 m-0">
                                                <strong className="font-min d-block">{item.direccion}</strong>
                                            </p>
                                            {(item.referencia || item.oficina) && <small>{item.oficina} {item.referencia}</small>}
                                            <p className="m-0 text-grey"><i>{`Región de ${region[0].region_nombre}, Comuna de ${comuna.comuna_nombre}`}</i></p>
                                        </div>
                                        <div className="col-6 text-right">
                                            <div className="btn-group">
                                                <button
                                                    className="btn btn-link px-1 py-0 text-red"
                                                    onClick={() => this._updateAddress(item)}
                                                >
                                                    <img src={AssetsImg.Edit} className="img-fluid" alt="..." style={{ width: '20px' }} />
                                                </button>
                                                <button
                                                    className="btn btn-link px-1 py-0 text-red"
                                                    onClick={() => this._handleDelete(item.id)}
                                                >
                                                    <img src={AssetsImg.Remove} className="img-fluid" alt="..." style={{ width: '20px' }} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        ) :
                            <div className="container-fluid bg-white py-4 rounded px-lg-5">
                                <div className="row">
                                    <div className="col-12 text-center">
                                        <h4>No hay direcciones agregadas</h4>
                                    </div>
                                </div>
                            </div>
                        }
                    </RadioGroup>
                    <div className="d-block d-sm-none py-2">
                        {(cart.shipping && cart.shipping.comuna !== null) ? <Link to="/finalizar-compra" className="btn btn-one w-100">finalizar compra</Link> : null}{' '}
                        <button className="btn btn-success w-100" onClick={() => this.setState({ inputs: { ...template }, openModal: true })}>
                            Añadir Dirección
                        </button>
                    </div>
                </LoaderRsuite>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    cart: state.cart,
    address: state.address,
    checkout: state.checkout
});

const mapDispatchToProps = dispatch => ({
    getAddresses: (token) => dispatch(getListAddress(token)),
    updateAddresses: (id, data, token, value, edit) => dispatch(updateAddresses(id, data, token, value, edit)),
    updateCheckout: (data) => dispatch(checkout_update_form(data)),
    postAddresses: (data, token) => dispatch(postAddresses(data, token)),
    address_reset: () => dispatch(address_reset())
});

export default connect(mapStateToProps, mapDispatchToProps)(DetailAddresses);