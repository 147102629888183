import { createAction } from 'redux-actions';
import { ProductDetails } from '../../services/api/endpoints';

export const fetch_start_detail = createAction('fetch_start_detail');
export const fetch_success_detail = createAction('fetch_success_detail');
export const fetch_error_detail = createAction('fetch_error_detail');

export const getProductDetail = (slug) => async (dispatch) => {
    dispatch(fetch_start_detail());
    try {
        const {data} = await ProductDetails(slug);
        dispatch(fetch_success_detail({data}));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_detail());
    }
};