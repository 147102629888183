import { Component } from "react";
import { connect } from "react-redux";
import { v4 } from "uuid";
import AutoComplete from "../AutoComplete";
import Title from "../Title";
import '../../../styles/component.admin.roles.scss'
import { AssignRole, UnassignRole, Users } from "../../../services/api/endpoints";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";
import Dialogs from "../Dialogs";

class AsignUser extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            data: {
                headers: [
                    {
                        name: '#',
                        target: 'id'
                    },
                    {
                        name: 'Nombre',
                        target: 'name'
                    },
                    {
                        name: 'Correo',
                        target: 'email'
                    },
                    {
                        name: 'Acciones',
                        target: 'actions'
                    },

                ],
                items: []
            },
            users: [
                { id: v4(), value: 'Franco Nascimento' },
                { id: v4(), value: 'Pedro Henrique' },
                { id: v4(), value: 'Rodrigo Valenzuela' },
                { id: v4(), value: 'Pablo Gonzales' },
            ],
            filtered: [],
            inputs: {
                search: ''
            },
            user: {},
            allUsers: [],
            isRemoveModalOpen: false,
            messageDialog: '',
            removeUserId: -1
        }
    }

    async componentDidMount() {
        const { role, auth } = this.props
        const { data } = this.state
        const allUsers = await Users(auth.session.token);
        const autoCompleteUsers = allUsers.data.map((u) => ({ id: u.id_usuario, value: `${u.id_usuario} ${u.nombre} ${u.correo}` }));
        const users = allUsers.data.filter((u) => u.id_rol === role.id).map((user) => {
            const actions = <i className="fa fa-trash" onClick={() => this.askRemoveUser(user)}></i>
            return { id: user.id_usuario, nombre: user.nombre, correo: user.correo, actions }
        })
        data.items = users
        this.setState({ data, users: autoCompleteUsers, allUsers: allUsers.data })
    }

    askRemoveUser = (user) => {
        this.setState({ modalTypeRemove: true, isModalOpen: true, messageDialog: `¿Desea remover al usuario ${user.nombre} de este rol?`, removeUserId: user.id_usuario });
    }

    handleChange = ({ target }) => {
        const { name, value } = target
        const { inputs, users } = this.state
        let filtered = [];
        if (value && value !== '') {
            filtered = users.filter((item) => {
                if (item.value.toLowerCase().indexOf(value.toLowerCase()) !== -1) {
                    return item
                }

                return false
            })
        }
        this.setState({ inputs: { ...inputs, [name]: value }, filtered })
    }

    handleClick = (id) => {
        const { allUsers } = this.state
        const user = allUsers.find(item => item.id_usuario === id)
        this.setState({ filtered: [], inputs: { search: '' }, user })
    }

    handleAssociate = async (user) => {
        const { data } = this.state
        const { auth, role, START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const find = data.items.find((item) => item.id === user.id)
        if (!find) {
            const currentUser = user;
            await AssignRole({
                "id_rol": role.id,
                "id_usuario": currentUser.id_usuario
            }, auth.session.token)
            const allUsers = [...this.state.allUsers];
            const i = allUsers.findIndex(u => u.id_usuario === user.id_usuario);
            let item = {
                ...allUsers[i],
                id_rol: role.id
            }
            allUsers[i] = item
            data.items.push({
                id: currentUser.id_usuario,
                name: currentUser.nombre,
                email: currentUser.correo,
                actions: <i className="fa fa-trash" onClick={() => this.askRemoveUser(currentUser)}></i>
            })
            this.setState({ data, user: {}, allUsers, isModalOpen: false })
        }
        END_LOADING()
    }

    handleRemove = async (id) => {
        const { data } = this.state
        const { START_LOADING, END_LOADING } = this.props
        START_LOADING()
        const items = [];
        await UnassignRole(id, this.props.auth.session.token)
        data.items.forEach((item) => {
            if (item.id !== id) {
                items.push(item)
            }
        })
        items.map((item) => {
            item.actions = <i className="fa fa-trash" onClick={() => this.handleRemove(item.id)}></i>
            return item
        })
        this.setState({ data: { headers: data.headers, items: items }, isModalOpen: false, messageDialog: '' })
        END_LOADING()
    }

    render() {
        const { role } = this.props
        const { filtered, inputs, user, data, messageDialog, removeUserId, modalTypeRemove, isModalOpen } = this.state
        return (
            <div className="container-assign-user size mt-5">
                <Dialogs handleAcept={() => {
                    modalTypeRemove ? this.handleRemove(removeUserId) : this.handleAssociate(user)
                }} handleClose={() => this.setState({ isModalOpen: false, messageDialog: '' })} message={messageDialog} isOpen={isModalOpen} />
                <Title text={`Asociar usuario con rol de ${role.nombre.toLowerCase()}`} />
                <div className="row justify-content-center px-md-2 px-1 mb-2">
                    <div className="col-12 mb-2">
                        <AutoComplete
                            label="Buscar Usuarios"
                            placeholder="Buscar usuario id, nombre, email"
                            name="search"
                            data={filtered}
                            onChange={this.handleChange}
                            value={inputs.search}
                            handleClick={this.handleClick}
                        />
                    </div>
                </div>
                {user && user.nombre_usuario && (
                    <div className="row justify-content-center px-md-2 px-1 mb-2">
                        <Title size="sm" text="Usuario" />
                        <div className="col-12 mb-2">
                            {`${user.nombre_usuario}, ${user.nombre}`} {user.id_rol !== null ? role.id === user.id_rol ? '(este usuario ya está asignado a este rol)' : '(este usuario ya está asignado a un rol)' : ''}
                        </div>
                        {
                            role.id !== user.id_rol && (<div className="col-12 mb-2">
                                <button className="btn btn-rioclaro" onClick={() => user.id_rol === null ? this.handleAssociate(user) : this.setState({ isModalOpen: true, modalTypeRemove: false, messageDialog:'Está cambiando el rol del usuario ¿desea continuar?' })}>Asociar a esta rol</button>
                            </div>)
                        }
                    </div>
                )}

                <Title text={`Usuarios asociados a ${role.nombre.toLowerCase()}`} />
                <div className="row justify-content-center px-md-2 px-1 mb-2 mt-3">
                    <div className="col-12 mb-2">
                        <div className="container-table">
                            <table className="table table-striped table-hover table-bordered" style={{ overflow: 'auto' }}>
                                <thead>
                                    <tr>
                                        {data.headers && data.headers.map((header, index) => (
                                            <th className="text-center" scope="col" key={v4()}>
                                                {header.name}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.items && data.items.length > 0
                                        ? data.items.map((item, index) => (
                                            <tr key={v4()}>
                                                {item && Object.keys(item).map((key, i) => (
                                                    <td className="text-center" key={v4()}>{item[key]}</td>
                                                ))}
                                            </tr>

                                        ))
                                        : (<tr><td className="text-center" colSpan={data.headers && data.headers.length}>Sin Data</td></tr>)}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    role: state.role,
    auth: state.auth
})

const mapDispatchToProps = (dispatch) => ({
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING())
})
export default connect(mapStateToProps, mapDispatchToProps)(AsignUser)