const validate = (schema, inputs, callback = null) => {
    return schema.validate(inputs, { abortEarly: false })
        .then(() => {
            if (callback) {
                callback()
            } else {
                console.log('Submit Form');
            }
            return false
        }).catch((error) => {
            const validationErrors = {}
            if (error && error.inner) {
                error.inner.forEach((errorList) => {
                    validationErrors[errorList.path] = errorList.errors
                })
            } else {
                console.log(error)
            }
            return validationErrors
        })
}

export default validate