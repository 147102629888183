import React, { Component } from 'react';
import AssetsImg from '../../assets/image';
import { Link } from 'react-router-dom';
import * as RulesValidate from '../../utils/validations';
import * as Api from '../../services/api/endpoints';
import { Loader } from 'rsuite';
import { _maskRut } from '../../utils/helpers';

class SignUp extends Component {
    constructor(props) {
        super(props);
        this.formSignUp = React.createRef();
        this.state = {
            btnDisabled: false,
            inputs: {
                telefono: '',
                rut: '',
                apellidos: '',
                correo: '',
                contrasena: '',
                nombres: ''
            },
            loading: false,
            success: false,
            error: false,
            remove: false,
            invalidations: {}
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this._detectDelete, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._detectDelete, false);
    }

    _detectDelete = (e) => {
        if (e.key === "Delete" || e.key === "Backspace") {
            this.setState({ remove: true })
        } else {
            this.setState({ remove: false });
        }
    }

    _handleInput = (e) => {
        let { inputs } = this.state;
        this.setState({ inputs: { ...inputs, [e.target.name]: e.target.value } })
    }

    _handleRut = async (e) => {
        this.setState({ inputs: _maskRut(e, this.state) }, async () => {
            const { invalidations } = this.state;
            if (RulesValidate.rutEsValido(e.target.value)) {
                if (invalidations[e.target.name]) {
                    delete invalidations[e.target.name]
                    this.setState({ invalidations })
                    Api.FormValidator.validate(this.formSignUp.current, invalidations);
                } else {
                    Api.FormValidator.validate(this.formSignUp.current, invalidations);
                }

            } else {
                if (e.target.value.length > 10) {
                    const rutError = { [e.target.name]: ['El rut no es válido'] }
                    const errors = { ...invalidations, ...rutError };
                    Api.FormValidator.validate(this.formSignUp.current, errors);
                }
            }
        })
    }

    _handleSubmit = async (e) => {
        e.preventDefault();
        const { inputs } = this.state;
        this.setState({ btnDisabled: true });
        let invalidations = await RulesValidate.Register(inputs);
        if (invalidations !== null) {
            Api.FormValidator.validate(this.formSignUp.current, invalidations);
            this.setState({ btnDisabled: false, invalidations });
            return false;
        } else {
            inputs.correo = inputs.correo.toLowerCase();
            inputs.direccion = null;
            inputs.nombre_empresa = null;
            inputs.giro = null;
            inputs.rut_empresa = null;
            inputs.telefono_empresa = null;
            inputs.direccion_empresa = null;
            inputs.id_comuna_empresa = null;
            this.setState({ loading: true });

            const data = { ...inputs };
            data.rut = inputs.rut.replace(/[^\dkK-]/g, '');


            await Api.SignUp(data)
                .then(() => {
                    this.setState({ loading: false, success: true });
                })
                .catch((e) => {
                    this.setState({ loading: false, error: e });
                })
        }

        this.setState({ btnDisabled: false });
    }

    _handlePhone = (e) => {
        const { remove } = this.state;
        if (remove) {
            this._handleInput(e);
        } else {
            if (e.target.value.match(/^\d+$/)) {
                this._handleInput(e);
            }
        }
    }

    render() {
        let { btnDisabled, inputs, loading, error, success } = this.state;
        let { collapse, mobile } = this.props;
        if (success) {
            return (
                <div className="row justify-content-lg-center">
                    <div className="col-12 text-center mb-0">
                        <img src={AssetsImg.Success_Register} alt="..." className="img-fluid icon-msg" />
                        <h5 className="mb-2">
                            <strong>Cuenta creada exitosamente</strong>
                            <br />
                            <small>Revisa tu correo y activa tu cuenta</small>
                        </h5>
                        {
                            mobile && <Link to="/ingresar" className="btn bg-orange text-white font-weight-bold">Ingresa a tu cuenta</Link>
                        }
                    </div>
                </div>
            )
        }

        return (
            <div className="row justify-content-lg-center">
                <div className="col-12 text-center mb-1">
                    <img src={AssetsImg.Logo} alt="..." className="w-75" />
                </div>
                <h4 className="text-center my-2 font-weight-bold w-100">Regístrate</h4>
                <form className="col-lg-8 mt-2" ref={this.formSignUp} onSubmit={(e) => this._handleSubmit(e)}>
                    <div className="mb-4">
                        <p className="font-weight-bold mb-1" style={{ fontSize: '.9em' }}>Nombres</p>
                        <input
                            name="nombres"
                            type="text"
                            className="form-control"
                            onChange={this._handleInput}
                            value={inputs.nombres}
                        />
                    </div>
                    <div className="mb-4">
                        <p className="font-weight-bold mb-1" style={{ fontSize: '.9em' }}>Apellidos</p>
                        <input
                            name="apellidos"
                            type="text"
                            className="form-control"
                            onChange={this._handleInput}
                            value={inputs.apellidos}
                        />
                    </div>
                    <div className="mb-4">
                        <p className="font-weight-bold mb-1" style={{ fontSize: '.9em' }}>Correo electrónico</p>
                        <input
                            name="correo"
                            type="correo"
                            className="form-control"
                            onChange={this._handleInput}
                            value={inputs.correo}
                        />
                    </div>
                    <div className="mb-4">
                        <p className="font-weight-bold mb-1" style={{ fontSize: '.9em' }}>Teléfono</p>
                        <input
                            name="telefono"
                            type="text"
                            className="form-control"
                            onChange={this._handlePhone}
                            value={inputs.telefono}
                        />
                    </div>
                    <div className="mb-4">
                        <p className="font-weight-bold mb-1" style={{ fontSize: '.9em' }}>RUT <small>(ej: 11111111-1)</small></p>
                        <input
                            name="rut"
                            type="text"
                            className="form-control"
                            onChange={this._handleRut}
                            value={inputs.rut}
                            autoComplete="off"
                        />
                    </div>
                    <div className="mb-4">
                        <p className="font-weight-bold mb-1" style={{ fontSize: '.9em' }}>Crear contraseña</p>
                        <input
                            name="contrasena"
                            type="password"
                            className="form-control"
                            onChange={this._handleInput}
                            value={inputs.contrasena}
                        />
                    </div>
                    <button disabled={btnDisabled} className="btn btn-success w-100 font-weight-bold">REGISTRARSE</button>
                    {error && <p className="text-center mt-1 mb-0">{error}</p>}
                    {!collapse && (
                        <>
                            <Link to="/ingresar" className="text-center w-100">
                                <p className="mt-4" style={{ fontSize: '.9em', color: 'black' }}>Volver</p>
                            </Link>
                        </>
                    )}
                    {loading && <Loader backdrop content="Cargando..." vertical style={{ zIndex: 4 }} />}
                </form>
            </div>
        );
    }
}

export default SignUp;