import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Alert, Notification } from 'rsuite';
import { updateProfile } from '../../redux/actions/profile';
import { checkout_update_login } from '../../redux/actions/checkout';
import { AuthToCheckout, _formatRut, _maskRut } from '../../utils/helpers';
import { FormValidator } from '../../services/api/endpoints';
import * as Api from '../../services/api/endpoints';
import { GetRegions } from '../../services/session';
import * as RulesValidate from '../../utils/validations';
import SelectSimple from '../../components/Selects/Select_Simple';
import LoaderRsuite from '../../components/Loaders/Loader_Rsuite';
import { dgv } from '../../utils/helpers';
import BackLink from './BackLink';

const initForm = {
	inputs: {
		nombres: '', apellidos: '', rut: '', telefono: '', correo: '', nombre_empresa: '', rut_empresa: '',
		giro: '', telefono_empresa: '', direccion_empresa: '', id_region_empresa: '', id_comuna_empresa: ''
	},
	edit: false,
	inputsPass: {
		contrasena: '', nueva_contrasena: ''
	},
	editPass: false,
	add: false
}

class DetailProfile extends Component {
	constructor(props) {
		super(props);
		this.refForm = React.createRef();
		this.refFormPass = React.createRef();
		this.refFormEnterprise = React.createRef();
		this.state = {
			form: initForm,
			loadingPass: false,
			listCommunes: [],
			loading: true,
			loaderMessage: 'Cargando Perfil...',
			error: null,
			invalidations: {}
		}
	}

	componentDidMount = async () => {
		const { auth: { session: { usuario, token } } } = this.props;
		document.addEventListener('keydown', this._detectDelete, false);
		let listRegions = GetRegions();
		let comunas = [];
		let finded = listRegions?.find(x => x.id === usuario.id_region_empresa);
		if (finded) {
			comunas = finded.comunas
		}
		await Api.ShowProfile(token)
			.then(({ data }) => {
				Object.keys(data).forEach((key) => {
					if (key === 'rut' || key === 'rut_empresa') {
						data[key] = data[key] ? _formatRut(data[key]) : data[key]
					}
				})
				Object.keys(data).forEach((key) => {
					if (data[key] === null) {
						data[key] = ''
					}
				})
				this.setState({ form: { inputs: { ...data }, edit: false, inputsPass: { contrasena: '', nueva_contrasena: '' }, editPass: false, add: false }, listCommunes: comunas, loading: false });
			})
			.catch((e) => {
				this.setState({ loading: false, error: true })
			})
	}

	_handleEdit = (on) => {
		const { form } = this.state;
		form.edit = on;
		this.setState({ form: { ...form } })
	}

	_handleAdd = (on) => {
		const { form } = this.state;
		form.add = on;
		this.setState({ form: { ...form } })
	}

	_detectDelete = (e) => {
		if (e.key === "Delete" || e.key === "Backspace") {
			this.setState({ remove: true })
		} else {
			this.setState({ remove: false });
		}
	}

	_handleInputs = (e) => {
		const { form } = this.state;
		form.inputs = { ...form.inputs, [e.target.name]: e.target.value }
		this.setState({ form: { ...form } })
	}

	// Chnage Password
	_handleChangePass = (on) => {
		const { form } = this.state;
		form.editPass = on;
		this.setState({ form: { ...form } })
	}

	_handleInputPass = (e) => {
		const { form } = this.state;
		form.inputsPass = { ...form.inputsPass, [e.target.name]: e.target.value }
		this.setState({ form: { ...form } })
	}

	_handleRut = async (e) => {
		const { form } = this.state;
		this.setState({ form: { ...form, inputs: _maskRut(e, form) } }, () => {
			const { form, invalidations } = this.state;
			if (RulesValidate.rutEsValido(form.inputs[e.target.name])) {
				if (invalidations[e.target.name]) {
					delete invalidations[e.target.name]
					this.setState({ invalidations })
					FormValidator.validate(this.refForm.current, {});
					FormValidator.validate(this.refFormEnterprise.current, {});
				}
			} else {
				if (e.target.value.length > 10) {
					const rutError = { [e.target.name]: ['El rut no es válido'] }
					const errors = { ...invalidations, ...rutError };
					FormValidator.validate(this.refForm.current, errors);
					FormValidator.validate(this.refFormEnterprise.current, errors);
					this.setState({ invalidations: errors })
				}
			}
		})
	}

	_handlePhone = (e) => {
		const { remove } = this.state;
		if (remove) {
			this._handleInputs(e);
		} else {
			if (e.target.value.match(/^\d+$/)) {
				this._handleInputs(e);
			}
		}
	}

	_saveProfile = async () => {
		const { form } = this.state;
		const { auth: { session: { token } }, updateProfile, checkout_update_login } = this.props;
		let invalidations = await RulesValidate.UpdateProfile(form.inputs);
		if (invalidations !== null) {
			FormValidator.validate(this.refForm.current, invalidations);
			return false;
		} else {
			if (form.inputs.id_comuna_empresa === '') {
				form.inputs.id_comuna_empresa = null
			}
			this._handleEdit(false);
			this.setState({ loading: true, loaderMessage: 'Actualizando perfil...' })

			const params = { ...form.inputs }
			params.rut = params.rut.replace(/[^\dkK-]/g, "")
			params.rut_empresa = params.rut_empresa.replace(/[^\dkK-]/g, "")

			await updateProfile(params, token)
				.then((response) => {
					checkout_update_login(AuthToCheckout(response.data));
					Notification['success']({
						title: 'Tu perfil ha sido actualizado.',
						placement: 'bottomEnd',
					});
				})
				.catch((e) => {
					Alert.error('Se ha producido un error al intentar actualizar tu perfil', 3000)
				})
			this.setState({ loading: false, loaderMessage: 'Cargando Perfil...' })
		}
	}

	_saveEnterpriseProfile = async () => {
		const { form } = this.state;
		const { auth: { session: { token } }, updateProfile, checkout_update_login } = this.props;
		let invalidations = await RulesValidate.UpdateEnterprise(form.inputs);
		if (invalidations !== null) {
			FormValidator.validate(this.refFormEnterprise.current, invalidations);
			return false;
		} else {
			this._handleAdd(false)
			this.setState({ loading: true, loaderMessage: 'Actualizando perfil empresa...' })

			const params = { ...form.inputs }
			params.rut = params.rut.replace(/[^\dkK-]/g, "")
			params.rut_empresa = params.rut_empresa.replace(/[^\dkK-]/g, "")

			await updateProfile(params, token)
				.then((response) => {
					checkout_update_login(AuthToCheckout(response.data));
					Notification['success']({
						title: 'Datos de empresa han sido actualizados.',
						placement: 'bottomEnd',
					});
				})
				.catch((e) => {
					Alert.error('Se ha producido un error al intentar actualizar tus datos de empresa', 3000)
				})
			this.setState({ loading: false, loaderMessage: 'Cargando Perfil...' })
		}
	}

	_updatePassword = async () => {
		const { form } = this.state;
		const { auth: { session: { token } } } = this.props;
		let invalidations = await RulesValidate.ChangePassword(form.inputsPass);
		if (invalidations !== null) {
			FormValidator.validate(this.refFormPass.current, invalidations);
			return false;
		} else {
			this._handleChangePass(false)
			this.setState({ loading: true, loaderMessage: 'Actualizando contraseña...' })
			await Api.UpdatePassword(form.inputsPass, token)
				.then(() => {
					Notification['success']({
						title: 'Tu contraseña ha sido actualizada.',
						placement: 'bottomEnd',
					});
				})
				.catch((e) => {
					Alert.error('Se ha producido un error al intentar actualizar tu contraseña', 3000)
				});

			this.setState({ loading: false, loaderMessage: 'Cargando Perfil...' })
		}
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this._detectDelete, false);
	}

	render() {
		const { form, listCommunes, loading, error, loaderMessage } = this.state;
		const { auth: { session: { usuario } } } = this.props;
		let listRegions = GetRegions();
		let comuna_empresa= [];
		let rut = usuario.rut.toString().includes('-') ? _formatRut(usuario.rut) : _formatRut(`${usuario.rut}-${dgv(usuario.rut)}`);
		let region_empresa = listRegions !== null ?  listRegions.find(x => x.id === usuario.id_region_empresa) : [];
		if (region_empresa !== undefined) {
			comuna_empresa = region_empresa.length  !== 0 ? region_empresa.comunas.find(y => y.id === usuario.id_comuna_empresa) : [];
		}

		return (
			<LoaderRsuite
				loading={{ run: loading, title: loaderMessage }}
				fail={{ run: error, title: "Se ha producido un error", description: "Inténtalo más tarde." }}>
				<BackLink actual="Mi Cuenta" />
				<div className="title-page d-none d-sm-block">
					<h3 className="mb-0">Perfil</h3>
					<hr className="my-3" />
				</div>
				<div className="row">
					<div className="col-12 col-lg-8 mb-3">
						<div className="bg-white py-5 rounded shadow-sm">
							<div className="container-lg px-lg-5">
								<form className="row" ref={this.refForm} onSubmit={(e) => e.preventDefault()}>
									<div className="col-12 mb-3 text-right d-none d-sm-block">
										{
											form.edit ?
												<>
													<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._saveProfile()}>Guardar</button>{' '}
													<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._handleEdit(false)}>Cancelar</button>
												</> :
												<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._handleEdit(true)}>Modificar</button>
										}
									</div>
									<div className="col-6 mb-3 d-block d-sm-none">
										<h4 className="mb-0">Mis datos</h4>
									</div>
									<div className="col-6 mb-3 text-right d-block d-sm-none">
										{
											form.edit ?
												<>
													<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._saveProfile()}>Guardar</button>{' '}
													<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._handleEdit(false)}>Cancelar</button>
												</> :
												<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._handleEdit(true)}>Modificar</button>
										}
									</div>
									<div className="col-12 col-sm-6 mb-3 mb-lg-5">
										<strong className="d-block">Nombre</strong>
										{
											form.edit ?
												<input
													value={form.inputs.nombres}
													name="nombres"
													onChange={(e) => this._handleInputs(e)}
													className="form-control"
												/> :
												<label className="text-grey">{form.inputs.nombres}</label>
										}
									</div>
									<div className="col-12 col-sm-6 mb-3 mb-lg-5">
										<strong className="d-block">Apellido</strong>
										{
											form.edit ?
												<input
													value={form.inputs.apellidos}
													name="apellidos"
													onChange={(e) => this._handleInputs(e)}
													className="form-control"
												/> :
												<label className="text-grey">{form.inputs.apellidos}</label>
										}
									</div>
									<div className="col-12 col-sm-6 mb-3 mb-lg-5">
										<span className="d-block"><strong>Rut </strong></span>
										{
											form.edit ?
												<input
													className="form-control"
													disabled
													value={rut}
												/> :
												<label className="text-grey">{rut}</label>
										}
									</div>
									<div className="col-12 col-sm-6 mb-3 mb-lg-5">
										<strong className="d-block">Teléfono</strong>
										{
											form.edit ?
												<input
													value={form.inputs.telefono}
													name="telefono"
													onChange={(e) => this._handlePhone(e)}
													className="form-control"
												/> :
												<label className="text-grey">{form.inputs.telefono}</label>
										}
									</div>
									<div className="col-12 col-sm-6 mb-3 mb-lg-5">
										<strong className="d-block">Email</strong>
										{
											form.edit ?
												<input
													value={form.inputs.correo}
													name="correo"
													onChange={(e) => this._handleInputs(e)}
													className="form-control"
												/> :
												<label className="text-grey">{form.inputs.correo}</label>
										}
									</div>
								</form>
								<form className="row" ref={this.refFormEnterprise} onSubmit={(e) => e.preventDefault()}>
									<div className="col-6 mb-3 d-block d-sm-none">
										<h4 className="mb-0">Datos empresa</h4>
									</div>
									<div className="col-12 mb-3 text-right d-none d-sm-block">
										{
											form.add ?
												<>
													<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._saveEnterpriseProfile()}>Guardar</button>{' '}
													<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._handleAdd(false)}>Cancelar</button>
												</> :
												<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._handleAdd(true)}>Modificar</button>
										}
									</div>
									<div className="col-6 mb-3 text-right d-block d-sm-none">
										{
											form.add ?
												<>
													<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._saveEnterpriseProfile()}>Guardar</button>{' '}
													<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._handleAdd(false)}>Cancelar</button>
												</> :
												<button type="button" className="btn btn-link text-orange px-0" onClick={() => this._handleAdd(true)}>Modificar</button>
										}
									</div>
									<div className="col-12 col-sm-6 mb-3 mb-lg-5">
										<strong className="d-block">Razón social</strong>
										{
											form.add ?
												<input
													value={form.inputs.nombre_empresa}
													name="nombre_empresa"
													onChange={(e) => this._handleInputs(e)}
													className="form-control"
												/> :
												<label className="text-grey">{form.inputs.nombre_empresa}</label>
										}
									</div>
									<div className="col-12 col-sm-6 mb-3 mb-lg-5">
										<span className="d-block"><strong>Rut Tributario</strong></span>
										{
											form.add ?
												<input
													name="rut_empresa"
													className="form-control"
													value={form.inputs.rut_empresa}
													onChange={(e) => this._handleRut(e)}
												/> :
												<label className="text-grey">{form.inputs.rut_empresa}</label>
										}
									</div>
									<div className="col-12 col-sm-6 mb-3 mb-lg-5">
										<strong className="d-block">Giro</strong>
										{
											form.add ?
												<input
													value={form.inputs.giro}
													name="giro"
													onChange={(e) => this._handleInputs(e)}
													className="form-control"
												/> :
												<label className="text-grey">{form.inputs.giro}</label>
										}
									</div>
									<div className="col-12 col-sm-6 mb-3 mb-lg-5">
										<span className="d-block"><strong>Teléfono de oficina </strong></span>
										{
											form.add ?
												<input
													value={form.inputs.telefono_empresa}
													name="telefono_empresa"
													onChange={(e) => this._handlePhone(e)}
													className="form-control"
												/> :
												<label className="text-grey">{form.inputs.telefono_empresa}</label>
										}
									</div>
									<div className="col-12 col-sm-6 mb-3 mb-lg-5">
										<strong className="d-block">Región</strong>
										{
											form.add ?
												<SelectSimple
													type="simple"
													data={listRegions.map(x => ({ label: x.region_nombre, value: x.id }))}
													initValue={form.inputs.id_region_empresa}
													name="id_region_empresa"
													placeholder="Región"
													runChange={(e) => {
														this._handleInputs(e);
														let finded = listRegions.find(x => x.id === parseInt(e.target.value));
														finded && this.setState({ listCommunes: finded.comunas })
													}}
													className="form-control"
												/> :
												<label className="text-grey">{region_empresa && region_empresa.region_nombre}</label>
										}
									</div>
									<div className="col-12 col-sm-6 mb-3 mb-lg-5">
										<strong className="d-block">Comuna</strong>
										{
											form.add ?
												<SelectSimple
													type="simple"
													data={listCommunes.map(y => ({ label: y.comuna_nombre, value: y.id }))}
													className="form-select form-control-sm"
													name="id_comuna_empresa"
													placeholder="Comuna"
													runChange={(e) => this._handleInputs(e)}
													initValue={form.inputs.id_comuna_empresa}
												/> :
												<label className="text-grey">{comuna_empresa && comuna_empresa.comuna_nombre}</label>
										}
									</div>
									<div className="col-12 mb-3 mb-lg-5">
										<strong className="d-block">Dirección legal</strong>
										{
											form.add ?
												<input
													value={form.inputs.direccion_empresa}
													name="direccion_empresa"
													onChange={(e) => this._handleInputs(e)}
													className="form-control"
												/> :
												<label className="text-grey">{form.inputs.direccion_empresa}</label>
										}
									</div>
								</form>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-4">
						<div className="bg-white py-5 rounded shadow-sm">
							<div className="container-lg px-lg-3">
								<div className="row">
									<div className="col-12 mb-3 text-right d-inline">
										{
											form.editPass ?
												<>
													<button className="btn btn-link text-orange px-0" onClick={() => this._updatePassword()}>Guardar</button>{' '}
													<button className="btn btn-link text-orange px-0" onClick={() => this._handleChangePass(false)}>Cancelar</button>
												</> :
												<button className="btn btn-link text-orange px-0" onClick={() => this._handleChangePass(true)}>Modificar</button>
										}
									</div>
									<form className="col-12" ref={this.refFormPass} onSubmit={(e) => e.preventDefault()}>
										<div className="col-12">
											<div className="form-group">
												<strong className="d-block">Contraseña</strong>
												{
													form.editPass ?
														<input
															type="password"
															value={form.inputsPass.contrasena}
															name="contrasena"
															placeholder="Contraseña actual"
															onChange={(e) => this._handleInputPass(e)}
															className="form-control mb-3"
														/>
														:
														<label className="h2 text-grey" style={{ letterSpacing: "3px" }}>
															********
												</label>
												}
											</div>
											<div className="form-group">
												{
													form.editPass ?
														<>
															<strong className="d-block">Nueva Contraseña</strong>
															<input
																type="password"
																value={form.inputsPass.nueva_contrasena}
																name="nueva_contrasena"
																placeholder="Nueva contraseña"
																onChange={(e) => this._handleInputPass(e)}
																className="form-control mb-3"
															/>
														</> : null
												}
											</div>
										</div>
									</form>
								</div>
							</div>
						</div>
					</div>
				</div>
			</LoaderRsuite>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth
});

const mapDispatchToProps = (dispatch) => ({
	updateProfile: (data, token) => dispatch(updateProfile(data, token)),
	checkout_update_login: (data) => dispatch(checkout_update_login(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(DetailProfile);
