import { createAction } from 'redux-actions'
import { CreateDiscount, Discounts, EditDiscount, RemoveDiscount } from '../../services/api/endpoints';

export const fetch_start_discounts = createAction('fetch_start_discounts');
export const fetch_success_discounts = createAction('fetch_success_discounts');
export const fetch_error_discounts = createAction('fetch_error_discounts');

export const getDiscountsList = () => async (dispatch) => {
    dispatch(fetch_start_discounts());
    try {
        const response = await Discounts({});
        console.log(response, 'RESPUESTA DE LISTADO DE DESCUENTOS');
        const { data, last_page } = response;
        dispatch(fetch_success_discounts({ data, last_page }))
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_discounts());
    }
};

export const doEditDiscount = (params, id, token) => async (dispatch) => {
    dispatch(fetch_start_discounts());
    try {
        await EditDiscount(params, id, token);
        dispatch(getDiscountsList({}));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_discounts());
    }
}

export const doRemoveDiscount = (params, id, token) => async (dispatch) => {
    dispatch(fetch_start_discounts());
    try {
        await RemoveDiscount(params, id, token);
        dispatch(getDiscountsList({}));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_discounts());
    }
}

export const doCreateDiscount = (params, token) => async (dispatch) => {
    dispatch(fetch_start_discounts());
    try {
        await CreateDiscount(params, token);
        dispatch(getDiscountsList({}));
    } catch (e) {
        console.log('error discount');
        console.log(e);
        dispatch(fetch_error_discounts());
    }
}

export const SET_DISCOUNT = createAction('SET_DISCOUNT')