import { createAction } from 'redux-actions'
import { Banners, EditBanner } from '../../services/api/endpoints';

export const fetch_start_banners = createAction('fetch_start_banners');
export const fetch_success_banners = createAction('fetch_success_banners');
export const fetch_error_banners = createAction('fetch_error_banners');

export const getBannerList = () => async (dispatch) => {
    dispatch(fetch_start_banners());
    try {
        const response = await Banners();
        console.log(response, 'RESPUESTA DE LISTADO DE BANNERS');
        const { data } = response;
        dispatch(fetch_success_banners({ data }))
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_banners());
    }
};

export const doEditBanner = (params, id, token) => async (dispatch) => {
    dispatch(fetch_start_banners());
    try {
        await EditBanner(params, id, token);
        dispatch(getBannerList({}));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_banners());
    }
}


export const SET_BANNER = createAction('SET_BANNER');
