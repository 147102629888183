import { Component } from "react";
import { connect } from "react-redux";
import Input from "../Input";
import Title from "../Title";
import moment from 'moment';
import validate from '../../../utils/validate';
import { discountSchema } from '../../../utils/validations';
import { doEditDiscount } from "../../../redux/actions/discount";
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";

class EditDiscount extends Component {

    constructor(props) {
        super(props)
        this.state = {
            inputs: {},
            errors: {}
        }
    }

    componentDidMount() {
        const { discount: inputs } = this.props
        inputs.fecha_creacion = moment(inputs.fecha_creacion).format('YYYY-MM-DD')
        inputs.fecha_expiracion = moment(inputs.fecha_expiracion).format('YYYY-MM-DD')
        this.setState({ inputs })
    }

    handleSwitch = ({ target }) => {
        const { inputs } = this.state
        const { checked, name } = target
        this.setState({ inputs: { ...inputs, [name]: checked } })
    }

    handleChange = ({ target }) => {
        const { inputs } = this.state
        if (target.type === 'number' && target.value === '0') {
            target.value = ''
        }
        this.setState({ inputs: { ...inputs, [target.name]: target.name === "codigo" ? target.value.toUpperCase() : target.value } }, () => {
            const { errors } = this.state
            Object.keys(errors).forEach((key) => {
                if (key === target.name) {
                    delete errors[key]
                }
            })
            this.setState({ errors })
        })
    }

    handleSubmit = async () => {
        const { inputs } = this.state
        const validations = await validate(discountSchema, inputs, this._editDiscount)
        if (validations) {
            this.setState({ errors: validations })
        }
    }

    _editDiscount = async () => {
        const { editDiscount, auth, drawer, CLOSE_DRAWER, START_LOADING, END_LOADING } = this.props
        const { inputs } = this.state
        START_LOADING()
        await editDiscount(inputs, inputs.id, auth.session.token)
        CLOSE_DRAWER(drawer.RENDER);
        END_LOADING()
    }

    render() {
        const { inputs, errors } = this.state
        const { codigo, valor, fecha_creacion, fecha_expiracion, cantidad } = inputs

        return (
            <div className="container-component-discount size mt-5">
                <Title text="Editar Cupón" />

                <div className="row justify-content-center px-md-2 px-1 mb-md-2">
                    <div className="col-12 col-md-12 mb-2">
                        <Input
                            label="Cantidad"
                            placeholder="cantidad"
                            value={cantidad}
                            name="cantidad"
                            onChange={this.handleChange}
                            errors={errors}
                            type="number"
                        />
                    </div>

                </div>

                <div className="row justify-content-center px-md-2 px-1 mb-md-5">
                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="Código"
                            placeholder="10off"
                            value={codigo}
                            name="codigo"
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="Porcentaje"
                            placeholder="10"
                            value={valor}
                            name="valor"
                            onChange={this.handleChange}
                            errors={errors}
                            type="number"
                        />
                    </div>
                </div>

                <div className="row justify-content-center px-md-2 px-1 mb-md-5">

                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="Desde"
                            placeholder="01/01/2021"
                            type="date"
                            value={fecha_creacion}
                            name="fecha_creacion"
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="Hasta"
                            placeholder="01/01/2021"
                            type="date"
                            value={fecha_expiracion}
                            name="fecha_expiracion"
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                </div>

                <div className="row justify-content-center px-md-2 px-1 mb-2 mt-4">
                    <div className="col-12 mb-2 d-flex justify-content-end">
                        <button className="btn btn-rioclaro" onClick={this.handleSubmit}>Guardar</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    discount: state.discount,
    auth: state.auth,
    drawer: state.drawer,
})
const mapDispatchToProps = (dispatch) => ({
    editDiscount: (data, id, token) => dispatch(doEditDiscount(data, id, token)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING())
})
export default connect(mapStateToProps, mapDispatchToProps)(EditDiscount)