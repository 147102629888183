import React, { Component } from 'react';
import history from '../../services/history';

import NavProfile from '../../components/Navs/Nav_Profile';
import { validateScreens } from '../../utils/breakpoints';

class LayoutTwo extends Component {

    state = {
        dimensions: { size: null },
        activeNav: history.location.pathname === '/perfil' ? true : false
    }

    componentDidMount() {
        history.listen((e) => {
            this.setState({  activeNav: e.pathname==='/perfil' ? true : false});
        });

        window.addEventListener('resize', this._handleResize);
        this._handleResize();
    }

    _handleResize = () => {
        if (validateScreens(window.innerWidth, 'md')) {
            this.setState({dimensions: {size: 'md'}})
        } else {
            this.setState({dimensions: {size: 'lg'}})
        }
    }

    render(){
        const { activeNav, dimensions } = this.state;
        return(
            <div className="row bg-i-gray layout-two py-3" style={{minHeight:'100vh'}}>
			<div className="col-12">
				<div className="container-lg px-0">
					<div className="row d-none d-md-flex">
						<div className="col-12 col-md-3 py-5">
							<NavProfile/>
						</div>
						<div className="col-12 col-md-9">
							{ dimensions.size==='lg' ? this.props.children : null}
						</div>
					</div>
					<div className="row d-flex d-md-none">
						<div className="col-12 pb-5 px-5">
							{ activeNav ? <NavProfile/> : (dimensions.size==='md' ? this.props.children : null) }
						</div>
					</div>
				</div>
			</div>
		</div>
        )
    }
}

export default LayoutTwo;
