import React from 'react';
import PageWrapper from '../../components/Common/Page_Wrapper';
import { Component } from 'react';
import { getTextList } from '../../redux/actions/text';
import { connect } from 'react-redux';
import history from '../../services/history';
const linkApi = process.env.REACT_APP_ENV_API_URL
class Terms extends Component {

    constructor(props) {
        super(props)
        this.state = {
            text: {
                nombre: "",
                id: -1,
                texto: ""
            },
            banner: {}
        }
    }

    init() {
        const { text, banners } = this.props
        const currentPage = text.data.find((t) => t.nombre === window.location.pathname);
        const currentBanner = banners.data.find((b) => b.pagina === window.location.pathname);
        this.setState({ text: currentPage, banner: currentBanner })
    }

    async componentDidMount() {
        await this._showTextsList();
        this.init();
    }

    _showTextsList = async () => {
        const { getTexts } = this.props
        await getTexts(history.location.search)
    }

    render() {
        return (<PageWrapper title={this.state.banner?.texto} image={this.state.banner?.imagen_path === undefined ? null : `${linkApi}/imagenes/banners/${this.state.banner?.imagen_path[0]}`}>
            <div className="font-weight-light" style={{ fontSize: '.8em', letterSpacing: 1 }} dangerouslySetInnerHTML={{ __html: this.state.text?.texto }}>

            </div>
        </PageWrapper>)
    }
}

const mapStateToProps = (state) => ({
    text: state.text,
    banners: state.banners
})
const mapDispatchToProps = (dispatch) => ({
    getTexts: (data) => dispatch(getTextList(data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(Terms)
