import { Component } from "react";
import { v4 } from 'uuid'
class Select extends Component {
    constructor(props) {
        super(props)
        this.state = {
            ref: ''
        }
    }

    componentDidMount() {
        this.setState({ ref: v4() })
    }

    render() {
        const { value, data, label, onChange, id, errors, name, customClassName, disabled, ...rest } = this.props
        const { ref } = this.state
        return (
            <div className="w-100">
                {label && (
                    <label htmlFor={id || ref} className="form-label">{label}</label>
                )}
                <select
                    disabled={disabled}
                    className={customClassName ? customClassName : (errors && Object.keys(errors).find((key) => key === name) ? "form-control border border-danger text-danger" : "form-control")}
                    value={value || -1}
                    onChange={onChange}
                    name={name}
                    id={id || ref}
                    {...rest}
                >
                    <option value={-1}>Elija una opcion</option>
                    {data && data.map((item) => (
                        <option key={`${item.id}-${item.value}`} value={item.id}>{item.value}</option>
                    ))}
                </select>
                {errors && errors[name] && errors[name].length > 0 && (
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {errors[name].map((error) => (
                            <div className="invalid" key={v4()}>
                                {error}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        )
    }
}

export default Select