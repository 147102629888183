import { handleActions } from 'redux-actions'
import { fetch_error_texts, fetch_start_texts, fetch_success_texts } from "../actions/text";

export const INITIAL_STATE = {
    data: [],
    last_page: null,
    loading: false,
    success: false,
    error: null
};

export default handleActions({
    [fetch_start_texts]: (state) => {
        return {...state, loading: true};
    },
    [fetch_success_texts]: (state, action) => {
        const {data, last_page} = action.payload;
        return {...state, loading: false, success: true, data, last_page};
    },
    [fetch_error_texts]: (state) => {
        return {...state, loading: false, error: true};
    }
}, INITIAL_STATE)