import React, { Component } from 'react';
import ProductList from '../../components/Products/Product_List';
import BannerTitle from '../../components/Banners/Banner_Title';
import FooterMain from '../../components/Footers/Footer_Main';
import { connect } from 'react-redux';
import { getProductsList } from '../../redux/actions/product';

const linkApi = process.env.REACT_APP_ENV_API_URL
class Products extends Component {
    constructor(props) {
        super(props)
        this.state = {
            banner: {
                id: -1,
                imagen_path: "",
                link: "",
                pagina: "",
                texto: ""
            }
        }
    }

    async init() {
        await this._showProductList();
        const { banners } = this.props
        const currentBanner = banners.data.find((t) => t.pagina === window.location.pathname);
        this.setState({ banner: currentBanner })
    }
    componentDidMount() {
        this.init()
    }

    _showProductList = async () => {
        const { getProducts } = this.props;
        await getProducts(false, 1, 15);
    }

    render() {
        return (
            <>
                <BannerTitle image={(this.state.banner === undefined || this.state.banner.imagen_path === "") ? null : `${linkApi}/imagenes/banners/${this.state.banner.imagen_path[0]}`} title={this.state.banner === undefined ? "PELLET" : this.state.banner?.texto}/>
                {this.props.products.data.length > 0 && <ProductList />}
                <FooterMain main />
            </>
        )
    }
}


const mapStateToProps = (state) => ({
    auth: state.auth,
    loader: state.loader,
    banners: state.banners,
    products: state.products
});

const mapDispatchToProps = (dispatch) => ({
    getProducts: (data, page, pageSize) => dispatch(getProductsList(data, page, pageSize))
})

export default connect(mapStateToProps, mapDispatchToProps)(Products);