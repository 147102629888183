import { createAction } from 'redux-actions';

export const OPEN_DRAWER = createAction('OPEN_DRAWER');
export const CLOSE_DRAWER = createAction('CLOSE_DRAWER');
export const SET_RENDER = createAction('SET_RENDER');
export const CLEAR_RENDER = createAction('CLEAR_RENDER');

export const BACK_RENDER = (drawer) => async (dispatch) => {
    dispatch(CLOSE_DRAWER(drawer.RENDER))
    dispatch(SET_RENDER(drawer.LAST_RENDERED))
    await new Promise((resolve, reject) => {
        setTimeout(() => {
            dispatch(OPEN_DRAWER())
            resolve()
        }, 500)
    })
}