import { createAction } from 'redux-actions'
import { GetFile, UploadFile } from '../../services/api/endpoints';

export const fetch_start_file = createAction('fetch_start_file');
export const fetch_success_file = createAction('fetch_success_file');
export const fetch_error_file = createAction('fetch_error_file');

export const doGetFilePath = (path) => async (dispatch) => {
    dispatch(fetch_start_file());
    try {
        const response = await GetFile(path);
        console.log(response, 'RESPUESTA DE ARCHIVO');
        const { data } = response;
        dispatch(fetch_success_file({ data }))
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_file());
    }
};

export const doUploadFile = (params, token) => async (dispatch) => {
    dispatch(fetch_start_file());
    try {
        const response = await UploadFile(params, token);
        console.log(response, 'REPUESTA DE ARCHIVO SUBIDO');
        console.log(response.archivo);
        dispatch(fetch_success_file(response))
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_file());
        return false;
    }
}