import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logout } from '../../redux/actions/auth';
import Desktop from './Desktop';
import Mobile from './Mobile';

class Checkout extends Component {
    componentDidMount() {
        const { auth, logout } = this.props
        if (auth?.session?.usuario?.id_rol !== undefined) {
            logout();
        }
    }
    render() {
        return (
            <>
                <div className="d-none d-sm-block">
                    <Desktop />
                </div>
                <div className="d-block d-sm-none">
                    <Mobile />
                </div>
            </>
        )
    }
}
const mapStateToProps = (state) => ({
    auth: state.auth
});

const mapDispatchToProps = dispatch => ({
    logout: (data) => dispatch(logout(data)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
