import { handleActions } from 'redux-actions';
import { fetch_start_login, fetch_error_login, fetch_success_login, update_session, logout, clean_fetch } from '../actions/auth';
import { updateGlobalState } from '../../services/session';

export const INITIAL_STATE = {
    loading: false,
    error: null,
    success: false,
    session: null,
    role: null,
}

export default handleActions({
    [fetch_start_login]: (state) => {
        return {...state, loading: true}
    },
    [fetch_error_login]: (state, action) => {
        return {...state, loading: false, error: action.payload}
    },
    [fetch_success_login]: (state, action) => {
        let { token, usuario, variables } = action.payload;
        let n_state = {...state, success: true, loading: false, error: false, session: {token, usuario, variables}};
        updateGlobalState('auth', n_state);
        return n_state;
    },
    [update_session]: (state, action) => {
        let n_state = { ...state, session: { usuario: {...state.session.usuario, ...action.payload.user}, token: state.session.token,  variables: {...state.session.variables, ...action.payload.variables}} };
        updateGlobalState('auth', n_state);
        return n_state
    },
    [logout]: (state) => {
        let n_state = { ...state, success: true, loading: false, error: false, session: null};
        updateGlobalState('auth', n_state);
        return n_state
    },
    [clean_fetch]: (state) => {
        return {...state, loading: false, error: null, success: false}
    }
}, INITIAL_STATE);
