import { handleActions } from 'redux-actions';
import { show } from '../actions/authModal';

export const INITIAL_STATE = {
    visible: false,
}

export default handleActions({
    [show]: (state) => {
        return {...state, visible: !state.visible}
    },
}, INITIAL_STATE);
