import React from 'react';

const SimpleCollapse = (props) => {
	const addClass= props.addClass ? props.addClass : '';
	const idParent= props.parend ? props.parend : '';
	const expanded= props.expanded ? props.expanded : false;
	let addAttr = {}
	if (idParent) {
		addAttr= {
			'aria-expanded':expanded,
			'data-parent':idParent
		}
	}
	return(
		<div className={`collapse ${addClass}`} id={`${props.idCollapse}`} {...addAttr}>
			{props.children}
		</div>
	);
};

export default SimpleCollapse;