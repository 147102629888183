import { Component } from "react";
import { connect } from "react-redux";
import { CLEAR_RENDER, OPEN_DRAWER, SET_RENDER } from "../../../redux/actions/drawer";
import { SET_CONTRACT } from "../../../redux/actions/contract";
import '../../../styles/component.admin.actions.scss'
import EditContract from "../Contracts/EditContract";
import { Notification } from "rsuite";

class ContractAction extends Component {

    hasPermission = () => {
        return JSON.parse(sessionStorage.getItem('modules'))['textos'].edit;
    }

    handleEdit = () => {
        const { OPEN_DRAWER, SET_RENDER, CLEAR_RENDER, SET_CONTRACT, item } = this.props
        if (!this.hasPermission()) {
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." });
            return;
        }
        SET_CONTRACT(item)
        CLEAR_RENDER()
        SET_RENDER(EditContract)
        OPEN_DRAWER()
    }

    render() {
        return (
            <div className="container-categories-actions">
                <i dataToggle="tooltip" title="Editar" className="fa fa-pencil" onClick={this.handleEdit}></i>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => ({
    OPEN_DRAWER: () => dispatch(OPEN_DRAWER()),
    CLEAR_RENDER: () => dispatch(CLEAR_RENDER()),
    SET_RENDER: (component) => dispatch(SET_RENDER(component)),
    SET_CONTRACT: (payload) => dispatch(SET_CONTRACT(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ContractAction)