import { Button, Modal } from "rsuite";

const Dialogs = ({ isOpen, handleClose, handleAcept, message, title }) => {
    return (
        <div className="modal-container">
            <Modal backdrop="static" role="alertdialog" show={isOpen} onClose={handleClose} size="xs">
                {title && <Modal.Title>{title}</Modal.Title>}
                <Modal.Body style={{ whiteSpace: "pre-line" }}>
                    {message}
                </Modal.Body>
                <Modal.Footer>
                    {handleAcept &&
                        <Button onClick={handleAcept} appearance="primary">
                            Aceptar
                        </Button>
                    }

                    {handleClose &&
                        <Button onClick={handleClose} appearance="subtle">
                            Cancelar
                        </Button>
                    }
                </Modal.Footer>
            </Modal>
        </div>
    )
}
export default Dialogs;