import React, { useState, useEffect } from 'react';
import { Modal } from 'rsuite';

const ModalRsuite = props => {
    const [ show, setShow ] = useState(false);
    const {open, title, children, propsModal, idCall} = props;
    const close = () => {
        setShow(false);
        props.handleModal();
    }

    useEffect(() => {
        setShow(open);
    }, [open],)

    return (
        <Modal show={show} onHide={close} {...propsModal} id={idCall}>
            <Modal.Header>
                <Modal.Title>{title ?? null}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}

export default ModalRsuite;