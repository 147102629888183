import React from 'react';
import { Radio, RadioGroup } from 'rsuite';
import SelectSimple from '../../../components/Selects/Select_Simple';

const Voucher = ({ inputs, handleInput, handleRut, handlePhone, auth: { session } }) => {
	return (
		<>
			<div className="col-12 title-page">
				<strong>Tus Datos</strong>
				<hr className="mt-1" />
			</div>
			<div className="form-group mb-0 col-12">
				<label>Correo electrónico</label>
				<input value={inputs.email} name="email" type="text" className="form-control" onChange={handleInput} disabled={session ? true : false} />
			</div>
			<div className="form-group mb-0 col-6">
				<label>Nombre</label>
				<input value={inputs.name} name="name" type="text" className="form-control" onChange={handleInput} disabled={session ? true : false} />
			</div>
			<div className="form-group mb-0 col-6">
				<label>Apellido</label>
				<input value={inputs.last_name} name="last_name" type="text" className="form-control" onChange={handleInput} disabled={session ? true : false} />
			</div>
			<div className="form-group mb-0 col-6">
				<label>RUT (ej: 11111111-1)</label>
				<input value={inputs.rut} name="rut" type="text" className="form-control" onChange={handleRut} disabled={session ? true : false} autoComplete="off" />
			</div>
			<div className="form-group mb-0 col-6">
				<label>Teléfono</label>
				<input value={inputs.phone} name="phone" type="text" className="form-control" onChange={handlePhone} disabled={session ? true : false} autoComplete="off" />
			</div>
		</>
	);
}
const Bill = ({ inputs, handleInput, handleRegion, communes, listRegions, handleRut, handlePhone, auth: { session } }) => {
	return (
		<>
			<div className="col-12 title-page mt-2">
				<strong>Datos para facturar</strong>
				<hr className="mt-1" />
			</div>
			<div className="form-group mb-0 col-6">
				<label>Razón social o nombre</label>
				<input value={inputs.bill_business_name} name="bill_business_name" type="text" className="form-control" onChange={handleInput} disabled={session && session.usuario.nombre_empresa ? true : false} />
			</div>
			<div className="form-group mb-0 col-6">
				<label>Giro</label>
				<input value={inputs.bill_turn} name="bill_turn" type="text" className="form-control" onChange={handleInput} disabled={session && session.usuario.giro ? true : false} />
			</div>
			<div className="form-group mb-0 col-6">
				<label>RUT tributario</label>
				<input value={inputs.bill_rut} name="bill_rut" type="text" className="form-control" onChange={handleRut} autoComplete="off" disabled={session && session.usuario.rut_empresa ? true : false} />
			</div>
			<div className="form-group mb-0 col-6">
				<label>Teléfono de oficina</label>
				<input value={inputs.bill_phone} name="bill_phone" type="text" className="form-control" onChange={handlePhone} autoComplete="off" disabled={session && session.usuario.telefono_empresa ? true : false} />
			</div>
			<div className="col-12 title-page mt-2">
				<strong>Dirección legal</strong>
				<hr className="mt-1" />
			</div>
			<div className="form-group mb-1 col-6">
				<label>Región</label>
				<SelectSimple
					type="simple"
					data={listRegions.map(x => ({ value: x.id, label: x.region_nombre }))}
					className="form-select"
					name="bill_region_id"
					placeholder="Región"
					runChange={e => handleRegion(e, listRegions)}
					initValue={inputs.bill_region_id}
					disabled={session && session.usuario.id_region_empresa ? true : false}
				/>
			</div>
			<div className="form-group mb-1 col-6">
				<label>Comuna</label>
				<SelectSimple
					type="simple"
					data={communes}
					className="form-select"
					name="bill_commune_id"
					placeholder="Comunas"
					runChange={handleInput}
					initValue={inputs.bill_commune_id}
					disabled={session && session.usuario.id_comuna_empresa ? true : false}
					alphabetically
				/>
			</div>
			<div className="form-group mb-1 col-12">
				<label>Dirección</label>
				<input value={inputs.bill_address_description} name="bill_address_description" type="text" className="form-control" onChange={handleInput} disabled={session && session.usuario.direccion_empresa ? true : false} />
			</div>
		</>
	);
}

const StepClientData = (props) => {
	const { inputs, handleInput } = props;
	return (
		<div className="py-3">
			<div className="form-chekout row">
				<div className="form-group mb-0 col-12">
					<RadioGroup
						name="bill" inline className="type-checkbox"
						value={inputs.bill}
						onChange={(value) => {
							handleInput({
								target: {
									name: 'bill',
									value
								}
							})
						}}>
						<Radio value={false} className="col">Boleta</Radio>
						<Radio value={true} className="col">Factura</Radio>
					</RadioGroup>
				</div>
				<Voucher {...props} />
				{inputs.bill ? <Bill {...props} /> : null}
			</div>
		</div>
	)
}

export default StepClientData;