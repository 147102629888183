const MenuLeft = [
    {
        label: 'Repartos',
        to: '/admin/dashboard/router',
        path: '/admin/dashboard/router',
        icon: 'fa fa-truck',
        name: "repartos",
        selected: false,
        roles: [],
    }, {
        label: 'Productos',
        to: '/admin/dashboard/products',
        path: '/admin/dashboard/products',
        icon: 'fa fa-cubes',
        name: 'productos',
        selected: false,
        roles: [],
    },
    {
        label: 'Categorías',
        to: '/admin/dashboard/categories',
        path: '/admin/dashboard/categories',
        icon: 'fa fa-tags',
        name: 'categorias',
        selected: false,
        roles: [],
    },
    {
        label: 'Descuentos',
        to: '/admin/dashboard/discounts',
        path: '/admin/dashboard/discounts',
        icon: 'fa fa-percent',
        name: 'descuentos',
        selected: false,
        roles: [],
    },
    {
        label: 'Cobertura',
        to: '/admin/dashboard/cobertura',
        path: '/admin/dashboard/cobertura',
        icon: 'fa fa-globe',
        name: 'cobertura',
        selected: false,
        roles: [],
    },
    {
        label: 'Banners',
        to: '/admin/dashboard/banners',
        path: '/admin/dashboard/banners',
        icon: 'fa fa-cog',
        name: 'banners',
        selected: false,
        roles: [],
    },
    {
        label: 'Textos Legales',
        to: '/admin/dashboard/contracts',
        path: '/admin/dashboard/contracts',
        icon: 'fa fa-file-text',
        name: 'textos',
        selected: false,
        roles: [],
    }, {
        label: 'Roles',
        to: '/admin/dashboard/users',
        path: '/admin/dashboard/users',
        icon: 'fa fa-user',
        name: 'roles',
        selected: false,
        roles: [],
    }
];

const MenuAccount = [
    {
        label: 'Cerrar Sesión',
        to: '/admin/dashboard/exit',
        path: '/admin/dashboard/exit',
        icon: 'fa fa-arrow-circle-o-right',
        name: 'exit',
        selected: false,
        roles: [],
    },
    {
        label: 'Autorización Gmail (OAuth 2.0)',
        to: '/admin/dashboard/OAuth',
        path: '/admin/dashboard/OAuth',
        icon: 'fa fa-envelope-o',
        name: 'OAuth',
        selected: false,
        roles: [],
    },
];

const MenuMobile = [
    {
        label: 'Repartos',
        to: '/admin/dashboard/router',
        path: '/admin/dashboard/router',
        icon: 'fa fa-truck',
        name: "repartos",
        selected: false,
        roles: [],
    }, {
        label: 'Productos',
        to: '/admin/dashboard/products',
        path: '/admin/dashboard/products',
        icon: 'fa fa-cubes',
        name: 'productos',
        selected: false,
        roles: [],
    },
    {
        label: 'Categorías',
        to: '/admin/dashboard/categories',
        path: '/admin/dashboard/categories',
        icon: 'fa fa-tags',
        name: 'categorias',
        selected: false,
        roles: [],
    },
    {
        label: 'Cobertura',
        to: '/admin/dashboard/cobertura',
        path: '/admin/dashboard/cobertura',
        icon: 'fa fa-institution',
        name: 'cobertura',
        selected: false,
        roles: [],
    },
    {
        label: 'Descuentos',
        to: '/admin/dashboard/discounts',
        path: '/admin/dashboard/discounts',
        icon: 'fa fa-percent',
        name: 'descuentos',
        selected: false,
        roles: [],
    },
    {
        label: 'Banners',
        to: '/admin/dashboard/banners',
        path: '/admin/dashboard/banners',
        icon: 'fa fa-cog',
        name: 'banners',
        selected: false,
        roles: [],
    },
    {
        label: 'Textos Legales',
        to: '/admin/dashboard/contracts',
        path: '/admin/dashboard/contracts',
        icon: 'fa fa-file-text',
        name: 'textos',
        selected: false,
        roles: [],
    },
    {
        label: 'Roles',
        to: '/admin/dashboard/users',
        path: '/admin/dashboard/users',
        icon: 'fa fa-user',
        name: 'roles',
        selected: false,
        roles: [],
    },
    {
        label: 'Cerrar Sesión',
        to: '/admin/dashboard/exit',
        path: '/admin/dashboard/exit',
        icon: 'fa fa-arrow-circle-o-right',
        name: 'exit',
        selected: false,
        roles: [],
    }
]

export { MenuAccount, MenuLeft, MenuMobile }
