import { useEffect, useState } from "react"
import '../../../styles/component.admin.title.scss'
const Title = (props) => {
    const [size, setSize] = useState('1.4em')
    const Component = props.component
    useEffect(() => {
        switch (props.size) {
            case 'sm':
                setSize('1.1em');
                break;
            case 'md':
                setSize('1.3em')
                break;
            default:
                setSize('1.4em')
        }
    }, [props.size])
    return (
        <div className={props.noBorder ? 'container-title no-border' : 'container-title'} style={{ ...props.style, fontSize: size }}>
            {props.text} {Component ? <Component /> : ''}
        </div>
    )
}

export default Title