import { createAction } from 'redux-actions'
import { Formatos, CreateFormato } from '../../services/api/endpoints';

export const fetch_start_formatos = createAction('fetch_start_formatos');
export const fetch_success_formatos = createAction('fetch_success_formatos');
export const fetch_error_formatos = createAction('fetch_error_formatos');

export const getFormatosList = (id_categoria) => async (dispatch) => {
    dispatch(fetch_start_formatos());
    try {
        const response = await Formatos(id_categoria);
        console.log(response, 'RESPUESTA DE LISTADO DE FORMATOS');
        const { data } = response;
        dispatch(fetch_success_formatos({ data }))
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_formatos());
    }
};

export const doCreateFormato = (params, token) => async (dispatch) => {
    dispatch(fetch_start_formatos());
    try {
        await CreateFormato(params, token);
        dispatch(getFormatosList({}));
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_formatos());
    }
}
