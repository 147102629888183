import { createStore, combineReducers, applyMiddleware } from 'redux';
import {
  address,
  auth,
  authModal,
  cart,
  checkout,
  detail,
  payment,
  products,
  profile,
  register,
  loader,
  drawer,
  banner,
  product,
  category,
  discount,
  role,
  contract,
  categories,
  discounts,
  text,
  banners,
  formato,
  actionsRoles,
  despacho
} from '../reducers';

import thunk from 'redux-thunk';

var intialState = {};
try {
  intialState = sessionStorage.getItem("global_state") ? JSON.parse(sessionStorage.getItem("global_state")) : {};
} catch (error) {
  console.log('getError', error)
}

const reducers = combineReducers({
  address,
  auth,
  authModal,
  cart,
  checkout,
  detail,
  payment,
  products,
  profile,
  register,
  loader,
  drawer,
  banner,
  product,
  category,
  discount,
  role,
  contract,
  categories,
  discounts,
  text,
  banners,
  formato,
  actionsRoles,
  despacho
});

const saver = (store) => (next) => (action) => {
  let stateToSave = store.getState();
  sessionStorage.setItem("global_state", JSON.stringify({ ...stateToSave }))
  next(action);
}

const store = createStore(reducers, intialState, applyMiddleware(thunk, saver));

export default store;