import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import CartItem from '../../components/Carts/Cart_Items';
import FooterMain from '../../components/Footers/Footer_Main';
import FooterTerms from '../../components/Footers/Footer_Terms';
import DiscountForm from '../../components/Discounts/Discount_Form';
import { get_currency } from '../../utils/currency';

class Cart extends Component {
    render(){
        const { cart } = this.props;
        return(
            <>
                <div className="bg-i-gray"  style={{ minHeight: '100vh' }}>
                    <div className="container py-4 px-4">
                        <p className="font-weight-light">Inicio / Carro de compras</p>
                        <div className="row">
                            <div className="col-12 col-sm-8">
                                <CartItem products={cart.items}/>
                            </div>
                            <div className="col-12 col-sm-4">
                                <div className="p-4 mb-2 bg-white rounded d-block d-sm-none">
                                    <p className="font-weight-bold mb-1" style={{fontSize: '.9em'}}>Modo de entrega</p>
                                    <hr />
                                    {
                                        cart.items.map((item, i) => {
                                            return (
                                                <div className="row mt-2" key={`product-mobile-item-${i}`}>
                                                    <div className="col-7">
                                                        <p className="mb-0 font-weight-bold">RÍO CLARO</p>
                                                        <p className="font-weight-light" style={{fontSize: '.8em'}}>{item.nombre_formato}</p>
                                                    </div>
                                                    <div className="col-5 text-right">
                                                        <p className="mb-1" style={{fontSize: '.8em'}}>{item.shipping === 1 ? 'Retiro en tienda' : 'Despacho'}</p>
                                                        <p className="font-weight-light" style={{fontSize: '.8em'}}>{item.shipping === 1 ? `Sucursal ${cart.shipping.sucursal.nombre_punto_venta}` : `Comuna de ${cart.shipping.comuna.nombre_comuna}`}</p>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                { cart.total_amount_items ? (<DiscountForm/>) : null }
                                <div className="p-4 mb-2 bg-white rounded">
                                    <p className="font-weight-bold text-center mb-4" style={{fontSize: '.9em'}}>Resumen de pedido</p>
                                    <div className="w-100 bg-light my-2" style={{height: 1}}/>
                                        <div className="row mb-2">
                                            <div className="col-6">
                                                <p className="font-weight-bold mb-0" style={{fontSize: '.8em'}}>Valor neto</p>
                                            </div>
                                            <div className="col-6 d-flex justify-content-end">
                                                <p className="font-weight-bold mb-0" style={{fontSize: '.8em'}}>{get_currency(cart.total_amount_payment)}</p>
                                            </div>
                                        </div>
                                    {
                                        cart.discount_code ? (
                                            <>
                                            <div className="row mb-2">
                                                <div className="col-7">
                                                    <p className="font-weight-bold mb-0 text-dark" style={{fontSize: '.8em'}}>{`Descuento cupón ${cart.total_discount_coupon}%`}</p>
                                                </div>
                                                <div className="col-5 d-flex justify-content-end">
                                                    <p className="font-weight-bold mb-0 text-orange" style={{fontSize: '.8em'}}>{`${get_currency(cart.total_amount_payment/cart.total_discount_coupon)}`}</p>
                                                </div>
                                            </div>
                                            </>
                                        ) : null
                                    }
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <p className="font-weight-bold mb-0" style={{fontSize: '.8em'}}>Subtotal</p>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <p className="font-weight-bold mb-0" style={{fontSize: '.8em'}}>{get_currency(Math.round(cart.total_amount_payment-(cart.total_amount_payment*(cart.total_discount_coupon/100))))}</p>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <p className="font-weight-bold mb-0" style={{fontSize: '.8em'}}>IVA</p>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <p className="font-weight-bold mb-0" style={{fontSize: '.8em'}}>{get_currency(Math.round(0.19*(cart.total_amount_payment-(cart.total_amount_payment*(cart.total_discount_coupon/100)))))}</p>
                                        </div>
                                    </div>
                                    <div className="row mb-2">
                                        <div className="col-6">
                                            <p className="font-weight-bold mb-0 text-dark" style={{fontSize: '.8em'}}>Total a pagar</p>
                                        </div>
                                        <div className="col-6 d-flex justify-content-end">
                                            <p className="font-weight-bold mb-0 text-orange" style={{fontSize: '.8em'}}>{get_currency(Math.round(1.19*Math.round(cart.total_amount_payment-(cart.total_amount_payment*(cart.total_discount_coupon/100)))))}</p>
                                        </div>
                                    </div>
                                    {
                                        cart.total_amount_items ? (
                                            <Link to="/finalizar-compra" className="btn btn-success w-100 font-weight-bold" style={{fontSize: '.8em'}}>
                                                Finalizar la compra
                                            </Link>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </div>
                        <FooterTerms />
                    </div>
                </div>
                <FooterMain main/>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    cart: state.cart
});


export default connect(mapStateToProps)(Cart);