import React from 'react';

class SetupForm extends React.Component {

    componentDidMount = () => {
        this.inputElement.click();
    }

    render() {
        const { url, token } = this.props;
        return (
            <form method="post" action={url} style={{ display: 'none' }}>
                <input type="hidden" name="token_ws" value={token} />
                <input ref={input => this.inputElement = input} type="submit" value="Ir a pagar" />
            </form>
        );
    }
}

export default SetupForm;