import { handleActions } from 'redux-actions'
import { SET_PRODUCT } from '../actions/product_admin'


export const INITIAL_STATE = {
    descripcion_corta: "",
    id: -1,
    id_categoria: -1,
    imagenes: [],
    nombre: "",
    product_relacionados: [],
    productos_relacionados: "",
    sku: ""
}

export default handleActions({
    [SET_PRODUCT]: (state, action) => {
        return { ...state, ...action.payload }
    }
}, INITIAL_STATE)