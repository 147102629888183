import React from 'react';

const ContentTitle = (props) => {
    const {title} = props;
    return (
        <>
            <div className="row mb-1">
                <div className="col-8 p-0">
                    <p className="font-weight-bold mb-1">{title}</p>
                </div>
                <div className="col-4 p-0 d-flex justify-content-end">
                    <p className="font-weight-bold mb-1"></p>
                </div>
            </div>
            <div className="row">
                <div className="col-3 p-0" style={{ borderBottom: '2px solid #f04e28' }}/>
                <div className="col-9 p-0 border-bottom" />
            </div>
        </>
    )
}

export default ContentTitle;