import { Component, Fragment } from "react";
import { connect } from "react-redux";
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import history from '../../../services/history'
import { MenuAccount as MenuAccountData } from '../MenuItems'
import '../../../styles/DashboardStyles/MenuAccount.scss'
import { update_session } from '../../../redux/actions/auth';

class MenuAccount extends Component {

    constructor(props) {
        super(props)
        this.state = {
            pathname: '',
            data: []
        }
    }

    componentDidMount() {
        const { location } = history
        const { pathname } = location
        this.setState({ data: MenuAccountData }, () => {
            const { data } = this.state
            const updatedData = data.map((item) => {
                if (item.path === pathname) {
                    item.selected = true
                    return item;
                }
                item.selected = false
                return item
            })
            this.setState({ data: updatedData, pathname })
        })
    }

    push = (to) => {
        const { CLOSE_DRAWER, drawer } = this.props
        const { data } = this.state
        data.map((item) => {
            if (item.path === to) {
                item.selected = true
                return item
            }
            item.selected = false
            return item
        })

        this.setState({ data })
        CLOSE_DRAWER(drawer.RENDER);
        history.push(to)
    }

    render() {
        const { data } = this.state
        const { auth } = this.props
        return (
            <div className="container-menuaccount">
                <ul>
                    {data && data.map((item) => (
                        auth.session.usuario.id_rol === 1 ? (
                            <Fragment key={item.name}>
                                <li className={item.selected ? 'selected' : ''} onClick={() => this.push(item.to)}>
                                    <i className={item.icon}></i>
                                    {item.label}
                                </li>
                            </Fragment>
                        ) : (
                            item.name !== 'OAuth' && (
                                <Fragment key={item.name}>
                                    <li className={item.selected ? 'selected' : ''} onClick={() => this.push(item.to)}>
                                        <i className={item.icon}></i>
                                        {item.label}
                                    </li>
                                </Fragment>
                            )
                        )
                    ))}

                </ul>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    drawer: state.drawer
})
const mapDispatchToProps = (dispatch) => ({
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    updateSession: (data) => dispatch(update_session(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MenuAccount)