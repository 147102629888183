import { handleActions } from "redux-actions";
import { fetch_error_despachos, fetch_start_despachos, fetch_success_despachos } from "../actions/despacho";

export const INITIAL_STATE = {
    data: [],
    last_page: null,
    loading: false,
    success: false,
    error: null
}

export default handleActions({
    [fetch_start_despachos]: (state) => {
        return {...state, loading: true};
    },
    [fetch_success_despachos]: (state, action) => {
        const {data, last_page} = action.payload;
        return {...state, loading: false, success: true, data, last_page};
    },
    [fetch_error_despachos]: (state) => {
        return {...state, loading: false, error: true};
    }
}, INITIAL_STATE);