import { Component } from 'react'
import Title from '../../../components/Admin/Title'
import Table from '../../../components/Admin/Table'
import { Loader } from "@googlemaps/js-api-loader"
import Images from '../../../assets/image'
import CardMaps from '../../../components/Admin/CardMaps'
import { Notification } from 'rsuite'
import '../../../styles/page.admin.routes.scss'
import GetPhoto from '../../../components/Admin/GetPhoto'
import ImageView from '../../../components/Admin/ImageView'
import { getDespachosList } from '../../../redux/actions/despacho'
import { END_LOADING, START_LOADING } from '../../../redux/actions/loader'
import { connect } from 'react-redux'
import { Despachos, doCreateDespacho, ListStores, Truckers, Trucks } from '../../../services/api/endpoints'
import Select from '../../../components/Admin/Select'
import Input from '../../../components/Admin/Input'
import { dgv, getRandomString, _formatRut } from '../../../utils/helpers'
import Dialogs from '../../../components/Admin/Dialogs';
import moment from 'moment'

const headers = [
    { name: 'Pedido', target: 'id' },
    { name: 'Dirección', target: 'address' },
    { name: 'Camión', target: 'camion_patente' },
    { name: 'Chofer', target: 'chofer' },
    { name: 'DTE', target: 'dte' },
    { name: 'Fecha', target: 'fecha_despacho' },
    { name: 'Ruta', target: 'ruta' },
    { name: 'Rut', target: 'rut_cliente' },
    { name: 'Cliente', target: 'cliente' },
    { name: 'Contacto', target: 'contacto' },
    { name: 'Teléfono', target: 'telefono' },
    { name: 'Estado', target: 'status' },
    { name: 'Acciones', target: 'action' }
]
class Routes extends Component {

    constructor(props) {
        super(props)
        this.state = {
            inputs: {
                origin: 'Forestal Rio Claro Limitada - Ruta 5 S 266, Talca, Maule',
                destination: 'Forestal Rio Claro Limitada - Ruta 5 S 266, Talca, Maule',
                status_filter: "1",
                start_date: moment().startOf('month').format('YYYY-MM-DD'),
                end_date: moment().endOf('month').format('YYYY-MM-DD'),
                id_truck: "",
                id_trucker: "",
                id_shop: ""
            },
            page: 1,
            data: {
                headers: [],
                items: []
            },
            requestData: [],
            waypoints: [],
            waypointsWithId: [],
            map: null,
            google: null,
            directionsRenderer: null,
            directionsService: null,
            directionsResponse: null,
            stepDisplay: null,
            modal: {
                showModal: false,
                data: {}
            },
            camera: {
                value: null,
                show: false
            },
            imageView: false,
            trucks: [],
            truckers: [],
            stores: [],
            showMap: false,
            modalData: <></>,
            disableIndputsModal: false,
            validIcons: {},
            onRouteNum: 0,
            waypointsWithLocation: [],
            markers: []
        }
        this.invalidIconsRef = []
        this.setinvalidIconsRef = (element, index) => {
            this.invalidIconsRef[index] = element
        };
    }

    componentDidMount() {
        this.loadTrucks();
        this.getCurrentLocation()
        this.loadInitialData({
            "fecha_inicio": moment().startOf('month').format('YYYY-MM-DD'),
            "fecha_fin": moment().endOf('month').format('YYYY-MM-DD'),
            "id_conductor": "",
            "id_punto_venta": "",
            "id_camion": "",
            "id_estado": "1"
        })
    }

    async loadTrucks() {
        try {
            const { auth, START_LOADING } = this.props
            START_LOADING()
            const trucks = (await Trucks(auth.session.token)).map(t => ({ id: t.id_camion, value: t.nombre }))
            const truckers = (await Truckers(auth.session.token)).map(t => ({ id: t.id_conductor, value: t.nombre }))
            const stores = (await ListStores()).data.map(t => ({ id: t.id, value: t.nombre }))
            const onRouteNum = (await Despachos(auth.session.token, {
                "fecha_inicio": moment().startOf('month').format('YYYY-MM-DD'),
                "fecha_fin": moment().endOf('month').format('YYYY-MM-DD'),
                "id_conductor": "",
                "id_punto_venta": "",
                "id_camion": "",
                "id_estado": "2"
            })).length;
            this.setState({ trucks, truckers, stores, onRouteNum })
        } catch (error) {
            console.log(error);
        }
    }

    getCurrentLocation() {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(({ coords }) => {
                const loader = new Loader({
                    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                    version: "weekly",
                    ...{},
                });
                loader.load().then(google => {
                    const geocoder = new google.maps.Geocoder()
                    geocoder.geocode({ location: new google.maps.LatLng(coords.latitude, coords.longitude) }, (data) => {
                        this.setState({ inputs: { ...this.state.inputs, origin: data[0].formatted_address } })
                    }, (error) => console.log(error));
                })
            });
        } else {
            console.log("Not Available");
        }
    }

    downloadGuide = (url, nombre) => {
        const a = document.createElement('a');
        a.href = url;
        a.download = nombre
        a.click();
    }

    loadInitialData = async (data) => {
        const { START_LOADING, END_LOADING } = this.props
        const loader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            version: "weekly",
            ...{},
        });
        const google = await loader.load();
        START_LOADING()
        const despachos = await Despachos(this.props.auth.session.token, data)
        if ((!despachos.data || despachos.data.length === 0) && Array.isArray(despachos)) {
            if (this.state.inputs.status_filter === "2") {
                this.setState({ onRouteNum: despachos.length })
                despachos.forEach(item => {
                    var geocoder = new google.maps.Geocoder()
                    geocoder.geocode({
                        address: `${item.direccion}, ${item.comuna_destino}, CHILE`,
                        componentRestrictions: {
                            country: 'CL',
                        }
                    }, (results, status) => this.verifyAddress(results, status, item.id_pedido, google, item.codigo_pedido))
                })
                setTimeout(() => {
                    END_LOADING()
                }, 1500);
                setTimeout(() => {
                    const items = [];
                    despachos.map((item, index) => this.castItemDespacho(item, index, items))
                    this.setState({ data: { items, headers }, waypoints: [], requestData: despachos })
                }, 1500);
            } else {
                const items = [];
                despachos.map((item, index) => this.castItemDespacho(item, index, items))
                this.setState({ data: { items, headers }, waypoints: [], requestData: despachos })
            }
        }
        this.state.inputs.status_filter !== "2" && END_LOADING()
    }

    castItemDespacho = async (item, index, items) => {
        delete item.latitud;
        delete item.longitud;
        const warning = { description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." };
        let action = "";
        if (this.state.inputs.status_filter === "1" || this.state.inputs.status_filter === undefined) {
            action = (<>
                <i dataToggle="tooltip" data-placement="left" title="Descargar guía de despacho" className="fa fa-download mr-2" onClick={() => this.downloadGuide(item.path_guia, item.nombre_documento)} />
                <i dataToggle="tooltip" data-placement="left" title="Pasar a reparto" className="fa fa-plus-circle mr-2" onClick={() => this.hasPermission() ? this.handleAddWaypoint(item.codigo_pedido, 1) : Notification.warning(warning)} />
                <i dataToggle="tooltip" data-placement="left" title="Mostrar detalles" className="fa fa-list" onClick={() => this.createModalData(item)} />
                {item.estado === "No Entregado" && <i dataToggle="tooltip" data-placement="left" title="Ver estado" className="fa fa-truck ml-2" onClick={() => this.hasPermission() ? this.setState({ modal: { showModal: true, data: item }, disableIndputsModal: true }) : Notification.warning(warning)}></i>}
            </>)
        } else if (this.state.inputs.status_filter === "2") {
            action = (
                <>
                    <i dataToggle="tooltip" data-placement="left" title="Descargar guía de despacho" className="fa fa-download mr-2" onClick={() => this.downloadGuide(item.path_guia, item.nombre_documento)}></i>
                    <i dataToggle="tooltip" data-placement="left" title="Pasar a no entregado" className="fa fa-minus-circle" onClick={() => this.hasPermission() ? this.handleAddWaypoint(item.codigo_pedido, 2) : Notification.warning(warning)} />
                    <i dataToggle="tooltip" data-placement="left" title="Editar estado" className="fa fa-truck ml-2" onClick={() => this.hasPermission() ? this.setState({ modal: { showModal: true, data: item }, disableIndputsModal: false }) : Notification.warning(warning)}></i>
                    <i key={Math.random() * 1000} className={`fa ${this.state.validIcons[item.id_pedido] ? 'fa-check' : 'fa-warning'} ml-2`} ref={(el) => this.setinvalidIconsRef(el, index)} id={`invalid-${item.id_pedido}`} dataToggle="tooltip" data-placement="left" title={`${this.state.validIcons[item.id_pedido] ? 'Válido' : 'Inválido'}`}></i>
                    <i dataToggle="tooltip" data-placement="left" title="Mostrar detalles" className="fa fa-list" onClick={() => this.createModalData(item)} />
                </>
            )
        } else if (this.state.inputs.status_filter === "3") {
            action = (<>
                <i dataToggle="tooltip" title="Ver estado" className="fa fa-truck ml-2" onClick={() => this.hasPermission() ? this.setState({ modal: { showModal: true, data: item }, disableIndputsModal: true }) : Notification.warning(warning)}></i>
            </>)
        }
        const {codigo_pedido, direccion, cliente, contacto, telefono, estado, camion_patente, chofer, dte, fecha_despacho, rut_cliente, ruta} = item;
        items.push({
            id: codigo_pedido,
            address: direccion,
            camion_patente,
            chofer,
            dte,
            fecha_despacho: moment(fecha_despacho).format('DD-MM-YYYY'),
            ruta,
            rut_cliente: _formatRut(`${rut_cliente}${dgv(rut_cliente)}`),
            cliente,
            contacto,
            telefono,
            status: estado,
            action
        })
    }

    createModalData = (item) => {
        const data = (
            <table className="table">
                <thead>
                    <tr>
                        <td className="text-center">Cantidad</td>
                        <td className="text-center">Producto</td>
                    </tr>
                </thead>
                <tbody>
                    {item.detalle.map(i => <>
                        <tr>
                            <td className="text-center">{i.cantidad_pack} x {i.cantidad_bolsas}</td>
                            <td className="text-center">{i.producto}</td>
                        </tr>
                    </>)}
                </tbody>
            </table>
        )
        this.setState({ ...this.state, productsDetails: data, showProductsDetails: true })
    }

    verifyAddress = (results, status, id, google, codigo_pedido) => {
        if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
            this.setState({ waypointsWithLocation: [...this.state.waypointsWithLocation, { id: codigo_pedido, location: results[0].geometry.location }] })
        }
        this.setState(prevState => {
            let validIcons = Object.assign({}, prevState.validIcons);
            validIcons[id] = status === google.maps.GeocoderStatus.OK && results.length > 0;
            return { validIcons };
        })
    }

    async _getDespachos(data) {
        const { getDespachos, auth } = this.props
        await getDespachos(auth.session.token, data);
    }

    componentDidUpdate() {
        console.log(this.state.waypoints);
    }

    nextPage = () => {
        if(this.hasPermission()){
            this.setState({ inputs: { ...this.state.inputs, status_filter: "2" } }, async () => {
                const { waypoints, data, waypointsWithId } = this.state
                const container = document.querySelector('.container-dashboard__content');
                let newWaypoint = null;
                let newWaypointWithId = null;
                if (data.items.length < 1) {
                    Notification['error']({
                        title: "Error",
                        description: "Es necesario agregar al menos una entrega para continuar..."
                    })
                    return
                }
                data.items.forEach((item) => {
                    const find = this.state.requestData.find(d => d.codigo_pedido === item.id)
                    const location = this.state.waypointsWithLocation.find(w => w.id === item.id);
                    newWaypoint = {
                        location,
                        address: `${item.address}, ${find.comuna_destino}, CHILE`,
                        stopover: true,
                        kilometros: find.kilometros
                    }
                    newWaypointWithId = { ...newWaypoint, id: item.id }
                    waypoints.push(newWaypoint)
                    waypointsWithId.push(newWaypointWithId)
                })
                this.setState({ waypoints, waypointsWithId, showMap: true })
                this.loadMapsData();
                container.scrollTo({ top: 0, behavior: 'smooth' });
            })
        }else{
            Notification.warning({ description: "Consulte con el administrador del sistema", title: "No tienes los permisos suficientes." })
        }
    }

    loadMapsData = (waypointsPin = false, removeWaypoint = undefined) => {
        const loader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            version: "weekly",
            ...{},
        });

        loader.load().then((google) => {
            const { inputs, waypoints } = this.state
            const Santiago = { lat: -33.45610959553399, lng: -70.64866411285843 };
            const directionsService = new google.maps.DirectionsService();
            const directionsRenderer = new google.maps.DirectionsRenderer({
                markerOptions: {
                    visible: false
                },
                suppressMarkers: true
            });
            const map = new google.maps.Map(document.getElementById("map"), {
                center: Santiago,
                zoom: 12,
            });
            directionsRenderer.setMap(map);
            const fartestWaypints = waypoints.sort((a, b) => Number(b.kilometros) - Number(a.kilometros));
            const wp = waypoints.filter(w => w !== fartestWaypints[0] && w !== removeWaypoint, fartestWaypints[0])
            console.log(wp);
            directionsService
                .route({
                    origin: {
                        query: inputs.origin,
                    },
                    destination: {
                        query: fartestWaypints[0]?.address,
                    },
                    waypoints: wp.map(w => ({ location: w.address, stopover: w.stopover })),
                    optimizeWaypoints: true,
                    travelMode: google.maps.TravelMode.DRIVING,
                    region: 'CL'
                })
                .then((response) => {
                    if (waypointsPin) {
                        this.setState({ directionsResponse: response }, () => {
                            directionsRenderer.setDirections(this.state.directionsResponseBackup);
                            this.showSteps(google, map)
                            this.handleApiMapsUrl()
                        })
                    } else {
                        directionsRenderer.setDirections(response);
                        this.showSteps(google, map)
                        this.setState({ directionsResponseBackup: response })
                    }
                })
                .catch((e) => console.error("Directions request failed due to ", e));

            this.setState({ map, google, directionsRenderer, directionsService })
        });
    }

    showSteps = (google, map) => {
        const { waypointsWithId, inputs } = this.state
        const geocoder = new google.maps.Geocoder()
        this.addMarker(google, map, geocoder, Images.StartDelivery, inputs.origin)
        this.addMarker(google, map, geocoder, Images.EndDelivery, inputs.destination)
        const that = this;
        map.setCenter(waypointsWithId[0].location.location);
        const markers = [];
        waypointsWithId.forEach((item) => {
            var marker = new google.maps.Marker({
                map: map,
                position: item.location.location,
                icon: Images.Delivery
            });
            markers.push({ item, marker });
            that.attachOnClickAction(marker, google, that, item);
        })
        this.setState({ markers })
    }

    addMarker = (google, map, geocoder, icon, address,) => {
        geocoder.geocode({ 'address': address }, function (results, status) {
            if (status === 'OK') {
                map.setCenter(results[0].geometry.location);
                new google.maps.Marker({
                    map: map,
                    position: results[0].geometry.location,
                    icon: icon
                });
            } else {
                console.log('Geocode was not successful for the following reason: ' + status);
            }
        });
    }

    attachOnClickAction = (marker, google, that, item) => {
        google.maps.event.addListener(marker, 'click', function () {
            const { requestData } = that.state
            const waypoint = requestData.find((el) => el.codigo_pedido === item.id)
            that.setState({ modal: { showModal: true, data: waypoint } })
        });
    }

    handleAddWaypoint = async (id, status) => {
        if (status === 1 && this.state.onRouteNum >= 10) {
            Notification.error({ title: 'Error', description: 'No se pueden agregar más de 10 repartos.' })
            return;
        }
        try {
            const { auth } = this.props
            const { data } = this.state
            const { items } = data
            const newItems = items.filter((item) => {
                if (item.id === id) {
                    const find = this.state.requestData.find(d => d.codigo_pedido === item.id)
                    doCreateDespacho(auth.session.token, {
                        "id_planificacion": find.id_planificacion,
                        "id_pedido": find.id_pedido,
                        "id_estado": status === 1 ? "2" : "4",
                        "id_tipo_venta": find.id_tipo_venta,
                        "observacion_entrega": find.observacion_entrega,
                        "registro_entrega": status === 2 ? " " : find.registro_entrega,
                        "path_entrega": status === 2 ? "" : find.path_entrega,
                        "detalle": find.detalle.map(i => ({ id_item: i.id_item, cantidad_pack: Number(i.cantidad_pack) }))
                    })
                    return false;
                }
                return true;
            })
            this.setState({ data: { items: newItems, headers }, onRouteNum: status === 1 ? this.state.onRouteNum + 1 : this.state.onRouteNum - 1 })
            Notification.success({ title: "Éxito", description: "Operación realizada correctamente." })
        } catch (error) {
            Notification.error({ title: "Error", description: "Hubo un error en la operación." })
        }
    }

    handleApiMapsUrl = () => {
        const { directionsResponse } = this.state
        const { routes } = directionsResponse
        const latlng = routes[0].legs.map((item) => {
            const direction = {
                start_location: {
                    address: item.start_address
                },
                end_location: {
                    address: item.end_address
                }
            }

            return direction
        })

        const ordered_routes = [];
        ordered_routes.push(latlng[0].start_location.address)
        ordered_routes.push(latlng[0].end_location.address)

        for (let i = 1; i <= ordered_routes.length; i++) {
            if (latlng && latlng[i]) {
                ordered_routes.push(latlng[i].end_location.address)
            }
        }

        const params = { waypoints: [] };
        let points='';

        ordered_routes.forEach((item, index) => {
            if (index === 0) {
                params.origin = `origin=${item}`;
            } else if (index === ordered_routes.length - 1) {
                params.destination = `destination=${item}`
            } else {
                params.waypoints.push(`${item}`)
            }
            points+=`${item}/`
        })

        params.waypoints = params.waypoints.join('|')
        const url = `https://www.google.com/maps/dir/${points.replaceAll(' ','+')}`;
        window.open(url)
    }

    handleInputChange = ({ target }) => {
        const { inputs } = this.state
        this.setState({ inputs: { ...inputs, [target.name]: target.value } })
    }

    handleChange = (id, target) => {
        const { requestData } = this.state
        requestData.map((item) => {
            if (item.id_pedido === id) {
                item[target.name] = target.value;
                return item
            }
            return item
        })

        this.setState({ requestData })
    }

    handleSave = (data) => {
        if (data.status_filter === undefined || data.status_filter ==="-1") {
            Notification.warning({ description: "Debe seleccionar un estado para guardar la entrega", title: "Entrega de Pedidos" });
            return;
        }
        const { auth } = this.props
        const base64 = this.state.camera.value;
        const ext = base64 === null ? null : base64.substring("data:image/".length, base64.indexOf(";base64"));
        const resp = doCreateDespacho(auth.session.token, {
            "id_planificacion": data.id_planificacion,
            "id_pedido": data.id_pedido,
            "id_estado": data.status_filter === undefined ? '' : data.status_filter,
            "id_tipo_venta": data.id_tipo_venta,
            "observacion_entrega": data.notes === undefined ? '' :data.notes,
            "registro_entrega": base64 === null ? '' : data.registro_entrega === "" ? `${getRandomString(10)}.${ext}` : data.registro_entrega,
            "path_entrega": this.state.camera.value === null ? '' : this.state.camera.value,
            "detalle": data.detalle.map(i => ({ ...i, cantidad_pack: Number(i.cantidad_pack) }))
        })
        const newData = this.state.data.items.filter((d) => d.id !== data.codigo_pedido)
        this.setState({ data: { ...this.state.data, items: newData }})
        if (resp) {
            Notification['success']({
                title: "Éxito",
                description: "Los datos se guardaran con éxito"
            })
        }
        const { modal } = this.state;
        modal.showModal = false;
        modal.data={}
        const waypointsWithId= this.state.waypointsWithId.filter(function(waypointWithId){
            return waypointWithId.id !== data.codigo_pedido
        })
        const waypoints= this.state.waypoints.filter(function(waypoint){
            return waypoint.location.id !== data.codigo_pedido
        })
        this.setState({ modal, camera: { value: null, show: false },waypointsWithId:waypointsWithId,waypoints:waypoints})

        if(this.state.markers.length){
            const m = this.state.markers.find(m => m.item.id === data.codigo_pedido);
            m.marker.setMap(null);
        }
    }

    handleOnTakePhoto = (data) => {
        this.setState({ camera: { value: data, show: true } }, () => {
            const { camera } = this.state
            setTimeout(() => {
                this.setState({ camera: { ...camera, show: false } })
            }, 600)
        })
    }

    openImageView = () => {
        this.setState({ imageView: true })
    }

    handleLoadData = async () => {
        const { inputs } = this.state
        const { status_filter, id_truck, id_trucker, id_shop, start_date, end_date } = inputs
        await this.loadInitialData({
            "fecha_inicio": start_date,
            "fecha_fin": end_date,
            "id_conductor": id_trucker === "-1" ? "" : id_trucker,
            "id_punto_venta": id_shop === "-1" ? "" : id_shop,
            "id_camion": id_truck === "-1" ? "" : id_truck,
            "id_estado": status_filter
        })
    }

    hasPermission = () => {
        return JSON.parse(sessionStorage.getItem('modules'))['repartos']?.edit;
    }

    render() {

        const { data, page, inputs, modal, camera, imageView, showMap, disableIndputsModal, showProductsDetails, productsDetails } = this.state
        const { status_filter, start_date, end_date, id_shop, id_truck, id_trucker } = inputs
        return (
            <div className="container-router">
                {camera && camera.show && (
                    <GetPhoto onTakePhoto={this.handleOnTakePhoto} />
                )}
                <Dialogs title="Productos" isOpen={showProductsDetails} handleAcept={() => this.setState({ showProductsDetails: false })} message={productsDetails} />
                <ImageView src={camera.value} alt="delivery-picture" show={imageView} closeViewer={() => this.setState({ imageView: false })} />
                <Title text="Repartos" />
                <div className="container-router__form">

                    {!showMap && (
                        <>
                            <div className="row">
                                <div className="col-6 col-md-6 mb-2">
                                    <Input
                                        type="date"
                                        label="Fecha de inicio"
                                        name="start_date"
                                        placeholder="Fecha de inicio"
                                        value={start_date}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="col-6 col-md-6 mb-2">
                                    <Input
                                        type="date"
                                        label="Fecha de fin"
                                        name="end_date"
                                        placeholder="Fecha de fin"
                                        value={end_date}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="col-6 col-md-4 mb-2">
                                    <Select
                                        label="Punto de venta"
                                        value={id_shop}
                                        name="id_shop"
                                        onChange={this.handleInputChange}
                                        data={this.state.stores}
                                    />
                                </div>
                                <div className="col-6 col-md-4 mb-2">
                                    <Select
                                        label="Camiones"
                                        value={id_truck}
                                        name="id_truck"
                                        onChange={this.handleInputChange}
                                        data={this.state.trucks}
                                    />
                                </div>
                                <div className="col-12 col-md-4 mb-2">
                                    <Select
                                        label="Conductores"
                                        value={id_trucker}
                                        name="id_trucker"
                                        onChange={this.handleInputChange}
                                        data={this.state.truckers}
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-center px-md-2 px-1 mb-2">
                                <div className="col-12 mb-2 d-flex justify-content-end">
                                    <button className="btn btn-rioclaro" onClick={this.handleLoadData}>Filtrar</button>
                                </div>
                            </div>
                            <div className="container_router filter">
                                <div className={`container_router filter item ${(status_filter === "1" || status_filter === undefined) && "selected"}`} onClick={() => this.setState({ inputs: { ...this.state.inputs, status_filter: "1" },disableIndputsModal: true }, () => this.handleLoadData())}>
                                    Despacho, No Entregado
                                </div>
                                <div className={`container_router filter item ${status_filter === "2" && "selected"}`} onClick={() => this.setState({ inputs: { ...this.state.inputs, status_filter: "2" },disableIndputsModal: false }, () => this.handleLoadData())}>
                                    En Reparto
                                </div>
                                <div className={`container_router filter item ${status_filter === "3" && "selected"}`} onClick={() => this.setState({ inputs: { ...this.state.inputs, status_filter: "3" },disableIndputsModal: true }, () => this.handleLoadData())}>
                                    Entregado, Entrega Parcial
                                </div>
                            </div>
                            <div className="row">
                                {
                                    this.state.inputs.status_filter === "2" && (<div className="col-12"><button style={{ float: "right" }} type="button" className={page === 3 ? 'd-none' : 'btn btn-rioclaro mx-2 mb-2'} onClick={this.nextPage}>Comenzar reparto</button></div>)
                                }
                                <div className="col-12"><Table data={data} /></div>
                            </div>
                        </>
                    )}
                    <div className={modal.showModal && "container-maps"}>
                        <CardMaps
                            disabled={disableIndputsModal}
                            show={modal.showModal}
                            data={modal.data}
                            handleClose={() => this.setState({ modal: { ...modal, showModal: false, data: {} }, camera: { ...camera, value: null } })}
                            onChange={this.handleChange}
                            onSave={this.handleSave}
                            openCamera={() => this.setState({ camera: { ...camera, show: true } })}
                            camera={camera}
                            openImageView={this.openImageView}
                            openCameraWithData={(path) => this.setState({ camera: { value: path, show: false, new: false } })}
                        />
                        {showMap && (
                            <>
                                <div id="map" style={{ width: '100%', height: '80vh' }} />
                                <button className="btn btn-rioclaro mt-3 mr-3" onClick={() => this.loadMapsData(true)}>Iniciar Navegacion</button>
                                <button className="btn btn-rioclaro mt-3" onClick={() => {
                                    this.setState({ showMap: false });
                                    this.handleLoadData();
                                }}>Volver</button>
                            </>
                        )}
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => ({
    drawer: state.drawer,
    auth: state.auth,
    despacho: state.despacho
})
const mapDispatchToProps = (dispatch) => ({
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING()),
    getDespachos: (token, data) => dispatch(getDespachosList(token, data))
})
export default connect(mapStateToProps, mapDispatchToProps)(Routes)