import { createAction } from 'redux-actions'
import { Despachos } from '../../services/api/endpoints';

export const fetch_start_despachos = createAction('fetch_start_despachos');
export const fetch_success_despachos = createAction('fetch_success_despachos');
export const fetch_error_despachos = createAction('fetch_error_despachos');

export const getDespachosList = (token, data = {}) => async (dispatch) => {
    dispatch(fetch_start_despachos());
    try {
        const response = await Despachos(token, data);
        console.log(response, 'RESPUESTA DE LISTADO DE DESPACHOS');
        dispatch(fetch_success_despachos({ data: [...response], response }))
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_despachos());
    }
};

export const SET_CATEGORY = createAction('SET_CATEGORY');