import { createAction } from 'redux-actions';
import { CreateAddress, ListAddress, RemoveAddress, AllProducts, UpdateAddress } from '../../services/api/endpoints';
import { GetRegions, SaveCategories, SaveOffices, SaveRegions } from '../../services/session';

export const fetch_start_addresses = createAction('fetch_start_addresses');
export const fetch_success_addresses = createAction('fetch_success_addresses');
export const fetch_error_addresses = createAction('fetch_error_addresses');
export const fetch_udpate_addresses = createAction('fetch_udpate_addresses');
export const address_reset = createAction('address_reset');

export const getListAddress = (token) => async (dispatch) => {
    dispatch(fetch_start_addresses());
    try {
        let listRegions = GetRegions();
        if (!listRegions) {
            const response = await AllProducts({});
            const { categorias, regiones, sucursales } = response;
            categorias && SaveCategories(categorias);
            sucursales && SaveOffices(sucursales);
            regiones && SaveRegions(regiones);
        }
        const data = await ListAddress(token);
        dispatch(fetch_success_addresses({ data }))
    } catch (e) {
        dispatch(fetch_error_addresses());
    }
};

export const updateAddresses = (id, data, token, edit = null, values = null) => async (dispatch) => {
    dispatch(fetch_start_addresses());
    try {
        edit ? await UpdateAddress(id, values, token) : await RemoveAddress(id, token);
        dispatch(fetch_success_addresses({ data }));
        return true;
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_addresses());
        return false;
    }
}

export const postAddresses = (params, token) => async (dispatch) => {
    dispatch(fetch_start_addresses());
    try {
        params.oficina = params.oficina ? params.oficina : ''
        params.referencia = params.referencia ? params.referencia : ''
        await CreateAddress(params, token);
        const data = await ListAddress(token);
        dispatch(fetch_success_addresses({ data }))
        return true;
    } catch (e) {
        console.log(e);
        dispatch(fetch_error_addresses());
        return false;
    }
}