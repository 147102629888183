import { createAction } from 'redux-actions';
import { SignUp } from '../../services/api/endpoints';

export const fetch_start_register = createAction('fetch_start_register');
export const fetch_success_register = createAction('fetch_success_register');
export const fetch_error_register = createAction('fetch_error_register');
export const register_resert = createAction('register_reset');

export const postSignUp = (data) => async (dispatch) => {
    dispatch(fetch_start_register());
    try {
        const response = await SignUp(data);
        dispatch(fetch_success_register(response));
        return true;
    } catch (e) {
        dispatch(fetch_error_register());
        return false;
    }
}