import { useEffect, useState } from "react"
import { v4 } from 'uuid'
import '../../../styles/component.admin.table.scss'
import { truncateString } from "../../../utils/helpers"

const Table = (props) => {
    const [data, setData] = useState({ headers: [], items: [] })
    const [order, setOrder] = useState({
        column: null,
        direction: null
    })

    useEffect(() => {
        setData({ ...props.data })
    }, [props.data])

    const orderColumn = (column) => {
        const { items } = data
        const ordered = [...items]

        if (order.column && order.column === column) {
            if (order.direction) {
                order.direction = !order.direction
            } else {
                order.direction = null
                order.column = null
            }
        } else {
            order.column = column
            order.direction = true
        }

        if (order.direction !== null) {
            ordered.sort(function (a, b) {
                if (a[column] > b[column]) {
                    return order.direction ? 1 : -1;
                }
                if (a[column] < b[column]) {
                    return order.direction ? -1 : 1;
                }
                return 0;
            });
            data.items = ordered;

        } else {
            data.items = props.data.items
        }
        setData(data)
        setOrder({ ...order })
    }

    const { hasPagination, totalPages, page, onPageChangeCallback } = props
    return (
        <div className="container-table">
            <table className="table table-striped table-hover table-bordered" style={{ overflow: 'auto' }}>
                <thead>
                    <tr>
                        {data.headers && data.headers.map((header, index) => (
                            <th id={header.target} className="text-center" scope="col" key={v4()} onClick={() => orderColumn(header.target)}>
                                {order.column === header.target && !order.direction && (
                                    <i className="fa fa-arrow-down"></i>
                                )}

                                {order.column === header.target && order.direction && (
                                    <i className="fa fa-arrow-up"></i>
                                )}

                                {header.name}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.items && data.items.length > 0
                        ? data.items.map((item, index) => (
                            <tr key={v4()}>
                                {item && Object.keys(item).map((key, i) => {
                                    return (
                                        key === "valor_retiro_tienda" || key === "valor_retiro_tienda_iva" || key === "valor_despacho" || key === "valor_despacho_iva"
                                    ) ? <td headers={key} data-title={data.headers[i]?.name} className="text-center" key={v4()}>{Intl.NumberFormat('es', {}).format(Math.round(item[key]))}</td> : <td headers={key} data-title={data.headers[i]?.name} className="text-center" key={v4()}>{(item[key] && item[key].length > 100) ? truncateString(item[key], 100).replace(/(<([^>]+)>)/gi, " ") : item[key]}</td>
                                })}
                            </tr>

                        ))
                        : (<tr><td className="text-center" colSpan={data.headers && data.headers.length}>{props.emptyMessage || 'Sin Data'}</td></tr>)}
                </tbody>
            </table>
            {hasPagination && (
                <nav aria-label="Page navigation example">
                    <ul className="pagination">
                        {Array.from(Array(totalPages)).map((n, i) => (<li className={`page-item ${(i + 1) === page && 'active'}`}><button onClick={() => onPageChangeCallback(i + 1)} className="page-link" href="#">{i + 1}</button></li>))}
                    </ul>
                </nav>
            )}
        </div>
    )
}

export default Table;