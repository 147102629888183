import React from 'react';
import FooterMain from '../Footers/Footer_Main';
import BannerTitle from '../Banners/Banner_Title';

const PageWrapper = (props) => {
    const {image, title, children, noFooter } = props;
    return(
        <>
            <BannerTitle image={image} title={title} />
            <div className="container my-4 py-4">
                {children}
            </div>
            {!noFooter && <FooterMain main/>}
        </>
    )
}

export default PageWrapper;