import React, {useState} from 'react';

import SignIn from './SignIn';
import SignUp from './SignUp';
import Reset from './Reset';

import SimpleCollapse from '../List/Simple_Collapse';

const AuthCollapse = (props) => {
	const {handleAuth} = props;
	const [ btns, setBtns ] = useState({
		btnBack:{
			addClass:'d-none',
			disabled:true,
		},
		btnPass:{
			addClass:'',
			disabled:false,
		},
		btnUp:{
			addClass:'',
			disabled:false,
		},
		classes:"btn btn-link btn-sm text-decoration-none px-0 text-muted"
	});

	const [views, setView] = useState({
		AuthSingIn: true,
		AuthSingUp: false,
		AuthRecoveryPassword: false
	})

	const handledClick = (btn, selectedView) => {
		if(btn!=='btnBack'){
			btns.btnBack.addClass='';
			btns.btnBack.disabled=false;

			btns.btnPass.addClass='d-none';
			btns.btnPass.disabled=true;
			btns.btnUp.addClass='d-none';
			btns.btnUp.disabled=true;
		}else{
			btns.btnBack.addClass='d-none';
			btns.btnBack.disabled=true;

			btns.btnPass.addClass='';
			btns.btnPass.disabled=false;
			btns.btnUp.addClass='';
			btns.btnUp.disabled=false;
		}

		setBtns({...btns});
		setView({
			AuthSingIn: false,
			AuthSingUp: false,
			AuthRecoveryPassword: false,
			[selectedView]: true
		});
	}

	return(
		<div id="accordionExample">
			<SimpleCollapse idCollapse={`AuthSingIn`} parend="#accordionExample" addClass={views.AuthSingIn ? 'show' : null}>
				<SignIn handleAuth={handleAuth} collapse />
			</SimpleCollapse>
			<SimpleCollapse idCollapse={`AuthSingUp`} parend="#accordionExample" addClass={views.AuthSingUp ? 'show' : null}>
				<SignUp handleAuth={handleAuth} collapse />
			</SimpleCollapse>
			<SimpleCollapse idCollapse={`AuthRecoveryPassword`} parend="#accordionExample" addClass={views.AuthRecoveryPassword ? 'show' : null}>
				<Reset handleAuth={handleAuth} collapse />
			</SimpleCollapse>
			<p className="text-center m-0">
				<button
					className={`${btns.classes} ${btns.btnBack.addClass}`}
					type="button"
					data-toggle="collapse"
					data-target={`#AuthSingIn`}
					aria-expanded={true}
					aria-controls={`AuthSingIn`}
					disabled={btns.btnBack.disabled}
					onClick={() => handledClick('btnBack', 'AuthSingIn')}
				>
					Volver
				</button>
				<br/>
				<button
					className={`${btns.classes} ${btns.btnPass.addClass}`}
					type="button"
					data-toggle="collapse"
					data-target={`#AuthRecoveryPassword`}
					aria-expanded={false}
					aria-controls={`AuthRecoveryPassword`}
					disabled={btns.btnPass.disabled}
					onClick={() => handledClick('btnPass', 'AuthRecoveryPassword')}
				>
					¿Olvidaste tu contraseña?
				</button>
				<br/>
				<button
					className={`${btns.classes} ${btns.btnUp.addClass}`}
					type="button"
					data-toggle="collapse"
					data-target={`#AuthSingUp`}
					aria-expanded={false}
					aria-controls={`AuthSingUp`}
					disabled={btns.btnUp.disabled}
					onClick={() => handledClick('btnUp', 'AuthSingUp')}
				>
					Crear cuenta
				</button>
			</p>
		</div>
	);
};

export default AuthCollapse;
