import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Panel, PanelGroup } from 'rsuite';
import LoaderRsuite from '../../components/Loaders/Loader_Rsuite';
import { doGetDespacho, ListOrders } from '../../services/api/endpoints';
import BackLink from './BackLink';
import { get_currency } from '../../utils/currency';
import FollowUp from '../../components/FollowUp';
import { handleDownloadPdf } from '../../utils/helpers';
import moment from 'moment';
const linkApi = process.env.REACT_APP_ENV_API_URL
class Historial extends Component {

	state = {
		list: [],
		loading: true,
		error: false,
		showDetails: false,
		follow_up: {}
	}

	componentDidMount = async () => {
		const { auth: { session: { token } } } = this.props;
		const response = await ListOrders(token);
		this.setState({ list: response.data, loading: false });
	}


	async showDetails(item) {
		const despacho = await doGetDespacho(item.codigo_pedido)
		if (despacho.tracking) {
			const follow_up = despacho.tracking.filter((d) => d.fecha !== "").map((d) => ({ status: d.estado, created_at: d.fecha }))
			this.setState({ follow_up: { status: follow_up, id: item.codigo_pedido, search: false }, showDetails: true })
		} else {
			this.setState({ follow_up: { status: [], id: item.codigo_pedido, search: false }, showDetails: true })
		}
	}

	render() {
		const { loading, error, list, showDetails } = this.state;
		return (
			<div className="page-historial">
				<FollowUp show={showDetails} handleClose={() => this.setState({ showDetails: false })} data={this.state.follow_up} />
				<BackLink actual="Mi Cuenta" />
				<div className="title-page">
					<h3 className="mb-0">Pedidos</h3>
					<hr className="my-3" />
				</div>
				<LoaderRsuite
					loading={{ run: loading, title: "Cargando pedidos..." }}
					fail={{ run: error, title: "Se ha producido un error", description: "Inténtalo más tarde." }}>
					<div className="bg-white py-4 rounded shadow-sm px-3">
						{list !== null && list.length > 0 ? (
							<PanelGroup accordion defaultActiveKey={1}>
								{list.sort((a, b) => new Date(b.fecha) - new Date(a.fecha)).map((item, i) => {
									return (
										<Panel header={(() => {
											return (
												<div className="bg-light">
													<h5 className="mb-0 font-weight-lighter">{`PEDIDO #${item.codigo_pedido}`}</h5>
													<strong>{moment(item.fecha.replace('GMT', '')).format('DD-MM-YYYY hh:mm:ss')}</strong>
												</div>
											)
										})()} eventKey={i} key={`panel-compras-${i}`}>
											<div className="row d-flex  flex-lg-row">
												<div className="col-12 col-lg-9">
													{item.items.map((x, i0) => {
														return (
															<div className="row mb-3" key={`product--comporado-${i0}`}>
																<div className="col-2">
																	<img src={`${linkApi}/imagenes/productos/${x.image}`} className="img-fluid" alt="..." />
																</div>
																<div className="col-10">
																	<h5 className="font-weight-bold mb-0">{x.nombre}</h5>
																	<h5 className="font-weight-lighter mb-0">{x.formato}</h5>
																	<p className="font-weight-lighter mb-2">{`Cantidad: ${x.cantidad}`}</p>
																	<p><strong>{get_currency(Math.round(x.valor_iva))}</strong></p>
																</div>
															</div>
														);
													})}
												</div>
												<div className="col-12 col-lg-3 mb-3">
													<div className="mt-2">
														<p className="mb-1 font-weight-lighter">TOTAL</p>
														<strong className="text-red">{get_currency(Math.round(item.total))}</strong> {' '}

													</div>
													<div onClick={() => this.showDetails(item)}>
														<span style={{ textDecoration: 'underline', cursor: 'pointer' }}>Seguimiento</span>
													</div>

												</div>

												<div className="col-12 d-flex justify-content-start">
													<button disabled={(!item.pdf_path && !item.pdf_nombre)} onClick={() => handleDownloadPdf(`${linkApi}/dte/?pdf_nombre=${item.pdf_nombre}&pdf_path=${item.pdf_path}`, item.pdf_nombre)} className="rs-btn rs-btn-default shadow-sm rs-btn-red">
														<i className="fa fa-file-text-o mr-2"></i>
														{(item.pdf_path && item.pdf_nombre) ? "Descargar Boleta/Factura" : "Boleta/Factura no disponible"}
													</button>
												</div>
											</div>
										</Panel>
									);
								})}
							</PanelGroup>
						) : (
							<div className="container-fluid bg-white py-4 rounded px-lg-5">
								<div className="row">
									<div className="col-12 text-center">
										<h4>Aun no se han realizado pedidos.</h4>
									</div>
								</div>
							</div>
						)}
					</div>
				</LoaderRsuite >
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProps)(Historial);
