import { Component } from "react";
import { connect } from "react-redux";
import Input from "../Input";
import Title from "../Title";
import validate from '../../../utils/validate';
import { discountSchema } from '../../../utils/validations';
import moment from 'moment';
import { doCreateDiscount } from "../../../redux/actions/discount";
import { CLOSE_DRAWER } from "../../../redux/actions/drawer";
import { END_LOADING, START_LOADING } from "../../../redux/actions/loader";

class AddDiscount extends Component {
    constructor(props) {
        super(props)
        this.state = {
            inputs: {
                cantidad: 0,
                codigo: "",
                fecha_creacion: moment().format('YYYY-MM-DD'),
                fecha_expiracion: moment().format('YYYY-MM-DD'),
                valor: 0,
                state: false
            },
            errors: {},
        }
    }

    handleChange = ({ target }) => {
        let { name, value } = target
        if ((name === "cantidad" || name === "valor") && value !== "") {
            const reg = new RegExp(/^\d+$/);
            if (!reg.test(value)) {
                return
            }
            if (name === "valor" && Number(value) > 100) {
                return
            }
        }
        const { inputs } = this.state
        if (target.type === 'number' && target.value === '') {
            target.value = 0
        }
        this.setState({ inputs: { ...inputs, [target.name]: target.name === "codigo" ? target.value.toUpperCase() : target.value } }, () => {
            const { errors } = this.state
            Object.keys(errors).forEach((key) => {
                if (key === target.name) {
                    delete errors[key]
                }
            })
            this.setState({ errors })
        })
    }

    handleSubmit = async () => {
        const { inputs } = this.state
        const validations = await validate(discountSchema, inputs, () => this._createDiscount(inputs))
        if (validations) {
            this.setState({ errors: validations })
        }
    }

    _createDiscount = async (inputs) => {
        const { createDiscount, auth, drawer, CLOSE_DRAWER, START_LOADING, END_LOADING } = this.props
        START_LOADING()
        await createDiscount(inputs, auth.session.token)
        END_LOADING()
        CLOSE_DRAWER(drawer.RENDER);
    }

    render() {
        const { inputs, errors } = this.state
        const { codigo, valor, fecha_creacion, fecha_expiracion, cantidad } = inputs

        return (
            <div className="container-component-discount size mt-5">
                <Title text="Agregar Cupón" />

                <div className="row justify-content-center px-md-2 px-1 mb-md-2">
                    <div className="col-12 mb-2">
                        <Input
                            label="Cantidad"
                            placeholder="cantidad"
                            value={cantidad}
                            name="cantidad"
                            type="number"
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>

                </div>

                <div className="row justify-content-center px-md-2 px-1 mb-md-5">
                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="Código"
                            placeholder="10off"
                            value={codigo}
                            name="codigo"
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="Porcentaje"
                            type="number"
                            placeholder="10"
                            value={valor}
                            name="valor"
                            onChange={this.handleChange}
                            errors={errors}
                            max="100"
                        />
                    </div>
                </div>

                <div className="row justify-content-center px-md-2 px-1 mb-md-5">

                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="Desde"
                            placeholder="01/01/2021"
                            type="date"
                            value={fecha_creacion}
                            name="fecha_creacion"
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                    <div className="col-12 col-md-6 mb-2">
                        <Input
                            label="Hasta"
                            placeholder="01/01/2021"
                            type="date"
                            value={fecha_expiracion}
                            name="fecha_expiracion"
                            onChange={this.handleChange}
                            errors={errors}
                        />
                    </div>
                </div>

                <div className="row justify-content-center px-md-2 px-1 mb-2">
                    <div className="col-12 mb-2 d-flex justify-content-end">
                        <button className="btn btn-rioclaro" onClick={this.handleSubmit}>Guardar</button>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    drawer: state.drawer,
})
const mapDispatchToProps = (dispatch) => ({
    createDiscount: (data, token) => dispatch(doCreateDiscount(data, token)),
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    START_LOADING: () => dispatch(START_LOADING()),
    END_LOADING: () => dispatch(END_LOADING())
})
export default connect(mapStateToProps, mapDispatchToProps)(AddDiscount)