import hookup from './hookup.axios';
import Processing from './form_validator';

export const FormValidator = new Processing();

export const Products = async (data, page, pageSize) => await hookup('get', `/productos?page=${page}&pageSize=${pageSize}`, data, true);
export const AllProducts = async (data) => await hookup('get', `/productos?page=1&pageSize=all`, data, true);
export const ProductDetails = async (slug) => await hookup('get', `/productos/${slug}`, {});
export const CreateProduct = async (data, token) => await hookup('post', '/productos', data, token);
export const EditProduct = async (data, id, token) => await hookup('put', `/productos/${id}`, data, token);
export const RemoveProduct = async (data, id, token) => await hookup('delete', `/productos/${id}`, data, token);

// Category
export const Categories = async (data) => await hookup('get', '/producto/categorias', data, true);
export const CreateCategory = async (data, token) => await hookup('post', '/producto/categorias', data, token);
export const EditCategory = async (data, id, token) => await hookup('put', `/producto/categorias/${id}`, data, token);
export const RemoveCategory = async (data, id, token) => await hookup('delete', `/producto/categorias/${id}`, data, token);
export const CategoryDetails = async (id) => await hookup('get', `/producto/categorias/${id}`, {});
// Banner
export const Banners = async () => await hookup('get', '/banners', {}, true);
export const EditBanner = async (data, id, token) => await hookup('put', `/banners/${id}`, data, token);
// Text
export const Texts = async (data) => await hookup('get', '/textos_legales', data, true);
export const EditText = async (data, id, token) => await hookup('put', `/textos_legales/${id}`, data, token);
export const doGetText = async (id) => await hookup('get', `/textos_legales/${id}`, {});

// Discount
export const Discounts = async (data) => await hookup('get', '/cupones', data, true);
export const CreateDiscount = async (data, token) => await hookup('post', '/cupones', data, token);
export const EditDiscount = async (data, id, token) => await hookup('put', `/cupones/${id}`, data, token);
export const RemoveDiscount = async (data, id, token) => await hookup('delete', `/cupones/${id}`, data, token);

// Auth
export const SignUp = async (data) => await hookup('post', '/clientes', data);
export const SignIn = async (data) => await hookup('post', '/cliente/login/', data, undefined, false, false, true);
export const ResetPassword = async (data) => await hookup('post', '/recuperar/', data);
export const RecoveryPassword = async (data) => await hookup('post', '/confirmar_recuperar/', data);
export const ConfirmAccount = async (data) => await hookup('post', '/confirmar_cuenta/', data);
export const UserSignIn = async (data) => await hookup('post', '/usuario/login/', data, undefined, false, false, true);

// Profile
export const ShowProfile = async (token) => await hookup('get', '/mi_perfil', {}, token);
export const ProfileUpdate = async (data, token) => await hookup('put', '/mi_perfil', data, token);
export const UpdatePassword = async (data, token) => await hookup('post', `/cambiar_contrasena`, data, token);
export const CheckEmail = async (data) => await hookup('post', '/checkEmail', data);
export const CheckRut = async (rut) => await hookup('get', `/checkRut/${rut}`, {});
export const AnonLogin = async (data) => await hookup('post', '/anon/login/', data);

export const ListAddress = async (token) => await hookup('get', '/mis_direcciones', {}, token);
export const CreateAddress = async (data, token) => await hookup('post', '/direcciones', data, token);
export const UpdateAddress = async (id, data, token) => await hookup('put', `/direcciones/${id}`, data, token)
export const RemoveAddress = async (id, token) => await hookup('delete', `/direcciones/${id}`, {}, token);

// Shop
export const DiscountCodeSearch = async (data) => await hookup('get', `/cupones/${data}`, data);

// Localidades
export const GetLocalidades = async (token) => await hookup('get', `/localidades`, {}, token);
export const UpdateLocalidad = async (token, data) => await hookup('put', `/localidades`, data, token);

// Payment
export const CreateOrder = async (data, token) => await hookup('post', '/pedidos', data, token);
export const CreateTransbank = async (data, token) => await hookup('post', '/transbank', data, token);
export const ShowPayment = async (id) => await hookup('put', `/transbank/${id}`, {});
export const ListOrders = async (token) => await hookup('get', '/mis_pedidos', {}, token);

// Sucursales
export const ListStores = async () => await hookup('get', '/puntos', {});
export const CreateStore = async (token, data) => await hookup('post', '/puntos', data, token);
export const RemoveStore = async (id, token) => await hookup('delete', `/puntos/${id}`, {}, token);
export const UpdateStore = async (token, id, data) => await hookup('put', `/puntos/${id}`, data, token);

// Files
export const GetFile = async (path) => await hookup('get', `/imagenes/productos/${path}`, {}, true);
export const UploadFile = async (data, token) => await hookup('post', '/imagenes/productos', data, token);
export const UploadBannerFile = async (data, token) => await hookup('post', '/imagenes/banners', data, token, true);

// Formatos
export const Formatos = async (id_categoria) => await hookup('get', `/formatos?id_categoria=${id_categoria}&page=1&pageSize=9999`, {});
export const CreateFormato = async (data, token) => await hookup('post', '/formatos', data, token);
export const Defontana = async (token) => await hookup('get', '/cod?page=1&pageSize=9999', {}, token);
export const CreateDefontana = async (token, data) => await hookup('post', '/cod', data, token, false, true);
export const UpdateDefontana = async (token, id, data) => await hookup('put', `/cod/${id}`, data, token, false, true);
export const DeleteDefontana = async (token, id) => await hookup('delete', `/cod/${id}`, {}, token, false, true, true);
// Roles
export const Roles = async (token) => await hookup('get', '/roles', {}, token);
export const CreateRole = async (data, token) => await hookup('post', '/roles', data, token);
export const EditRole = async (data, id, token) => await hookup('put', `/roles/${id}`, data, token);
export const AssignRole = async (data, token) => await hookup('post', '/asignar', data, token);
export const UnassignRole = async (id, token) => await hookup('delete', `/asignar/${id}`, {}, token);
export const doGetRole = async (id, token) => await hookup('get', `/roles/${id}`, {}, token);
export const doRemoveRole = async (id, token) => await hookup('delete', `/roles/${id}`, {}, token);
// Modulos
export const Modules = async (token) => await hookup('get', '/modulos', {}, token);

// Usuarios
export const Users = async (token) => await hookup('get', '/usuarios?page=1&pageSize=9999', {}, token);

// despachos
export const Despachos = async (token, data) => await hookup('get', `/despachos?fecha_fin=${data.fecha_fin}&fecha_inicio=${data.fecha_inicio}&id_camion=${data.id_camion}&id_conductor=${data.id_conductor}&id_estado=${data.id_estado}&id_punto_venta=${data.id_punto_venta}`, data, token);
export const doGetDespacho = async (id) => await hookup('get', `/despacho/${id}`, {}, null, true, true);
export const Trucks = async (token) => await hookup('get', '/camiones', {}, token);
export const Truckers = async (token) => await hookup('get', '/conductores', {}, token);
export const doCreateDespacho = async (token, data) => await hookup('post', '/despacho', data, token, false, true, true);
export const doGetReparto = async (token, data) => await hookup('post', '/reparto', data, token);

// Lista precios
export const doCreatePriceList = async (token, data) => await hookup('post', '/lista_precios', data, token);
export const doGetPrice = async (token, id) => await hookup('get', `/lista_precios/${id}`, {}, token);
export const doRemovePriceList = async (token, id) => await hookup('delete', `/lista_precios/${id}`, {}, token);
export const doEditPriceList = async (token, data, id) => await hookup('put', `/lista_precios/${id}`, data, token);

// excel
export const getProductsExcel = async (id) => await hookup('get', `/productos/excel/${id}`, {}, true);
export const updateProductsExcel = async (token, data, id) => await hookup('put', `/productos/excel/${id}`, data, token);