import CartEmpty from '../../components/Carts/Cart_Empty';
import history from '../../services/history';

export const Empty = () => (
    <div className="d-flex justify-content-center align-items-center">
        <div className="container w-75 bg-white p-4" style={{ marginTop: '10vh' }}>
            <CartEmpty action={() => history.push('/')}/>
        </div>
    </div>
);
