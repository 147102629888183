import React, { useState } from 'react';
import { GetRegions } from '../../services/session';
import SelectSimple from '../../components/Selects/Select_Simple';

const Address = ({inputs, handleInput, edit}) => {
    let listRegions = GetRegions();
    const [ listComunnes, setListComunnes ] = useState([]);
    if (edit && !listComunnes.length) {
        let region = listRegions.filter(y => (y.comunas.find(z => z.id === parseInt(inputs.id_comuna))));
        inputs.region_id = region[0].id;
        setListComunnes(region[0].comunas);
    }
    return(
        <>
        <div className="form-group mb-1 col-6">
            <label>Región</label>
            <SelectSimple
                type="simple"
                data={listRegions.map(x => ({ label: x.region_nombre, value: x.id }))}
                className="form-select"
                name="region_id"
                placeholder="Región"
                runChange={(e) => {
                    let finded = listRegions.find(x => x.id === parseInt(e.target.value));
                    finded && setListComunnes(finded.comunas)
                    handleInput(e);
                }}
                initValue={inputs.region_id}
            />
        </div>
        <div className="form-group mb-1 col-6">
            <label>Comuna</label>
            <SelectSimple
                type="simple"
                data={listComunnes.map(y => ({ label: y.comuna_nombre, value: y.id }))}
                className="form-select form-control-sm"
                name="id_comuna"
                placeholder="Comuna"
                runChange={handleInput}
                initValue={inputs.id_comuna}
                alphabetically
            />
        </div>
        <div className="form-group mb-1 col-12">
            <label>Dirección</label>
            <input value={inputs.direccion} name="direccion" type="text" className="form-control" onChange={handleInput}/>
        </div>
        <div className="form-group mb-1 col-12">
            <label>Oficina</label>
            <input value={inputs.oficina} name="oficina" type="text" className="form-control" onChange={handleInput}/>
        </div>
        <div className="form-group mb-1 col-12">
            <label>Referencia</label>
            <input value={inputs.referencia} name="referencia" type="text" className="form-control" onChange={handleInput}/>
        </div>
        </>
    );
}

export default Address;