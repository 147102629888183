import { Component } from "react";
import Header from "./Header";
import MenuLeft from "./MenuLeft";
import history from '../../services/history'
import Drawer from "../../components/Admin/Drawer";
import { connect } from "react-redux";
import { MenuLeft as ItemsMenu } from './MenuItems';
import '../../styles/DashboardStyles/Dashboard.scss';

class Dashboard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pathname: '',
            data: []
        }
    }

    componentDidMount() {
        const { location } = history
        const { pathname } = location
        setTimeout(() => {
            const modules = JSON.parse(sessionStorage.getItem('permissions')) || [];
            this.setState({ data: ItemsMenu.filter(m => modules.includes(m.name)) }, () => {
                const { data } = this.state
                const updatedData = data.map((item) => {
                    if (item.path === pathname) {
                        item.selected = true
                        return item;
                    }
                    item.selected = false
                    return item
                })
                this.setState({ data: updatedData, pathname })
            })
        }, 500);
    }

    componentDidUpdate() {
        const { location } = history
        const { pathname } = location
        const { data, pathname: pathName } = this.state
        if (pathname !== pathName) {
            const updatedData = data.map((item) => {
                if (item.path === pathname) {
                    item.selected = true
                    return item;
                }
                item.selected = false
                return item
            })
            this.setState({ data: updatedData, pathname })
        }
    }



    render() {
        const { data } = this.state
        return (
            <div className="container-dashboard">
                <Header />
                <Drawer />
                <div className="container-dashboard__content">
                    <MenuLeft items={data} />
                    {this.props.children}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    actionsRoles: state.actionsRoles,
    auth: state.auth
})
const mapDispatchToProps = (dispatch) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)