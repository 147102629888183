import React, { Component } from 'react'
import '../../../styles/component.admin.imageview.scss'

class ImageView extends Component {
    render() {
        const { src, alt, show, closeViewer } = this.props
        return (
            <div className={show ? "container-image-view" : "container-image-view d-none"} onClick={closeViewer}>
                <div className="container-image-view__bg">
                    <img src={src} alt={alt} />
                </div>
            </div>
        )
    }
}

export default ImageView