const toolbar = [
    "heading",
    "|",
    "bold",
    "fontSize",
    "fontColor",
    "underline",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "|",
    "indent",
    "outdent",
    "|",
    "blockQuote",
    "insertTable",
    "undo",
    "redo"
]

export default toolbar