import React, {Component} from 'react';
import LoaderRsuite from '../../components/Loaders/Loader_Rsuite';
import * as Api from '../../services/api/endpoints';
import AssetsImg from '../../assets/image';

class Confirm extends Component{
    state = {
        loading: true,
        error: null
    }

    async componentDidMount(){
        const { match: {params} } = this.props;
        await Api.ConfirmAccount({token: params.token})
        .then(() => this.setState({loading: false}))
        .catch(e => this.setState({error: e, loading: false}))
    }

    render(){
        const {loading, error} = this.state;
        return(
            <div className="bg-i-white d-flex justify-content-center align-items-center container-signin">
                <div className="container w-50 bg-white p-4">
                <LoaderRsuite
                    loading={{
                        run: loading,
                    }}
                    fail={{
                        run: error,
                        title:"Se ha producido un error" ,
                        description:"Se ha producido un error al intentar confirmar tu cuenta, inténtalo más tarde" ,
                    }}
                    >
                        <div className="row">
                            <div className="col-12 col-sm-8 mx-auto text-center">
                                <img src={AssetsImg.Success_Register} alt="..." className="img-fluid icon-msg"/>
                                <h5 className="mb-2">
                                    <strong>Cuenta confirmada exitosamente</strong>
                                    <br/>
                                    <small>Ahora puedes iniciar sesión en tu cuenta Rio Claro</small>
                                </h5>
                            </div>
                        </div>
                    </LoaderRsuite>
                </div>
            </div>
        )
    }
}

export default Confirm;