import { Component } from "react";
import { connect } from "react-redux";
import { v4 } from "uuid";
import { CLEAR_RENDER, CLOSE_DRAWER, SET_RENDER } from "../../../redux/actions/drawer";

import '../../../styles/component.admin.drawer.scss'
import PreRender from "./PreRender";
class Drawer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            key: v4()
        }
    }
    componentDidMount() {
        const { CLOSE_DRAWER } = this.props;
        CLOSE_DRAWER();
    }

    closeDrawer = (e) => {
        const { CLOSE_DRAWER, CLEAR_RENDER, drawer, SET_RENDER } = this.props;
        if (e.target.className === 'container-drawer' || e.currentTarget.className === 'container-drawer__close') {
            SET_RENDER(<></>);
            CLEAR_RENDER()
            CLOSE_DRAWER(drawer.RENDER);
        }

    }


    render() {
        const { drawer } = this.props
        const { OPEN, RENDER } = drawer
        return (
            <div name="main_drawer" className="container-drawer" style={{ transform: OPEN ? 'translateX(0%)' : 'translateX(100%)' }} onClick={this.closeDrawer}>
                <div className="container-drawer__close" onClick={this.closeDrawer}>
                    <i className="fa fa-times-circle" style={{ position: "absolute", zIndex: 1 }}></i>
                </div>
                <div style={{ width: RENDER && RENDER.WrappedComponent && (RENDER.WrappedComponent.name === "AdvancedProduct" || RENDER.WrappedComponent.name === "AddCategory" || RENDER.WrappedComponent.name === "EditCategory" || RENDER.WrappedComponent.name === "EditBanner") && "100%" }} className="container-drawer__content" name="content_drawer">
                    <PreRender key={this.state.key} componentChange={this.state.key} Component={RENDER} />
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    drawer: state.drawer
})
const mapDispatchToProps = (dispatch) => ({
    CLOSE_DRAWER: (component) => dispatch(CLOSE_DRAWER(component)),
    CLEAR_RENDER: () => dispatch(CLEAR_RENDER()),
    SET_RENDER: () => dispatch(SET_RENDER())
})

export default connect(mapStateToProps, mapDispatchToProps)(Drawer)