import { handleActions } from 'redux-actions'
import { SET_CATEGORY } from '../actions/category'

export const INITIAL_STATE = {
    id: '',
    name: '',
    formatos: []
}

export default handleActions({
    [SET_CATEGORY]: (state, action) => {
        return { ...state, ...action.payload }
    }
}, INITIAL_STATE)