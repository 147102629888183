import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssetsImg from '../../../assets/image';
import { get_currency } from '../../../utils/currency';
import DiscountForm from '../../../components/Discounts/Discount_Form';
import HelperCoin from '../../../utils/currency';
import { Button, Checkbox } from 'rsuite';
import ModalRsuite from '../../../components/Modals/Modal_Rsuite';
import ProductOptions from '../../../components/Products/Product_Options';
import SelectAddress from '../Mobile/Select_Address';
import { compareItems } from '../../../utils/helpers';
import { Alert, Notification, Message, Loader } from 'rsuite';
const linkApi = process.env.REACT_APP_ENV_API_URL

const ReceivedByAThird = ({ inputs, handleInput, handlePhone, handleRut }) => {
    return (
        <>
            <div className="col-12  mt-2 title-page">
                <p className="d-inline pr-4 mb-0 ml-2">Ingresa los datos de quién recibe</p>
                <hr className="mt-1" />
            </div>
            <div className="form-group mb-0 col-6 font-small">
                <label>Nombre</label>
                <input value={inputs.third_name} name="third_name" type="text" className="form-control" onChange={handleInput} />
            </div>
            <div className="form-group mb-0 col-6 font-small">
                <label>Teléfono</label>
                <input value={inputs.third_phone} name="third_phone" type="text" className="form-control" onChange={handlePhone} />
            </div>
            <div className="form-group mb-0 col-6 font-small">
                <label>Rut (ej: 11111111-1)</label>
                <input value={inputs.third_rut} name="third_rut" type="text" className="form-control" onChange={handleRut} autoComplete="off" />
            </div>
        </>
    );
}
export const Address = ({ inputs, handleInput, cart }) => {
    return (
        <>
            <div className="form-group mb-1 col-12">
                <Message
                    closable
                    type="warning"
                    title="Importante"
                    description="La dirección debe estar en la comuna de despacho seleccionada."
                />
            </div>
            <div className="form-group mb-1 col-6">
                <label>Región</label>
                <input value={cart.shipping.comuna.region_nombre} name="region" type="text" className="form-control" disabled />
            </div>
            <div className="form-group mb-1 col-6">
                <label>Comuna</label>
                <input value={cart.shipping.comuna.comuna_nombre} name="comuna" type="text" className="form-control" disabled />
            </div>
            <div className="form-group mb-1 col-12">
                <label>Dirección</label>
                <input disabled={inputs.loading} value={inputs.description} name="description" type="text" className="form-control" onChange={handleInput} />
            </div>
            <div className="form-group mb-1 col-6">
                <label>Depto/Oficina</label>
                <input disabled={inputs.loading} value={inputs.department_number} name="department_number" type="text" className="form-control" onChange={handleInput} />
            </div>
            <div className="form-group mb-1 col-6">
                <label>Información adicional(opcional)</label>
                <input disabled={inputs.loading} value={inputs.note} name="note" type="text" className="form-control" onChange={handleInput} />
            </div>
        </>
    );
}

const initial = { shipping: null, region: null, store: null, commune: null, format: null }

class Delivery extends Component {
    state = {
        edit: 0,
        initial: null,
        modal: false
    }

    _removeItem = (index) => {
        const { cart, updateItems, updateShipping, checkout, updateCheckout } = this.props;
        cart.items.splice(index, 1);
        updateItems(cart.items);
        let comunaShipping = cart.items.some(x => x.shipping === 2) ? cart.shipping.comuna : null;
        let sucursalShipping = cart.items.some(x => x.shipping === 1) ? cart.shipping.sucursal : null;
        updateShipping({ shipping: { comuna: comunaShipping, sucursal: sucursalShipping } });
        if (cart.items.length === 0) {
            updateCheckout({ ...checkout.form, address: null, address_id: null, description: null });
        }
    }
    _showEdit = (index) => {
        this.setState({ modal: true, edit: index });
    }
    _updateItemOnCart = (selected, formats, regions) => {
        const { edit } = this.state;
        const { cart: { items } } = this.props;
        let sRegions = regions.find(x => x.id === selected.region);
        let values = selected.shipping === 1 ? { list: 'comunas_disponibles', name: 'comuna_nombre', selected: 'commune', price: 'valor_despacho_iva' } : { list: 'sucursales_disponibles', name: 'nombre', selected: 'store', price: 'valor_retiro_tienda_iva' };
        let sFormato = formats.find(z => z.id === selected.format);
        if (compareItems(selected, items[edit])) {
            items[edit].precio = parseInt(sFormato[values.price])
            items[edit].id_lista_precio = sFormato.id_lista_precio;
            items[edit].comunas_disponible = sRegions.comunas_disponibles;
            items[edit].sucursales_disponible = sRegions.sucursales_disponibles;
            items[edit].region = selected.region;
            items[edit].store = selected.store;
            items[edit].commune = selected.commune;
        } else {
            // encuentro otro
            let findedIndex = items.findIndex(x => (compareItems(selected, x)));
            if (findedIndex > -1) {
                items[findedIndex].cantidad += items[edit].cantidad;
                this.setState({ edit: 0 })
                items.splice(edit, 1);
            } else {
                let despacho = items[edit].despacho.find(x => x.id === selected.region);
                let retiro = items[edit].retiro.find(y => y.id === selected.region);
                items[edit].id_formato = selected.format;
                items[edit].nombre_formato = sFormato.nombre;
                items[edit].formatos = sFormato;
                items[edit].precio = parseInt(sFormato[values.price]);
                items[edit].comunas_disponible = despacho ? despacho.comunas_disponibles : null;
                items[edit].sucursales_disponible = retiro ? retiro.sucursales_disponibles : null;
                items[edit].id_lista_precio = sFormato.id_lista_precio;
                items[edit].region = selected.region;
                items[edit].store = selected.store;
                items[edit].commune = selected.commune;
                items[edit].shipping = selected.shipping;
            }

            Notification['success']({
                title: 'El producto ha sido actualizado',
                placement: 'bottomEnd',
            });
        }

        this.setState({ modal: false, initial: null });
        this._updateActualShipping(selected.commune, selected.store, items, edit, sRegions);
    }
    _updateActualShipping = (comuna_id, punto_venta_id, items, edit, sRegions) => {
        const { cart: { shipping, discount_code }, updateShipping, updateItems } = this.props;
        let n_comuna = items.some(x => x.shipping === 1) ? shipping.comuna : null;
        let n_sucursal = items.some(x => x.shipping === 2) ? shipping.sucursal : null;
        if (comuna_id && items[edit]) {
            n_comuna = items[edit].comunas_disponible.find(x => x.id === comuna_id);
            n_comuna.region_nombre = sRegions.region_nombre;
        }

        if (punto_venta_id && items[edit]) {
            n_sucursal = items[edit].sucursales_disponible.find(y => y.id === punto_venta_id);
        }

        let n_items = items.map(z => {
            if (z.shipping === 1) {
                let region = z.despacho.find(x => x.id === sRegions.id);
                if (region) {
                    let comuna = region.comunas_disponibles.find(y => y.id === n_comuna.id);
                    let formato = comuna?.formatos.find(x => x.id === z.id_formato);
                    if (formato) {
                        z.precio = formato.valor_despacho_iva;
                        z.region = sRegions.id;
                        z.commune = n_comuna.id;                            
                    }
                } else {
                    return null;
                }
            } else {
                let region = z.retiro.find(x => x.id === sRegions.id);
                if (region) {
                    let comuna = region.sucursales_disponibles.find(y => y.id === n_sucursal.id);
                    let formato = comuna.formatos.find(x => x.id === z.id_formato);
                    z.precio = formato.valor_retiro_tienda_iva;
                    z.region = sRegions.id;
                    z.store = n_sucursal.id;
                } else {
                    return null;
                }
            }

            return z;
        });

        if (n_items.some(x => x === null)) {
            Alert.error(`Uno o más productos no pueden ser enviados a la comuna o sucursal seleccionada`, 5000);
        } else {
            updateShipping({ shipping: { comuna: n_comuna, sucursal: n_sucursal } });
            updateItems([...n_items], discount_code);
        }
    }
    render() {
        const { inputs, handleInput, handlePayment, handleAddress, cart: { shipping, items }, cart, handleQuantity, auth: { session } } = this.props;
        const { edit, modal } = this.state;
        return (
            <>

                {cart.shipping && cart.shipping.sucursal ? (
                    <>
                        <div className="bg-i-gray p-2 mb-1 rounded">
                            <div className="pb-1 d-flex justify-content-between align-items-center">
                                <p className="d-inline mb-0 ml-1 font-weight-bold">
                                    <img src={AssetsImg.Location_f} alt="..." style={{ width: '20px' }} />
                                    {`Retiro en sucursal ${cart.shipping.sucursal.nombre}`}
                                </p>
                                <p className="d-inline mb-0 mt-1 text-right ml-1 font-small">{`${cart.shipping.sucursal.direccion}`}</p>
                            </div>
                        </div>
                    </>)
                    :
                    null
                }
                {
                    cart.shipping && cart.shipping.comuna ? (
                        <>
                            <div className="bg-i-gray p-2 mb-1 rounded">
                                <div className="pb-1">
                                    <img src={AssetsImg.Truck_f} alt="..." style={{ width: '20px' }} />
                                    <p className="d-inline pr-4 mb-0 ml-2 font-weight-bold">{`Despacho a la comuna de ${cart.shipping.comuna.comuna_nombre}`}</p>
                                </div>
                            </div>


                            <div className="bg-white p-2 border rounded mb-2">
                                <div className="row mt-2">
                                    <div className="col-12 title-page mt-2">
                                        <p className="d-inline pr-4 mb-0 ml-2">¿A qué dirección despachamos?</p>
                                        <hr className="my-2" />
                                    </div>
                                    {(session && session.usuario && session.usuario.confirmado) ?
                                        <SelectAddress callback={handleAddress} /> :
                                        <Address {...this.props} />
                                    }
                                    <Checkbox
                                        disabled={inputs.loading}
                                        defaultChecked={inputs.received_by_a_third_party}
                                        className="checkbox-not-account col  mt-2"
                                        value={true}
                                        onChange={(value, event) => {
                                            handleInput({
                                                target: {
                                                    name: 'received_by_a_third_party',
                                                    value: event
                                                }
                                            })
                                        }}><span className="col font-small">Lo recibirá un tercero</span></Checkbox>
                                </div>
                                <div className="row mt-2">
                                    {inputs.received_by_a_third_party ?
                                        <ReceivedByAThird {...this.props} /> :
                                        null
                                    }
                                </div>
                            </div>
                        </>
                    ) : null
                }

                <div className="bg-i-gray p-2 mb-1 rounded">
                    <div className="pb-1">
                        <img src={AssetsImg.Check_f} alt="..." style={{ width: '20px' }} />
                        <p className="d-inline mb-0 ml-1">Resumen del pedido</p>
                    </div>
                </div>
                <div className="bg-white p-2 border rounded mb-2">
                    <div className="row">
                        <div className="col-2 text-center">
                        </div>
                        <div className="col-2 text-center">
                            <p className="font-small font-weight-bold mb-0"><ins>Descripción</ins></p>
                        </div>
                        <div className="col-2 text-center">
                            <p className="font-small font-weight-bold mb-0"><ins>Precio Unitario</ins></p>
                        </div>
                        <div className="col-2 text-center">
                            <p className="font-small font-weight-bold mb-0"><ins>Cantidad</ins></p>
                        </div>
                        <div className="col-2 text-right">
                            <p className="font-small font-weight-bold mb-0"><ins>Total</ins></p>
                        </div>
                        <div className="col-2 text-right">
                            <p className="font-small font-weight-bold mb-0"><ins>Acciones</ins></p>
                        </div>
                    </div>
                    {
                        items.length > 0 ? (
                            items.map((item, i) => {
                                return (
                                    <div className="row mb-1" key={`products-${i}`}>
                                        <div className="col-2 text-center">
                                            <img src={item?.item?.imagenes && item?.item?.imagenes[0] ? `${linkApi}/imagenes/productos/${item.item.imagenes[0]}` : null} alt="..." className="w-75" />
                                        </div>
                                        <div className="col-2 py-3">
                                            <p className="font-small">{`${item.nombre_producto}, ${item.nombre_formato}`}, {item.store === null ? 'Despacho a Domicilio' : 'Retiro en Tienda'}</p>
                                        </div>
                                        <div className="col-2 py-3 text-center">
                                            <p className="font-small">{get_currency(item.precio)}</p>
                                        </div>
                                        <div className="col-2 py-3 text-center">
                                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                <button type="button" className="btn btn-outline-secondary px-2 py-1" onClick={() => { !inputs.loading && handleQuantity(i, -1) }} disabled={item.cantidad === 1 ? true : false}>-</button>
                                                <button type="button" className="btn btn-outline-secondary px-2 py-1">{item.cantidad}</button>
                                                <button type="button" className="btn btn-outline-secondary px-2 py-1" onClick={() => { !inputs.loading && handleQuantity(i, 1) }} disabled={item.cantidad >= item.item.cantidad_disponible ? true : false}>+</button>
                                            </div>
                                        </div>
                                        <div className="d-inline col-2 py-3  text-right">
                                            <p className="font-small">{get_currency(item.precio * item.cantidad)}</p>
                                        </div>
                                        <div className="d-inline col-2 py-3  text-right">
                                            <div className="btn-group" role="group" aria-label="Basic outlined example">
                                                <button type="button" className="btn p-0" onClick={() => !inputs.loading && this._showEdit(i)}>
                                                    <img src={AssetsImg.Edit} className="" alt="..." style={{ width: '15px', height: '15px' }} />
                                                </button>
                                                <button type="button" className="btn" onClick={() => !inputs.loading && this._removeItem(i)}>
                                                    <img src={AssetsImg.Remove} alt="..." style={{ width: 15 }} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        ) : (
                            <p className="font-small text-center my-4">No se han agregado productos al carrito.</p>
                        )
                    }

                    <div className="w-100 bg-light" style={{ height: 1 }} />
                    <div className="row mt-4">
                        <div className="col-10 d-flex justify-content-end">
                            <p className="font-weight-bold mb-0" style={{ fontSize: '.8em' }}>Valor</p>
                        </div>
                        <div className="col-2 d-flex justify-content-end">
                            <p className="font-weight-bold mb-0" style={{ fontSize: '.8em' }}>{HelperCoin.format(Math.round(cart.total_amount_payment))}</p>
                        </div>
                    </div>
                    {
                        cart.discount_code ? (
                            <>
                                <div className="row">
                                    <div className="col-10 d-flex justify-content-end">
                                        <p className="font-weight-bold mb-0 text-dark" style={{ fontSize: '.8em' }}>{`Descuento Cupón ${cart.total_discount_coupon}%`}</p>
                                    </div>
                                    <div className="col-2 d-flex justify-content-end">
                                        <p className="font-weight-bold mb-0 text-orange" style={{ fontSize: '.8em' }}>{HelperCoin.format(Math.round(cart.total_amount_payment * cart.total_discount_coupon / 100))}</p>
                                    </div>
                                </div>
                            </>
                        ) : null
                    }
                    <div className="row">
                        <div className="col-10 d-flex justify-content-end">
                            <p className="font-weight-bold mb-0" style={{ fontSize: '.8em' }}>Subtotal</p>
                        </div>
                        <div className="col-2 d-flex justify-content-end">
                            <p className="font-weight-bold mb-0" style={{ fontSize: '.8em' }}>{HelperCoin.format(Math.round(cart.total_amount_payment - (cart.total_amount_payment * (cart.total_discount_coupon / 100))))}</p>
                        </div>
                    </div>
                    <hr />
                    <div className="row  mb-3">
                        <div className="col-10 d-flex justify-content-end">
                            <p className="font-weight-bold mb-0 text-dark" style={{ fontSize: '.8em' }}>Total a pagar</p>
                        </div>
                        <div className="col-2 d-flex justify-content-end">
                            <p className="font-weight-bold mb-0 text-orange" style={{ fontSize: '.8em' }}>{HelperCoin.format(Math.round(cart.total_amount_payment - (cart.total_amount_payment * (cart.total_discount_coupon / 100))))}</p>
                        </div>
                    </div>
                </div>
                {
                    items.length > 0 && (
                        <>
                            <div className="bg-i-gray p-2 mb-1">
                                <DiscountForm allow={!inputs.loading} loading={inputs.loading} />
                            </div>
                            <div className="bg-i-gray p-2 mb-1 rounded">
                                <div className="pb-1">
                                    <img src={AssetsImg.Card_f} alt="..." style={{ width: '20px' }} />
                                    <p className="d-inline mb-0 ml-1">Método de pago</p>
                                </div>
                            </div>
                            <div className="bg-white p-2 border rounded">
                                <img src={AssetsImg.Webpay} alt="..." style={{ width: '70px' }} />
                                <p className="ml-2 d-inline font-small mb-0">Pago con tarjetas de crédito o Redcompra</p>
                            </div>
                            <div className="row mt-4">
                                <div className="offset-10 col-2  text-center justify-content-end ">
                                    {inputs.loading ? <Loader backdrop content="Procesando compra..." center style={{ zIndex: 4 }} /> :
                                        <Button appearance="default" className="col btn-one btn-sm text-white py-1 px-4 text-center" onClick={() => handlePayment()}>
                                            PAGAR
                                        </Button>}
                                </div>
                            </div>
                        </>
                    )
                }
                <ModalRsuite
                    open={modal}
                    handleModal={() => this.setState({ modal: false, initial: null })}
                    propsModal={{ size: 'lg', overflow: true }}>
                    <ProductOptions
                        item={items[edit] ? items[edit].item : {}}
                        initial={items[edit] ? { shipping: items[edit].shipping, region: items[edit].region, store: items[edit].store, commune: items[edit].commune, format: items[edit].id_formato } : initial}
                        shipping={shipping}
                        action={this._updateItemOnCart}
                        title={'ACTUALIZAR'}
                        params={null}
                        edit
                    />
                </ModalRsuite>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    cart: state.cart,
})

export default connect(mapStateToProps)(Delivery);
